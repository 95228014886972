import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as weightCodeActions from "./weight-code.actions";
import { CriteriaService } from '../../../../../api/general-admin-services/criteria.service';
import { weightCodePath } from '../../../../../api/api-data/api.constants';

@Injectable()
export class WeightCodeEffects {
  constructor(private actions$: Actions,
              private criteriaService: CriteriaService) {
  }

  getWeightCodeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(weightCodeActions.getWeightCodeData),
      switchMap(() =>
        this.criteriaService.getExpandedCriteriaData(weightCodePath).pipe(
          map(response => weightCodeActions.getWeightCodeDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(weightCodeActions.getWeightCodeDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createWeightCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(weightCodeActions.addWeightCodeElement),
      switchMap(action =>
        this.criteriaService.addExpandedCriteriaElement(weightCodePath, action.payload).pipe(
          map(response => weightCodeActions.addWeightCodeElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(weightCodeActions.addWeightCodeElementFailure({payload: err})))
        )
      )
    )
  )

  updateWeightCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(weightCodeActions.updateWeightCodeElement),
      switchMap(action =>
        this.criteriaService.updateExpandedCriteriaElement(action.payload, action.id).pipe(
          map(response => weightCodeActions.updateWeightCodeElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(weightCodeActions.updateWeightCodeElementFailure({payload: err})))
        )
      )
    )
  )

  deleteWeightCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(weightCodeActions.deleteWeightCodeElement),
      switchMap(action =>
        this.criteriaService.deleteCriteria(action.payload).pipe(
          map(() => weightCodeActions.deleteWeightCodeElementSuccess()),
          catchError((err: HttpErrorResponse) => of(weightCodeActions.deleteWeightCodeElementFailure({payload: err})))
        )
      )
    )
  )
}
