import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SpeedCodeElement, SpeedCodeState } from "./speed-code.model";
import { HttpErrorResponse } from '@angular/common/http';

export const getSpeedCodeState = createFeatureSelector<SpeedCodeState>('speedCode');

export const getSpeedCodeTable = createSelector(getSpeedCodeState,
  (state: SpeedCodeState):SpeedCodeElement[] => state.content);

export const getSpeedCodeSuccessLoader = createSelector(getSpeedCodeState,
  (state: SpeedCodeState): boolean => state.isLoadingSuccess);

export const getSpeedCodeLoader = createSelector(getSpeedCodeState,
  (state: SpeedCodeState): boolean => state.isLoading);

export const getSpeedCodeError= createSelector(getSpeedCodeState,
  (state: SpeedCodeState): HttpErrorResponse => state.error);
