import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EModalType, ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from '../../../api/language.service';
import { TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { Observable } from 'rxjs';
import { BannersTableMeta } from '../pages.constants';
import { EBannerType, EBannerTypeId, getBannersList, IBanner } from '../../../modules/state/general-admin/banners';
import { BannersAddDialogComponent } from './banners-add-dialog/banners-add-dialog.component';
import {
  getBannersLoader,
  getDesktopBannersTable,
  getMobileBannersTable
} from '../../../modules/state/general-admin/banners/banners.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

  public tableMeta: TableMeta[] = BannersTableMeta;
  public modalType: EModalType = EModalType.add;
  public desktopTableContent$: Observable<IBanner[]>;
  public mobileTableContent$: Observable<IBanner[]>;
  public isTableLoad$: Observable<boolean>;
  public typeId: number = 1;
  public EBannerTypeId = EBannerTypeId;
  public EBannerType = EBannerType;

  constructor(private dialog: MatDialog,
              private store$: Store,
              private router: Router,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.typeId = +this.router.url.split('/')[4];
    this.store$.dispatch(getBannersList({typeId: this.typeId}));
    this.mobileTableContent$ = this.store$.select(getMobileBannersTable);
    this.desktopTableContent$ = this.store$.select(getDesktopBannersTable);
    this.isTableLoad$ = this.store$.select(getBannersLoader);
  }

  dispatchEvent(event: ITableData<IBanner>, bannerType: string): void {
    this.dialog.open(BannersAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...event.data, typeId: this.typeId, bannerType}
    })
  }
}
