import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { IParameter } from '../../../state/general-admin/tire-reseller';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultipleSelectComponent),
    multi: true
  },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultipleSelectComponent),
      multi: true,
    }]
})
export class MultipleSelectComponent implements ControlValueAccessor, Validator {

  @Input() placeholder: string;
  @Input() label: string;
  @Input() options: IParameter[];
  @Input() needCancelAll: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() allowAllSelection: boolean = false;
  @Input() needStringValue: boolean = false;
  @Output() selectedValue: EventEmitter<{ ids: number[], status: boolean }> = new EventEmitter<{ ids: number[], status: boolean }>();
  public value: number[];
  public onChange: (p: any) => void = () => {};
  public onTouch: () => void = () => {};
  public control: AbstractControl | null = null;
  public allSelected: boolean = false;
  @ViewChild('select') select: MatSelect;
  public cancelAll: boolean = false;

  valueChange(event: MatSelectChange): void {
    this.value = event.value;
    this.onTouch();
    this.onChange(event.value);
    this.selectedValue.emit({status: true, ids: this.value});
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control) return null;
    this.control = control;
    return control.value ? null : {...control.errors};
  }

  toggleAllSelection(): void {
    this.allSelected ? this.select.options.forEach((item: MatOption) => item.select())
      : this.select.options.forEach((item: MatOption) => item.deselect());
  }

  selectValue(): void {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => newStatus = !item.selected && this.value.length === this.options.length);
    this.allSelected = newStatus;
  }

  sortOptions(options: IParameter[]): IParameter[] {
    if (!options?.length) return [];
    let list = [...options];
    const stringValue = list.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? 1 : -1)
    const integerValue = list.filter(x => !isNaN(+x.value)).sort((a, b) => +a.value - +b.value);
    return [...integerValue, ...stringValue];
  }

  cancelAllSelection(): void {
    this.select.options.forEach((item: MatOption) => item.deselect());
    this.cancelAll = false;
    this.value = [];
  }

  checkOption(id: string | number): string | number {
    return isNaN(+id) || this.needStringValue ? id : +id;
  }
}
