import { HttpErrorResponse } from "@angular/common/http";
import { Action, createReducer, on } from "@ngrx/store";

import * as LeadsStatusActions from './leads-status.actions';
import { ILeadsStatusState, ILeadStatus, ILeadStatusDB } from './leads-status.model';

export const initialState: ILeadsStatusState = {
  current: {} as ILeadStatus,
  deleteId: null,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  content: [],
  error: {} as HttpErrorResponse,
};

const leadsStatusReducer = createReducer(
  initialState,
  on(LeadsStatusActions.getLeadsStatusList, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsStatusActions.getLeadsStatusListSuccess, (state, {payload}) => ({
    ...state,
    content: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsStatusActions.getLeadsStatusListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true
  })),
  on(LeadsStatusActions.addLeadStatus, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsStatusActions.addLeadStatusSuccess, (state, {payload}) => {
    return {
      ...state,
      content: [...state.content, payload],
      current: {} as ILeadStatus,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(LeadsStatusActions.addLeadStatusFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
  on(LeadsStatusActions.updateLeadStatus, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsStatusActions.updateLeadStatusSuccess, (state, {payload}) => {
    const speedCodeData = [...state.content];
    const newData = speedCodeData.map((mnf: ILeadStatusDB) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    return {
      ...state,
      content: newData,
      current: {} as ILeadStatus,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(LeadsStatusActions.updateLeadStatusFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
  on(LeadsStatusActions.deleteLeadStatus, (state, {id}) => ({
    ...state,
    deleteId: id,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsStatusActions.deleteLeadStatusSuccess, (state) => {
    const speedCodeData = [...state.content];
    const newData = speedCodeData.filter((mnf: ILeadStatusDB) => mnf.id !== state.deleteId);
    return {
      ...state,
      content: newData,
      deleteId: null,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(LeadsStatusActions.deleteLeadStatusFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
);

export function LeadsStatusReducer(state: ILeadsStatusState | undefined, action: Action): any {
  return leadsStatusReducer(state, action);
}



