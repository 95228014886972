<div class="content manage-car-tires">
  <div class="content-head">
    <div class="head-content">
      <div class="head-right">
        <h3 class="head-title">
          {{'MANAGE_CAR_TIRES' | translate}}
        </h3>
        <div class="search-wrap">
          <mat-form-field class="form-element search" appearance="fill">
            <input [formControl]="searchControl" placeholder="{{'SEARCH' | translate}}" matInput type="text" autocomplete="off">
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="actions" *ngIf="this.route === 'manage'">
        <a (click)="changePrices()">{{'CHANGE_PRICES' | translate}}</a>
        <button (click)="export()" class="btn-admin btn-white export-btn">
          <mat-icon class="icon-img" svgIcon="upload"></mat-icon>
          {{'EXPORT' | translate}}
        </button>
        <button class="btn-admin" (click)="openAddDialog()">
          <mat-icon>add</mat-icon>
          {{'ADD_TIRE' | translate}}
        </button>
      </div>
    </div>
    <div class="head-filters">
      <app-filter-selects (filtersChange)="getHeadFilters($event)" [filters]="(carTiresList$ | async)?.filters"></app-filter-selects>
    </div>
  </div>
  <div class="content blog_table" *ngIf="(carTiresList$ | async) as tiresList">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="tiresList.content"
      [language]="languageService.mainLanguage.value"
      [filterData]="tiresList.filters"
      [isLoadingTable]="!(isTableLoad$ | async)"
      (onClick)="dispatchEvent($event)"
      (onCheck)="changeVisibility($event)"
      (filters)="getFilters($event)"
      (sorts)="sortData($event)"
      (editablePrice)="getNewBruttoPrice($event, tiresList.content)"
    >
    </starter-table>
    <div *ngIf="tiresList.content && !tiresList.content.length" class="no-result-table">
      {{'NO_DATA_MATCHING_THE_FILTER' | translate}}
    </div>
    <div class="pagination" *ngIf="tiresList.content">
          <span class="pagination-item"
                *ngFor="let page of tiresList.content | paginate:
                { itemsPerPage: tiresList.pageSize,
                 currentPage: tiresList.currentPage,
                 totalItems: tiresList.rowCount }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>
