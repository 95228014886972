import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRimsState } from './rims.model';

export const getRimsState = createFeatureSelector<IRimsState>('rims');

export const getRimsTableData = createSelector(getRimsState,
  (state: IRimsState) => state.response);

export const getRimsSuccessLoader = createSelector(getRimsState,
  (state: IRimsState) => state.isLoadingSuccess);

export const getRimsLoader = createSelector(getRimsState,
  (state: IRimsState) => state.isLoading);

export const getRimsError = createSelector(getRimsState,
  (state: IRimsState) => state.error);
