import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IOrdersState } from './orders.model';

export const getOrdersState = createFeatureSelector<IOrdersState>('orders');

export const selectOrdersList = createSelector(getOrdersState,
  (state: IOrdersState) => state.orders);
export const selectOrdersLoader = createSelector(getOrdersState,
  (state: IOrdersState) => state.isLoading);
export const selectOrdersSuccessLoader = createSelector(getOrdersState,
  (state: IOrdersState) => state.isLoadingSuccess);
export const selectOrdersError = createSelector(getOrdersState,
  (state: IOrdersState) => state.error);
export const selectOrdersStatistic = createSelector(getOrdersState,
  (state: IOrdersState) => state.statistic);

export const selectPayedOrderSuccess = createSelector(getOrdersState,
  (state: IOrdersState) => state.payedSuccess);
export const selectPayedMessage = createSelector(getOrdersState,
  (state: IOrdersState) => state.chargeMessage);
export const selectOrderPaymentInfo = createSelector(getOrdersState,
  (state: IOrdersState) => state.currentOrderInfo);
