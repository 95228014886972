import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StatisticCard, StatisticCardDataItemTypes} from "../../interfaces/statistic-card.interface";


@Component({
  selector: 'statistic-card',
  templateUrl: './statistic-card.component.html',
  styleUrls: ['./statistic-card.component.scss']
})
export class StatisticCardComponent implements OnInit {

  types = StatisticCardDataItemTypes;

  @Input() card: StatisticCard = {
    title: '',
    data: []
  };

  @Output() textClick: EventEmitter<StatisticCard> = new EventEmitter<StatisticCard>()

  constructor() { }

  ngOnInit(): void {
  }

  textClickHandler(): void {
    this.textClick.emit(this.card)
  }

}
