<div class="image-uploader form-group row">
  <div class="col-6 form-col">
    <label class="label">
      <ng-content></ng-content>
    </label>

    <div class="image-uploader-requirements">
      <ng-content select="[requirements]"></ng-content>
    </div>
    <button class="image-uploader-button"
            (click)="fileUpload.click()"
            *ngIf="!uploadedFile && !moreThenThreeFiles || !isImageSizeCorrect || !isImageDimensionsCorrect; else removeImageButton">
      <mat-icon>upload</mat-icon>
      <bdi>{{'BROWSE' | translate}}...</bdi>
      <input #fileUpload type="file" [accept]="fileAccepting" hidden [multiple]="isMultiple"
             (change)="uploadImageFile($event)">
    </button>
    <div class="text-error" *ngIf="!isImageDimensionsCorrect">{{'CHOOSE_AN_IMAGE_WITH_THE_CORRECT_DIMENSIONS' | translate}}</div>
    <div class="text-error" *ngIf="!isImageSizeCorrect">{{'THE_IMAGE_SIZE_IS_TOO_LARGE' | translate}}</div>
    <ng-template #removeImageButton>
      <button *ngIf="showRemoveButton && loaderType===EImgLoaderType.one && isImageSizeCorrect" class="image-uploader-button"
              (click)="removeImage()">
        {{'REMOVE_IMAGE' | translate}}
      </button>
      <div class="image-remove-block" *ngIf="showRemoveButton && loaderType===EImgLoaderType.imgWithFileName">
        <div class="file-name">{{uploadedFile.name}}</div>
        <mat-icon class="close" (click)="removeImage()">close</mat-icon>
      </div>
    </ng-template>
  </div>

  <ng-container [ngSwitch]="loaderType">
    <ng-container *ngSwitchCase="EImgLoaderType.one" [ngTemplateOutlet]="oneImg"></ng-container>
    <ng-container *ngSwitchCase="EImgLoaderType.multiple" [ngTemplateOutlet]="multiple"></ng-container>
    <ng-container *ngSwitchCase="EImgLoaderType.oneWithIcon" [ngTemplateOutlet]="oneWithIcon"></ng-container>
    <ng-container *ngSwitchCase="EImgLoaderType.imgWithFileName" [ngTemplateOutlet]="imgWithFileName"></ng-container>
  </ng-container>

  <ng-template #oneImg>
    <div class="col-6 form-col">
      <img class="image-preview" [src]="imagePreview" [alt]="imagePreview && 'image-preview'" loading="lazy">
    </div>
  </ng-template>

  <ng-template #oneWithIcon>
    <div class="multiple-image" *ngIf="uploadedFile || imgSrc">
      <div class="image-wrap">
        <mat-icon class="close" *ngIf="uploadedFile && isImageDimensionsCorrect" (click)="removeImage()">close</mat-icon>
        <img class="image-preview" [src]="imagePreview" [alt]="imagePreview && 'image-preview'" loading="lazy">
      </div>
    </div>
  </ng-template>

  <ng-template #multiple>
    <div class="multiple-image">
      <div class="image-wrap" *ngFor="let img of loadedImages; index as i">
        <mat-icon class="close" (click)="removeImg(i)">close</mat-icon>
        <img class="image-preview" [src]="img" [alt]="imagePreview && 'image-preview'" loading="lazy">
      </div>
      <div class="image-wrap" *ngFor="let img of previousPictures">
        <mat-icon class="close" (click)="removePrevious(img.id)">close</mat-icon>
        <img class="image-preview" [src]="img.pictureUrl" [alt]="imagePreview && 'image-preview'" loading="lazy">
      </div>
    </div>
  </ng-template>

  <ng-template #imgWithFileName>
    <div class="col-6 form-col">
      <img class="image-preview" [src]="imagePreview" [alt]="imagePreview && 'image-preview'" loading="lazy">
    </div>
  </ng-template>
</div>
