import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Observable, Subject } from 'rxjs';

import { TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { SpeedCodeAddDialogComponent } from "./speed-code-add-dialog/speed-code-add-dialog.component";
import { getSpeedCodeData, SpeedCodeElement } from "../../../../../modules/state/general-admin/criteria/speed-code";
import {
  getSpeedCodeLoader,
  getSpeedCodeTable
} from "../../../../../modules/state/general-admin/criteria/speed-code/speed-code.selectors";
import { speedCodeTableMeta } from '../criteria.constants';
import { EModalType, ICriteriaItem, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import { LanguageService } from '../../../../../api/language.service';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-speed-code',
  templateUrl: './speed-code.component.html',
  styleUrls: ['./speed-code.component.scss']
})
export class SpeedCodeComponent implements OnInit, OnDestroy {
  public tableContent$: Observable<SpeedCodeElement[]>;
  public tableMeta: TableMeta[] = speedCodeTableMeta;
  public modalType: EModalType = EModalType.add;
  public isTableLoad$: Observable<boolean>;
  public sorts: string = '';
  public searchControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();
  public searchQuery: string = '';

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  public openAddDialog(): void {
    this.dialog.open(SpeedCodeAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {type: EModalType.add}
    })
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.store$.dispatch(getSpeedCodeData());
    this.tableContent$ = this.store$.select(getSpeedCodeTable);
    this.isTableLoad$ = this.store$.select(getSpeedCodeLoader);
  }

  dispatchEvent(table: ITableData<ICriteriaItem>): void {
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete
    }
    this.changeCriteria(table.data);
  }

  changeCriteria(item: ICriteriaItem): void {
    this.dialog.open(SpeedCodeAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, type: this.modalType}
    })
  }

  getSortValue(sort: string) {
    this.sorts = sort;
  }

  sortContent(content: SpeedCodeElement[]): SpeedCodeElement[] {
    let list = [...content];
    if (!list.length) return [];
    switch (this.sorts) {
      case ('value'):
        const stringValue = list.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? 1 : -1)
        const integerValue = list.filter(x => !isNaN(+x.value)).sort((a, b) => +a.value - +b.value);
        list = [...integerValue, ...stringValue];
        break;
      case ('-value'):
        const strings = list.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? -1 : 1)
        const integers = list.filter(x => !isNaN(+x.value)).sort((a, b) => +b.value - +a.value);
        list = [...integers, ...strings];
        break;
      default:
        list = [...content];
    }
    list = list.filter(item => item.value.toLowerCase().includes(this.searchQuery.toLowerCase()));
    return list;
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchQuery = value;
      })
  }
}
