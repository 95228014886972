import { HttpErrorResponse } from "@angular/common/http";

export interface WidthElement {
  id: number,
  value: string,
  isActive: boolean,
  priority: string,
  additionalValue: string
}

export interface WidthState {
  current: WidthElement;
  deleteId: number | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  content: WidthElement[];
  error: HttpErrorResponse;
}
