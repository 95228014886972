import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { LeadStatusTableMeta } from '../criteria.constants';
import { EModalType, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import { LanguageService } from '../../../../../api/language.service';
import { getLeadsStatusList, ILeadStatusDB } from '../../../../../modules/state/general-admin/criteria/leads-status';
import {
  getLeadStatusLoader,
  getLeadStatusTable
} from '../../../../../modules/state/general-admin/criteria/leads-status/leads-status.selectors';
import { LeadsStatusDialogComponent } from './leads-status-dialog/leads-status-dialog.component';
import { sortContentByName } from '../../../../../utils/helpers';

@Component({
  selector: 'app-leads-status',
  templateUrl: './leads-status.component.html',
  styleUrls: ['./leads-status.component.scss']
})
export class LeadsStatusComponent implements OnInit {
  public modalType: EModalType = EModalType.add;
  public tableContent$: Observable<ILeadStatusDB[]>;
  public tableMeta: TableMeta[] = LeadStatusTableMeta;
  public isTableLoad$: Observable<boolean>;
  public sorts: string = '';
  public sortContent: Function = sortContentByName;

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  public openAddDialog(): void {
    this.dialog.open(LeadsStatusDialogComponent, {
      panelClass: 'admin-dialog',
      data: {modalType: EModalType.add}
    })
  }

  ngOnInit(): void {
    this.store$.dispatch(getLeadsStatusList());
    this.tableContent$ = this.store$.select(getLeadStatusTable);
    this.isTableLoad$ = this.store$.select(getLeadStatusLoader);
  }

  actionDispatch(table: ITableData<ILeadStatusDB>): void {
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete
    }
    this.changeCriteria(table.data);
  }

  changeCriteria(item: ILeadStatusDB): void {
    this.dialog.open(LeadsStatusDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, modalType: this.modalType}
    })
  }

  getSortValue(sort: string): void {
    this.sorts = sort;
  }
}
