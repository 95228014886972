import { Component, OnDestroy, OnInit } from '@angular/core';
import { IProfitValue, TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { ExpectedProfitTableMeta } from '../../business-user.constants';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  getExpectedProfit,
  IExpectedProfit,
  ISetProfit,
  setExpectedProfit,
  setMultipleExpectedProfit
} from '../../../modules/state/business-user/article-price';
import {
  getTireResellerById,
  INumberParam,
  TireResellerFull
} from '../../../modules/state/general-admin/tire-reseller';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { LanguageService } from '../../../api/language.service';
import {
  getResellerLoader,
  getTireReseller
} from '../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import {
  selectProfitError,
  selectProfitList,
  selectProfitListLoader,
  selectProfitListSuccessLoader,
  selectSettingValue
} from '../../../modules/state/business-user/article-price/article-price.selectors';
import { ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { takeUntil } from 'rxjs/operators';
import { isEmptyObj } from '../../../utils/helpers';
import { EModalMessage } from '../../../t4u-admin/pages/criteria/components/criteria.constants';
import { NotificationService } from '../../../api/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getLookupParameters, ILookupParameters } from '../../../modules/state/general-admin/characteristic';
import {
  selectCharacteristicLoader,
  selectLookupParams
} from '../../../modules/state/general-admin/characteristic/characteristic.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expected-profit',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit, OnDestroy {

  public expectedProfitTableMeta: TableMeta[] = ExpectedProfitTableMeta;
  public expectedProfit$: Observable<IExpectedProfit[]>;
  public reseller$: Observable<TireResellerFull>;
  public isResellerBrandsLoad$: Observable<boolean>;
  public isTableLoad$: Observable<boolean>;
  public firstStep: boolean = true;
  public profit: IProfitValue;
  public destroy$: Subject<void> = new Subject<void>();
  public brandId: number;
  public profitForm: FormGroup;
  public params$: Observable<ILookupParameters>;
  public paramsLoader$: Observable<boolean>;
  public userId: string;

  constructor(private store$: Store,
              private authService: AuthService,
              private alertService: NotificationService,
              private fb: FormBuilder,
              private router: Router,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.userId = this.authService.reseller?.tireResellerId;
    if(!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    this.profitForm = this.fb.group({
      brandIds: ['', Validators.required],
      characteristicOptionIds: ['', Validators.required],
      profit: ['', Validators.required],
      tireResellerId: [this.userId]
    })
  }

  getData(): void {
    if(!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    this.store$.dispatch(getTireResellerById({payload: this.userId}));
    this.store$.dispatch(getLookupParameters({payload: 'TireDiameter'}));
    this.isResellerBrandsLoad$ = this.store$.select(getResellerLoader);
    this.reseller$ = this.store$.select(getTireReseller);
    this.isTableLoad$ = this.store$.select(selectProfitListLoader);
    this.expectedProfit$ = this.store$.select(selectProfitList);
    this.params$ = this.store$.select(selectLookupParams);
    this.paramsLoader$ = this.store$.select(selectCharacteristicLoader);
  }

  getSelectedBrand(checkedIds: number[]): void {
    if (!checkedIds.length) {
      this.firstStep = true;
      return;
    }
    this.brandId = checkedIds[0];
    const params = {brandId: checkedIds[0], tireResellerId: this.userId};
    this.store$.dispatch(getExpectedProfit({params}));
    this.firstStep = false;
  }

  getProfitValue(value: IProfitValue): void {
    this.profit = value;
  }

  actionDispatch(event: ITableData<IExpectedProfit>): void {
    if (event.userAction === 'saveInput') {
      if (!this.profit) return;
      const profit: ISetProfit = {
        tireResellerId: this.userId,
        brandId: this.brandId,
        profit: this.profit?.profit,
        characteristicOptionId: this.profit?.characteristicOptionId
      };
      this.store$.dispatch(setExpectedProfit({profit}));
      this.checkRequest(EModalMessage.setProfit);
    }
  }

  checkRequest(message: string, needReload?: boolean): void {
    combineLatest(
      this.store$.select(selectProfitError),
      this.store$.select(selectProfitListSuccessLoader),
      this.store$.select(selectSettingValue))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader, setting]) => {
          if (loader && isEmptyObj(error) && setting) {
            this.alertService.onSuccess(message);
            if (needReload) this.loadContent();
          }
          if (!loader && !isEmptyObj(error) && setting) {
            const failures = error.error.failures;
            const errors = [];
            for (const key in failures) {
              errors.push(failures[key][0])
              this.alertService.onError(errors);
            }
          }
        }
      })
  }

  loadContent(): void {
    if (!this.brandId) return;
    const params = {brandId: this.brandId, tireResellerId: this.userId};
    this.store$.dispatch(getExpectedProfit({params}));
  }

  sortContent(content: IExpectedProfit[]): IExpectedProfit[] {
    let list = [...content];
    if (!list.length) return [];
    const stringValue = list.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? 1 : -1)
    const integerValue = list.filter(x => !isNaN(+x.value)).sort((a, b) => +a.value - +b.value);
    return [...integerValue, ...stringValue];
  }

  setMultipleProfit(): void {
    const profit = {...this.profitForm.value, profit: +this.profitForm.value.profit};
    this.store$.dispatch(setMultipleExpectedProfit({profit}));
    this.checkRequest(EModalMessage.setProfit, true);
  }

  filterBrands(brands: INumberParam[]): INumberParam[] {
    if(!brands) return [];
    return brands.filter(brand => !brand.isPrivate);
  }
}
