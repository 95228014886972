import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IAdminNotification, NotificationData } from "../../modules/state/general-admin/admin-notifications";

@Injectable({
  providedIn: "root",
})
export default class AdminNotificationsApiService {
  readonly PATH = `/notification`;

  constructor(private apiService: ApiService) {
  }

  addNotification(payload: NotificationData): Observable<any> {
    return this.apiService.post(`${this.PATH}`, payload);
  }

  getNotificationList(): Observable<IAdminNotification[]> {
    return this.apiService.get(`${this.PATH}`);
  }

  dismissNotification(id: number): Observable<any> {
    return this.apiService.put(`${this.PATH}/${id}/dismiss`);
  }
}
