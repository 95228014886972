import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  getArticleBrandList,
  IBrandArticlePrice,
  IBrandListParams,
  ISetDiscount,
  setBrandDiscount
} from '../../../modules/state/business-user/article-price';
import {
  selectBrandListError,
  selectBrandListForDiscount,
  selectBrandListLoader,
  selectBrandListSuccessLoader,
  selectSettingValue
} from '../../../modules/state/business-user/article-price/article-price.selectors';
import { IProfitValue, TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { ArticleBrandTableMeta } from '../../business-user.constants';
import { combineLatest, Observable, Subject } from 'rxjs';
import { LanguageService } from '../../../api/language.service';
import { ITableCheckData, ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { IParameter } from '../../../modules/state/general-admin/tire-reseller';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { getResellerLoader } from '../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import { takeUntil } from 'rxjs/operators';
import { isEmptyObj } from '../../../utils/helpers';
import { NotificationService } from '../../../api/notification.service';
import { EModalMessage } from '../../../t4u-admin/pages/criteria/components/criteria.constants';
import { Router } from '@angular/router';
import { getLookupParameters } from '../../../modules/state/general-admin/characteristic';
import { selectLookupParams } from '../../../modules/state/general-admin/characteristic/characteristic.selectors';

@Component({
  selector: 'app-article-price',
  templateUrl: './manage-prices.component.html',
  styleUrls: ['./manage-prices.component.scss']
})
export class ManagePricesComponent implements OnInit, OnDestroy {

  public tableMeta: TableMeta[] = ArticleBrandTableMeta;
  public brandsDiscountContent$: Observable<IBrandArticlePrice[]>;
  public isResellerBrandsLoad$: Observable<boolean>;
  public isBrandListLoad$: Observable<boolean>;
  public brandsListLoad: boolean = false;
  public discountValue: IProfitValue;
  public destroy$: Subject<void> = new Subject<void>();
  public brandsIds: number[] = [];
  public brandList: IParameter[];
  public userId?: string;

  constructor(private store$: Store,
              private authService: AuthService,
              private alertService: NotificationService,
              public languageService: LanguageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.userId = this.authService.reseller.tireResellerId;
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    // this.store$.dispatch(getTireResellerById({payload: this.userId}));
    this.store$.dispatch(getLookupParameters({
      payload: 'TireManufacturer',
      isDisplayPrivateBrand: true
    }));
    this.isResellerBrandsLoad$ = this.store$.select(getResellerLoader);
    this.isBrandListLoad$ = this.store$.select(selectBrandListLoader);
    this.brandsDiscountContent$ = this.store$.select(selectBrandListForDiscount);
    this.store$.select(selectLookupParams).subscribe({
      next: (list) => {
        if (!list.TireManufacturer?.length) return;
        const brandsIds: number[] = [];
        list.TireManufacturer.forEach(item => brandsIds.push(+item.id));
        this.getSelectedBrand(brandsIds);
      }
    })
    // this.store$.select(getTireReseller).pipe(takeUntil(this.destroy$))
    //   .subscribe({
    //     next: reseller => {
    //       if(!reseller) return;
    //       const activeBrands = reseller.brands?.filter(brand => !brand.isPrivate);
    //       this.brandsIds = this.getCheckedIds(activeBrands);
    //       this.brandList = activeBrands;
    //       this.getSelectedBrand(this.brandsIds);
    //     }
    //   })
  }

  getSelectedBrand(brandIds: number[]): void {
    if (!brandIds.length || !this.userId) {
      this.brandsListLoad = false;
      return;
    }
    const payload: IBrandListParams = {tireResellerId: this.userId, brandIds};
    this.store$.dispatch(getArticleBrandList({payload}));
    this.brandsListLoad = true;
  }

  actionDispatch(table: ITableData<IBrandArticlePrice>): void {
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    if (table.userAction === 'saveInput') {
      if (!this.discountValue) return;
      let discount: ISetDiscount = {
        tireResellerId: this.userId,
        brandId: this.discountValue.characteristicOptionId,
        isActive: true,
        discount: this.discountValue.profit
      };
      // if (this.discountValue.profit === 0) discount = {...discount, isActive: false};
      this.store$.dispatch(setBrandDiscount({discount}));
      this.checkRequest(EModalMessage.setDiscount)
    }
  }

  getDiscountValue(value: IProfitValue): void {
    this.discountValue = value;
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(selectBrandListError),
      this.store$.select(selectBrandListSuccessLoader),
      this.store$.select(selectSettingValue))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader, setting]) => {
          if (loader && isEmptyObj(error) && setting) {
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error) && setting) {
            const failures = error.error.failures;
            const errors = [];
            for (const key in failures) {
              errors.push(failures[key][0])
              this.alertService.onError(errors);
            }
            this.alertService.onError(error.message);
          }
        }
      })
  }

  changeStatus(table: ITableCheckData<IBrandArticlePrice>): void {
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    let discount: ISetDiscount = {
      tireResellerId: this.userId,
      brandId: table.target.brandId,
      isActive: table.checked,
      discount: table.target.discount
    };
    if (table.checked === table.target.isActive) return;
    this.store$.dispatch(setBrandDiscount({discount}));
    this.checkRequest(EModalMessage.statusChanged)
  }

  sortContent(brandsList: IBrandArticlePrice[]): IBrandArticlePrice[] {
    let list = [...brandsList];
    if (!list.length) return [];
    list = list.sort((a, b) => a.brand > b.brand ? 1 : -1)
    return list;
  }

  getCheckedIds(params: IParameter[]): number[] {
    const checked: number[] = [];
    if (!params) return [];
    let list = [...params];
    list.forEach(item => checked.push(+item.id));
    this.brandsIds = checked;
    return checked;
  }
}
