import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { IApiCriteria, IApiCriteriaExpanded } from '../api-data/api.interfaces';
import { criteriaEndpoint, defaultCriteriaApi } from '../api-data/api.constants';

@Injectable({
  providedIn: 'root'
})
export class CriteriaService {

  constructor(private apiService: ApiService) { }

  getCriteriaData(path: string): Observable<IApiCriteria[]> {
    return this.apiService.get(path);
  }

  getExpandedCriteriaData(path: string): Observable<IApiCriteriaExpanded[]> {
    return this.apiService.get(path);
  }

  addCriteriaElement(path: string, element: IApiCriteria): Observable<IApiCriteria> {
    return this.apiService.post(path, element);
  }

  addExpandedCriteriaElement(path: string, element: IApiCriteria): Observable<IApiCriteriaExpanded> {
    return this.apiService.post(path, element);
  }

  updateCriteriaElement(element: IApiCriteria, id: number): Observable<IApiCriteria> {
    return this.apiService.put(defaultCriteriaApi + id, element);
  }

  updateExpandedCriteriaElement(element: IApiCriteria, id: number): Observable<IApiCriteriaExpanded> {
    return this.apiService.put(defaultCriteriaApi + id, element);
  }

  deleteCriteria(id: number): Observable<any> {
    return this.apiService.deleteNumber(criteriaEndpoint, id);
  }
}
