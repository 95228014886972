import { createFeatureSelector, createSelector } from "@ngrx/store";
import { HttpErrorResponse } from '@angular/common/http';
import { WidthElement, WidthState } from './width.model';

export const getWidthState = createFeatureSelector<WidthState>('width');

export const getWidthTable = createSelector(getWidthState,
  (state: WidthState): WidthElement[] => state.content);

export const getWidthSuccessLoader = createSelector(getWidthState,
  (state: WidthState): boolean => state.isLoadingSuccess);

export const getWidthLoader = createSelector(getWidthState,
  (state: WidthState): boolean => state.isLoading);

export const getWidthError = createSelector(getWidthState,
  (state: WidthState): HttpErrorResponse => state.error);
