import { HttpErrorResponse } from "@angular/common/http";

export interface TireTypeElement {
  id: number,
  name: string,
  parentTireTypeId: number | null,
  isActive: boolean,
  priority: string,
  vehicleType?: string,
  type?: string
}

export type TireTypeTable = {
  carTireType: TireTypeElement[],
  motorcycleTireType: TireTypeElement[],
};

export interface TireTypeState {
  current: TireTypeElement,
  deleteId: number,
  result: any,
  isLoading: boolean,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  content: TireTypeTable,
  error: HttpErrorResponse,
}
