import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  addBlogElement,
  BlogDBElement,
  deleteBlogElement,
  updateBlogElement
} from '../../../../modules/state/general-admin/blog';
import { EModalType } from '../../../../modules/shared/interfaces/table.interfaces';
import { EBlogInputPlaceholder, EBlogInputTitle, EBlogMainLabel, EditorConfig } from '../blog.enums';
import { ImageModeEnum } from '../../../../modules/shared/enums/imageMode.enums';
import { IOption } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { EModalMessage } from '../../criteria/components/criteria.constants';
import { isEmptyObj } from '../../../../utils/helpers';
import { getBlogError, getBlogSuccessLoader } from '../../../../modules/state/general-admin/blog/blog.selectors';
import { NotificationService } from '../../../../api/notification.service';
import { IImageSize } from '../../../../modules/shared/enums/imageLoader.enums';

@Component({
  selector: 'app-blog-add-dialog',
  templateUrl: './blog-add-dialog.component.html',
  styleUrls: ['./blog-add-dialog.component.scss']
})
export class BlogAddDialogComponent implements OnInit, OnDestroy {
  public blogForm: FormGroup;
  public EModalType = EModalType;
  public EBlogInputTitle = EBlogInputTitle;
  public EBlogInputPlaceholder = EBlogInputPlaceholder;
  public editorConfig: AngularEditorConfig = EditorConfig;
  public imageMode = ImageModeEnum.None;
  public newsTypeOptions: IOption[] = [{id: 0, value: 'News'}, {id: 1, value: 'Article'}];
  public mainTitle: string = EBlogMainLabel.add;
  public destroy$: Subject<void> = new Subject<void>();
  public loader$: Observable<boolean>;
  public imageSize: IImageSize = {width: 5000, height: 5000};

  constructor(private dialog: MatDialog,
              private store$: Store,
              private fb: FormBuilder,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: BlogDBElement) {
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  updateImage(image: File): void {
    this.blogForm.controls['PreviewPicture.File']?.setValue(image);
    this.blogForm.controls['PreviewPicture.Mode']?.setValue(ImageModeEnum.Replace);
  }

  ngOnInit(): void {
    this.initForm();
    this.loader$ = this.store$.select(getBlogSuccessLoader);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    if (this.data.modalType === EModalType.edit) {
      this.blogForm = this.fb.group({
        Description: [this.data.description || ''],
        Title: [this.data.title, [Validators.required]],
        Auditor: [this.data.auditor, [Validators.required]],
        NewsType: [this.data.type?.id, [Validators.required]],
        "PreviewPicture.File": [null],
        "PreviewPicture.Mode": [this.imageMode]
      })
      this.mainTitle = EBlogMainLabel.edit;
      return;
    }
    this.blogForm = this.fb.group({
      Description: [''],
      Title: ['', [Validators.required]],
      Auditor: ['', [Validators.required]],
      NewsType: ['', [Validators.required]],
      "PreviewPicture.File": [null],
      "PreviewPicture.Mode": [this.imageMode]
    });
    if (this.data.modalType === EModalType.delete) this.mainTitle = EBlogMainLabel.delete;
  }

  submit(): void {
    if (this.data.modalType === EModalType.add) {
      this.store$.dispatch(addBlogElement({payload: this.createFormData()}));
      this.checkRequest(EModalMessage.add);
      return;
    }
    this.store$.dispatch(updateBlogElement({payload: this.createFormData(), id: this.data.id}));
    this.checkRequest(EModalMessage.edit);
  }

  createFormData(): FormData {
    const formValue = {...this.blogForm.value};
    const formData = new FormData();
    for (const key in formValue) formData.append(key, formValue[key]);
    if (this.data.modalType === EModalType.edit) formData.append('Id', this.data.id.toString());
    return formData;
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getBlogError),
      this.store$.select(getBlogSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }

  deleteItem(id: number): void {
    this.store$.dispatch(deleteBlogElement({id}));
    this.checkRequest(EModalMessage.delete);
  }
}
