<div class="content manufacturer">
  <div class="content-head">
    <div class="head-right">
      <h3 class="head-title">
        {{'TIRE_BRAND' | translate}}
      </h3>
      <div class="search-wrap">
        <mat-form-field class="form-element search" appearance="fill">
          <input [formControl]="searchTireControl" placeholder="{{'SEARCH' | translate}}" matInput type="text" autocomplete="off">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="actions">
      <button class="btn-admin" (click)="openAddDialog(EBrandType.tire)">
        <mat-icon>add</mat-icon>
        {{'ADD' | translate}}
      </button>
    </div>
  </div>
  <div class="content-body table" *ngIf="(tireTableContent$ | async) as content">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="sortContent(content, tireSorts, EBrandType.tire)"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [language]="languageService.mainLanguage.value"
      (onClick)="dispatchEvent($event, EBrandType.tire)"
      (sorts)="getSortValue($event, EBrandType.tire)"
    >
    </starter-table>
  </div>
</div>

<div class="content manufacturer">
  <div class="content-head">
    <div class="head-right">
      <h3 class="head-title">
        {{'RIM_BRAND' | translate}}
      </h3>
      <div class="search-wrap">
        <mat-form-field class="form-element search" appearance="fill">
          <input [formControl]="searchRimControl" placeholder="{{'SEARCH' | translate}}" matInput type="text" autocomplete="off">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="actions">
      <button class="btn-admin" (click)="openAddDialog(EBrandType.rim)">
        <mat-icon>add</mat-icon>
        {{'ADD' | translate}}
      </button>
    </div>
  </div>
  <div class="content-body table" *ngIf="(rimTableContent$ | async) as rimContent">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="sortContent(rimContent, rimSorts, EBrandType.rim)"
      [language]="languageService.mainLanguage.value"
      [isLoadingTable]="!(isTableLoad$ | async)"
      (onClick)="dispatchEvent($event, EBrandType.rim)"
      (sorts)="getSortValue($event, EBrandType.rim)"
    >
    </starter-table>
  </div>
</div>




