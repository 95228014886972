<div class="content">
  <div class="content-head">
    <div class="head-right">
      <h3 class="head-title">
        {{'LOCATION' | translate}}
      </h3>
      <div class="search-wrap">
        <mat-form-field class="form-element search" appearance="fill">
          <input [formControl]="searchControl" placeholder="{{'SEARCH' | translate}}" matInput type="text" autocomplete="off">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="actions">
      <button class="btn-admin" (click)="openAddDialog()">
        <mat-icon>add</mat-icon>
        {{'ADD' | translate}}
      </button>
    </div>
  </div>
  <div class="content-body" *ngIf="(locationResponse$ | async) as location">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="location.content"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [language]="languageService.mainLanguage.value"
      (onClick)="dispatchEvent($event)"
      (sorts)="getSortValue($event)"
    >
    </starter-table>
    <div class="pagination" *ngIf="location.content">
          <span class="pagination-item"
                *ngFor="let page of location.content | paginate:
                { itemsPerPage: location.pageSize,
                 currentPage: location.currentPage,
                 totalItems: location.rowCount }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>
