import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { IMotoTireDBData } from '../../../../modules/state/general-admin/manage-moto-tires/manage-moto-tires.model';
import {
  ICharacteristicObject,
  ILookupParameters,
  IOptions
} from '../../../../modules/state/general-admin/characteristic';
import { NotificationService } from '../../../../api/notification.service';
import { isEmptyObj } from '../../../../utils/helpers';
import { EModalMessage } from '../../criteria/components/criteria.constants';
import {
  ECarTireLabel,
  ECarTirePlaceholder,
  ETirePricesLabel,
  ETirePricesPlaceholder,
  pricesAction
} from '../../manage-car-tires/manage-car.enums';
import { EModalType } from '../../../../modules/shared/interfaces/table.interfaces';
import {
  getOptionsList,
  selectCharacteristicSuccessLoader,
  selectLookupParams
} from '../../../../modules/state/general-admin/characteristic/characteristic.selectors';
import {
  addMotoTire,
  changeMotoTirePrices,
  getMotoTiresError,
  getMotoTiresList,
  getMotoTiresLoader,
  getMotoTiresSuccessLoader,
  updateMotoTire
} from '../../../../modules/state/general-admin/manage-moto-tires';
import { ETireLabel } from '../../../../modules/state/general-admin/manage-car-tires';
import { refactorCharacteristicList } from '../../../../utils/converting.functions';

@Component({
  selector: 'app-manage-moto-tires-dialog',
  templateUrl: './manage-moto-tires-dialog.component.html',
  styleUrls: ['./manage-moto-tires-dialog.component.scss']
})
export class ManageMotoTiresDialogComponent implements OnInit {

  public params$: Observable<ILookupParameters>;
  public isParamsLoad$: Observable<boolean>;
  public options$: Observable<IOptions>;
  public destroy$: Subject<void> = new Subject<void>();
  public characteristicList: ICharacteristicObject;
  public tireForm: FormGroup;
  public priceFrom: FormGroup;
  public ECarTireLabel = ECarTireLabel;
  public ECarTirePlaceholder = ECarTirePlaceholder;
  public EModalType = EModalType;
  public mainTitle: string = ETireLabel.add;
  public ETirePricesLabel = ETirePricesLabel;
  public ETirePricesPlaceholder = ETirePricesPlaceholder;
  public priceActions = pricesAction;
  public loader$: Observable<boolean>;

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private store$: Store,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: IMotoTireDBData) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getOptionsList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    if (this.data.modalType === EModalType.edit) {
      const tireTypes: string[] = [];
      this.data.tireType?.forEach(item => tireTypes.push(item.id.toString()));
      this.tireForm = this.fb.group({
        Name: [this.data.name, Validators.required],
        BrandId: [this.data.manufacturer.id, Validators.required],
        BruttoPrice: [this.data.bruttoPrice, Validators.required],
        TireTypes: [tireTypes, Validators.required],
        Remark: [this.data.remark || ''],
        width: [this.data.width?.id, Validators.required],
        diameter: [this.data.diameter?.id, Validators.required],
        height: [this.data.height?.id, Validators.required],
        weightCode: [this.data.weightCode?.id, Validators.required],
        speedCode: [this.data.speedCode?.id],
        isFront: [this.data.isFront || false],
        isBack: [this.data.isBack || false]
      });
      this.mainTitle = ETireLabel.edit;
      return;
    }
    if (this.data.modalType === EModalType.prices) {
      this.priceFrom = this.fb.group({
        Percentage: ['', [Validators.required]],
        BrandId: ['', Validators.required],
        Navigation: [0],
        VehicleType: ['motorcycle']
      });
      this.mainTitle = ETireLabel.prices;
      return;
    }
    this.tireForm = this.fb.group({
      Name: ['', Validators.required],
      BrandId: ['', Validators.required],
      BruttoPrice: ['', Validators.required],
      TireTypes: ['', Validators.required],
      Remark: [''],
      width: ['', Validators.required],
      diameter: ['', Validators.required],
      height: ['', Validators.required],
      weightCode: ['', Validators.required],
      speedCode: [''],
      isFront: [''],
      isBack: ['']
    })
  }

  getOptionsList(): void {
    this.params$ = this.store$.select(selectLookupParams);
    this.loader$ = this.store$.select(getMotoTiresLoader);
    this.options$ = this.store$.select(getOptionsList);
    this.isParamsLoad$ = this.store$.select(selectCharacteristicSuccessLoader);
    this.store$.select(getOptionsList).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (list) => {
          this.characteristicList = refactorCharacteristicList(list)
        }
      });
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  submitForm(type: EModalType): void {
    if (type === EModalType.add) {
      this.store$.dispatch(addMotoTire({payload: this.createFormData()}));
      this.checkRequest(EModalMessage.add);
      return;
    }
    this.store$.dispatch(updateMotoTire({payload: this.createFormData()}));
    this.checkRequest(EModalMessage.edit);
  }

  createFormData(): FormData {
    const formValue = {...this.tireForm.value};
    const formData = new FormData();
    let characteristicValues = [];
    let optionIds = '';
    const formKeys = ['Name', 'BrandId', 'BruttoPrice', 'TireTypes', 'Remark', 'isFront', 'isBack'];
    for (const key in formValue) {
      if (formKeys.includes(key)) {
        formData.append(key, formValue[key])
      } else {
        characteristicValues.push(formValue[key])
        optionIds = characteristicValues.filter(value => value !== '').join();
      }
    }
    formData.append('CharacteristicsOptionId', optionIds);
    if (this.data.modalType === EModalType.edit) {
      formData.append('Id', this.data.id.toString());
      return formData;
    }
    return formData;
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getMotoTiresError),
      this.store$.select(getMotoTiresSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) {
            const failures = error.error.errors;
            const errors = [];
            for (const key in failures) {
              errors.push(failures[key][0])
              this.alertService.onError(errors)
            }
          }
        }
      })
  }

  submitPriceForm(): void {
    const payload = new FormData();
    let pricesValue;
    if (this.priceFrom.value.Navigation === 0) {
      pricesValue = {...this.priceFrom.value, Navigation: 'increase'}
    } else {
      pricesValue = {...this.priceFrom.value, Navigation: 'decrease'}
    }
    for (const key in pricesValue) {
      payload.append(key, pricesValue[key]);
    }
    this.store$.dispatch(changeMotoTirePrices({payload}));
    this.checkPricesRequest(EModalMessage.pricesChanged);
  }

  checkPricesRequest(message: string): void {
    combineLatest(
      this.store$.select(getMotoTiresError),
      this.store$.select(getMotoTiresSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.store$.dispatch(getMotoTiresList({params: this.data.params}));
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }
}
