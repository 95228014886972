<div class="content manufacturer">
  <div class="content-head">
    <h3 class="head-title">
      {{'MANAGE_TIRE_PRICES' | translate}}
    </h3>
  </div>
<!--  <div class="content-body">-->
<!--    <div class="content-main">-->
<!--      <div>{{'MANUFACTURER' | translate}}</div>-->
<!--      <div class="content-progress" *ngIf="(isResellerBrandsLoad$ | async)">-->
<!--        <mat-progress-bar mode="query"></mat-progress-bar>-->
<!--      </div>-->
<!--      <app-chips [dataList]="brandList" [checkedIds]="brandsIds"-->
<!--                 [isListLoad]="(isResellerBrandsLoad$ | async)" (selectedIds)="getSelectedBrand($event)"></app-chips>-->
<!--    </div>-->
<!--  </div>-->
</div>

<div class="table-wrap">
  <div *ngIf="brandsListLoad && (brandsDiscountContent$ | async) as brandsList">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="sortContent(brandsList)"
      [language]="languageService.mainLanguage.value"
      [isLoadingTable]="!(isBrandListLoad$ | async)"
      [loaderText]="'WAIT_DATA_IS_SAVED' | translate"
      (onClick)="actionDispatch($event)"
      (inputValue)="getDiscountValue($event)"
      (onCheck)="changeStatus($event)"
    >
    </starter-table>
  </div>
</div>

