import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageCarTiresComponent } from './components/manage-car-tires/manage-car-tires.component';
import { ETireRoute } from './manage-car.enums';

export const carTiresRoutes: Routes = [
  {
    path: ETireRoute.manage,
    component: ManageCarTiresComponent,
    data: {
      menuTitle: 'MANAGE_TIRES'
    }
  },
  {
    path: ETireRoute.review,
    component: ManageCarTiresComponent,
    data: {
      menuTitle: 'FOR_REVIEW'
    }
  },
]

@NgModule({
  imports: [RouterModule.forChild([...carTiresRoutes, {path: '**', redirectTo: 'manage'}])],
  exports: [RouterModule]
})
export class ManageCarTiresRoutingModule {
}
