import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as BannersActions from "./banners.actions";
import { BannersService } from '../../../../api/general-admin-services/banners.service';

@Injectable()
export class BannersEffects {
  constructor(private actions$: Actions,
              private bannersService: BannersService) {
  }

  getBannersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.getBannersList),
      switchMap((action) =>
        this.bannersService.getData(action.typeId).pipe(
          map(response => BannersActions.getBannersListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(BannersActions.getBannersListFailure({payload: err}))
          )
        )
      )
    )
  )

  getBannerSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.getBannerSection),
      switchMap(() =>
        this.bannersService.getBannerSection().pipe(
          map(response => BannersActions.getBannerSectionSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(BannersActions.getBannerSectionFailure({payload: err})))
        )
      )
    )
  )

  getSectionById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.getBannerSectionById),
      switchMap(action =>
        this.bannersService.getBannerSectionById(action.id).pipe(
          map(response => BannersActions.getBannerSectionByIdSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(BannersActions.getBannerSectionByIdFailure({payload: err})))
        )
      )
    )
  )

  updateBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.updateBanner),
      switchMap(action =>
        this.bannersService.updateBanner(action.payload).pipe(
          map(response => BannersActions.updateBannerSuccess({payload: response })),
          catchError((err: HttpErrorResponse) => of(BannersActions.updateBannerFailure({payload: err})))
        )
      )
    )
  )
}
