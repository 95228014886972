import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { EModalType, ICriteriaItem } from '../../../../../../modules/shared/interfaces/table.interfaces';
import {
  addHeightElement,
  deleteHeightElement,
  updateHeightElement
} from '../../../../../../modules/state/general-admin/criteria/height';
import {
  getHeightError, getHeightLoader,
  getHeightSuccessLoader
} from '../../../../../../modules/state/general-admin/criteria/height/height.selectors';
import { isEmptyObj } from '../../../../../../utils/helpers';
import { NotificationService } from '../../../../../../api/notification.service';
import { ECriteriaControls, EModalMessage } from '../../criteria.constants';

@Component({
  selector: 'app-height-add-dialog',
  templateUrl: './height-add-dialog.component.html',
  styleUrls: ['./height-add-dialog.component.scss']
})
export class HeightAddDialogComponent implements OnInit, OnDestroy {
  public tireWeightForm: FormGroup;
  public EModalType = EModalType;
  public destroy$: Subject<void> = new Subject<void>();
  public isLoading$: Observable<boolean>;
  public ECriteriaLabel = ECriteriaControls;

  constructor(private dialog: MatDialog,
              private store$: Store,
              private fb: FormBuilder,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: ICriteriaItem) {
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    if (this.data.type === EModalType.add) {
      this.tireWeightForm = this.fb.group({
        value: ['', [Validators.required]],
        // priority: ['', [Validators.required]],
        isActive: [false]
      });
      return;
    }
    this.tireWeightForm = this.fb.group({
      value: [this.data.value, [Validators.required]],
      // priority: [this.data.priority, [Validators.required]],
      isActive: [this.data.isActive]
    });
    this.isLoading$ = this.store$.select(getHeightLoader);
  }

  submit(event: Event): void {
    event.preventDefault();
    if (this.data.type === EModalType.add) {
      this.store$.dispatch(addHeightElement({payload: this.tireWeightForm.value}));
      this.checkRequest(EModalMessage.add);
      return;
    }
    if (this.data.type === EModalType.edit) {
      this.store$.dispatch(updateHeightElement({
        payload: this.tireWeightForm.value,
        id: this.data.id
      }));
    }
    this.checkRequest(EModalMessage.edit);
  }

  deleteItem(itemId: number): void {
    this.store$.dispatch(deleteHeightElement({payload: itemId}));
    this.checkRequest(EModalMessage.delete);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getHeightError),
      this.store$.select(getHeightSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }
}
