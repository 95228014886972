import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { IParameter } from "../../../state/general-admin/tire-reseller";
import { IStatistic, IStatisticParams } from '../../../state/general-admin/leads/leads.model';
import {
  exportStatisticFile,
  getLeadsLoader,
  getStatistic,
  selectStatistic,
} from '../../../state/general-admin/leads';
import { EStatisticTitle } from './statistics.enums';
import { LanguageService } from '../../../../api/language.service';
import { getLookupParameters, ILookupParameters } from '../../../state/general-admin/characteristic';
import { selectLookupParams } from '../../../state/general-admin/characteristic/characteristic.selectors';
import { TableDialogComponent } from '../../../shared/components/table-dialog/table-dialog.component';
import { GoldLeadsTableMeta } from '../../../../t4u-admin/pages/pages.constants';
import { environment } from '../../../../../environments/environment';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnDestroy {
  public destroy$: Subject<void> = new Subject<void>();
  public params$: Observable<ILookupParameters>;
  public filterGroup: FormGroup;
  public mainTitle: string = EStatisticTitle.all;
  public allTWS: IParameter = {id: '', value: EStatisticTitle.all};
  public apiParams: IStatisticParams;
  public today: Date = new Date();
  public isLoad$: Observable<boolean>;
  public isOneReseller: boolean = false;
  public statistic$: Observable<IStatistic | null>;
  public EStatisticTitle = EStatisticTitle;
  public dateFrom: Date;
  public dateTo: Date;
  public minDate: Date = new Date('2023-08-01');

  constructor(private store$: Store,
              private fb: FormBuilder,
              public languageService: LanguageService,
              public translate: TranslateService,
              private _adapter: DateAdapter<Date>,
              private dialog: MatDialog) {
    this._adapter.setLocale(localStorage.getItem('langCode') || environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.getData();
    this.initForm();
    this.getFiltersValue();
    this.getResellerValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.filterGroup = this.fb.group({
      tireResellerId: [''],
      dateFrom: this.dateFrom || [''],
      dateTo: this.dateTo || ['']
    })
  }

  getResellerValue(): void {
    this.filterGroup.get('tireResellerId')?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: reseller => {
          this.isOneReseller = reseller.id !== '';
        }
      })
  }

  getData(): void {
    this.store$.dispatch(getLookupParameters({
      payload: 'TWSName,subscription,TireType,VehicleType,Manufacture,City,LeadType,ModelName,RimManufacturer,TireManufacturer'
    }));
    this.statistic$ = this.store$.select(selectStatistic);
    this.params$ = this.store$.select(selectLookupParams);
    this.isLoad$ = this.store$.select(getLeadsLoader);
    this.getCurrentMonth();
    this.getAmounts(this.apiParams);
  }

  getCurrentMonth(): void {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();

    this.dateFrom = new Date(year, month, 1);
    this.dateFrom.setHours(4);
    this.dateTo = new Date();
    this.dateTo.setHours(4);

    this.apiParams = {dateFrom: this.dateFrom.toISOString(), dateTo: this.dateTo.toISOString(), tireResellerId: ''};
  }

  getAmounts(params?: IStatisticParams): void {
    this.store$.dispatch(getStatistic({params}));
  }

  getFiltersValue(): void {
    this.filterGroup.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(1000))
      .subscribe({
        next: (filters) => {
          const params = {...filters, tireResellerId: filters.tireResellerId.id};
          delete params.dateFrom;
          delete params.dateTo;
          if (filters.tireResellerId === '') delete params.tireResellerId;
          if (filters.dateFrom) {
            const dateFrom = filters.dateFrom;
            dateFrom.setHours(4);
            params.dateFrom = dateFrom.toISOString();
          }
          if (filters.dateTo) {
            const dateTo = filters.dateTo;
            dateTo.setHours(4);
            params.dateTo = dateTo.toISOString();
          }
          this.apiParams = params;
          this.mainTitle = filters.tireResellerId.value || EStatisticTitle.all;
          this.getAmounts(params);
        }
      })
  }

  print(): void {
    window.print();
  }

  export(): void {
    const startDate = this.convertTime(this.apiParams?.dateFrom);
    const endDate = this.convertTime(this.apiParams?.dateTo);
    const shopName = this.mainTitle;
    const fileName = `${shopName}.${startDate}-${endDate}.statistics`;
    this.store$.dispatch(exportStatisticFile({params: this.apiParams, fileName}));
  }

  convertTime(date: string | undefined): string {
    if (!date) return '';
    const fullDate = new Date(date);
    const month = fullDate.getMonth() + 1;
    const day = fullDate.getDate();
    const year = fullDate.getFullYear();
    const shortDate = [day, month, year];
    return shortDate.join('/');
  }

  openDetails(): void {
    this.dialog.open(TableDialogComponent, {
      panelClass: 'price-details',
      width: '600px',
      height: '300px',
      data: {
        tableMeta: GoldLeadsTableMeta,
        params: this.apiParams
      }
    })
  }

  sortContent(params: IParameter[]): IParameter[] {
    if (!params?.length) return [];
    let options = [...params];
    return options.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? 1 : -1)
  }

  changeVirtualNumber(number: string): string {
    if(!number.includes('972')) return number;
    let newNumber = number.split('972')[1];
    const firstLetters = newNumber.slice(0, 2);
    newNumber = newNumber.slice(2, newNumber.length)
    return `0${firstLetters}-${newNumber}`;
  }
}
