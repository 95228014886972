import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";
import { IBanner, IBannerList, IBannerSection } from './banners.model';

export const GET_BANNERS_LIST = '[BANNERS] Get banners list';
export const GET_BANNERS_LIST_SUCCESS = '[BANNERS] Get banners list success';
export const GET_BANNERS_LIST_FAILURE = '[BANNERS] Get banners list failure';

export const GET_BANNERS_SECTION = '[BANNERS] Get banners  section';
export const GET_BANNERS_SECTION_SUCCESS = '[BANNERS] Get banners  section success';
export const GET_BANNERS_SECTION_FAILURE = '[BANNERS] Get banners  section failure';

export const GET_SECTION_BY_ID = '[BANNERS] Get section by id';
export const GET_SECTION_BY_ID_SUCCESS = '[BANNERS] Get section by id success';
export const GET_SECTION_BY_ID_FAILURE = '[BANNERS] Get section by id failure';

export const UPDATE_BANNER = '[BANNERS] Update banner';
export const UPDATE_BANNER_SUCCESS = '[BANNERS] Update banner success';
export const UPDATE_BANNER_FAILURE = '[BANNERS] Update banner failure';

export const getBannersList = createAction(
  GET_BANNERS_LIST,
  props<{ typeId: number }>()
)

export const getBannersListSuccess = createAction(
  GET_BANNERS_LIST_SUCCESS,
  props<{ payload: IBannerList }>()
)

export const getBannersListFailure = createAction(
  GET_BANNERS_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getBannerSection = createAction(
  GET_BANNERS_SECTION
)

export const getBannerSectionSuccess = createAction(
  GET_BANNERS_SECTION_SUCCESS,
  props<{ payload: IBannerSection[] }>()
)

export const getBannerSectionFailure = createAction(
  GET_BANNERS_SECTION_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getBannerSectionById = createAction(
  GET_SECTION_BY_ID,
  props<{ id: number }>()
)

export const getBannerSectionByIdSuccess = createAction(
  GET_SECTION_BY_ID_SUCCESS,
  props<{ payload: IBannerList }>()
)

export const getBannerSectionByIdFailure = createAction(
  GET_SECTION_BY_ID_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateBanner = createAction(
  UPDATE_BANNER,
  props<{ payload: FormData }>()
)

export const updateBannerSuccess = createAction(
  UPDATE_BANNER_SUCCESS,
  props<{ payload: IBanner }>()
)

export const updateBannerFailure = createAction(
  UPDATE_BANNER_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
