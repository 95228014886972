import { HttpErrorResponse } from "@angular/common/http";
import { Action, createReducer, on } from "@ngrx/store";

import { SpeedCodeElement, SpeedCodeState } from './speed-code.model';
import * as SpeedCodeActions from './speed-code.actions';

export const initialState: SpeedCodeState = {
  current: {} as SpeedCodeElement,
  deleteId: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  content: [],
  error: {} as HttpErrorResponse,
};

const speedCodeReducer = createReducer(
  initialState,
  on(SpeedCodeActions.getSpeedCodeData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(SpeedCodeActions.getSpeedCodeDataSuccess, (state, {payload}) => ({
    ...state,
    content: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(SpeedCodeActions.getSpeedCodeDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(SpeedCodeActions.addSpeedCodeElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(SpeedCodeActions.addSpeedCodeElementSuccess, (state, {payload}) => {
    return {
      ...state,
      content: [...state.content, payload],
      current: {} as SpeedCodeElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(SpeedCodeActions.addSpeedCodeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(SpeedCodeActions.updateSpeedCodeElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(SpeedCodeActions.updateSpeedCodeElementSuccess, (state, {payload}) => {
    const speedCodeData = [...state.content];
    const newData = speedCodeData.map((mnf: SpeedCodeElement) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    return {
      ...state,
      content: newData,
      current: {} as SpeedCodeElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(SpeedCodeActions.updateSpeedCodeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(SpeedCodeActions.deleteSpeedCodeElement, (state, {payload}) => ({
    ...state,
    deleteId: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(SpeedCodeActions.deleteSpeedCodeElementSuccess, (state) => {
    const speedCodeData = [...state.content];
    const newData = speedCodeData.filter((mnf: SpeedCodeElement) => mnf.id !== state.deleteId);
    return {
      ...state,
      content: newData,
      deleteId: null,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(SpeedCodeActions.deleteSpeedCodeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function SpeedCodeReducer(state: SpeedCodeState | undefined, action: Action): any {
  return speedCodeReducer(state, action);
}



