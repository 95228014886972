import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

import { WeightCodeElement } from "./weight-code.model";

export const GET_WEIGHT_CODE_DATA = '[WEIGHT_CODE] Get data';
export const GET_WEIGHT_CODE_SUCCESS = '[WEIGHT_CODE] Get data success';
export const GET_WEIGHT_CODE_FAILURE = '[WEIGHT_CODE] Get data failure';

export const ADD_WEIGHT_CODE_ELEMENT = '[WEIGHT_CODE] Add element';
export const ADD_WEIGHT_CODE_ELEMENT_SUCCESS = '[WEIGHT_CODE] Add element success';
export const ADD_WEIGHT_CODE_ELEMENT_FAILURE = '[WEIGHT_CODE] Add element failure';

export const UPDATE_WEIGHT_CODE_ELEMENT = '[WEIGHT_CODE] Update element';
export const UPDATE_WEIGHT_CODE_ELEMENT_SUCCESS = '[WEIGHT_CODE] Update element success';
export const UPDATE_WEIGHT_CODE_ELEMENT_FAILURE = '[WEIGHT_CODE] Update element failure';

export const DELETE_WEIGHT_CODE_ELEMENT = '[WEIGHT_CODE] Delete element';
export const DELETE_WEIGHT_CODE_ELEMENT_SUCCESS = '[WEIGHT_CODE] Delete element success';
export const DELETE_WEIGHT_CODE_ELEMENT_FAILURE = '[WEIGHT_CODE] Delete element failure';

export const getWeightCodeData = createAction(
  GET_WEIGHT_CODE_DATA,
)

export const getWeightCodeDataSuccess = createAction(
  GET_WEIGHT_CODE_SUCCESS,
  props<{ payload: WeightCodeElement[] }>()
)

export const getWeightCodeDataFailure = createAction(
  GET_WEIGHT_CODE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addWeightCodeElement = createAction(
  ADD_WEIGHT_CODE_ELEMENT,
  props<{ payload: WeightCodeElement }>()
)

export const addWeightCodeElementSuccess = createAction(
  ADD_WEIGHT_CODE_ELEMENT_SUCCESS,
  props<{ payload: WeightCodeElement }>()
)

export const addWeightCodeElementFailure = createAction(
  ADD_WEIGHT_CODE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateWeightCodeElement = createAction(
  UPDATE_WEIGHT_CODE_ELEMENT,
  props<{ payload: WeightCodeElement, id: number }>()
)

export const updateWeightCodeElementSuccess = createAction(
  UPDATE_WEIGHT_CODE_ELEMENT_SUCCESS,
  props<{ payload: WeightCodeElement }>()
)

export const updateWeightCodeElementFailure = createAction(
  UPDATE_WEIGHT_CODE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteWeightCodeElement = createAction(
  DELETE_WEIGHT_CODE_ELEMENT,
  props<{ payload: number }>()
)

export const deleteWeightCodeElementSuccess = createAction(
  DELETE_WEIGHT_CODE_ELEMENT_SUCCESS
)

export const deleteWeightCodeElementFailure = createAction(
  DELETE_WEIGHT_CODE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
