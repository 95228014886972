import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { LanguageService } from '../../../../api/language.service';
import { IOrderContent } from '../../../state/general-admin/payments/payments.model';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { IStatisticParams } from '../../../state/general-admin/leads/leads.model';
import {
  getGoldDetailsByMonth,
  getGoldLeadsStatistic, selectGoldDetailsByMonth, selectGoldDetailsByMonthLoader,
  selectGoldLeadsLoader,
  selectGoldLeadStatistic
} from '../../../state/general-admin/payments';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-table-manufacturer-add-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent implements OnInit, OnDestroy {
  public isLoad: Observable<boolean>;
  public content: IOrderContent[];
  public destroy$: Subject<void> = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<TableDialogComponent>,
              public languageService: LanguageService,
              private store$: Store,
              @Inject(MAT_DIALOG_DATA) public data: {
                tableMeta: TableMeta[],
                params: IStatisticParams,
                searchByMonth: boolean
              }) {
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    // this.isLoad = this.store$.select(selectGoldLeadsPerMonthLoader)
    // this.store$.dispatch(getGoldLeadsPerMonth({params: this.data.params}));
    // this.store$.select(selectGoldLeadPerMonth).pipe(takeUntil(this.destroy$))
    //   .subscribe({
    //     next: payments => {
    //       const leads = convertPaymentsLeadsCount(payments).goldLeads;
    //       this.content = this.sortByDateCreation(leads);
    //     }
    //   })
    if(this.data.searchByMonth) {
      this.isLoad = this.store$.select(selectGoldDetailsByMonthLoader)
      this.store$.dispatch(getGoldDetailsByMonth({params: this.data.params}));
      this.store$.select(selectGoldDetailsByMonth).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (orders) => {
            const leads = orders.result;
            this.content = this.sortByDateCreation(leads);
          }
        })
      return;
    }
    this.isLoad = this.store$.select(selectGoldLeadsLoader)
    this.store$.dispatch(getGoldLeadsStatistic({params: this.data.params}));
    this.store$.select(selectGoldLeadStatistic).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (orders) => {
          const leads = orders.result;
          this.content = this.sortByDateCreation(leads);
        }
      })
  }

  sortByDateCreation(orders: IOrderContent[]): IOrderContent[] {
    if (!orders) return [];
    let list = [...orders];
    list = list.sort((a, b) => {
      const dateA = new Date(a.orderDate).getTime();
      const dateB = new Date(b.orderDate).getTime();
      return dateB - dateA;
    });
    return list;
  }
}
