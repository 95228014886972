import { CellType } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';

export interface ICriteriaItem {
  id: number,
  value: string,
  additionalValue: string,
  priority: number,
  isActive: boolean,
  type?: string
}

export enum EModalType {
  add = 'add',
  delete = 'delete',
  edit = 'edit',
  prices = 'prices',
  business = 'business',
  editPrice = 'editPrice'
}

export enum EVehicleType {
  car = 'car',
  moto = 'motorcycle',
  carTitle = 'CAR_TIRE_TYPE',
  motoTitle = 'MOTO_TIRE_TYPE'
}

export enum ERimTitle {
  add = 'ADD_RIM',
  edit = 'UPDATE_RIM'
}

export enum EBrandType {
  rim = 'rim',
  tire = 'tire'
}

export interface ITableAction {
  action: string;
  icon: string;
}

export interface ITableMeta {
  "name": string;
  "sortable": boolean;
  "filterable": boolean;
  "type": CellType;
  "jsonPath": string;
  "actions"?: ITableAction[]
}

export interface ITableData<T> {
  action: ITableAction,
  data: T,
  meta: ITableMeta,
  source: string,
  userAction?: string
}

export interface ITableCheckData<T> {
  checked: boolean,
  checkedList: T[],
  target: T
}

export interface IFilterChips {
  [key: string]: string
}
