import { HttpErrorResponse } from "@angular/common/http";
import { IResponse } from '../../../../api/api-data/api.interfaces';

export interface IConnectImageElement {
  id: number;
  name: string;
  description: string;
  pictureUrl: string;
  brand: { id: number, name: string }
}

export interface ConnectImageState {
  result: IResponse<IConnectImageElement[]>;
  checkedTires: number[];
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  isLoading: boolean;
  error: HttpErrorResponse | null;
}

export interface IConnectImageParams {
  modelName: string;
  brandId: number;
  query: string;
  vehicleType: string,
  page: number
}
