
<div class="statistic-card" [class.bgSecondary]="card.bgSecondary">
  <h5 class="title">
    {{card.title | translate}}
  </h5>
  <div class="body">
    <div class="item" [ngClass]="{'notAlone': card.data.length > 1}" *ngFor="let item of card.data">
      <ng-container [ngSwitch]="item.type">
        <ng-container *ngSwitchCase="types.Default">
          {{item.value}}
        </ng-container>
        <ng-container *ngSwitchCase="types.Currency">
          {{item.value | currency: 'ILS'}}
        </ng-container>
        <ng-container *ngSwitchCase="types.Text">
          <span class="text-wrap" (click)="textClickHandler()">
            {{item.value | translate}}
          </span>
        </ng-container>
      </ng-container>
    </div>
  </div>

</div>
