import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule, TranslateStore } from "@ngx-translate/core";
import { StarterTableModule } from "starter-shared/src/app/modules/starter-table/starter-table.module";
import { NgxMaskModule } from 'ngx-mask';

import { StyleguideComponent } from './styleguide/styleguide.component';
import { MaterialsModule } from "../../material/materials/materials.module";
import { StatisticCardComponent } from './components/statistic-card/statistic-card.component';
import { TableDialogComponent } from './components/table-dialog/table-dialog.component';
import { NavTabsComponent } from "../../components/nav-tabs/nav-tabs.component";
import { ImageUploaderComponent } from "../../components/image-uploader/image-uploader.component";
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { InputComponent } from './components/input/input.component';
import { ChipsComponent } from './components/chips/chips.component';
import { SelectComponent } from './components/select/select.component';
import { CalendarRangeComponent } from './components/calendar-range/calendar-range.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterChipsComponent } from './components/filter-chips/filter-chips.component';
import {
  ManufacturerAddDialogComponent
} from '../../t4u-admin/pages/criteria/components/manufacturer/manufacturer-add-dialog/manufacturer-add-dialog.component';
import { FilterSelectsComponent } from '../../components/filter-selects/filter-selects.component';
import { PlateSearchComponent } from './components/plate-search/plate-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ImgDirective } from '../../utils/image.directive';

const routes: Routes = [
  {path: 'styleguide', component: StyleguideComponent}
]

@NgModule({
  declarations: [
    StyleguideComponent,
    StatisticCardComponent,
    TableDialogComponent,
    NavTabsComponent,
    ImageUploaderComponent,
    LangSwitcherComponent,
    InputComponent,
    ChipsComponent,
    SelectComponent,
    CalendarRangeComponent,
    TimeInputComponent,
    MultipleSelectComponent,
    FilterChipsComponent,
    ManufacturerAddDialogComponent,
    FilterSelectsComponent,
    PlateSearchComponent,
    ImgDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    MaterialsModule,
    ReactiveFormsModule,
    StarterTableModule,
    TranslateModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    MatAutocompleteModule,
  ],
  exports: [
    MaterialsModule,
    CommonModule,
    StatisticCardComponent,
    NavTabsComponent,
    ImageUploaderComponent,
    TranslateModule,
    LangSwitcherComponent,
    InputComponent,
    ChipsComponent,
    SelectComponent,
    CalendarRangeComponent,
    TimeInputComponent,
    MultipleSelectComponent,
    NgxPaginationModule,
    FilterChipsComponent,
    ManufacturerAddDialogComponent,
    FilterSelectsComponent,
    ImgDirective
  ],
  providers: [
    TranslateStore
  ]
})
export class SharedModule {
}
