import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';

import { AdminNotificationsState, NotificationData } from './admin-notifications.model';
import * as AdminNotificationActions from './admin-notifications.actions';

export const initialState: AdminNotificationsState = {
  notificationList: [],
  notificationForm: {} as NotificationData,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse,
};

const adminNotificationsReducer = createReducer(
  initialState,
  on(AdminNotificationActions.getAdminNotification, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(AdminNotificationActions.getAdminNotificationSuccess, (state, {payload}) => ({
    ...state,
    notificationList: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(AdminNotificationActions.getAdminNotificationFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true
  })),
  on(AdminNotificationActions.addAdminNotification, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(AdminNotificationActions.addAdminNotificationSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(AdminNotificationActions.addAdminNotificationFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  }))
);

export function AdminNotificationReducer(state: AdminNotificationsState | undefined, action: Action): any {
  return adminNotificationsReducer(state, action);
}
