<div class="dialog manufacturer-add-dialog">
  <div class="dialog-title">
    <h3 class="title">
      {{'UPDATE_BANNER' | translate}}
    </h3>
    <div class="title-actions">
      <mat-icon class="close" (click)="closeAddDialog()">close</mat-icon>
    </div>
  </div>

  <mat-dialog-content>
    <div class="dialog-form-wrap " [formGroup]="bannerForm">
      <div class="table-loader" *ngIf="(isLoad$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <mat-checkbox class="form-checkbox"
                    formControlName="Visibility">{{'DISPLAYED_ON_THE_WEBSITE' | translate}}</mat-checkbox>

      <div formArrayName="BannerImages">
        <div *ngFor="let control of bannersArray.controls; let i = index" [formGroupName]="i" class="banner-form-wrap">
          <div class="banner-form-info">
            <app-input formControlName="HayperLink" [isDisabled]="bannersArray.at(i).get('saveAsGoogleAds').value"
                       [placeholder]="'ENTER_HYPERLINK'" [label]="'HYPERLINK'">
            </app-input>
            <app-input formControlName="TagName" [isDisabled]="bannersArray.at(i).get('saveAsGoogleAds').value"
                       [placeholder]="'ENTER_TAG_NAME'" [label]="'TAG_NAME'">
            </app-input>

            <div class="col-6 form-col buttons-wrap">
              <mat-checkbox class="form-checkbox" (change)="changeImageToGoogleAd($event,i)"
                            formControlName="saveAsGoogleAds">{{'GOOGLE_ADS' | translate}}</mat-checkbox>
            </div>
            <div class="code-wrap">
              <app-input *ngIf="bannersArray.at(i).get('saveAsGoogleAds').value"
                         formControlName="GoogleAdCode" [placeholder]="'ENTER_GOOGLE_AD_CODE'"
                         [label]="'GOOGLE_AD_CODE'"
              ></app-input>
            </div>

            <div>
              <button class="btn-admin remove-btn" *ngIf="bannersArray.controls.length !== 1"
                      (click)="removeBanner(i)">{{'REMOVE_BANNER' | translate}}</button>
            </div>
          </div>

          <div class="col-6 banner-image-loader">
            <app-image-uploader [showImage]="true" [isBanner]="true" [imageSize]="imageSize"
                                (uploadImage)="changeImg($event, i)"
                                [imgSrc]="data.banner.images[i]?.pictureUrl || null">
              <ng-container>
                {{'UPLOAD_IMG' | translate}}
              </ng-container>
              <ng-container requirements>
                {{'IMAGE_SIZE_SHOULD_BE' | translate}} {{imageSize.width}}x{{imageSize.height}}
              </ng-container>
              <div style="padding-top: 8px;">
                {{'IMAGE_FILE_SIZE_SHOULD_BE' | translate}}
              </div>
            </app-image-uploader>
          </div>

        </div>
        <div class="banner-add-btn">
          <button class="btn-admin btn" *ngIf="bannersArray.controls.length < 4"
                  (click)="addBanner()">{{'ADD_BANNER' | translate}}</button>
        </div>
      </div>

      <div class="save-btn">
        <button [ngClass]="!bannerForm.valid ? 'disabled' : ''" (click)="submit()" class="btn-admin">
          {{'SAVE' | translate}}
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
