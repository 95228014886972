import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { SubscriptionTableMeta } from '../criteria.constants';
import { EModalType, ICriteriaItem, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import { getSubscriptionData, ISubDBElement } from '../../../../../modules/state/general-admin/criteria/subscription';
import {
  getSubscriptionLoader,
  getSubscriptionTable
} from '../../../../../modules/state/general-admin/criteria/subscription/subscription.selectors';
import { SubscriptionAddDialogComponent } from './subscription-add-dialog/subscription-add-dialog.component';
import { LanguageService } from '../../../../../api/language.service';
import { sortContentByName } from '../../../../../utils/helpers';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  public tableContent$: Observable<ISubDBElement[]>;
  public tableMeta: TableMeta[] = SubscriptionTableMeta;
  public modalType: EModalType = EModalType.add;
  public isTableLoad$: Observable<boolean>;
  public sorts: string = '';
  public sortContent: Function = sortContentByName;

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  public openAddDialog(): void {
    this.dialog.open(SubscriptionAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {type: EModalType.add}
    })
  }

  ngOnInit(): void {
    this.store$.dispatch(getSubscriptionData());
    this.tableContent$ = this.store$.select(getSubscriptionTable);
    this.isTableLoad$ = this.store$.select(getSubscriptionLoader);
  }

  dispatchEvent(table: ITableData<ICriteriaItem>): void {
    this.dialog.open(SubscriptionAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...table.data, type: EModalType.edit}
    })
  }

  changeCriteria(item: ICriteriaItem): void {
    this.dialog.open(SubscriptionAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, type: this.modalType}
    })
  }

  getSortValue(sort: string): void {
    this.sorts = sort;
  }
}
