<div class="content manufacturer">
  <div class="content-head">
    <div class="head-right">
      <h3 class="head-title">
        {{'SPEED_CODE' | translate}}
      </h3>
      <div class="search-wrap">
        <mat-form-field class="form-element search" appearance="fill">
          <input [formControl]="searchControl" placeholder="{{'SEARCH' | translate}}" matInput type="text" autocomplete="off">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="actions">
      <button class="btn-admin" (click)="openAddDialog()">
        <mat-icon>add</mat-icon>
        {{'ADD' | translate}}
      </button>
    </div>
  </div>
  <div class="content-body" *ngIf="(tableContent$ | async) as content">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="sortContent(content)"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [language]="languageService.mainLanguage.value"
      (onClick)="dispatchEvent($event)"
      (sorts)="getSortValue($event)"
    >
    </starter-table>
  </div>
</div>




