import { IParameter, ITWSValidator } from '../../../modules/state/general-admin/tire-reseller';

export enum ETWSLabel {
  userName = 'USER_NAME',
  password = 'PASSWORD',
  shopName = 'TIRE_SHOP_NAME',
  subType = 'SUBSCRIPTION_TYPE',
  location = 'LOCATION',
  street = 'ADDRESS',
  description = 'DESCRIPTION',
  coupon = 'COUPON',
  waze = 'WAZE_NAVIGATION',
  contactName = 'CONTACT_NAME',
  contactPhone = 'CONTACT_TELEPHONE',
  officePhone = 'OFFICE_PHONE',
  email = 'EMAIL',
  fax = 'FAX',
  rimsPrice = 'RIMS_PRICE',
  phonePrice = 'PHONE_PRICE',
  reversePrice = 'REVERSE_PRICE',
  indexPrice = 'INDEX_PRICE',
  zapPrice = 'ZAP_PRICE',
  navPrice = 'NAV_PRICE',
  starterPrice = 'STARTER_PRICE',
  tireType = 'TIRE_TYPE',
  vehicleType = 'VEHICLE_TYPE',
  moreOptions = 'MORE_OPTIONS',
  googleSearch = 'BUSINESS_GOOGLE_SEARCH',
  subLocation = 'SUB_LOCATION',
  secondContactPhone = 'SECOND_CONTACT_TELEPHONE',
  secondPhoneForBusiness = 'MASKYOO_NUMBER'
}

export enum ETWSPlaceholder {
  userName = 'ENTER_USER_NAME',
  shopName = 'ENTER_TIRE_SHOP',
  subType = 'SELECT_SUB_TYPE',
  location = 'SELECT_LOCATION',
  street = 'ENTER_ADDRESS',
  number = '№',
  description = 'ENTER_DESCRIPTION_TEXT',
  coupon = 'ENTER_COUPON',
  waze = 'ENTER_WAZE_NAV',
  contactName = 'ENTER_CONTACT_NAME',
  contactPhone = 'ENTER_CONTACT_TELEPHONE',
  officePhone = 'ENTER_OFFICE_PHONE',
  email = 'ENTER_EMAIL',
  fax = 'ENTER_FAX',
  rimsPrice = 'ENTER_RIMS_PRICE',
  phonePrice = 'ENTER_PHONE_PRICE',
  reversePrice = 'ENTER_REVERSE_PRICE',
  indexPrice = 'ENTER_INDEX_PRICE',
  zapPrice = 'ENTER_ZAP_PRICE',
  navPrice = 'ENTER_NAV_PRICE',
  tireType = 'SELECT_TIRE_TYPE',
  vehicleType = 'SELECT_VEHICLE_TYPE',
  moreOptions = 'SELECT_MORE_OPTIONS',
  googleSearch = 'ENTER_GOOGLE_WORDS',
  subLocation = 'ENTER_SUB_LOCATION',
  secondContactPhone = 'ENTER_SECOND_CONTACT_TELEPHONE'
}

export enum EVehicleType {
  car = 'car',
  moto = 'motorcycle',
  motoTitle = 'ADD_MOTO_TWS',
  carTitle = 'ADD_CAR_TWS',
  editCarTitle = 'EDIT_CAR_TWS',
  editMotoTitle = 'EDIT_MOTO_TWS'
}

export enum ETWSControls {
  password = 'Password',
  confirmPassword = 'ConfirmPassword',
  regularOpen = 'RegularWorkingHours.OpenTime',
  regularClose = 'RegularWorkingHours.CloseTime',
  sabbathOpen = 'SabbathWorkingHours.OpenTime',
  sabbathClose = 'SabbathWorkingHours.CloseTime',
  fridayOpen = 'FridayWorkingHours.OpenTime',
  fridayClose = 'FridayWorkingHours.CloseTime',
  regularErrorTime = 'regularErrorTime',
  fridayErrorTime = 'fridayErrorTime',
  sabbathErrorTime = 'sabbathErrorTime',
  regularError = 'regularError',
  fridayError = 'fridayError',
  sabbathError = 'sabbathError',
}

export const validatorsFields: ITWSValidator[] = [
  {
    firstError: ETWSControls.regularError,
    secondError: ETWSControls.regularErrorTime,
    controlNameFirst: ETWSControls.regularOpen,
    controlNameSecond: ETWSControls.regularClose
  },
  {
    firstError: ETWSControls.fridayError,
    secondError: ETWSControls.fridayErrorTime,
    controlNameFirst: ETWSControls.fridayOpen,
    controlNameSecond: ETWSControls.fridayClose
  },
  {
    firstError: ETWSControls.sabbathError,
    secondError: ETWSControls.sabbathErrorTime,
    controlNameFirst: ETWSControls.sabbathOpen,
    controlNameSecond: ETWSControls.sabbathClose
  }
]

export const TimeFormControlWithSabbath = [
  'RegularWorkingHours.OpenTime', 'RegularWorkingHours.CloseTime',
  'FridayWorkingHours.OpenTime', 'FridayWorkingHours.CloseTime',
  'SabbathWorkingHours.OpenTime', 'SabbathWorkingHours.CloseTime']

export const TimeControlsWithoutSabbath = [
  'RegularWorkingHours.OpenTime', 'RegularWorkingHours.CloseTime',
  'FridayWorkingHours.OpenTime', 'FridayWorkingHours.CloseTime'
]

export const SabbathTimeControls = ['SabbathWorkingHours.OpenTime', 'SabbathWorkingHours.CloseTime'];

export const CDayOff: IParameter[] = [
  {id: 0, value: 'FRIDAY'},
  {id: 1, value: 'SABBATH'}
]
