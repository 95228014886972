import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import * as WidthActions from './width.actions';
import { WidthElement, WidthState } from './width.model';

export const initialState: WidthState = {
  current: {} as WidthElement,
  deleteId: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  content: [],
  error: {} as HttpErrorResponse,
};

const widthReducer = createReducer(
  initialState,
  on(WidthActions.getWidthData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(WidthActions.getWidthDataSuccess, (state, {payload}) => ({
    ...state,
    content: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(WidthActions.getWidthDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(WidthActions.addWidthElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(WidthActions.addWidthElementSuccess, (state, {payload}) => {
    return {
      ...state,
      content: [...state.content, payload],
      current: {} as WidthElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(WidthActions.addWidthElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(WidthActions.updateWidthElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(WidthActions.updateWidthElementSuccess, (state, {payload}) => {
    const tireWeightData = [...state.content];
    const newContent = tireWeightData.map((mnf: WidthElement) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    return {
      ...state,
      content: newContent,
      current: {} as WidthElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(WidthActions.updateWidthElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(WidthActions.deleteWidthElement, (state, {payload}) => ({
    ...state,
    deleteId: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(WidthActions.deleteWidthElementSuccess, (state) => {
    const tireWeightData = [...state.content];
    const newContent = tireWeightData.filter((mnf: WidthElement) => mnf.id !== state.deleteId);
    return {
      ...state,
      content: newContent,
      deleteId: null,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(WidthActions.deleteWidthElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function WidthReducer(state: WidthState | undefined, action: Action): any {
  return widthReducer(state, action);
}



