import { HttpErrorResponse } from '@angular/common/http';

export interface IBannerList {
  DesktopImage: IBanner[],
  MobileImage: IBanner[]
}

export interface IBanner {
  id: number,
  visibility: boolean,
  page: string,
  location: string,
  bannerImageType: string,
  banner: {
    bannerId: number,
    parameter: string,
    images: IBannerImage[],
  },
  typeId: number,
  bannerType: string
}

export interface IBannerImage {
  id: number,
  pictureUrl: string;
  clipUrl: string;
  tagName: string | null;
  googleAdCode: string | null;
  isBannerGoogleAd: boolean;
}

export interface IBannerFormControl {
  GoogleAdCode: string;
  HayperLink: string;
  'Image.File': File;
  'Image.Mode': string;
  TagName: string;
  id: string;
}

export interface IBannersState {
  result: IBannerList,
  sectionContent: IBannerSection[],
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  error: HttpErrorResponse;
}

export interface IBannerSection {
  id: number,
  name: string
}

export enum EBannerTypeId {
  carTires = 1,
  motoTires = 2,
  rims = 3
}

export enum EBannerType {
  mobile = 'mobile',
  desktop = 'desktop'
}
