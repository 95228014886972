import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DiameterElement, DiameterState } from "./diameter.model";

export const getDiameterState = createFeatureSelector<DiameterState>('diameter');

export const getDiameterTable = createSelector(getDiameterState,
  (state: DiameterState): DiameterElement[] => state.content);

export const getDiameterSuccessLoader = createSelector(getDiameterState,
  (state: DiameterState) => state.isLoadingSuccess);

export const getDiameterLoader = createSelector(getDiameterState,
  (state: DiameterState) => state.isLoading);

export const getDiameterError = createSelector(getDiameterState,
  (state: DiameterState) => state.error);
