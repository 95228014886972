import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { IBanner, IBannerList, IBannersState } from './banners.model';

import * as BannersActions from './banners.actions';

export const initialState: IBannersState = {
  result: {} as IBannerList,
  sectionContent: [],
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse,
};

const bannersReducer = createReducer(
  initialState,
  on(BannersActions.getBannersList, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BannersActions.getBannersListSuccess, (state, {payload}) => {
    const list = {...payload};
    const MobileImage = list.MobileImage.filter(banner => banner.location === 'Bottom');
    return {
      ...state,
      result: {...payload, MobileImage},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(BannersActions.getBannersListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(BannersActions.getBannerSection, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BannersActions.getBannerSectionSuccess, (state, {payload}) => ({
    ...state,
    sectionContent: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BannersActions.getBannerSectionFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
  on(BannersActions.getBannerSectionById, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BannersActions.getBannerSectionByIdSuccess, (state, {payload}) => {
    return {
      ...state,
      section: payload,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(BannersActions.getBannerSectionByIdFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(BannersActions.updateBanner, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BannersActions.updateBannerSuccess, (state, {payload}) => {
    let newDesktopContent = [...state.result.DesktopImage];
    let newMobileContent = [...state.result.MobileImage];
    const desktop = newDesktopContent.map((mnf: IBanner) => {
      if (mnf.banner.bannerId === payload.banner.bannerId) mnf = payload;
      return mnf;
    });
    const mobile = newMobileContent.map((mnf: IBanner) => {
      if (mnf.banner.bannerId === payload.banner.bannerId) mnf = payload
      return mnf;
    });
    return {
      ...state,
      result: {
        MobileImage: mobile,
        DesktopImage: desktop
      },
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(BannersActions.updateBannerFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function BannersReducer(state: IBannersState | undefined, action: Action): any {
  return bannersReducer(state, action);
}
