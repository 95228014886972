import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as ManageCarTiresActions from "./manage-car-tires.actions";
import ManageCarTiresApiService from "../../../../api/general-admin-services/manage-car-tires.api.service";

@Injectable()
export class ManageCarTiresEffects {
  constructor(private actions$: Actions,
              private carService: ManageCarTiresApiService) {
  }

  getCarTiresList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageCarTiresActions.getCarTiresList),
      switchMap(action =>
        this.carService.getCarTiresList(action.url, action.params).pipe(
          map(response => ManageCarTiresActions.getCarTiresListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(ManageCarTiresActions.getCarTiresListFailure({payload: err}))
          )
        )
      )
    )
  )

  addCarTire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageCarTiresActions.addCarTire),
      switchMap(action =>
        this.carService.addCarTire(action.payload).pipe(
          map(tire => ManageCarTiresActions.addCarTireSuccess({payload: tire.entity, copyTireId: action.copyTireId})),
          catchError((err: HttpErrorResponse) => of(ManageCarTiresActions.addCarTireFailure({payload: err})))
        )
      )
    )
  )

  addBusinessCarTire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageCarTiresActions.addBusinessCarTire),
      switchMap(action =>
        this.carService.addBusinessCarTire(action.payload).pipe(
          map(tire => ManageCarTiresActions.addBusinessCarTireSuccess({payload: tire})),
          catchError((err: HttpErrorResponse) => of(ManageCarTiresActions.addBusinessCarTireFailure({payload: err})))
        )
      )
    )
  )

  updateCarTire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageCarTiresActions.updateCarTire),
      switchMap(action =>
        this.carService.updateCarTire(action.payload).pipe(
          map(tire => ManageCarTiresActions.updateCarTireSuccess({payload: tire.entity})),
          catchError((err: HttpErrorResponse) => of(ManageCarTiresActions.updateCarTireFailure({payload: err})))
        )
      )
    )
  )

  exportFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageCarTiresActions.exportFileAboutCarTire),
      switchMap(action =>
        this.carService.exportFile(action.filters, action.fileName).pipe(
          map(() => ManageCarTiresActions.exportFileAboutCarTireSuccess()),
          catchError((err: HttpErrorResponse) => of(ManageCarTiresActions.exportFileAboutCarTireFailure({payload: err})))
        )
      )
    )
  )

  changeTireVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageCarTiresActions.changTireVisibility),
      switchMap(action =>
        this.carService.changeVisibility(action.id).pipe(
          map(() => ManageCarTiresActions.changTireVisibilitySuccess({id: action.id, forReviews: action.forReviews})),
          catchError((err: HttpErrorResponse) => of(ManageCarTiresActions.changTireVisibilityFailure({payload: err})))
        )
      )
    )
  )

  changeTirePrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageCarTiresActions.changTirePrices),
      switchMap(action =>
        this.carService.changeTirePrices(action.payload).pipe(
          map(() => ManageCarTiresActions.changTirePricesSuccess()),
          catchError((err: HttpErrorResponse) => of(ManageCarTiresActions.changTirePricesFailure({payload: err})))
        )
      )
    )
  )

  changeTireBruttoPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageCarTiresActions.changTireBruttoPrice),
      switchMap(action =>
        this.carService.changeTireBruttoPrice(action.id, action.payload).pipe(
          map((price) => ManageCarTiresActions.changTireBruttoPriceSuccess({price})),
          catchError((err: HttpErrorResponse) => of(ManageCarTiresActions.changTireBruttoPriceFailure({payload: err})))
        )
      )
    )
  )
}
