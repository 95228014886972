import { Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  IBillingOrder,
  IBillingTire,
  IEditOrderItem,
  IOrderEdit
} from '../../../../modules/state/general-admin/orders/orders.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { EOrderLabel } from '../../../business-user.enums';
import { TireDialogComponent } from '../tire-dialog/tire-dialog.component';
import { takeUntil } from 'rxjs/operators';
import {
  changeBillingStatus,
  chargeOrder,
  editOrder,
  getPaymentOrderInfo,
  selectOrderPaymentInfo,
  selectOrdersError,
  selectOrdersLoader,
  selectOrdersSuccessLoader,
  selectPayedMessage,
  selectPayedOrderSuccess
} from '../../../../modules/state/general-admin/orders';
import { NotificationService } from '../../../../api/notification.service';
import { EModalMessage } from '../../../../t4u-admin/pages/criteria/components/criteria.constants';
import { ICarTireDBData } from '../../../../modules/state/general-admin/manage-car-tires';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-order-detail-dialog',
  templateUrl: './order-detail-dialog.component.html',
  styleUrls: ['./order-detail-dialog.component.scss']
})
export class OrderDetailDialogComponent implements OnInit, OnDestroy {

  public orderForm: FormGroup;
  public orderLoader$: Observable<boolean>;
  public EOrderLabel = EOrderLabel;
  private destroy$: Subject<void> = new Subject<void>();
  public acceptCharging: boolean = true;
  public disabledFields: boolean = false;
  public canceledPayment: boolean = false;
  public editingInfoWithoutSumChanged: boolean = false;
  public editingOrderSum: boolean = false;
  public notificationText: string | null = null;
  @ViewChild('notificationDialog') notificationTemplate: TemplateRef<ElementRef>;
  @ViewChild('chargeDialog') chargeTemplate: TemplateRef<ElementRef>;
  public paymentLink: SafeUrl;
  public paymentImages: any[] = [];

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              private store$: Store,
              private alertService: NotificationService,
              private dialogRef: MatDialogRef<OrderDetailDialogComponent>,
              public notificationDialogRef: MatDialogRef<ElementRef>,
              public chargeDialog: MatDialogRef<ElementRef>,
              private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: IBillingOrder) {
  }

  ngOnInit(): void {
    this.orderLoader$ = this.store$.select(selectOrdersLoader);
    this.disabledFields = this.data.billingStatus === 'Paid' || this.data.billingStatus === 'CanecelTransaction';
    this.canceledPayment = this.data.billingStatus === 'CanecelTransaction';
    this.editingOrderSum = this.data.orderStatus === 'LogLead';
    this.initForm();
    this.getQuantity();
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handlePaymentMessageEvent);
    this.notificationText = null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  getOrderPaymentInfo(): void {
    this.store$.dispatch(getPaymentOrderInfo({orderId: this.data.orderId}));
    this.store$.select(selectOrderPaymentInfo).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          if (!data) return;
          this.paymentLink = this.sanitizer.bypassSecurityTrustResourceUrl(data.paymentUrl);
          this.acceptCharging = !!data.paymentUrl;
          this.notificationText = data.status ? null : data.message;
          if (!data.status) return;
          this.openChargingDialog();
        }
      })
  }

  openChargingDialog(): void {
    this.chargeDialog = this.dialog.open(this.chargeTemplate, {
      panelClass: 'admin-dialog',
      minWidth: '800px',
      minHeight: '600px',
      disableClose: true
    });
    this.chargeDialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe({
      next: (success: boolean) => {
        if (success) {
          this.alertService.onSuccess(EModalMessage.payedOrder);
          this.closeDialog(true);
        } else {
          this.alertService.onError(EModalMessage.failedPayment);
        }
      }
    })
    window.addEventListener('message', this.handlePaymentMessageEvent);
  }

  initForm(): void {
    this.orderForm = this.fb.group({
      customerName: [''],
      customerPhone: [''],
      quantity: [''],
      tireName: [''],
      price: [''],
      totalAmount: [''],
      products: this.fb.array([])
    })
    this.orderForm.patchValue(this.data);
    this.data.orderItem.forEach(item => {
      this.addProduct({
        tireResellerId: item.tireResellerId,
        tireName: item.tireName,
        price: item.price,
        quantity: item.quantity,
        articleId: item.articleId,
        id: item.id
      });
    })
  }

  get products(): FormArray {
    return this.orderForm.get('products') as FormArray;
  }

  addProduct(tire: IBillingTire): void {
    const newGroup = this.fb.group({
      id: [tire.id],
      quantity: [tire.quantity],
      name: [tire.tireName],
      price: [tire.price],
      articleId: [tire.articleId]
    })
    this.products.push(newGroup);
  }

  getQuantity(): void {
    this.orderForm.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => {
          if (!value) return;
          if (this.data.totalAmount !== value.totalAmount) {
            this.editingOrderSum = true;
            this.acceptCharging = false;
            this.editingInfoWithoutSumChanged = false;
          } else {
            this.editingInfoWithoutSumChanged = true;
            this.editingOrderSum = false;
            this.acceptCharging = false;
          }
          this.calcTotalAmount();
        }
      })
  }

  closeDialog(needReload: boolean): void {
    this.dialogRef.close(needReload);
  }

  openTiresDialog(adding: boolean, idx?: number): void {
    this.dialog.open(TireDialogComponent, {
      data: this.data.orderItem[0].tireResellerId,
      panelClass: 'admin-dialog',
      minWidth: '80vw'
    }).afterClosed().pipe(takeUntil(this.destroy$)).subscribe({
      next: (tire: ICarTireDBData) => {
        if (!tire) return;
        if (adding) {
          const newProduct: IBillingTire = {
            tireResellerId: this.data.orderItem[0].tireResellerId,
            quantity: 1,
            price: tire.price,
            tireName: tire.name,
            articleId: tire.id
          };
          this.addProduct(newProduct);
        } else if (!adding && idx !== undefined) {
          const productGroup = this.products.at(idx) as FormGroup;
          productGroup.patchValue({
            name: tire.name,
            price: tire.price,
            articleId: tire.id
          }, {emitEvent: false});
        }
        this.calcTotalAmount();
      }
    });
  }

  calcTotalAmount(): void {
    const total = this.products.controls.reduce((acc, control) => {
      const product = control.value;
      return acc + (+product.price * +product.quantity);
    }, 0);
    this.orderForm.controls['totalAmount'].patchValue(total, {emitEvent: false});
  }

  checkRequest(message: string, closeModal?: boolean): void {
    combineLatest(
      this.store$.select(selectOrdersError),
      this.store$.select(selectOrdersSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && !error) {
            this.alertService.onSuccess(message);
            if (closeModal) {
              this.acceptCharging = true;
              return;
            }
            this.dialog.closeAll();
          }
          if (!loader && error) this.alertService.onError(error.error.message);
        }
      })
  }

  editOrder(sendSms: boolean, closeModal?: boolean): void {
    const formValue = this.orderForm.value;
    let orderItems: IEditOrderItem[] = this.products.value;
    orderItems = orderItems.map(item => {
      if (item.id === null) {
        const {id, ...rest} = item;
        return rest;
      }
      return item;
    });

    let newOrderDetails: IOrderEdit = {
      customerName: formValue.customerName,
      phoneNumber: formValue.customerPhone,
      remark: this.data.remark,
      orderItems
    }

    if (sendSms) {
      this.store$.dispatch(editOrder({id: this.data.orderId, payload: newOrderDetails, sendSmsAfterEdit: true}));
      this.checkRequest(EModalMessage.smsWasSend, closeModal);
      return;
    }

    this.store$.dispatch(editOrder({id: this.data.orderId, payload: newOrderDetails, sendSmsAfterEdit: false}));
    this.checkRequest(EModalMessage.edit, closeModal);
  }

  chargeOrder(): void {
    if (this.data.billingStatus === 'BuyByPhone') {
      this.getOrderPaymentInfo();
      return;
    }
    this.store$.dispatch(chargeOrder({payload: this.data.orderId}));
    this.checkPaymentRequest(EModalMessage.payedOrder);
  }

  private handlePaymentMessageEvent = (event: MessageEvent) => {
    if (event.origin !== 'https://sandbox.meshulam.co.il') {
      return;
    }
    console.log('event', event);
    console.log('event.data', event.data);
    if (event.data && typeof event.data === 'object' && event.data?.action === 'payment' && 'status' in event.data) {
      this.chargeDialog.close(event.data.status === 1);
    }
  }

  checkPaymentRequest(message: string): void {
    combineLatest(
      this.store$.select(selectOrdersError),
      this.store$.select(selectPayedOrderSuccess),
      this.store$.select(selectPayedMessage),
      this.store$.select(selectOrdersSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, payed, apiMessage, loader]) => {
          if (loader && !error && payed) {
            this.alertService.onSuccess(message);
            this.closeDialog(true);
            return;
          }
          if (!payed && !loader && apiMessage) this.alertService.onError(apiMessage);
        }
      })
  }

  removeTire(index: number): void {
    this.products.removeAt(index);
  }

  cancelOrder(): void {
    this.notificationDialogRef = this.dialog.open(this.notificationTemplate, {
      panelClass: 'admin-dialog',
      minWidth: '800px'
    });
  }

  cancelPaymentOrder(): void {
    this.store$.dispatch(changeBillingStatus({id: this.data.orderId, status: 'CanecelTransaction'}));
  }

  closeAll(): void {
    this.notificationDialogRef.close();
    this.closeDialog(false);
  }
}
