import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { AngularSvgIconModule } from "angular-svg-icon";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MaterialsModule } from './material/materials/materials.module';
import { SharedModule } from './modules/shared/shared.module';
import { StatisticsModule } from './modules/statistics/statistics.module';
import { PaymentsModule } from './modules/payments/payments.module';
import { StarterSharedModule } from 'starter-shared';
import { environment } from "../environments/environment";
import { AppStateModule } from "./modules/state/state.module";
import { AuthInterceptor } from "./modules/auth/interceptors/auth.interceptor";
import { MonitorInterceptor } from './api/interceptors/monitor.interceptor';
import { GoogleMapModule } from './modules/google-map/google-map.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SidebarComponent,
  ],
  imports: [
    StarterSharedModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialsModule,
    SharedModule,
    StatisticsModule,
    PaymentsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    AppStateModule,
    AngularEditorModule,
    GoogleMapModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: environment.defaultLanguage
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MonitorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
