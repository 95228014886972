import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NotificationService } from '../../../../api/notification.service';
import {
  INumberParam,
  ITWSValidator,
  TireResellerFull,
  updateTireReseller
} from '../../../../modules/state/general-admin/tire-reseller';
import { combineLatest, Observable, Subject } from 'rxjs';
import { getLookupParameters, ILookupParameters } from '../../../../modules/state/general-admin/characteristic';
import { ImageModeEnum } from '../../../../modules/shared/enums/imageMode.enums';
import {
  ETWSControls,
  ETWSLabel,
  ETWSPlaceholder,
  EVehicleType,
  SabbathTimeControls,
  TimeControlsWithoutSabbath,
  TimeFormControlWithSabbath,
  validatorsFields
} from '../../../../t4u-admin/pages/manage-tws/tws.enums';
import { EImgLoaderType, IImageFile, IImageSize } from '../../../../modules/shared/enums/imageLoader.enums';
import { selectLookupParams } from '../../../../modules/state/general-admin/characteristic/characteristic.selectors';
import {
  getResellerError,
  getResellerLoader,
  getResellerSuccessLoader
} from '../../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import { takeUntil } from 'rxjs/operators';
import { EModalMessage } from '../../../../t4u-admin/pages/criteria/components/criteria.constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { isEmptyObj } from '../../../../utils/helpers';
import { passwordValidator, timeValidator } from '../../../../utils/validators';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { GoogleMapComponent } from '../../../../modules/google-map/component/google-map.component';
import { IMapsMarker } from '../../../../modules/google-map/maps.interface';

@Component({
  selector: 'app-dashboard-dialog',
  templateUrl: './dashboard-dialog.component.html',
  styleUrls: ['./dashboard-dialog.component.scss']
})
export class DashboardDialogComponent implements OnInit {
  @ViewChildren("time") times: QueryList<ElementRef>;
  @ViewChildren("sabbathTime") sabbathTime: QueryList<ElementRef>;
  public twsForm: FormGroup;
  public ETWSLabel = ETWSLabel;
  public EVehicleType = EVehicleType;
  public ETWSPlaceholder = ETWSPlaceholder;
  public EImgLoaderType = EImgLoaderType;
  public tireReseller: TireResellerFull;
  public TWSParams$: Observable<ILookupParameters>;
  public isLoad$: Observable<boolean>;
  public destroy$: Subject<void> = new Subject<void>();
  public additionalFiles: IImageFile[] = [];
  public vehicleId: number;
  public imgMode: string = ImageModeEnum.None;
  public ETWSControls = ETWSControls;
  public validatorFields: ITWSValidator[] = validatorsFields;
  public checkedBrands: number[];
  public imageSize: IImageSize = {width: 234, height: 82};
  public imagesToRemove: number[] = [];
  public worksInSabbath: boolean = false;

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private store$: Store,
              public auth: AuthService,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: TireResellerFull) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    const more: number[] = [];
    const tireTypes: number[] = [];
    this.data.moreOptions?.forEach(item => more.push(item.id));
    this.data.tireTypes?.forEach(item => tireTypes.push(item.id));
    this.worksInSabbath = !!this.data.sabbathWorkingHours.closeTime && !!this.data.sabbathWorkingHours.openTime;
    this.twsForm = this.fb.group({
      UserName: [this.data.userName, [Validators.required]],
      Password: [''],
      ConfirmPassword: [''],
      isActive: [true],
      Email: [this.data.email, [Validators.email, Validators.required]],
      ContactPhoneNumber: [this.data.contactPhone, [Validators.required]],
      TWSName: [this.data.twsName, [Validators.required]],
      ContactName: [this.data.contactName, [Validators.required]],
      "Logo.File": [''],
      "Logo.Mode": [this.imgMode],
      OfficePhone: [this.data.officePhone],
      Description: [this.data.description || ''],
      TireTypes: [tireTypes],
      Subscription: [this.data.subscriptionType.id],
      // CoveredLead: [this.data.coveredLead],
      Fax: [this.data.fax || ''],
      IncludeVAT: [this.data.includeVAT],
      "AddressViewModel.LocationId": [this.data.address.city?.id || '', [Validators.required]],
      "AddressViewModel.Street": [this.data.address.street || '', [Validators.required]],
      "AddressViewModel.BuildingNumber": [''],
      "RegularWorkingHours.OpenTime": [this.convertTime(this.data.regularWorkingHours.openTime)],
      "RegularWorkingHours.CloseTime": [this.convertTime(this.data.regularWorkingHours.closeTime)],
      "FridayWorkingHours.OpenTime": [this.convertTime(this.data.fridayWorkingHours.openTime)],
      "FridayWorkingHours.CloseTime": [this.convertTime(this.data.fridayWorkingHours.closeTime)],
      "SabbathWorkingHours.OpenTime": [this.convertTime(this.data.sabbathWorkingHours.openTime)],
      "SabbathWorkingHours.CloseTime": [this.convertTime(this.data.sabbathWorkingHours.closeTime)],
      // BrandIds: [this.convertBrandData(this.data.brands)],
      isOpenDuringSaturday: [false],
      MoreOptions: [more],
      VehicleType: [this.data.vehicleType.value.toLowerCase()],
      IsOpenAllTime: [this.data.isOpenAllTime],
      Latitude: [+this.data.latitude, [Validators.required]],
      Longitude: [+this.data.longitude, [Validators.required]],
      IsActive: [this.data.active],
      WazeNavigation: [this.data.wazeNavigation || ''],
      Coupon: [this.data.coupon || ''],
      PhoneLeadPrice: [this.data.phoneLeadPrice || ''],
      RimsLeadPrice: [this.data.rimsLeadPrice || ''],
      IndexLeadPrice: [this.data.indexLeadPrice || ''],
      ConverseLeadPrice: [this.data.converseLeadPrice || ''],
      NavigationLeadPrice: [this.data.navigationLeadPrice || ''],
      ZapLeadPrice: [this.data.zapLeadPrice || ''],
      StarterLeadPrice: [this.data.starterLeadPrice || ''],
      SendSMS: [this.data.sendSMS || false],
      ZapPhone: [this.data.zapPhone],
      GoogleSearchText: [this.data.googleSearchText || ''],
      ZapTireShopId: [this.data.zapTireShopId || ''],
      SubLocation: [this.data?.subLocation || ''],
      OwnerPhoneNumber: [this.data?.ownerPhoneNumber || this.data.contactPhone]
    });
    this.addValidators();
  }

  convertPhone(phone: string): string {
    if (!phone) return '';
    return phone.includes('+9720') ? phone.replace('+972', '') : phone.replace('+972', '0');
  }

  addValidators(): void {
    this.twsForm.addValidators(passwordValidator(this.getControl(ETWSControls.password), this.getControl(ETWSControls.confirmPassword)));
    this.validatorFields.forEach(validator => {
      this.twsForm.addValidators(timeValidator(
        validator.firstError, validator.secondError,
        this.getControl(validator.controlNameFirst),
        this.getControl(validator.controlNameSecond)))
    });
  }

  getControl(control: string): AbstractControl {
    return this.twsForm?.controls[control] as AbstractControl;
  }

  convertTime(date: Date | null): string {
    if (!date) return '';
    const fullDate = new Date(date);
    const hours = (fullDate.getUTCHours() < 10 ? '0' : '') + fullDate.getUTCHours();
    const minutes = (fullDate.getUTCMinutes() < 10 ? '0' : '') + fullDate.getUTCMinutes();
    return hours + ':' + minutes;
  }

  convertBrandData(brands: INumberParam[]): number[] {
    const selectedBrands: number[] = [];
    if (!brands) return [];
    brands.forEach(brand => selectedBrands.push(brand.id));
    return selectedBrands;
  }

  getData(): void {
    this.store$.dispatch(getLookupParameters({payload: 'Location,TireManufacturer,TWSTireType,TireType,VehicleType,TWSMoreOptions'}));
    this.TWSParams$ = this.store$.select(selectLookupParams);
    this.isLoad$ = this.store$.select(getResellerLoader);
    this.vehicleId = this.data.vehicleType.id;
    this.checkedBrands = this.convertBrandData(this.data.brands);
  }

  updateCompanyLogo(image: File): void {
    this.twsForm.controls['Logo.File'].setValue(image);
    this.twsForm.controls['Logo.Mode'].setValue(ImageModeEnum.Replace);
  }

  updateAdditionalPhoto(images: IImageFile[]): void {
    this.additionalFiles = images;
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  submitForm(): void {
    this.store$.dispatch(updateTireReseller({payload: this.createFormData(), businessUser: true}));
    this.checkRequest(EModalMessage.edit);
  }

  createFormData(): FormData {
    const formData = new FormData();
    const MoreOptions = Array.isArray(this.twsForm.value.MoreOptions) ? this.twsForm.value.MoreOptions.join() : '';
    const TireTypes = Array.isArray(this.twsForm.value.TireTypes) ? this.twsForm.value.TireTypes.join() : '';
    // const OfficePhone = this.checkPhoneValue('OfficePhone');
    // const ContactPhoneNumber = this.checkPhoneValue('ContactPhoneNumber');
    const formValue = {...this.twsForm.value, MoreOptions, TireTypes};
    for (const key in formValue) formData.append(key, formValue[key]);
    formData.append("Id", this.data.id);
    if (this.imagesToRemove.length) this.imagesToRemove.forEach((id, idx) => formData.append(`RemoveAdditionalPictures[${idx}]`, id.toString()))
    if (!this.additionalFiles.length) {
      formData.append(`AdditionalPictures`, '');
      return formData;
    }
    this.additionalFiles.forEach((file, idx) => {
      formData.append(`AdditionalPictures[${idx}].mode`, file.mode);
      formData.append(`AdditionalPictures[${idx}].file`, file.file);
    });
    return formData;
  }

  checkPhoneValue(controlName: string): string {
    if (this.twsForm.get(controlName)?.value === '') return '';
    return this.twsForm.get(controlName)?.value.includes('+972') ? this.twsForm.get(controlName)?.value : `+972${this.twsForm.get(controlName)?.value}`
  }

  getBrandsIds(brandIds: number[]): void {
    this.checkedBrands = brandIds;
    this.twsForm.get('BrandIds')?.setValue(brandIds);
  }

  checkOpenTime(event: MatCheckboxChange): void {
    if (event.checked && this.worksInSabbath) {
      this.times.forEach((time) => time.nativeElement.value = '00:00');
      this.sabbathTime.forEach((time) => time.nativeElement.value = '00:00');
      TimeFormControlWithSabbath.forEach((item) => this.twsForm.controls[item]?.setValue('00:00'));
    }
    if (event.checked && !this.worksInSabbath) {
      this.sabbathTime.forEach((time) => time.nativeElement.value = '');
      SabbathTimeControls.forEach(item => this.twsForm.controls[item]?.setValue(''));
      this.times.forEach((time) => time.nativeElement.value = '00:00');
      TimeControlsWithoutSabbath.forEach(item => this.twsForm.controls[item]?.setValue('00:00'));
    }
    if (!event.checked) {
      TimeFormControlWithSabbath.forEach((item) => this.twsForm.controls[item]?.setValue(''));
      this.times.forEach(time => time.nativeElement.value = '');
      this.sabbathTime.forEach(time => time.nativeElement.value = '');
    }
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getResellerError),
      this.store$.select(getResellerSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) {
            const errorObj = error.error.errors;
            const failuresObj = error.error.failures;
            const errors = [];
            for (const key in errorObj) errors.push(errorObj[key][0]);
            for (const key in failuresObj) errors.push(failuresObj[key][0]);
            if (!errors.length) errors.push(error.statusText);
            this.alertService.onError(errors);
          }
        }
      })
  }

  getImagesToRemove(idxArr: number[]): void {
    this.imagesToRemove = idxArr;
  }

  checkSaturdayTime(): void {
    if (!this.worksInSabbath) {
      this.sabbathTime.forEach((time) => time.nativeElement.value = '');
      SabbathTimeControls.forEach(item => this.twsForm.controls[item]?.setValue(''));
    }
  }

  openMap(): void {
    this.dialog.open(GoogleMapComponent, {
      panelClass: 'admin-manufacturer-add-dialog',
      width: '950px',
      data: {
        latitude: +this.data.latitude,
        longitude: +this.data.longitude,
        address: this.data.address?.street || ''
      }
    }).afterClosed().subscribe({
      next: (address: IMapsMarker) => {
        if (!address) return;
        const street = address.address.split(',').slice(0, -1).join();
        this.twsForm.get('Latitude')?.setValue(address.latitude);
        this.twsForm.get('Longitude')?.setValue(address.longitude);
        this.twsForm.controls['AddressViewModel.Street']?.setValue(street);
      }
    })
  }
}
