import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BlogDBElement, IBlogParams } from "../../modules/state/general-admin/blog";
import { IResponse } from '../api-data/api.interfaces';

@Injectable({
  providedIn: "root",
})
export default class BlogApiService {
  readonly PATH = '/news';

  constructor(private apiService: ApiService) {
  }

  getBlogData(params?: IBlogParams): Observable<IResponse<BlogDBElement[]>> {
    return this.apiService.get(`${this.PATH}`, {...params});
  }

  addBlogElement(element: FormData): Observable<BlogDBElement> {
    return this.apiService.post(`${this.PATH}`, element);
  }

  updateBlogElement(element: FormData, id: number): Observable<BlogDBElement> {
    return this.apiService.put(`${this.PATH}/${id}`, element);
  }

  deleteBlogElement(id: number): Observable<BlogDBElement> {
    return this.apiService.deleteNumber(`${this.PATH}`, id);
  }

  changeVisibility(id: number): Observable<{ isVisible: boolean }> {
    return this.apiService.patch(`${this.PATH}/${id}/visibility`, id);
  }
}
