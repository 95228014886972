import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IParameter } from '../../../state/general-admin/tire-reseller';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {

  @Input() dataList: IParameter[];
  @Output() selectedIds = new EventEmitter<number[]>();
  @Input() isMultiple: boolean = true;
  @Input() isListLoad: boolean = false;
  public selectedValues: number[] = [];
  private _checkedIds: number[];
  public render: boolean = true;

  @Input() set checkedIds(ids: number[]) {
    this._checkedIds = ids;
    this.render = false;
    this.checkBrandIds();
  }

  get checkedIds(): number[] {
    return this._checkedIds;
  }

  checkBrandIds(): void {
    if (!this.checkedIds.length) {
      this.render = true;
      return;
    }
    this.selectedValues = [...new Set(this.selectedValues.concat(this.checkedIds))];
    if (this.isListLoad) this.selectedIds.emit(this.selectedValues);
    this.render = true;
  }

  addSelectedValue(id: string | number): void {
    if (this.selectedValues.includes(+id)) {
      this.selectedValues = this.selectedValues.filter(value => value !== +id);
      this.selectedIds.emit(this.selectedValues);
      return;
    }
    if (!this.isMultiple && this.selectedValues.length === 1) {
      this.selectedValues = [];
      this.selectedValues.push(+id);
      this.selectedIds.emit(this.selectedValues);
      return;
    }
    this.selectedValues = [...this.selectedValues];
    this.selectedValues.push(+id);
    this.selectedIds.emit(this.selectedValues);
  }

  checkValue(id: string | number): boolean {
    return this.selectedValues.includes(+id);
  }

  sortOptions(params: IParameter[]): IParameter[] {
    if (!params) return [];
    return [...params].sort((a, b) => a.value > b.value ? 1 : -1)
  }
}
