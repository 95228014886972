import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as widthActions from "./width.actions";
import { CriteriaService } from '../../../../../api/general-admin-services/criteria.service';
import { widthPath } from '../../../../../api/api-data/api.constants';

@Injectable()
export class WidthEffects {
  constructor(private actions$: Actions,
              private criteriaService: CriteriaService) {
  }

  getWidthData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widthActions.getWidthData),
      switchMap(() =>
        this.criteriaService.getExpandedCriteriaData(widthPath).pipe(
          map(response => widthActions.getWidthDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(widthActions.getWidthDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createWidthElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widthActions.addWidthElement),
      switchMap(action =>
        this.criteriaService.addExpandedCriteriaElement(widthPath, action.payload).pipe(
          map(response => widthActions.addWidthElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(widthActions.addWidthElementFailure({payload: err})))
        )
      )
    )
  )

  updateTireWeightElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widthActions.updateWidthElement),
      switchMap(action =>
        this.criteriaService.updateExpandedCriteriaElement(action.payload, action.id).pipe(
          map(response => widthActions.updateWidthElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(widthActions.updateWidthElementFailure({payload: err})))
        )
      )
    )
  )

  deleteTireWeightElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widthActions.deleteWidthElement),
      switchMap(action =>
        this.criteriaService.deleteCriteria(action.payload).pipe(
          map(() => widthActions.deleteWidthElementSuccess()),
          catchError((err: HttpErrorResponse) => of(widthActions.deleteWidthElementFailure({payload: err})))
        )
      )
    )
  )
}
