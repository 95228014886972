import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import {
  getOptionsList,
  selectCharacteristicSuccessLoader,
  selectLookupParams
} from "../../../../../modules/state/general-admin/characteristic/characteristic.selectors";
import { ImageModeEnum } from "../../../../../modules/shared/enums/imageMode.enums";
import {
  addCarTire,
  changTirePrices,
  ETireLabel,
  getCarTiresList,
  ICarTireDBData,
  updateCarTire
} from '../../../../../modules/state/general-admin/manage-car-tires';
import {
  ECarTireLabel,
  ECarTirePlaceholder,
  ETirePricesLabel,
  ETirePricesPlaceholder,
  pricesAction
} from '../../manage-car.enums';
import {
  ICharacteristicObject,
  ILookupParameters,
  IOptions,
  ISubCarTireType
} from '../../../../../modules/state/general-admin/characteristic';
import { isEmptyObj } from '../../../../../utils/helpers';
import { EModalType } from '../../../../../modules/shared/interfaces/table.interfaces';
import {
  getCarTirePricesError,
  getCarTirePricesLoader,
  getCarTirePricesSuccessLoader,
  getCarTiresError,
  getCarTiresLoader,
  getCarTiresSuccessLoader
} from '../../../../../modules/state/general-admin/manage-car-tires/manage-car-tires.selectors';
import { NotificationService } from '../../../../../api/notification.service';
import { EModalMessage } from '../../../criteria/components/criteria.constants';
import { IParameter } from '../../../../../modules/state/general-admin/tire-reseller';
import { refactorCharacteristicList } from '../../../../../utils/converting.functions';

@Component({
  selector: 'app-manage-tws-dialog',
  templateUrl: './manage-car-tires-dialog.component.html',
  styleUrls: ['./manage-car-tires-dialog.component.scss']
})
export class ManageCarTiresDialogComponent implements OnInit, OnDestroy {

  public params$: Observable<ILookupParameters>;
  public options$: Observable<IOptions>;
  public destroy$: Subject<void> = new Subject<void>();
  public characteristicList: ICharacteristicObject;
  public tireForm: FormGroup;
  public priceFrom: FormGroup;
  public ECarTireLabel = ECarTireLabel;
  public ECarTirePlaceholder = ECarTirePlaceholder;
  public ETirePricesLabel = ETirePricesLabel;
  public ETirePricesPlaceholder = ETirePricesPlaceholder;
  public EModalType = EModalType;
  public mainTitle: string = ETireLabel.add;
  public priceActions = pricesAction;
  public isParamsLoad$: Observable<boolean>;
  public tireTypes: number[] = [];
  public loader$: Observable<boolean>;
  public pricesLoader$: Observable<boolean>;
  public typeSelect: boolean = false;

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private store$: Store,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: ICarTireDBData) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getOptionsList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    if (this.data.modalType === EModalType.edit) {
      const tireTypes: number[] = [];
      this.data.tireType?.forEach(item => tireTypes.push(item.id));
      this.typeSelect = tireTypes.includes(84);
      this.tireForm = this.fb.group({
        Name: [this.data.name.toString().replace(/\u00a0/g, ' '), Validators.required],
        BrandId: [this.data.brand.id, Validators.required],
        BruttoPrice: [this.data.bruttoPrice],
        TireTypes: [tireTypes],
        SubTireTypeId: [this.data.subTireType?.id || ''],
        'Image.Mode': [ImageModeEnum.None],
        'Imane.File': [null],
        width: [this.data.width?.id, Validators.required],
        height: [this.data.height?.id, Validators.required],
        diameter: [this.data.diameter?.id, Validators.required],
        weightCode: [this.data.weightCode?.id],
        speedCode: [this.data.speedCode?.id],
        noiseLevel: [this.data.noiseLevel?.id],
        roadGrip: [this.data.roadGrip?.id],
        fuelConsumption: [this.data.fuelConsumption?.id],
        RunFlate: [this.data.runFlate]
      });
      this.mainTitle = this.data.copyTire ? ETireLabel.add : ETireLabel.edit;
      return;
    }
    if (this.data.modalType === EModalType.prices) {
      this.priceFrom = this.fb.group({
        Percentage: ['', [Validators.required]],
        BrandId: ['', Validators.required],
        Navigation: [0],
        VehicleType: ['car']
      });
      this.mainTitle = ETireLabel.prices;
      return;
    }
    this.tireForm = this.fb.group({
      Name: ['', Validators.required],
      BrandId: ['', Validators.required],
      BruttoPrice: [''],
      TireTypes: [''],
      SubTireTypeId: [''],
      'Image.Mode': [ImageModeEnum.None],
      width: ['', Validators.required],
      height: ['', Validators.required],
      diameter: ['', Validators.required],
      weightCode: [''],
      speedCode: [''],
      noiseLevel: [''],
      roadGrip: [''],
      fuelConsumption: [''],
      RunFlate: [false]
    });
  }

  getOptionsList(): void {
    this.data.tireType?.forEach(item => this.tireTypes.push(item.id));
    this.loader$ = this.store$.select(getCarTiresLoader);
    this.pricesLoader$ = this.store$.select(getCarTirePricesLoader);
    this.params$ = this.store$.select(selectLookupParams);
    this.options$ = this.store$.select(getOptionsList);
    this.isParamsLoad$ = this.store$.select(selectCharacteristicSuccessLoader);
    this.store$.select(getOptionsList).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (list) => this.characteristicList = refactorCharacteristicList(list)
      });
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  submitForm(type: EModalType): void {
    if (this.tireForm.invalid) return this.tireForm.markAllAsTouched();
    if (type === EModalType.add || this.data.copyTire) {
      const copyTireId = this.data.copyTire ? this.data.id : undefined;
      this.store$.dispatch(addCarTire({payload: this.createFormData(), copyTireId}));
      this.checkRequest(EModalMessage.add);
      return;
    }
    this.store$.dispatch(updateCarTire({payload: this.createFormData()}));
    this.checkRequest(EModalMessage.edit);
  }

  createFormData(): FormData {
    let formValue = {...this.tireForm.value};
    const formData = new FormData();
    const formKeys = ['Name', 'BrandId', 'BruttoPrice', 'TireTypes', 'Image.Mode', 'SubTireTypeId', 'RunFlate', 'isActive'];
    let characteristicValues = [];
    let optionIds = '';
    if (this.data.modalType === EModalType.edit && this.data.copyTire) {
      formValue = {...formValue, isActive: true}
    }
    for (const key in formValue) {
      if (formKeys.includes(key)) {
        formData.append(key, formValue[key])
      } else {
        characteristicValues.push(formValue[key])
        optionIds = characteristicValues.filter(value => value !== null).join();
      }
    }
    formData.append('CharacteristicsOptionId', optionIds);
    if (this.data.modalType === EModalType.edit && !this.data.copyTire) {
      formData.append('Id', this.data.id.toString());
      return formData;
    }
    return formData;
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getCarTiresError),
      this.store$.select(getCarTiresSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }

  submitPriceForm(): void {
    const payload = new FormData();
    let pricesValue;
    if (this.priceFrom.value.Navigation === 0) {
      pricesValue = {...this.priceFrom.value, Navigation: 'increase'}
    } else {
      pricesValue = {...this.priceFrom.value, Navigation: 'decrease'}
    }
    for (const key in pricesValue) {
      payload.append(key, pricesValue[key]);
    }
    this.store$.dispatch(changTirePrices({payload}));
    this.checkPricesRequest(EModalMessage.pricesChanged);
  }

  isSelected(value: { status: boolean; ids: number[] }): void {
    if (!value.status) return;
    this.typeSelect = true;
    if (!value.ids.includes(84)) this.tireForm.get('SubTireTypeId')?.setValue('');
    this.tireTypes = value.ids;
  }

  checkSubCategory(types: ISubCarTireType[]): IParameter[] {
    const subcategory = types.find(type => type.id === '84');
    const idInclude = this.tireTypes.includes(84);
    return subcategory && idInclude ? subcategory.subTireType : [];
  }

  checkPricesRequest(message: string): void {
    combineLatest(
      this.store$.select(getCarTirePricesError),
      this.store$.select(getCarTirePricesSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.store$.dispatch(getCarTiresList({params: this.data.params}));
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }
}
