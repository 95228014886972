import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NotificationService } from '../../../../api/notification.service';
import {
  getResellerPrices,
  getResellerPricesError,
  getResellerPricesLoader,
  getResellerPricesSuccessLoader
} from '../../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  changeTireResellerPricesProfit,
  getTireResellerPricesProfit,
  IChangeTWSProfit,
  ITWSPriceProfit,
  ITWSPricesControl
} from '../../../../modules/state/general-admin/tire-reseller';
import { EModalMessage } from '../../criteria/components/criteria.constants';
import { takeUntil } from 'rxjs/operators';
import { isEmptyObj } from '../../../../utils/helpers';

@Component({
  selector: 'app-change-prices-dialog',
  templateUrl: './change-prices-dialog.component.html',
  styleUrls: ['./change-prices-dialog.component.scss']
})
export class ChangePricesDialogComponent implements OnInit, OnDestroy {
  public profitForm: FormGroup;
  public pricesLoader$: Observable<boolean>;
  public destroy$: Subject<void> = new Subject<void>();
  public resellerPrices: { first: ITWSPriceProfit[], second: ITWSPriceProfit[] };

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private store$: Store,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeAddDialog() {
    this.dialog.closeAll();
  }

  initForm(): void {
    this.profitForm = this.fb.group({
      firstProfit: this.fb.array([]),
      secondProfit: this.fb.array([]),
    });
  }

  getData(): void {
    this.store$.dispatch(getTireResellerPricesProfit({id: this.data}));
    this.pricesLoader$ = this.store$.select(getResellerPricesLoader);
    this.store$.select(getResellerPrices).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: prices => {
          this.resellerPrices = this.sortPrices(prices);
          this.buildFormArray(this.firstProfit, this.resellerPrices.first);
          this.buildFormArray(this.secondProfit, this.resellerPrices.second);
        }
      });
  }

  get firstProfit(): FormArray {
    return this.profitForm.controls["firstProfit"] as FormArray;
  }

  get secondProfit(): FormArray {
    return this.profitForm.controls["secondProfit"] as FormArray;
  }

  buildFormArray(array: FormArray, prices: ITWSPriceProfit[]): void {
    if (!prices.length) return;
    array.clear();
    prices.forEach(option => {
      array.push(
        this.fb.group({
          profit: [option.profit || '', [Validators.required]],
          value: [option.characteristicValue],
          characteristicOptionId: [option.characteristicId]
        })
      )
    })
  }

  changeTirePrices(): void {
    const formValue: { firstProfit: ITWSPricesControl[], secondProfit: ITWSPricesControl[] } = {...this.profitForm.value};
    const profit: IChangeTWSProfit = {profit: []};
    formValue.firstProfit.forEach(option => {
      delete option['value'];
      profit.profit.push({profit: +option.profit, characteristicOptionId: option.characteristicOptionId})
    })
    formValue.secondProfit.forEach(option => {
      delete option['value'];
      profit.profit.push({profit: +option.profit, characteristicOptionId: option.characteristicOptionId})
    })
    this.store$.dispatch(changeTireResellerPricesProfit({profit, id: this.data}));
    this.checkRequest(EModalMessage.pricesChanged);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getResellerPricesError),
      this.store$.select(getResellerPricesSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) {
            const failures = error.error.failures;
            const errors = [];
            for (const key in failures) {
              errors.push(failures[key][0])
              this.alertService.onError(errors)
            }
            this.alertService.onError(error.statusText)
          }
        }
      })
  }

  sortPrices(params: ITWSPriceProfit[]): { first: ITWSPriceProfit[], second: ITWSPriceProfit[] } {
    if (!params.length) return {first: [], second: []};
    let options = [...params];
    const stringValue = options.filter(n => isNaN(+n.characteristicValue)).sort((a, b) => a.characteristicValue > b.characteristicValue ? 1 : -1)
    const integerValue = options.filter(x => !isNaN(+x.characteristicValue)).sort((a, b) => +a.characteristicValue - +b.characteristicValue);
    const prices = [...integerValue, ...stringValue];
    const partOne = prices.slice(0, options.length / 2);
    const partSecond = prices.slice(options.length / 2);
    return {first: partOne, second: partSecond};
  }
}
