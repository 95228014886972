import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";

import { IAdminNotification, NotificationData } from "./admin-notifications.model";

export const GET_ADMIN_NOTIFICATION_LIST = '[ADMIN_NOTIFICATION] Get admin notification';
export const GET_ADMIN_NOTIFICATION_LIST_SUCCESS = '[ADMIN_NOTIFICATION] Get admin notification success';
export const GET_ADMIN_NOTIFICATION_LIST_FAILURE = '[ADMIN_NOTIFICATION] Get admin notification failure';

export const ADD_ADMIN_NOTIFICATION_ELEMENT = '[ADMIN_NOTIFICATION] Add admin notification';
export const ADD_ADMIN_NOTIFICATION_ELEMENT_SUCCESS = '[ADMIN_NOTIFICATION] Add admin notification success';
export const ADD_ADMIN_NOTIFICATION_ELEMENT_FAILURE = '[ADMIN_NOTIFICATION] Add admin notification failure';

export const DISMISS_ADMIN_NOTIFICATION_ELEMENT = '[ADMIN_NOTIFICATION] Dismiss admin notification';
export const DISMISS_ADMIN_NOTIFICATION_ELEMENT_SUCCESS = '[ADMIN_NOTIFICATION] Dismiss admin notification success';
export const DISMISS_ADMIN_NOTIFICATION_ELEMENT_FAILURE = '[ADMIN_NOTIFICATION] Dismiss admin notification failure';

export const getAdminNotification = createAction(
  GET_ADMIN_NOTIFICATION_LIST
)

export const getAdminNotificationSuccess = createAction(
  GET_ADMIN_NOTIFICATION_LIST_SUCCESS,
  props<{ payload: IAdminNotification[] }>()
)

export const getAdminNotificationFailure = createAction(
  GET_ADMIN_NOTIFICATION_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addAdminNotification = createAction(
  ADD_ADMIN_NOTIFICATION_ELEMENT,
  props<{ payload: NotificationData }>()
)

export const addAdminNotificationSuccess = createAction(
  ADD_ADMIN_NOTIFICATION_ELEMENT_SUCCESS,
  props<{ payload: HttpResponse<any> }>()
)

export const addAdminNotificationFailure = createAction(
  ADD_ADMIN_NOTIFICATION_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const dismissAdminNotification = createAction(
  DISMISS_ADMIN_NOTIFICATION_ELEMENT,
  props<{ id: number }>()
)

export const dismissAdminNotificationSuccess = createAction(
  DISMISS_ADMIN_NOTIFICATION_ELEMENT_SUCCESS,
  props<{ payload: HttpResponse<any> }>()
)

export const dismissAdminNotificationFailure = createAction(
  DISMISS_ADMIN_NOTIFICATION_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
