<div class="select-wrap">
  <label class="label">
    {{label | translate}}
    <span *ngIf="isRequired">*</span>
  </label>
  <mat-form-field class="form-element" appearance="fill" [ngClass]="{'errorInput': !control.valid && (control.dirty || control.touched)}">
    <mat-select #select multiple placeholder="{{placeholder | translate}}"
                (selectionChange)="valueChange($event)" [value]="value">
      <div class="toggle">
        <mat-checkbox class="form-checkbox" *ngIf="allowAllSelection" [(ngModel)]="allSelected"
                      [ngModelOptions]="{standalone: true}"
                      (change)="toggleAllSelection()">{{'SELECT_ALL' | translate}}</mat-checkbox>
      </div>
      <div class="toggle">
        <mat-checkbox class="form-checkbox" *ngIf="needCancelAll" [(ngModel)]="cancelAll"
                      [ngModelOptions]="{standalone: true}"
                      (change)="cancelAllSelection()">{{'CANCEL_ALL' | translate}}</mat-checkbox>
      </div>
      <mat-option (click)="selectValue()" *ngFor="let option of sortOptions(options)" [value]="checkOption(option.id)">
        {{option.value | translate}}
      </mat-option>
    </mat-select>
    <mat-hint>
      <mat-error *ngIf="control.hasError('required') && (control.dirty || control.touched)">
        {{'THIS_FIELD_IS_REQUIRED' | translate}}
      </mat-error>
    </mat-hint>
  </mat-form-field>
</div>
