export enum EParamsLabel {
  width = 'WIDTH',
  height = 'HEIGHT',
  diameter = 'DIAMETER',
  tireType = 'TIRE_TYPE',
  brand = 'MANUFACTURER'
}

export enum EParamsPlaceholder {
  width = 'SELECT_WIDTH',
  height = 'SELECT_HEIGHT',
  diameter = 'SELECT_DIAMETER',
  tireType = 'SELECT_TIRE_TYPE',
  brand = 'SELECT_MANUFACTURER'
}
