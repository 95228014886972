import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { IFilters, IHeadFilters, IResponse } from '../../../api/api-data/api.interfaces';
import {
  getConsumePriceList,
  getListForSettingPrice,
  IArticlePriceParams,
  IInventoryTire,
  resetTirePrices
} from '../../../modules/state/business-user/article-price';
import { IFilterValue, TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { ConsumerPriceList, SetPriceTableMeta } from '../../business-user.constants';
import { EPricesTitle } from '../../business-user.enums';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../api/language.service';
import {
  selectArticlePriceLoader,
  selectResetPricesError,
  selectResetPricesLoader,
  selectSettingPriceList
} from '../../../modules/state/business-user/article-price/article-price.selectors';
import { getFilterQuery, isEmptyObj } from '../../../utils/helpers';
import {
  InventorySetUpDialogComponent
} from '../inventory-set-up/inventory-set-up-dialog/inventory-set-up-dialog.component';
import { EModalType, ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { NotificationService } from '../../../api/notification.service';
import { EModalMessage } from '../../../t4u-admin/pages/criteria/components/criteria.constants';

@Component({
  selector: 'app-set-price',
  templateUrl: './set-price.component.html',
  styleUrls: ['./set-price.component.scss']
})
export class SetPriceComponent implements OnInit {
  public currentPage: number = 1;
  public setPricesList$: Observable<IResponse<IInventoryTire[]>>;
  public setPricesTableMeta: TableMeta[] = SetPriceTableMeta;
  public filtersData: IFilters = {};
  public filters: string = '';
  public isTableLoad$: Observable<boolean>;
  public activeRoute: string;
  public mainTitle: string = EPricesTitle.setPriceCar;
  public sortingData: string[] = [];
  public sorts: string = 'afterVat';
  public searchControl: FormControl = new FormControl('');
  public searchQuery: string = '';
  public destroy$: Subject<void> = new Subject<void>();
  public vehicleType: string;
  public articleIds: number[] = [];
  public clearSelection: boolean = false;
  public isPriceList: boolean = false;
  public plateNumber: string | null = null;

  constructor(private store$: Store,
              private dialog: MatDialog,
              public route: ActivatedRoute,
              private auth: AuthService,
              private alertService: NotificationService,
              private router: Router,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.checkPage();
    this.getData();
    this.getSearchValue();
  }

  checkPage(): void {
    this.isPriceList = !!this.route.snapshot.data.consumer;
    if (!this.isPriceList) return;
    this.setPricesTableMeta = ConsumerPriceList;
    this.mainTitle = EPricesTitle.consumerPriceList;
  }

  getData(): void {
    this.activeRoute = this.route.snapshot.url[0].path;
    if (!this.auth.reseller) {
      this.router.navigate(['auth/login']);
      return;
    }
    if (this.auth.reseller.vehicleType === 0) this.vehicleType = 'car';
    if (this.auth.reseller.vehicleType === 1) this.vehicleType = 'motorcycle';
    if (this.activeRoute.includes('motorcycles') && !this.isPriceList) this.mainTitle = EPricesTitle.setPriceMoto;
    this.setPricesList$ = this.store$.select(selectSettingPriceList);
    this.isTableLoad$ = this.store$.select(selectArticlePriceLoader);
    this.filters = getFilterQuery(this.filtersData);
    this.isPriceList ? this.getConsumerList() : this.getTireList();
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.isPriceList ? this.getConsumerList() : this.getTireList();
  }

  getTireList(): void {
    this.store$.dispatch(getListForSettingPrice({
      params: {
        page: this.currentPage,
        sorts: this.sorts,
        filters: this.filters,
        search: this.searchQuery,
        VehicleType: this.vehicleType,
      }
    }));
  }

  getConsumerList(): void {
    const params: IArticlePriceParams = {
      page: this.currentPage,
      sorts: this.sorts,
      filters: this.filters,
      search: this.searchQuery,
      VehicleType: this.vehicleType,
    }
    if (!!this.plateNumber) params.plateNumber = this.plateNumber;
    this.store$.dispatch(getConsumePriceList({params}));
  }

  actionDispatch(table: ITableData<IInventoryTire>): void {
    this.dialog.open(InventorySetUpDialogComponent, {
      panelClass: 'admin-dialog',
      data: {
        ...table.data,
        modalType: EModalType.editPrice,
        search: this.searchQuery,
        apiFilters: this.filters,
        page: this.currentPage
      },
      width: '570px'
    })
    return;
  }

  getTableFilters(event: IFilterValue): void {
    this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
    if (!event.checkedIds.length) delete this.filtersData[event.filterKey];
    this.filters = getFilterQuery(this.filtersData);
    this.isPriceList ? this.getConsumerList() : this.getTireList();
  }

  sortData(sortValue: string): void {
    if (sortValue.includes('-')) {
      this.sortingData = this.sortingData.filter(item => item !== sortValue.slice(1));
    } else {
      this.sortingData = this.sortingData.filter(item => item !== `-${sortValue}`);
    }
    this.sortingData.push(sortValue);
    this.sorts = this.sortingData.join();
    this.isPriceList ? this.getConsumerList() : this.getTireList();
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchQuery = value;
        this.currentPage = 1;
        this.isPriceList ? this.getConsumerList() : this.getTireList();
      })
  }

  getArticleForReset(rows: IInventoryTire[]): void {
    const articleIds: any[] = [];
    rows.forEach(item => articleIds.push(item.id));
    this.articleIds = articleIds;
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(selectResetPricesError),
      this.store$.select(selectResetPricesLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
            this.clearSelection = false;
          }
          if (!loader && error && !isEmptyObj(error)) {
            const errorObj = error.error.errors;
            const failuresObj = error.error.failures;
            const errors = [];
            for (const key in errorObj) errors.push(errorObj[key][0]);
            for (const key in failuresObj) errors.push(failuresObj[key][0]);
            if (!errors.length) errors.push(error.statusText);
            this.alertService.onError(errors);
          }
        }
      })
  }

  resetPrices(): void {
    if (!this.articleIds.length) {
      this.alertService.onWarning(EModalMessage.setArticlesToReset);
      return;
    }
    this.store$.dispatch(resetTirePrices({payload: this.articleIds}));
    this.clearSelection = true;
    this.checkRequest(EModalMessage.resetSitePrice);
    this.isPriceList ? this.getConsumerList() : this.getTireList();
    this.articleIds = [];
  }

  getHeadFilters(filters: IHeadFilters): void {
    for (const key in filters) {
      // @ts-ignore
      this.filtersData[key] = {value: [filters[key]], sign: '=='};
      if (!filters[key] || key === 'plate') delete this.filtersData[key];
    }
    this.filters = getFilterQuery(this.filtersData);
    this.currentPage = 1;
    this.isPriceList ? this.getConsumerList() : this.getTireList();
  }

  searchByPlate(plateValue: string | null): void {
    this.plateNumber = plateValue;
    this.currentPage = 1;
    this.getConsumerList();
  }
}
