<div class="content payments">
  <div class="content-head">
    <h3 class="head-title">
      {{'MANAGE_RIMS' | translate}}
    </h3>
    <div class="actions">
      <button class="btn-admin" (click)="openAddDialog()">
        <mat-icon>add</mat-icon>
        {{'ADD_RIM' | translate}}
      </button>
    </div>
  </div>
  <div class="content blog_table" *ngIf="(rimsList$ | async) as response">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="response.content"
      [language]="languageService.mainLanguage.value"
      [filterData]="response.filters"
      [isLoadingTable]="!(isTableLoad$ | async)"
      (filters)="getFilters($event)"
      (onClick)="dispatchEvent($event)"
      (onCheck)="changeVisibility($event)"
    >
    </starter-table>
    <div *ngIf="response && !response.content.length" class="no-result-table">
      {{'NO_DATA_MATCHING_THE_FILTER' | translate}}
    </div>
    <div class="pagination" *ngIf="response.content">
          <span class="pagination-item"
                *ngFor="let page of response.content | paginate:
                { itemsPerPage: response.pageSize,
                 currentPage: response.currentPage,
                 totalItems: response.rowCount }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>
