import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFilterChips } from '../../interfaces/table.interfaces';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss']
})
export class FilterChipsComponent {
  @Output() filterToRemove = new EventEmitter<string>();
  @Input() isListLoad: boolean = false;
  private _filters: IFilterChips;
  public render: boolean = true;
  public filterList: string[][];

  @Input() set filters(value: IFilterChips) {
    this._filters = value;
    this.render = false;
    this.checkValues();
  }

  get filters(): IFilterChips {
    return this._filters;
  }

  checkValues(): void {
    if (!Object.keys(this.filters).length) {
      this.render = false;
      this.filterList = [];
      return;
    }
    this.render = true;
    this.filterList = Object.entries(this.filters);
  }

  removeFilter(filterKey: string): void {
    this.filterList = this.filterList.filter(value => value[0] !== filterKey);
    this.filterToRemove.emit(filterKey);
  }

  removeAllFilters(): void {
    this.filterList.forEach(value => {
      this.filterToRemove.emit(value[0]);
    })
    this.filterList = [];
  }
}
