import { createFeatureSelector, createSelector } from "@ngrx/store";
import { HttpErrorResponse } from '@angular/common/http';

import { BlogState } from "./blog.model";

export const getBlogState = createFeatureSelector<BlogState>('blog');

export const getBlogTable = createSelector(getBlogState,
  (state: BlogState) => state.result);

export const getBlogSuccessLoader = createSelector(getBlogState,
  (state: BlogState): boolean => state.isLoadingSuccess);

export const getBlogLoader = createSelector(getBlogState,
  (state: BlogState): boolean => state.isLoading);

export const getBlogError = createSelector(getBlogState,
  (state: BlogState): HttpErrorResponse => state.error);
