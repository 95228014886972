export enum ELeadsTitle {
  converse = 'TOTAL_CONVERSE_LEADS',
  gold = 'TOTAL_GOLD_LEADS',
  phone = 'TOTAL_PHONE_LEADS',
  navigation = 'TOTAL_NAVIGATIONS',
  index = 'TOTAL_INDEX_LEADS',
  zap = 'TOTAL_ZAP_LEADS',
  rims = 'TOTAL_RIMS'
}

export enum ELeadsLabel {
  vehicle = 'VEHICLE_TYPE',
  lead = 'LEAD_TYPE',
  tws = 'TWS'
}

export enum ELeadsPlaceholder {
  vehicle = 'SELECT_VEHICLE_TYPE',
  lead = 'SELECT_LEAD_TYPE',
  tws = 'SELECT_TWS'
}

export enum ELeadsTableFilterKey {
  TWSName = 'TWSName',
  dateTo = 'ToDate',
  dateFrom = 'FromDate',
  adminStatus = 'AdminStatus',
  TWSStatus = 'TwsStatus',
  fromInstalDate = 'FromInstallationDate',
  toInstalDate = 'ToInstallationDate'
}
