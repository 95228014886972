import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  get(route: string, params?: { [param: string]: string | ReadonlyArray<string> | number | boolean }): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}${route}`,
      params ? {params: new HttpParams({fromObject: params})} : undefined
    );
  }

  post(route: string, body: object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${route}`,
      body,
    );
  }

  delete(route: string, id: string): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}${route}/${id}`,
    );
  }

  deleteNumber(route: string, id: number): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}${route}/${id}`,
    );
  }

  put(route: string, body: object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${route}`,
      body,
    );
  }

  patch(route: string, body?: any): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}${route}`, body)
  }

  exportFile(route: string, params: { [param: string]: string | ReadonlyArray<string> }, fileName: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${route}`,
      {responseType: 'blob', params: new HttpParams({fromObject: params})}).pipe(
      tap(resp => {
        saveAs(resp, `${fileName}.xlsx`);
      })
    )
  }
}
