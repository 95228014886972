import { debounceTime, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

import { TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import {
  GoldLeadsBusinessTableMeta,
  GoldLeadsTableMeta,
  monthlyReportTableMeta,
  OrderTableMeta,
  paymentsPerMonthTableMeta
} from '../../../../t4u-admin/pages/pages.constants';
import { IStatistic, IStatisticParams } from '../../../state/general-admin/leads/leads.model';
import { EStatisticTitle } from '../../../statistics/components/statistics/statistics.enums';
import { IParameter } from '../../../state/general-admin/tire-reseller';
import { getLookupParameters, ILookupParameters } from '../../../state/general-admin/characteristic';
import { LanguageService } from '../../../../api/language.service';
import { selectLookupParams } from '../../../state/general-admin/characteristic/characteristic.selectors';
import { TableDialogComponent } from '../../../shared/components/table-dialog/table-dialog.component';
import { AuthService } from '../../../auth/services/auth.service';
import { convertMonthlyReport } from '../../../../utils/converting.functions';
import { ITableData } from '../../../shared/interfaces/table.interfaces';
import {
  IGolLead,
  IMonthlyLeadsList,
  IMonthlyLeadsListExtended,
  IOrdersExtended,
  ITotalMonthlyReport
} from '../../../state/general-admin/payments/payments.model';
import {
  getGoldLeadsStatistic,
  getMonthlyLeadsList,
  selectGoldLeadStatistic,
  selectMonthlyLeadsList,
  selectMonthlyLeadsLoader,
  selectPaymentsLoader
} from '../../../state/general-admin/payments';
import { NotificationService } from '../../../../api/notification.service';
import { exportStatisticFile, getStatistic, selectStatistic } from '../../../state/general-admin/leads';
import { DateAdapter } from '@angular/material/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {
  public isBusiness: boolean;
  public destroy$: Subject<void> = new Subject<void>();
  public paymentsPerMonthMeta: TableMeta[] = paymentsPerMonthTableMeta;
  public monthlyReport: TableMeta[] = monthlyReportTableMeta;
  public orderTableMeta: TableMeta[] = OrderTableMeta;
  public ordersTableContent$: Observable<IOrdersExtended>;
  public paymentsPerMonthContent$: Observable<IMonthlyLeadsListExtended>;
  public apiParams: IStatisticParams;
  public today: Date = new Date();
  public mainTitle: string = EStatisticTitle.all;
  public allTWS: IParameter = {id: '', value: EStatisticTitle.all};
  public params$: Observable<ILookupParameters>;
  public filterForm: FormGroup;
  public isLoad$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public goldLeadsPerMonth: IGolLead[];
  public reportPayments: ITotalMonthlyReport[];
  public isOneReseller: boolean = false;
  public statistic$: Observable<IStatistic | null>;
  public EStatisticTitle = EStatisticTitle;
  public dateFrom: Date;
  public dateTo: Date;
  public minDate: Date = new Date('2023-08-01');

  constructor(private store$: Store,
              private dialog: MatDialog,
              private fb: FormBuilder,
              public auth: AuthService,
              public alertService: NotificationService,
              private _adapter: DateAdapter<Date>,
              public languageService: LanguageService) {
    this._adapter.setLocale(localStorage.getItem('langCode') || environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.getData();
    this.initForm();
    this.getFiltersValue();
    this.getResellerValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.filterForm = this.fb.group({
      tireResellerId: [''],
      dateFrom: this.dateFrom || [''],
      dateTo: this.dateTo || ['']
    })
  }

  getResellerValue(): void {
    this.filterForm.get('tireResellerId')?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: reseller => {
          this.isOneReseller = reseller.id !== ''
        }
      })
  }

  getData(): void {
    this.isBusiness = this.auth.isReseller;
    this.store$.dispatch(getLookupParameters({
      payload: 'TWSName,subscription,TireType,VehicleType,Manufacture,City,LeadType,ModelName,RimManufacturer,TireManufacturer'
    }));
    this.paymentsPerMonthContent$ = this.store$.select(selectMonthlyLeadsList);
    this.ordersTableContent$ = this.store$.select(selectGoldLeadStatistic);
    this.params$ = this.store$.select(selectLookupParams);
    this.isLoad$ = this.store$.select(selectPaymentsLoader);
    this.isLoading$ = this.store$.select(selectMonthlyLeadsLoader);
    this.statistic$ = this.store$.select(selectStatistic);
    this.getCurrentMonth();
    this.getAmounts(this.apiParams);
  }

  // By defualt current month selected
  getCurrentMonth(): void {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();

    this.dateFrom = new Date(year, month, 1);
    this.dateFrom.setHours(4);
    this.dateTo = new Date();
    this.dateTo.setHours(4);

    this.apiParams = {dateFrom: this.dateFrom.toISOString(), dateTo: this.dateTo.toISOString(), tireResellerId: ''};
    if (this.isBusiness && this.auth.reseller) {
      this.apiParams = {...this.apiParams, tireResellerId: this.auth.reseller.tireResellerId};
      return;
    }
  }

  getAmounts(params?: IStatisticParams): void {
    this.store$.dispatch(getGoldLeadsStatistic({params}));
    this.store$.dispatch(getMonthlyLeadsList({params}));
    this.store$.dispatch(getStatistic({params}));
    this.store$.select(selectMonthlyLeadsList).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (report) => {
          this.reportPayments = convertMonthlyReport(report);
        }
      })
  }

  getFiltersValue(): void {
    this.filterForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(1000))
      .subscribe({
        next: (filters) => {
          const params = {...filters, tireResellerId: filters.tireResellerId.id};
          delete params.dateFrom;
          delete params.dateTo;
          if (filters.tireResellerId === '') delete params.tireResellerId;
          if (filters.dateFrom) {
            const dateFrom = filters.dateFrom;
            dateFrom.setHours(4);
            params.dateFrom = dateFrom.toISOString();
          }
          if (filters.dateTo) {
            const dateTo = filters.dateTo;
            dateTo.setHours(4);
            params.dateTo = dateTo.toISOString();
          }
          this.apiParams = params;
          this.mainTitle = filters.tireResellerId.value || EStatisticTitle.all;
          if (this.isBusiness && this.auth.reseller) {
            const businessParams = {...params, tireResellerId: this.auth.reseller.tireResellerId}
            this.getAmounts(businessParams);
            return;
          }
          this.getAmounts(params);
        }
      })
  }

  export(): void {
    const startDate = this.convertTime(this.apiParams?.dateFrom);
    const endDate = this.convertTime(this.apiParams?.dateTo);
    const shopName = this.mainTitle;
    const fileName = `${shopName}.${startDate}-${endDate}.payments`;
    this.store$.dispatch(exportStatisticFile({params: this.apiParams, fileName}));
  }

  convertTime(date: string | undefined): string {
    if (!date) return '';
    const fullDate = new Date(date);
    const month = fullDate.getMonth() + 1;
    const day = fullDate.getDate();
    const year = fullDate.getFullYear();
    const shortDate = [day, month, year];
    return shortDate.join('/');
  }

  openDetails(): void {
    const tableMeta = this.isBusiness ? GoldLeadsBusinessTableMeta : GoldLeadsTableMeta;
    this.isBusiness ? this.apiParams = {
      ...this.apiParams,
      tireResellerId: this.auth.reseller.tireResellerId
    } : this.apiParams;
    this.dialog.open(TableDialogComponent, {
      panelClass: 'price-details',
      width: '700px',
      height: '300px',
      data: {tableMeta, params: this.apiParams}
    })
  }

  clearDateFilter(): void {
    this.filterForm.controls['dateFrom'].setValue('');
    this.filterForm.controls['dateTo'].setValue('');
  }

  sortByMonth(result: IMonthlyLeadsList[]): IMonthlyLeadsList[] {
    let list = [...result];
    list = list.sort((a, b) => {
      const firstMonth = new Date().setMonth(+a.month.split('-')[1]);
      const secondMonth = new Date().setMonth(+b.month.split('-')[1]);
      return secondMonth - firstMonth;
    })
    return list;
  }

  checkOrdersTableShowing(): boolean {
    return !this.isBusiness || this.auth.reseller.subscriptionType.id === 1 || this.auth.reseller.subscriptionType.id === 3;
  }

  openGoldPriceDetails(table: ITableData<IMonthlyLeadsList>): void {
    const month = +table.data.month.split('-')[1] - 1;
    const year = +table.data.month.split('-')[0];
    const dateFrom = new Date(year, month, 1, 4).toISOString();
    const dateTo = new Date(year, month + 1, 0, 4).toISOString();
    const tireResellerId = this.apiParams?.tireResellerId ? this.apiParams.tireResellerId : '';
    let params: IStatisticParams = {tireResellerId, dateFrom, dateTo};
    const tableMeta = this.isBusiness ? GoldLeadsBusinessTableMeta : GoldLeadsTableMeta;
    if (this.isBusiness && this.auth.reseller) params = {...params, tireResellerId: this.auth.reseller.tireResellerId};
    if (this.apiParams.dateFrom || this.apiParams.dateTo) params = {
      ...params,
      dateFrom: this.apiParams.dateFrom,
      dateTo: this.apiParams.dateTo
    };
    this.dialog.open(TableDialogComponent, {
      panelClass: 'price-details',
      width: '600px',
      height: '300px',
      data: {tableMeta, params, searchByMonth: true}
    })
  }

  sortContent(params: IParameter[]): IParameter[] {
    if (!params?.length) return [];
    let options = [...params];
    return options.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? 1 : -1)
  }

  changeVirtualNumber(number: string): string {
    if(!number.includes('972')) return number;
    let newNumber = number.split('972')[1];
    const firstLetters = newNumber.slice(0, 2);
    newNumber = newNumber.slice(2, newNumber.length)
    return `0${firstLetters}-${newNumber}`;
  }
}
