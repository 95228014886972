import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';

import { IMotoTireDBData, ManageMotoTiresState } from './manage-moto-tires.model';
import { IResponse } from '../../../../api/api-data/api.interfaces';
import * as ManageMotoTiresActions from './manage-moto-tires.actions';

export const initialState: ManageMotoTiresState = {
  result: {} as IResponse<IMotoTireDBData[]>,
  prices: {
    loader: false,
    loaderSuccess: false,
    error: null
  },
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: null
};
const manageMotoTiresReducer = createReducer(
  initialState,
  on(ManageMotoTiresActions.getMotoTiresList, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(ManageMotoTiresActions.getMotoTiresListSuccess, (state, {payload}) => ({
    ...state,
    result: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(ManageMotoTiresActions.getMotoTiresListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ManageMotoTiresActions.addMotoTire, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(ManageMotoTiresActions.addMotoTireSuccess, (state, {payload}) => ({
    ...state,
    result: {...state.result, content: [...state.result.content, payload]},
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(ManageMotoTiresActions.addMotoTireFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ManageMotoTiresActions.updateMotoTire, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(ManageMotoTiresActions.updateMotoTireSuccess, (state, {payload}) => {
    const content = [...state.result.content];
    const newData = content.map((tire: IMotoTireDBData) => {
      if (tire.id === payload.id) tire = payload;
      return tire;
    });
    return {
      ...state,
      result: {...state.result, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(ManageMotoTiresActions.updateMotoTireFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ManageMotoTiresActions.changeMotoTirePrices, (state) => ({
    ...state,
    prices: {
      ...state.prices,
      loader: true,
      loaderSuccess: false,
      error: null
    }
  })),
  on(ManageMotoTiresActions.changeMotoTirePricesSuccess, (state) => {
    return {
      ...state,
      prices: {
        ...state.prices,
        loader: false,
        loaderSuccess: true,
        error: {} as HttpErrorResponse
      }
    }
  }),
  on(ManageMotoTiresActions.changeMotoTirePricesFailure, (state, {payload}) => ({
    ...state,
    prices: {
      ...state.prices,
      loader: false,
      loaderSuccess: false,
      error: payload
    }
  })),
  on(ManageMotoTiresActions.changeMotoTireVisibility, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(ManageMotoTiresActions.changeMotoTireVisibilitySuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse
    }
  }),
  on(ManageMotoTiresActions.changeMotoTireVisibilityFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(ManageMotoTiresActions.changeMotoTireBruttoPrice, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(ManageMotoTiresActions.changeMotoTireBruttoPriceSuccess, (state, {price}) => {
    const content = [...state.result.content];
    const newData = content.map((tire: IMotoTireDBData) => {
      if (tire.id === price.articleId) tire = {...tire, bruttoPrice: price.bruttoPrice};
      return tire;
    });
    return {
      ...state,
      result: {...state.result, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(ManageMotoTiresActions.changeMotoTireBruttoPriceFailure, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  }))
);

export function ManageMotoTiresReducer(state: ManageMotoTiresState | undefined, action: Action): any {
  return manageMotoTiresReducer(state, action);
}
