import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

import {
  IChangeTWSProfit,
  IResellerParams,
  IResselerByTire,
  ITWSPriceProfit,
  TireResellerFull
} from "./tire-reseller.model";
import { IResponse } from '../../../../api/api-data/api.interfaces';
import { LoginResponse } from '../../../auth/interfaces/auth.interface';
import { IPrivateBrand, ManufacturerElement } from '../criteria/manufacturer';

export const GET_TIRE_RESELLER_LIST = '[TIRE_RESELLER] Get tire reseller list';
export const GET_TIRE_RESELLER_LIST_SUCCESS = '[TIRE_RESELLER] Get tire reseller list success';
export const GET_TIRE_RESELLER_LIST_FAILURE = '[TIRE_RESELLER] Get tire reseller list failure';

export const ADD_TIRE_RESELLER = '[TIRE_RESELLER] Add tire reseller';
export const ADD_TIRE_RESELLER_SUCCESS = '[TIRE_RESELLER] Add tire reseller success';
export const ADD_TIRE_RESELLER_FAILURE = '[TIRE_RESELLER] AAdd tire reseller failure';

export const UPDATE_TIRE_RESELLER = '[TIRE_RESELLER] Update tire reseller';
export const UPDATE_TIRE_RESELLER_SUCCESS = '[TIRE_RESELLER] Update tire reseller success';
export const UPDATE_BUSINESS_TIRE_RESELLER_SUCCESS = '[TIRE_RESELLER] Update business tire reseller success';
export const UPDATE_TIRE_RESELLER_FAILURE = '[TIRE_RESELLER] Update tire reseller failure';

export const EDIT_TIRE_RESELLER_MASKYOO_NUMBER = '[TIRE_RESELLER] Edit tire reseller maskyoo number';
export const EDIT_TIRE_RESELLER_MASKYOO_NUMBER_SUCCESS = '[TIRE_RESELLER] Edit tire reseller maskyoo number success';
export const EDIT_TIRE_RESELLER_MASKYOO_NUMBER_FAILURE = '[TIRE_RESELLER] Edit tire reseller maskyoo number failure';

export const GET_TIRE_RESELLER_BY_ID = '[TIRE_RESELLER] Get tire reseller by id';
export const GET_TIRE_RESELLER_BY_ID_SUCCESS = '[TIRE_RESELLER] Get tire reseller by id success';
export const GET_TIRE_RESELLER_BY_ID_FAILURE = '[TIRE_RESELLER] Get tire reseller by id failure';

export const GET_TIRE_RESELLER_PRICES_PROFIT = '[TIRE_RESELLER] Get tire prices profit';
export const GET_TIRE_RESELLER_PRICES_PROFIT_SUCCESS = '[TIRE_RESELLER] Get tire reseller prices profit success';
export const GET_TIRE_RESELLER_PRICES_PROFIT_FAILURE = '[TIRE_RESELLER] Get tire reseller prices profit failure';

export const CHANGE_TIRE_RESELLER_PRICES_PROFIT = '[TIRE_RESELLER] Change tire prices profit';
export const CHANGE_TIRE_RESELLER_PRICES_PROFIT_SUCCESS = '[TIRE_RESELLER] Change tire reseller prices profit success';
export const CHANGE_TIRE_RESELLER_PRICES_PROFIT_FAILURE = '[TIRE_RESELLER] Change tire reseller prices profit failure';

export const GET_RESELLER_TOKEN = '[User] Get reseller token';
export const GET_RESELLER_TOKEN_SUCCESS = '[User] Get reseller token success';
export const GET_RESELLER_TOKEN_FAILED = '[User] Get reseller token failed';

export const CHANGE_TWS_SHOWING = '[TIRE_RESELLER] Change reseller showing';
export const CHANGE_TWS_SHOWING_SUCCESS = '[TIRE_RESELLER] Change reseller showing success';
export const CHANGE_TWS_SHOWING_FAILED = '[TIRE_RESELLER] Change reseller showing failed';

export const ADD_PRIVATE_BRAND = '[TIRE_RESELLER] Add private brand';
export const ADD_PRIVATE_BRAND_SUCCESS = '[TIRE_RESELLER] Add private brand success';
export const ADD_PRIVATE_BRAND_FAILURE = '[TIRE_RESELLER] Add private brand failure';

export const UPDATE_PRIVATE_BRAND = '[TIRE_RESELLER] Update private brand';
export const UPDATE_PRIVATE_BRAND_SUCCESS = '[TIRE_RESELLER] Update private brand success';
export const UPDATE_PRIVATE_BRAND_FAILURE = '[TIRE_RESELLER] Update private brand failure';

export const GET_PRIVATE_BRAND_LIST = '[MANUFACTURER] Get private brand list';
export const GET_PRIVATE_BRAND_LIST_SUCCESS = '[MANUFACTURER] Get private brand list success';
export const GET_PRIVATE_BRAND_LIST_FAILURE = '[MANUFACTURER] Get private brand list failure';

export const GET_PRIVATE_BRAND = '[MANUFACTURER] Get private brand';
export const GET_PRIVATE_BRAND_SUCCESS = '[MANUFACTURER] Get private brand success';
export const GET_PRIVATE_BRAND_FAILURE = '[MANUFACTURER] Get private brand failure';

export const GET_TIRE_INFO_BY_ID = '[TIRE_RESELLER] Get tire info by id';
export const GET_TIRE_INFO_BY_ID_SUCCESS = '[TIRE_RESELLER] Get tire info by id success';
export const GET_TIRE_INFO_BY_ID_FAILURE = '[TIRE_RESELLER] Get tire info by id failure';


export const getTireResellerList = createAction(
  GET_TIRE_RESELLER_LIST,
  props<{ payload: IResellerParams }>()
)

export const getTireResellerListSuccess = createAction(
  GET_TIRE_RESELLER_LIST_SUCCESS,
  props<{ payload: IResponse<TireResellerFull[]> }>()
)

export const getTireResellerListFailure = createAction(
  GET_TIRE_RESELLER_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addTireReseller = createAction(
  ADD_TIRE_RESELLER,
  props<{ payload: FormData }>()
)

export const addTireResellerSuccess = createAction(
  ADD_TIRE_RESELLER_SUCCESS,
  props<{ payload: TireResellerFull }>()
)

export const addTireResellerFailure = createAction(
  ADD_TIRE_RESELLER_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateTireReseller = createAction(
  UPDATE_TIRE_RESELLER,
  props<{ payload: FormData, businessUser?: boolean }>()
)

export const updateTireResellerSuccess = createAction(
  UPDATE_TIRE_RESELLER_SUCCESS,
  props<{ payload: TireResellerFull }>()
)

export const updateBusinessTireResellerSuccess = createAction(
  UPDATE_BUSINESS_TIRE_RESELLER_SUCCESS,
  props<{ payload: TireResellerFull }>()
)

export const updateTireResellerFailure = createAction(
  UPDATE_TIRE_RESELLER_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const editTireResellerMaskyooNumber = createAction(
  EDIT_TIRE_RESELLER_MASKYOO_NUMBER,
  props<{ payload: FormData }>()
)

export const editTireResellerMaskyooNumberSuccess = createAction(
  EDIT_TIRE_RESELLER_MASKYOO_NUMBER_SUCCESS,
  props<{ payload: boolean }>()
)
export const editTireResellerMaskyooNumberFailure = createAction(
  EDIT_TIRE_RESELLER_MASKYOO_NUMBER_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getTireResellerById = createAction(
  GET_TIRE_RESELLER_BY_ID,
  props<{ payload: string }>()
)

export const getTireResellerByIdSuccess = createAction(
  GET_TIRE_RESELLER_BY_ID_SUCCESS,
  props<{ payload: TireResellerFull }>()
)

export const getTireResellerByIdFailure = createAction(
  GET_TIRE_RESELLER_BY_ID_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getTireInfoById = createAction(
  GET_TIRE_INFO_BY_ID,
  props<{ payload: number }>()
)

export const getTireInfoByIdSuccess = createAction(
  GET_TIRE_INFO_BY_ID_SUCCESS,
  props<{ payload: IResselerByTire[] }>()
)

export const getTireInfoByIdFailure = createAction(
  GET_TIRE_INFO_BY_ID_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getTireResellerPricesProfit = createAction(
  GET_TIRE_RESELLER_PRICES_PROFIT,
  props<{ id: string }>()
)

export const getTireResellerPricesProfitSuccess = createAction(
  GET_TIRE_RESELLER_PRICES_PROFIT_SUCCESS,
  props<{ payload: ITWSPriceProfit[] }>()
)

export const getTireResellerPricesProfitFailure = createAction(
  GET_TIRE_RESELLER_PRICES_PROFIT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changeTireResellerPricesProfit = createAction(
  CHANGE_TIRE_RESELLER_PRICES_PROFIT,
  props<{ profit: IChangeTWSProfit, id: string }>()
)

export const changeTireResellerPricesProfitSuccess = createAction(
  CHANGE_TIRE_RESELLER_PRICES_PROFIT_SUCCESS
)

export const changeTireResellerPricesProfitFailure = createAction(
  CHANGE_TIRE_RESELLER_PRICES_PROFIT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getResellerToken = createAction(
  GET_RESELLER_TOKEN,
  props<{ id: string }>()
);

export const getResellerTokenSuccess = createAction(
  GET_RESELLER_TOKEN_SUCCESS,
  props<{ user: LoginResponse }>()
);

export const getResellerTokenFailed = createAction(
  GET_RESELLER_TOKEN_FAILED,
  props<{ payload: HttpErrorResponse }>()
);

export const changeTWSShowing = createAction(
  CHANGE_TWS_SHOWING,
  props<{ id: string, active: boolean }>()
);

export const changeTWSShowingSuccess = createAction(
  CHANGE_TWS_SHOWING_SUCCESS,
  props<{ payload: TireResellerFull }>()
);

export const changeTWSShowingFailed = createAction(
  CHANGE_TWS_SHOWING_FAILED,
  props<{ payload: HttpErrorResponse }>()
);

export const addPrivateBrand = createAction(
  ADD_PRIVATE_BRAND,
  props<{ payload: IPrivateBrand }>()
)

export const addPrivateBrandSuccess = createAction(
  ADD_PRIVATE_BRAND_SUCCESS,
  props<{ payload: ManufacturerElement }>()
)

export const addPrivateBrandFailure = createAction(
  ADD_PRIVATE_BRAND_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updatePrivateBrand = createAction(
  UPDATE_PRIVATE_BRAND,
  props<{ payload: IPrivateBrand, id: number }>()
)

export const updatePrivateBrandSuccess = createAction(
  UPDATE_PRIVATE_BRAND_SUCCESS,
  props<{ payload: ManufacturerElement }>()
)

export const updatePrivateBrandFailure = createAction(
  UPDATE_PRIVATE_BRAND_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getPrivateBrandList = createAction(
  GET_PRIVATE_BRAND_LIST,
  props<{ payload: string }>()
)

export const getPrivateBrandListSuccess = createAction(
  GET_PRIVATE_BRAND_LIST_SUCCESS,
  props<{ payload: ManufacturerElement[] }>()
)

export const getPrivateBrandListFailure = createAction(
  GET_PRIVATE_BRAND_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getPrivateBrand = createAction(
  GET_PRIVATE_BRAND,
  props<{ resellerId: string, brandId: number }>()
)

export const getPrivateBrandSuccess = createAction(
  GET_PRIVATE_BRAND_SUCCESS,
  props<{ payload: ManufacturerElement }>()
)

export const getPrivateBrandFailure = createAction(
  GET_PRIVATE_BRAND_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

