import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BannersComponent } from './banners.component';

export const bannersRoutes: Routes = [
  {
    path: 'tires/1',
    component: BannersComponent,
    data: {
      menuTitle: 'TIRES'
    }
  },
  {
    path: 'motorcycles/2',
    component: BannersComponent,
    data: {
      menuTitle: 'MOTORCYCLES'
    }
  },
  {
    path: 'rims/3',
    component: BannersComponent,
    data: {
      menuTitle: 'RIMS'
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild([...bannersRoutes, {path: '**', redirectTo: 'tires/1'}])],
  exports: [RouterModule]
})
export class BannersRoutingModule {
}
