import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { WeightCodeElement, WeightCodeState } from './weight-code.model';
import * as WeightCodeActions from './weight-code.actions';

export const initialState: WeightCodeState = {
  current: {} as WeightCodeElement,
  deleteId: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  content: [],
  error: {} as HttpErrorResponse,
};

const weightCodeReducer = createReducer(
  initialState,
  on(WeightCodeActions.getWeightCodeData, (state) => ({
    ...state,
    isLoading: true,
    error: {} as HttpErrorResponse,
  })),
  on(WeightCodeActions.getWeightCodeDataSuccess, (state, {payload}) => ({
    ...state,
    content: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(WeightCodeActions.getWeightCodeDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(WeightCodeActions.addWeightCodeElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(WeightCodeActions.addWeightCodeElementSuccess, (state, {payload}) => {
    return {
      ...state,
      content: [...state.content, payload],
      current: {} as WeightCodeElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(WeightCodeActions.addWeightCodeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(WeightCodeActions.updateWeightCodeElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(WeightCodeActions.updateWeightCodeElementSuccess, (state, {payload}) => {
    const weightCodeData = [...state.content];
    const newContent = weightCodeData.map((mnf: WeightCodeElement) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    })
    return {
      ...state,
      content: newContent,
      current: {} as WeightCodeElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(WeightCodeActions.updateWeightCodeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(WeightCodeActions.deleteWeightCodeElement, (state, {payload}) => ({
    ...state,
    deleteId: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(WeightCodeActions.deleteWeightCodeElementSuccess, (state) => {
    const weightCodeData = [...state.content];
    const newContent = weightCodeData.filter((mnf: WeightCodeElement) => mnf.id !== state.deleteId);
    return {
      ...state,
      content: newContent,
      deleteId: null,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(WeightCodeActions.deleteWeightCodeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function WeightCodeReducer(state: WeightCodeState | undefined, action: Action): any {
  return weightCodeReducer(state, action);
}



