import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { TireTypeElement, TireTypeTable } from "../../modules/state/general-admin/criteria/tire-type";

@Injectable({
  providedIn: "root",
})
export default class TireTypeApiService {
  readonly PATH = `/criteria/tiretype`;

  constructor(private apiService: ApiService) {
  }

  getTireTypeData(): Observable<TireTypeTable> {
    return this.apiService.get(`${this.PATH}`);
  }

  addTireTypeElement(element: TireTypeElement): Observable<TireTypeElement> {
    return this.apiService.post(`${this.PATH}`, element);
  }

  updateTireTypeElement(element: TireTypeElement, id: number): Observable<TireTypeElement> {
    return this.apiService.put(`${this.PATH}/${id}`, element);
  }

  deleteTireTypeElement(id: number): Observable<any> {
    return this.apiService.deleteNumber(`${this.PATH}`, id);
  }
}
