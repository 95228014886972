import { Action, createReducer, on } from "@ngrx/store";
import { LoginResponse, UserState } from "../interfaces/auth.interface";
import { UserLogin, UserLoginFailed, UserLoginSuccess, UserLogout } from "./user.actions";

export const initialUserState: UserState = {
  user: {} as LoginResponse,
  error: null
};

const UserReducer = createReducer(
  initialUserState,
  on(UserLogin, (state) => {
    return {...state}
  }),
  on(UserLoginSuccess, (state, {payload}) => {
    return {
      ...state,
      user: payload,
      error: null
    }
  }),
  on(UserLoginFailed, (state, {payload}) => {
    return {
      ...state,
      error: payload
    }
  }),
  on(UserLogout, (state) => {
    return {
      ...state,
      user: initialUserState.user,
      error: null
    }
  })
);

export function reducer(state: UserState | undefined, action: Action) {
  return UserReducer(state, action)
}
