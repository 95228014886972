import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TireTypeState } from "./tire-type.model";
import { HttpErrorResponse } from '@angular/common/http';

export const getTireTypeState = createFeatureSelector<TireTypeState>('tireType');

export const getMotoTireTypeTable = createSelector(getTireTypeState,
  (state: TireTypeState) => state.content.motorcycleTireType);

export const getCarTireTypeTable = createSelector(getTireTypeState,
  (state: TireTypeState) => state.content.carTireType);

export const getTireTypeSuccessLoader = createSelector(getTireTypeState,
  (state: TireTypeState): boolean => state.isLoadingSuccess);

export const getTireTypeLoader = createSelector(getTireTypeState,
  (state: TireTypeState): boolean => state.isLoading);

export const getTireTypeError = createSelector(getTireTypeState,
  (state: TireTypeState): HttpErrorResponse => state.error);
