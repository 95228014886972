<div class="filters-wrap">
  <div class="filters-title" *ngIf="filterList?.length">
    {{'FILTERS' | translate}}
  </div>
  <div class="chips-wrap" *ngIf="render">
    <div class="chips-elem" *ngFor="let param of filterList">
      <div class="chips-elem-text"> {{param[0] | translate}} : {{param[1]}}</div>
      <div class="chips-elem-close" (click)="removeFilter(param[0])">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div *ngIf="filterList?.length" (click)="removeAllFilters()" class="clear-btn">{{'CLEAR_ALL' | translate}}</div>
  </div>
</div>
