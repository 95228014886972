import { createFeatureSelector, createSelector } from "@ngrx/store";
import { HttpErrorResponse } from '@angular/common/http';

import { IResponse } from '../../../../api/api-data/api.interfaces';
import { IMotoTireDBData, ManageMotoTiresState } from './manage-moto-tires.model';

export const getMotoTiresState = createFeatureSelector<ManageMotoTiresState>('manageMotoTires');

export const getMotoTiresData = createSelector(getMotoTiresState,
  (state: ManageMotoTiresState): IResponse<IMotoTireDBData[]> => state.result);

export const getMotoTiresSuccessLoader = createSelector(getMotoTiresState,
  (state: ManageMotoTiresState): boolean => state.isLoadingSuccess);

export const getMotoTiresLoader = createSelector(getMotoTiresState,
  (state: ManageMotoTiresState): boolean => state.isLoading);

export const getMotoTiresError = createSelector(getMotoTiresState,
  (state: ManageMotoTiresState): HttpErrorResponse | null => state.error);
