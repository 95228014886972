import { HttpErrorResponse } from "@angular/common/http";
import { IResponse } from '../../../../api/api-data/api.interfaces';

export interface ICarTire {
  brand: ITireParameter,
  bruttoPrice: number,
  diameter: ITireParameter,
  height: ITireParameter,
  price: number,
  name: string,
  speedCode: ITireParameter,
  tireType: ITireParameter[],
  subTireType: ITireParameter,
  weightCode: ITireParameter,
  width: ITireParameter,
  noiseLevel: ITireParameter,
  roadGrip: ITireParameter,
  fuelConsumption: ITireParameter,
  isActive: boolean,
  inv: boolean,
  runFlate: boolean,
  modalType?: string
}

export interface ICarTireExpanded {
  entity: ICarTireDBData,
  status: number,
  message: string
}

export interface ITireParameter {
  id: number,
  value: string
}

export interface ICarTireDBData extends ICarTire {
  id: number;
  params?: ICarTiresParams,
  copyTire?: boolean;
}

export interface ManageCarTiresState {
  result: IResponse<ICarTireDBData[]>,
  prices: {
    loader: boolean,
    loaderSuccess: boolean,
    error: HttpErrorResponse | null
  },
  businessCarTire: {
    tire: IBusinessCarTire,
    isLoader: boolean,
    isLoadSuccess: boolean,
    error: HttpErrorResponse | null
  },
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  isLoading: boolean,
  error: HttpErrorResponse | null
}

export interface ICarTiresParams {
  page: number,
  filters?: string,
  search?: string,
  sorts?: string,
  plateNumber?: string
}

export enum ETireLabel {
  add = 'ADD_TIRE',
  edit = 'EDIT_TIRE',
  prices = 'CHANGE_PRICES'
}

export interface IBusinessCarTire extends ICarTire {
  yearOfManufacture: number,
  locationOnStore: number,
  costPrice: number,
  active: boolean,
  numberOfTire: number
}
