<div class="dialog-title" mat-dialog-title>

  <ng-container [ngSwitch]="data.modalType">
    <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="addTitle"></ng-container>
    <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="editTitle"></ng-container>
    <ng-container *ngSwitchCase="EModalType.editPrice" [ngTemplateOutlet]="editTitle"></ng-container>
    <ng-container *ngSwitchCase="EModalType.delete" [ngTemplateOutlet]="deleteTitle"></ng-container>
  </ng-container>

  <ng-template #addTitle>
    <h2 class="title">
      {{EFormLabel.dialogTitleAdd | translate}}
    </h2>
  </ng-template>
  <ng-template #editTitle>
    <h2 class="title">
      {{EFormLabel.dialogTitleEdit  | translate}}
    </h2>
  </ng-template>
  <ng-template #deleteTitle>
    <h2 class="title">
      {{EFormLabel.deleteConfirm  | translate}}
    </h2>
  </ng-template>

  <div class="title-actions" *ngIf="data.modalType !== EModalType.delete">
    <mat-icon class="close" (click)="closeAddDialog()">close</mat-icon>
  </div>
</div>

<ng-container [ngSwitch]="data.modalType">
  <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="addTire"></ng-container>
  <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="changeTire"></ng-container>
  <ng-container *ngSwitchCase="EModalType.editPrice" [ngTemplateOutlet]="editTirePrice"></ng-container>
  <ng-container *ngSwitchCase="EModalType.delete" [ngTemplateOutlet]="deleteModal"></ng-container>
</ng-container>

<ng-template #changeTire>
  <mat-dialog-content class="dialog-content">
    <div class="table-loader" *ngIf="(articlePriceLoader$ | async)">
      <mat-spinner></mat-spinner>
    </div>
    <div class="content-body-title">{{data.name}}</div>
    <div class="dialog-form-wrap">
      <div [formGroup]="editTireForm">
        <div class="form-group row">
          <div class="col-6 form-col">
            <app-input [isNumber]="true" [label]="EFormLabel.quantity" [placeholder]="EFormPlaceholder.quantity"
                       [isRequired]="true" formControlName="quantity"></app-input>
          </div>
          <div class="col-6 form-col">
            <app-select [options]="yearOptions" [label]="EFormLabel.brandYear"
                        [placeholder]="EFormPlaceholder.brandYear" [isSorted]="false"
                        [isRequired]="true" formControlName="yearOfManufacture"></app-select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-6 form-col">
            <app-input [label]="EFormLabel.location" [placeholder]="EFormPlaceholder.location"
                       formControlName="locationOnStore"></app-input>
          </div>
          <div class="col-6 form-col">
            <app-input [isNumber]="true" [label]="EFormLabel.costPrice" [placeholder]="EFormPlaceholder.costPrice"
                       [isRequired]="true" formControlName="costPrice"></app-input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-6 form-col">
            <app-input [label]="EFormLabel.afterVat" [isDisabled]="true"
                       formControlName="sitePrice"></app-input>
          </div>
          <!--            <div class="col-6 form-col">-->
          <!--            <label class="label">-->
          <!--              {{EFormLabel.sitePrice | translate}}-->
          <!--              <span class="sub-label">{{EFormLabel.saleVat | translate}}</span>-->
          <!--            </label>-->
          <!--            <mat-form-field class="form-element" appearance="fill">-->
          <!--              <input disabled matInput type="number" autocomplete="off" [ngModelOptions]="{standalone: true}"-->
          <!--                     [(ngModel)]="sitePrice">-->
          <!--            </mat-form-field>-->
          <!--          </div>-->
          <!--          <div class="col-6 form-col site-price">-->
          <!--            <button class="btn-admin" (click)="calculateSitePrice()">{{'SHOW_SITE_PRICE' | translate}}</button>-->
          <!--          </div>-->
        </div>
        <div class="form-group row">
          <div class="col-6 form-col">
            <mat-checkbox class="form-checkbox"
                          formControlName="visibility">{{EFormLabel.isDisplayed | translate}}</mat-checkbox>
          </div>
        </div>
      </div>

    </div>
    <div class="save-btn">
      <button [disabled]="!editTireForm.valid" class="btn-admin"
              (click)="editBusinessTire()">{{'SAVE' | translate}}</button>
      <!--      <button *ngIf="data.modalType === EModalType.edit"-->
      <!--              class="btn-admin delete-action" (click)="deleteTireQuantity()">{{'DELETE_QUANTITY' | translate}}</button>-->
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #addTire>
  <mat-dialog-content class="dialog-content">
    <div class="table-loader"
         *ngIf="(addModalCarLoader$ | async) || !(isParamsLoad$ | async) || (addModalMotoLoader$ | async)">
      <mat-spinner></mat-spinner>
    </div>
    <div class="dialog-form-wrap" *ngIf="(isParamsLoad$ | async)">
      <form action="" [formGroup]="addTireForm">
        <div class="row" *ngIf="(params$ | async) as params">
          <div class="col-4 border-left">
            <div class="form-group" *ngIf="(reseller$ | async) as reseller">
              <app-select
                [placeholder]="ECarTirePlaceholder.brand"
                [isRequired]="true"
                [label]="ECarTireLabel.brand"
                [options]="reseller.brands"
                [brandSelect]="true"
                [resellerId]="reseller.id"
                formControlName="BrandId"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.diameter.placeholder"
                [isRequired]="true"
                [label]="characteristicList.diameter.label"
                [options]="characteristicList.diameter.options"
                formControlName="diameter"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.height.placeholder"
                [isRequired]="true"
                [label]="characteristicList.height.label"
                [options]="characteristicList.height.options"
                formControlName="height"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.width.placeholder"
                [isRequired]="true"
                [label]="characteristicList.width.name"
                [options]="characteristicList.width.options"
                formControlName="width"
              ></app-select>
            </div>
            <div class="form-group">
              <app-input
                [isRequired]="true"
                [isNumber]="true"
                [placeholder]="ECarTirePlaceholder.costPrice"
                [label]="ECarTireLabel.costPrice"
                formControlName="CostPrice"
              ></app-input>
            </div>
            <div class="form-group">
              <app-input
                [isNumber]="true"
                [placeholder]="ECarTirePlaceholder.importerPrice"
                [label]="ECarTireLabel.importerPrice"
                formControlName="NetPrice"
              ></app-input>
            </div>
          </div>
          <div class="col-4 border-left">
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.speedCode.placeholder"
                [label]="characteristicList.speedCode.label"
                [options]="characteristicList.speedCode.options"
                formControlName="speedCode"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.weightCode.placeholder"
                [label]="characteristicList.weightCode.label"
                [options]="characteristicList.weightCode.options"
                formControlName="weightCode"
              ></app-select>
            </div>
<!--            <div class="form-group">-->
<!--              <app-input-->
<!--                [placeholder]="ECarTirePlaceholder.tireModel"-->
<!--                [label]="ECarTireLabel.tireModel"-->
<!--                formControlName="TireModel"-->
<!--              ></app-input>-->
<!--            </div>-->
            <div class="form-group">
              <app-input
                [isRequired]="true"
                [placeholder]="ECarTirePlaceholder.name"
                [label]="ECarTireLabel.name"
                formControlName="Name"
              ></app-input>
            </div>
            <div class="form-group" *ngIf="auth.reseller.vehicleType === 0">
              <app-multiple-select
                (selectedValue)="isSelected($event)"
                [placeholder]="ECarTirePlaceholder.type"
                [label]="ECarTireLabel.type"
                [options]="params.CarTireType"
                formControlName="TireTypes"
              ></app-multiple-select>
            </div>
            <div class="form-group" *ngIf="auth.reseller.vehicleType === 1">
              <app-select
                [placeholder]="ECarTirePlaceholder.type"
                [label]="ECarTireLabel.type"
                [options]="params.MotorcycleTireType"
                formControlName="TireTypes"
              ></app-select>
            </div>
            <div class="form-group" *ngIf="isTypeSelected && checkSubCategory(params.SubCarTireType)?.length">
              <app-select
                [placeholder]="ECarTirePlaceholder.subcategory"
                [label]="ECarTireLabel.subcategory"
                [options]="checkSubCategory(params.SubCarTireType)"
                formControlName="SubTireTypeId"
              ></app-select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <app-input
                [isNumber]="true"
                [isRequired]="true"
                [placeholder]="ECarTirePlaceholder.tireNumbers"
                [label]="ECarTireLabel.tireNumbers"
                formControlName="NumberOfTire"
              ></app-input>
            </div>
            <div class="form-group">
              <app-input
                [placeholder]="ECarTirePlaceholder.warehouseLocation"
                [label]="ECarTireLabel.warehouseLocation"
                formControlName="LocationOnStore"
              ></app-input>
            </div>
            <div class="form-group">
              <app-select
                [isSorted]="false"
                [isRequired]="true"
                [options]="yearOptions"
                [placeholder]="ECarTirePlaceholder.productionYear"
                [label]="ECarTireLabel.productionYear"
                formControlName="YearOfManufacture"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.roadGrip.placeholder"
                [label]="characteristicList.roadGrip.label"
                [options]="characteristicList.roadGrip.options"
                formControlName="roadGrip"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.fuelConsumption.placeholder"
                [label]="characteristicList.fuelConsumption.label"
                [options]="characteristicList.fuelConsumption.options"
                formControlName="fuelConsumption"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.noiseLevel.placeholder"
                [label]="characteristicList.noiseLevel.label"
                [options]="characteristicList.noiseLevel.options"
                formControlName="noiseLevel"
              ></app-select>
            </div>
            <div class="form-group" *ngIf="auth.reseller.vehicleType === 0">
              <mat-checkbox formControlName="RunFlate" class="form-checkbox">{{'RUN_FLAT' | translate}}</mat-checkbox>
            </div>
          </div>
        </div>
      </form>

    </div>
    <div class="save-btn">
      <button [ngClass]="!addTireForm.valid ? 'disabled' : ''"
              class="btn-admin" (click)="addBusinessTire()">{{'SAVE' | translate}}
      </button>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #editTirePrice>
  <mat-dialog-content class="dialog-content">
    <div class="table-loader" *ngIf="(articlePriceLoader$ | async)">
      <mat-spinner></mat-spinner>
    </div>
    <div class="content-body-title">{{data.name}}</div>
    <div class="content-body-title">{{data.brand}}</div>
    <div class="dialog-form-wrap" [formGroup]="tireSitePrice">
      <div class="col-6">
        <app-input [label]="EFormLabel.afterVat"
                   [isNumber]="true" formControlName="sitePrice"></app-input>
      </div>
    </div>
    <div class="save-btn">
      <button class="btn-admin" (click)="setTirePrice()">{{'SAVE' | translate}}</button>
      <button class="btn-admin delete-action" (click)="deleteTirePrice()">{{'DELETE_PRICE' | translate}}</button>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #deleteModal>
  <div class="table-loader" *ngIf="(invLoader$ | async)">
    <mat-spinner></mat-spinner>
  </div>
  <div class="confirmation">
    <button class="btn-admin" (click)="resetInventory()">{{'OK' | translate}}</button>
    <button class="btn-admin cancel" (click)="closeAddDialog()">{{'CANCEL' | translate}}</button>
  </div>
</ng-template>
