import { createFeatureSelector, createSelector } from "@ngrx/store";
import { HttpErrorResponse } from '@angular/common/http';
import { IBannersState } from './banners.model';

export const getBannersState = createFeatureSelector<IBannersState>('banners');

export const getMobileBannersTable = createSelector(getBannersState,
  (state: IBannersState) => state.result.MobileImage);

export const getDesktopBannersTable = createSelector(getBannersState,
  (state: IBannersState) => state.result.DesktopImage);

export const getBannersSuccessLoader = createSelector(getBannersState,
  (state: IBannersState) => state.isLoadingSuccess);

export const getBannersLoader = createSelector(getBannersState,
  (state: IBannersState) => state.isLoading);

export const getBannersError = createSelector(getBannersState,
  (state: IBannersState): HttpErrorResponse => state.error);
