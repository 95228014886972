import { Attribute, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[imgDir]'
})
export class ImgDirective {

  constructor(@Attribute('onErrorSrc') public onErrorSrc: string,
              private el: ElementRef) {
  }

  @HostListener('error') onError() {
    this.el.nativeElement.setAttribute('src', this.onErrorSrc)
  }

}
