import { Action, createReducer, on } from '@ngrx/store';

import * as OrdersActions from './orders.actions';
import { IOrdersState } from './orders.model';

export const initialState: IOrdersState = {
  orders: null,
  statistic: null,
  currentOrderInfo: null,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  payedSuccess: null,
  chargeMessage: null,
  error: null
};

const ordersReducer = createReducer(
  initialState,
  on(OrdersActions.getOrdersListAction, (state) => ({
    ...state,
    payedSuccess: null,
    chargeMessage: null,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.getOrdersListSuccessAction, (state, {payload}) => ({
    ...state,
    orders: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.getOrdersListFailureAction, (state, {payload}) => ({
    ...state,
    orders: null,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(OrdersActions.changeOrderStatus, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.changeOrderStatusSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.changeOrderStatusFailed, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(OrdersActions.changeBillingStatus, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.changeBillingStatusSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.changeBillingStatusFailed, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(OrdersActions.getPaymentOrderInfo, (state) => ({
    ...state,
    payedSuccess: null,
    chargeMessage: null,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.getPaymentOrderInfoSuccess, (state, {payload}) => ({
    ...state,
    currentOrderInfo: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.getPaymentOrderInfoFailed, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(OrdersActions.editOrder, (state) => ({
    ...state,
    payedSuccess: null,
    chargeMessage: null,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.editOrderSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.editOrderFailed, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(OrdersActions.chargeOrder, (state) => ({
    ...state,
    payedSuccess: null,
    chargeMessage: null,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.chargeOrderSuccess, (state, {payload}) => ({
    ...state,
    payedSuccess: payload.isSuccess,
    chargeMessage: payload.message,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.chargeOrderFailed, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(OrdersActions.sendPaymentLink, (state) => ({
    ...state,
    payedSuccess: null,
    chargeMessage: null,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.sendPaymentLinkSuccess, (state, {payload}) => ({
    ...state,
    payedSuccess: payload.isSuccess,
    chargeMessage: payload.message,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.sendPaymentLinkFailed, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(OrdersActions.getOrdersStatistic, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.getOrdersStatisticSuccess, (state, {payload}) => ({
    ...state,
    statistic: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(OrdersActions.getOrdersStatisticFailed, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
);

export function OrdersReducer(state: IOrdersState | undefined, action: Action): any {
  return ordersReducer(state, action);
}
