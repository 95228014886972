import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    duration: 2000
  }

  constructor(private matSnackBar: MatSnackBar,
              public translate: TranslateService) {
  }

  onSuccess(message: string): void {
    this.matSnackBar.open(this.translate.instant(message), 'Close', {
      panelClass: 'success-snackbar',
      ...this.snackBarConfig
    });
  }

  onError(message: string | string[]): void {
    if(message instanceof Array) {
      message.forEach( (mess, index) => {
        setTimeout(() => {
          this.matSnackBar.open(mess, 'close', {
            panelClass: 'error-snackbar',
            ...this.snackBarConfig
          });
        }, index * (1000));
      });
    }
    this.matSnackBar.open(this.translate.instant(message), 'Close', {
      panelClass: 'error-snackbar',
      ...this.snackBarConfig
    });
  }

  onWarning(message: string): void {
    this.matSnackBar.open(this.translate.instant(message), 'Close', {
      panelClass: 'warning-snackbar',
      ...this.snackBarConfig
    });
  }
}
