import { createFeatureSelector, createSelector } from "@ngrx/store";
import { HttpErrorResponse } from '@angular/common/http';

import { IDbLocation, ILocationState } from './location.model';
import { IResponse } from '../../../../../api/api-data/api.interfaces';

export const getLocationState = createFeatureSelector<ILocationState>('location');

export const getLocationTable = createSelector(getLocationState,
  (state: ILocationState): IResponse<IDbLocation[]> => state.data);

export const getLocationSuccessLoader = createSelector(getLocationState,
  (state: ILocationState): boolean => state.isLoadingSuccess);

export const getLocationLoader = createSelector(getLocationState,
  (state: ILocationState): boolean => state.isLoading);

export const getLocationError = createSelector(getLocationState,
  (state: ILocationState): HttpErrorResponse => state.error);
