import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

import { IBusinessCarTire, ICarTireDBData, ICarTiresParams } from "./manage-car-tires.model";
import { IResponse } from '../../../../api/api-data/api.interfaces';
import { ITireBruttoPrice } from '../../business-user/article-price';

export const GET_CAR_TIRES_LIST = '[MANAGE CAR TIRES] Get car tires list';
export const GET_CAR_TIRES_LIST_SUCCESS = '[MANAGE CAR TIRES] Get car tires list success';
export const GET_CAR_TIRES_LIST_FAILURE = '[MANAGE CAR TIRES] Get car tires list failure';

export const ADD_CAR_TIRE = '[MANAGE CAR TIRES] Add car tire';
export const ADD_CAR_TIRE_SUCCESS = '[MANAGE CAR TIRES] Add car tire success';
export const ADD_CAR_TIRE_FAILURE = '[MANAGE CAR TIRES] Add car tires failure';

export const ADD_BUSINESS_CAR_TIRE = '[INVENTORY_BUSINESS] Add car tire';
export const ADD_BUSINESS_CAR_TIRE_SUCCESS = '[INVENTORY_BUSINESS] Add car tire success';
export const ADD_BUSINESS_CAR_TIRE_FAILURE = '[INVENTORY_BUSINESS] Add car tires failure';

export const UPDATE_CAR_TIRE = '[MANAGE CAR TIRES] Update car tire';
export const UPDATE_CAR_TIRE_SUCCESS = '[MANAGE CAR TIRES] Update car tire success';
export const UPDATE_CAR_TIRE_FAILURE = '[MANAGE CAR TIRES] Update car tires failure';

export const EXPORT_CAR_TIRE_FILE = '[MANAGE CAR TIRES] Export file about car tire';
export const EXPORT_CAR_TIRE_FILE_SUCCESS = '[MANAGE CAR TIRES] Export file about car tire success';
export const EXPORT_CAR_TIRE_FILE_FAILURE = '[MANAGE CAR TIRES] Export file about car tires failure';

export const CHANGE_TIRE_VISIBILITY = '[MANAGE CAR TIRES] Change tire visibility';
export const CHANGE_TIRE_VISIBILITY_SUCCESS = '[MANAGE CAR TIRES] Change tire visibility success';
export const CHANGE_TIRE_VISIBILITY_FAILURE = '[MANAGE CAR TIRES] Change tire visibility failure';

export const CHANGE_TIRE_PRICES = '[MANAGE CAR TIRES] Change tire prices';
export const CHANGE_TIRE_PRICES_SUCCESS = '[MANAGE CAR TIRES] Change tire prices success';
export const CHANGE_TIRE_PRICES_FAILURE = '[MANAGE CAR TIRES] Change tire prices failure';

export const CHANGE_TIRE_BRUTTO_PRICE = '[MANAGE CAR TIRES] Change tire brutto price';
export const CHANGE_TIRE_BRUTTO_PRICE_SUCCESS = '[MANAGE CAR TIRES] Change tire brutto price success';
export const CHANGE_TIRE_BRUTTO_PRICE_FAILURE = '[MANAGE CAR TIRES] Change tire brutto price failure';

export const getCarTiresList = createAction(
  GET_CAR_TIRES_LIST,
  props<{ url?: string, params?: ICarTiresParams }>()
)

export const getCarTiresListSuccess = createAction(
  GET_CAR_TIRES_LIST_SUCCESS,
  props<{ payload: IResponse<ICarTireDBData[]> }>()
)

export const getCarTiresListFailure = createAction(
  GET_CAR_TIRES_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addCarTire = createAction(
  ADD_CAR_TIRE,
  props<{ payload: FormData, copyTireId?: number }>()
)

export const addCarTireSuccess = createAction(
  ADD_CAR_TIRE_SUCCESS,
  props<{ payload: ICarTireDBData, copyTireId?: number }>()
)

export const addCarTireFailure = createAction(
  ADD_CAR_TIRE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addBusinessCarTire = createAction(
  ADD_BUSINESS_CAR_TIRE,
  props<{ payload: FormData }>()
)

export const addBusinessCarTireSuccess = createAction(
  ADD_BUSINESS_CAR_TIRE_SUCCESS,
  props<{ payload: IBusinessCarTire }>()
)

export const addBusinessCarTireFailure = createAction(
  ADD_BUSINESS_CAR_TIRE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateCarTire = createAction(
  UPDATE_CAR_TIRE,
  props<{ payload: FormData }>()
)

export const updateCarTireSuccess = createAction(
  UPDATE_CAR_TIRE_SUCCESS,
  props<{ payload: ICarTireDBData }>()
)

export const updateCarTireFailure = createAction(
  UPDATE_CAR_TIRE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const exportFileAboutCarTire = createAction(
  EXPORT_CAR_TIRE_FILE,
  props<{ filters: string, fileName: string }>()
)

export const exportFileAboutCarTireSuccess = createAction(
  EXPORT_CAR_TIRE_FILE_SUCCESS
)

export const exportFileAboutCarTireFailure = createAction(
  EXPORT_CAR_TIRE_FILE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changTireVisibility = createAction(
  CHANGE_TIRE_VISIBILITY,
  props<{ id: number, forReviews: boolean }>()
)

export const changTireVisibilitySuccess = createAction(
  CHANGE_TIRE_VISIBILITY_SUCCESS,
  props<{ id: number, forReviews: boolean }>()
)

export const changTireVisibilityFailure = createAction(
  CHANGE_TIRE_VISIBILITY_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changTirePrices = createAction(
  CHANGE_TIRE_PRICES,
  props<{ payload: FormData }>()
)

export const changTirePricesSuccess = createAction(
  CHANGE_TIRE_PRICES_SUCCESS
)

export const changTirePricesFailure = createAction(
  CHANGE_TIRE_PRICES_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changTireBruttoPrice = createAction(
  CHANGE_TIRE_BRUTTO_PRICE,
  props<{ id: number, payload: FormData }>()
)

export const changTireBruttoPriceSuccess = createAction(
  CHANGE_TIRE_BRUTTO_PRICE_SUCCESS,
  props<{ price: ITireBruttoPrice }>()
)

export const changTireBruttoPriceFailure = createAction(
  CHANGE_TIRE_BRUTTO_PRICE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
