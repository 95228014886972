<div class="connect-image">
  <div class="content connect-image-form">
    <div [formGroup]="searchForm">
      <div class="content-head">
        <h3 class="head-title">
          {{'CONNECT_IMAGE' | translate}}
        </h3>
      </div>

      <div class="content-progress" *ngIf="!(isParamsLoad$ | async)">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </div>

      <div class="content-body" *ngIf="(isParamsLoad$| async) &&(searchParams$ | async) as params">
        <div class="connect-image-form_row">
          <div class="connect-image-form_field">
            <app-select [label]="EConnectImageLabel.type" [placeholder]="EConnectImagePlaceholder.type"
                        [isValueName]="true" [options]="params.VehicleType" formControlName="vehicleType"></app-select>
          </div>
          <div class="connect-image-form_field">
            <app-select [label]="EConnectImageLabel.brand" [placeholder]="EConnectImagePlaceholder.brand"
                        [options]="params.Manufacture" formControlName="brandId"></app-select>
          </div>
          <div class="connect-image-form_field search-wraper">
            <mat-form-field class="form-element search" appearance="fill">
              <input formControlName="query" placeholder="{{'SEARCH' | translate}}" matInput type="text"
                     autocomplete="off">
              <mat-icon matPrefix>search</mat-icon>
              <mat-error *ngIf="!searchForm.get('query').valid && searchForm.get('query').touched">
                {{'THIS_FIELD_IS_REQUIRED' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="connect-image-form_field save-btn">
            <button class="btn-admin" (click)="sendSearchForm()" [disabled]="!searchForm.valid">
              {{'SEARCH' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="searchSubmit">
    <div class="content connect-image-form_image-uploader beforeSubmitStep"
         [ngClass]="{'beforeSubmitStep': firstStep, 'submitStep': !firstStep}">
      <app-image-uploader [showImage]="true" [imageSize]="imageSize"
                          [loaderType]="EImgLoaderType.imgWithFileName"
                          [showRemoveButton]="submitStep"
                          (uploadImage)="updateImage($event)"
                          #loader
      >
        <div *ngIf="(tableContent$ | async)">
          <div>
            {{'SELECT_IMAGE' | translate}}
          </div>
          <ng-container>
            {{'FILE_SIZE_MUST_BE_NOT_GREATER_THAN_1234PX1234PX' | translate}}
          </ng-container>
        </div>
        <div *ngIf="!firstStep">
          <ng-container>
            <div>
              {{'SELECT_TIRES_TO_WHICH_YOU_WANT_TO_ATTACH_IMAGE_OR' | translate}}
              <a (click)="updateSubmitStep(true)">{{'CHOOSE_ANOTHER_IMAGE' | translate}}</a>
            </div>
          </ng-container>
        </div>
      </app-image-uploader>
      <button *ngIf="imageForm.get('Image').value && firstStep"
              (click)="updateSubmitStep(false)"
              class="btn-admin"
      >{{'SUBMIT' | translate}}
      </button>
      <button *ngIf="!firstStep" (click)="updateArticleImages()" [disabled]="!articleIds.length"
              class="btn-admin">{{'UPDATE' | translate}}
      </button>
    </div>
  </div>
  <div class="content connect-image_table" *ngIf="(tableContent$ | async) as list">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="list.content"
      [language]="languageService.mainLanguage.value"
      [isLoadingTable]="!(isLoad$ | async)"
      [clearSelection]="clearSelection"
      (checkboxColumn)="getCheckedArticles($event)"
    >
    </starter-table>
    <div *ngIf="list.content && !list.content.length" class="no-result-table">
      {{'NO_DATA_MATCHING_THE_FILTER' | translate}}
    </div>
    <div class="pagination-wrap" *ngIf="list.content?.length">
      <div class="pagination">
          <span class="pagination-item"
                *ngFor="let page of list.content | paginate:
                { itemsPerPage: list.pageSize,
                 currentPage: list.currentPage,
                 totalItems: list.rowCount }"></span>
        <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                             [nextLabel]="'NEXT' | translate"
                             (pageChange)="paginate($event)"></pagination-controls>
      </div>
      <div>
        <button (click)="updateArticleImages()" [disabled]="!articleIds.length" class="btn-admin">{{'UPDATE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
