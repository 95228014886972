import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import * as RimsActions from './rims.actions';
import { IRim, IRimsState } from './rims.model';

export const initialState: IRimsState = {
  response: {
    rowCount: 0,
    pageCount: 0,
    pageSize: 0,
    currentPage: 0,
    content: []
  },
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse
};

const rimsReducer = createReducer(
  initialState,
  on(RimsActions.getRimsList, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(RimsActions.getRimsListSuccess, (state, {payload}) => {
    return {
      ...state,
      response: payload,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(RimsActions.getRimsListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(RimsActions.addRim, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(RimsActions.addRimSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(RimsActions.updateRim, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(RimsActions.updateRimSuccess, (state, {payload}) => {
    const content = [...state.response.content];
    const newData = content.map((rim: IRim) => {
      if (rim.id === payload.id) rim = payload;
      return rim;
    });
    return {
      ...state,
      response: {...state.response, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(RimsActions.updateRimFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(RimsActions.changRimVisibility, (state) => ({
    ...state,
    error: {} as HttpErrorResponse,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false
  })),
  on(RimsActions.changRimVisibilitySuccess, (state) => ({
    ...state,
    error: {} as HttpErrorResponse,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  })),
  on(RimsActions.changRimVisibilityFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true
  })),
  on(RimsActions.deleteRim, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(RimsActions.deleteRimSuccess, (state, {id}) => {
    const content = [...state.response.content];
    const newData = content.filter(rim => rim.id !== id);
    return {
      ...state,
      response: {...state.response, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(RimsActions.deleteRimFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function RimsReducer(state: IRimsState | undefined, action: Action): any {
  return rimsReducer(state, action);
}



