import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ConnectImageState } from "./connect-image.model";
import { HttpErrorResponse } from '@angular/common/http';

export const getConnectImageState = createFeatureSelector<ConnectImageState>('connectImage');

export const getConnectImageTableData = createSelector(getConnectImageState,
  (state: ConnectImageState) => state.result);

export const getImageConnectLoader = createSelector(getConnectImageState,
  (state: ConnectImageState): boolean => state.isLoading);

export const getImageConnectSuccessLoader = createSelector(getConnectImageState,
  (state: ConnectImageState): boolean => state.isLoadingSuccess);

export const getImageConnectError = createSelector(getConnectImageState,
  (state: ConnectImageState): HttpErrorResponse | null => state.error);
