<div class="content payments">
  <div class="content-head">
    <div class="head-search">
      <h3 class="head-title">
        {{'MANAGE_LEADS' | translate}}  ({{month | translate}})
      </h3>
    </div>
    <div class="filters" *ngIf="(leadsList$ | async) as list">
      <span class="text-wrap">{{'FILTERS' | translate}}</span>
      <button class="filter-button" (click)="handleFilter($event)">
        <img src="assets/icons/svg/filter.svg" alt="Show filters" aria-label="show filters">
      </button>
      <div *ngIf="isFiltersShow && (params$ | async) as params" class="filters-window" [formGroup]="filterGroup" (click)="$event.stopPropagation()">
        <button mat-mini-fab class="close-filter-btn" (click)="isFiltersShow = !isFiltersShow">
          <i class="material-icons">close</i>
        </button>
        <app-multiple-select [label]="ELeadsLabel.vehicle" [placeholder]="ELeadsPlaceholder.vehicle"
                             [needCancelAll]="true" [options]="list.filters['Category']"
                             [needStringValue]="true" formControlName="Category"></app-multiple-select>
        <div class="filters-window-menu">
          <!--          <app-select [label]="ELeadsLabel.lead" [placeholder]="ELeadsPlaceholder.lead"-->
          <!--                      formControlName="LeadType" [options]="list.filters['LeadType']"></app-select>-->
          <app-multiple-select [label]="ELeadsLabel.lead" [placeholder]="ELeadsPlaceholder.lead"
                               [needStringValue]="true" [needCancelAll]="true" formControlName="LeadType" [options]="list.filters['LeadType']"></app-multiple-select>
          <app-multiple-select [label]="ELeadsLabel.tws" [placeholder]="ELeadsPlaceholder.tws"
                               [needStringValue]="true" [needCancelAll]="true" formControlName="TWSName" [options]="list.filters['TWSName']"></app-multiple-select>
        </div>
        <app-calendar [needMinDate]="false" formControlName="dateRange"></app-calendar>
      </div>
    </div>
  </div>
  <div class="content-body list">
    <div class="info-cards" *ngIf="(statistic$ | async) as list">
      <div class="card" *ngFor="let item of list.leads" [ngClass]="{'item-virtual': item.virtualNumber}">
        <div class="name">{{item.leadsTitle | translate}}</div>
        <div class="card-info">
          <div class="count">
            {{item.leadsNumber | number}}
            <span *ngIf="!!item.uniqueLeadsNumber" class="count-number">({{item.uniqueLeadsNumber | number}})</span>
            <span *ngIf="!!item.nettoLeadsNumber" class="count-number">({{item.nettoLeadsNumber | number}})</span>
            <mat-icon *ngIf="!!item.nettoLeadsNumber" matTooltip="{{'NETTO_LEAD_NOTIFICATION' | translate}}"
                      matTooltipPosition="above"
                      class="info">info
            </mat-icon>
          </div>
        </div>
        <div class="virtual-phone" *ngIf="item.virtualNumber">{{changeVirtualNumber(item.virtualNumber)}}</div>
      </div>
    </div>
  </div>

  <div class="content blog_table" *ngIf="(leadsList$ | async) as response">
    <div class="btn-wrap">
      <button (click)="export()" class="btn-admin btn-white export-btn">
        <mat-icon class="icon-img" svgIcon="upload"></mat-icon>
        {{'DOWNLOAD' | translate}}
      </button>
    </div>

    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="filterContent(response.content, response.totalRecord)"
      [language]="languageService.mainLanguage.value"
      [filterData]="response.filters"
      (onClick)="opent4uLink($event)"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [dateRange]="filterGroup.controls['dateRange'].value"
      (filters)="getTableFilters($event)"
      (dropdownValue)="changeStatus($event)"
      [columnFormValue]="formValue"
    >
    </starter-table>
    <div *ngIf="response.content && !response.content.length" class="no-result-table">
      {{'NO_DATA_MATCHING_THE_FILTER' | translate}}
    </div>
    <div class="pagination" *ngIf="response.content">
          <span class="pagination-item"
                *ngFor="let page of response.content | paginate:
                { itemsPerPage: 10,
                 currentPage: currentPage,
                 totalItems: response.totalRecord }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>
