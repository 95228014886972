import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapComponent } from './component/google-map.component';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from '../shared/shared.module';
import { MaterialsModule } from '../../material/materials/materials.module';

@NgModule({
  declarations: [GoogleMapComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialsModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyBRLgutleP1yY34ZD6Pj_QhWIytWkiwjU4', libraries: ['places']})
  ],
  exports: [GoogleMapComponent]
})
export class GoogleMapModule {
}
