export enum EInventorySetUpLabel {
  brandYear = 'YEAR_OF_MANUFACTURE',
  quantity = 'QUANTITY',
  afterVat = 'AFTER_VAT_FORM',
  location = 'LOCATION_ON_THE_STORE',
  costPrice = 'COST_PRICE_FORM',
  isDisplayed = 'DISPLAYED_ON_THE_WEBSITE',
  dialogTitleAdd = 'ADD_TIRE',
  dialogTitleEdit = 'EDIT',
  saleVat = 'SALE_PRICE_VAT',
  deleteConfirm = 'PRICES_FOR_TIRES_WITH_QUANTITY'
}

export enum EInventorySetUpPlaceholder {
  brandYear = 'SELECT_YEAR_OF_MANUFACTURE',
  quantity = 'ENTER_QUANTITY',
  sitePrice = 'ENTER_SITE_PRICE',
  location = 'ENTER_LOCATION_ON_THE_STORE',
  costPrice = 'ENTER_COST_PRICE'
}

export enum EBusinessRoutes {
  dashboard = 'dashboard/:resellerId',
  managePrices = 'manage-prices/:resellerId',
  expectedProfit = 'expected-profit/:resellerId',
  inventoryCars = 'inventory-set-up-cars/:resellerId',
  inventoryMoto = 'inventory-set-up-motorcycles/:resellerId',
  setPriceCars = 'set-price-cars/:resellerId',
  setPriceMoto = 'set-price-motorcycles/:resellerId',
  priceListCars = 'price-list-cars/:resellerId',
  priceListMoto = 'price-list-motorcycles/:resellerId',
  leads = 'leads/:resellerId',
  goldLeads = 'gold-leads/:resellerId',
  orders = 'orders/:resellerId',
  payments = 'payments/:resellerId'
}

export enum EPricesTitle {
  inventoryCar = 'INVENTORY_SET_UP_CARS',
  inventoryMoto = 'INVENTORY_SET_UP_MOTO',
  setPriceCar = 'SET_PRICE_CARS',
  setPriceMoto = 'SET_PRICE_MOTO',
  consumerPriceList = 'CONSUMER_PRICE_LIST'
}

export enum EOrderLabel {
  customerName = 'CONSUMER_NAME',
  customerPhone = 'PHONE',
  quantity = 'QUANTITY',
  tireName = 'TIRE_NAME',
  brandName = 'MANUFACTURER',
  price = 'PRICE',
  totalAmount = 'TOTAL_AMOUNT'
}
