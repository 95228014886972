import { HttpErrorResponse } from "@angular/common/http";
import { INumberParam } from '../../tire-reseller';

export interface ManufacturerElement {
  id: number,
  name: string,
  logo: string,
  isActive: boolean,
  priority: number,
  country: INumberParam,
  modalType?: string,
  brandType?: string,
  resellerPrivateBrand?: boolean,
  tireResellerId: string
}

export interface ManufacturerState {
  currentBrandType: string,
  deleteId: number,
  content: IManufactureList,
  isLoading: boolean,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  error: HttpErrorResponse | null
}

export interface IManufactureList {
  Tire: ManufacturerElement[],
  Rim: ManufacturerElement[]
}

export interface IPrivateBrand {
  name: string,
  logo: {
    mode: string,
    file: any
  },
  isActive: boolean,
  tireResellerId: string
}
