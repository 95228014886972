import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import ConnectImageApiService from "../../../../api/general-admin-services/connect-image.api.service";
import * as connectImageActions from "./connect-image.actions";

@Injectable()
export class ConnectImageEffects {
  constructor(private actions$: Actions,
              private connectImageApiService: ConnectImageApiService) {
  }

  getConnectImageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(connectImageActions.getConnectImageData),
      switchMap(action =>
        this.connectImageApiService.getImageConnectionList(action.params).pipe(
          map(response => connectImageActions.getConnectImageDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(connectImageActions.getConnectImageDataFailure({payload: err}))
          )
        )
      )
    )
  )

  connectImageForTires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(connectImageActions.connectImageForTires),
      switchMap(action =>
        this.connectImageApiService.addImageForTires(action.payload).pipe(
          map(response => connectImageActions.connectImageForTiresSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(connectImageActions.connectImageForTiresFailure({payload: err}))
          )
        )
      )
    )
  )
}
