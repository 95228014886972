import { HttpErrorResponse } from "@angular/common/http";
import { IResponse } from '../../../../api/api-data/api.interfaces';
import { LoginResponse } from '../../../auth/interfaces/auth.interface';
import { ManufacturerElement } from '../criteria/manufacturer';

export interface TireResellerElement {
  vehicleType: string,
  id: string,
  twsName: string,
  subscription: string,
  city: string,
  address: string,
  addressNumber: string,
  officePhoneNumber: string,
  email: string,
  mobilePhone: string,
  numberOfLeads: number,
  total: number,
  active: boolean,
  modalType?: string,
  zapTireShopId: string
}

export interface TireResellerDBElement extends TireResellerElement {
  id: string;
}

export interface TireResellersState {
  tireReseller: TireResellerFull,
  response: IResponse<TireResellerFull[]>,
  pricesProfit: {
    list: ITWSPriceProfit[],
    isLoader: boolean,
    isLoaderSuccess: boolean,
    error: HttpErrorResponse
  },
  resellersByTire: IResselerByTire[] | null,
  privateBrand: ManufacturerElement,
  privateBrandList: ManufacturerElement[],
  businessUserData: LoginResponse | null,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  isLoading: boolean,
  error: HttpErrorResponse,
}

export interface TireResellerFull {
  id: string,
  userName: string,
  subscriptionType: INumberParam,
  twsName: string,
  active: boolean,
  sabbathWorkingHours: {
    openTime: Date,
    closeTime: Date
  },
  fridayWorkingHours: {
    openTime: Date,
    closeTime: Date
  },
  regularWorkingHours: {
    openTime: Date,
    closeTime: Date
  },
  address: {
    city: INumberParam,
    street: string,
    buildingNumber: string
  },
  description: string,
  coupon: string,
  wazeNavigation: string,
  contactName: string,
  contactPhone: string,
  officePhone: string,
  email: string,
  fax: string,
  phoneLeadPrice: number,
  rimsLeadPrice: number,
  indexLeadPrice: number,
  converseLeadPrice: number,
  navigationLeadPrice: number,
  starterLeadPrice: number,
  goldLeadPrice: number,
  zapLeadPrice: number,
  tireTypes: INumberParam[],
  vehicleType: INumberParam,
  additionalPictures: IAdditionalPictures[],
  logoUrl: string,
  brands: INumberParam[],
  numberOfLeads: number,
  total: number,
  includeVAT: boolean,
  coveredLead: boolean,
  moreOptions: INumberParam[],
  isOpenAllTime: boolean,
  modalType?: string,
  filters: string,
  zapPhone: string,
  page: number,
  sendSMS: boolean,
  latitude: string,
  longitude: string,
  googleSearchText: string,
  search: string,
  zapTireShopId: string | null,
  subLocation: string | null,
  ownerPhoneNumber: string | null
}

export interface IAdditionalPictures {
  id: number,
  pictureUrl: string
}

export interface IParameter {
  id: string | number,
  value: string,
  isPrivate?: boolean
}

export interface INumberParam {
  id: number,
  value: string,
  isPrivate?:boolean
}

export interface IResellerParams {
  page: number;
  filters?: string;
  search?: string;
}

export interface ITWSPriceProfit {
  characteristicId: number,
  characteristicValue: string,
  profit: number
}

export interface ITWSPricesControl {
  characteristicOptionId: number,
  profit: number,
  value?: string
}

export interface IChangeTWSProfit {
  profit: ITWSPricesControl[]
}

export interface ITWSValidator {
  firstError: string,
  secondError: string,
  controlNameFirst: string,
  controlNameSecond: string
}

export interface IResellerResExtended {
  entity: TireResellerFull,
  status: number,
  message: string
}

export interface IResselerByTire {
  tireResellerId: string;
  logoUrl: string;
  companyName: string;
  articleId: number;
  warehouseLocation: string;
  productionYear: number;
  inventoryPrice: number;
  sitePrice: number;
  quantity: number;
  inStock: boolean;
  price: number;
  tireName: string;
  tireId: string;
}
