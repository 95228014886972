import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CharacteristicElement, CharacteristicState } from "./characteristic.model";

export const getCharacteristicState = createFeatureSelector<CharacteristicState>('characteristic');

export const getCharacteristicTable = createSelector(getCharacteristicState,
  (state: CharacteristicState): CharacteristicElement[] => state.characteristic.content)

export const getOptionsList = createSelector(getCharacteristicState,
  (state: CharacteristicState) => state.options);

export const getOptionsById = (index: number) => createSelector(
  getOptionsList, (options) => options[index]);

export const selectLookupParams = createSelector(getCharacteristicState,
  (state: CharacteristicState) => state.lookupParameters);

export const selectCharacteristicSuccessLoader = createSelector(getCharacteristicState,
  (state: CharacteristicState) => state.isLoadingSuccess);

export const selectCharacteristicLoader = createSelector(getCharacteristicState,
  (state: CharacteristicState) => state.isLoading);
