import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";
import { DiameterElement } from "./diameter.model";

export const GET_DIAMETER_DATA = '[DIAMETER] Get data';
export const GET_DIAMETER_SUCCESS = '[DIAMETER] Get data success';
export const GET_DIAMETER_FAILURE = '[DIAMETER] Get data failure';

export const ADD_DIAMETER_ELEMENT = '[DIAMETER] Add element';
export const ADD_DIAMETER_ELEMENT_SUCCESS = '[DIAMETER] Add element success';
export const ADD_DIAMETER_ELEMENT_FAILURE = '[DIAMETER] Add element failure';

export const UPDATE_DIAMETER_ELEMENT = '[DIAMETER] Update element';
export const UPDATE_DIAMETER_ELEMENT_SUCCESS = '[DIAMETER] Update element success';
export const UPDATE_DIAMETER_ELEMENT_FAILURE = '[DIAMETER] Update element failure';

export const DELETE_DIAMETER_ELEMENT = '[DIAMETER] Delete element';
export const DELETE_DIAMETER_ELEMENT_SUCCESS = '[DIAMETER] Delete element success';
export const DELETE_DIAMETER_ELEMENT_FAILURE = '[DIAMETER] Delete element failure';

export const getDiameterData = createAction(
  GET_DIAMETER_DATA
)

export const getDiameterDataSuccess = createAction(
  GET_DIAMETER_SUCCESS,
  props<{ payload: DiameterElement[] }>()
)

export const getDiameterDataFailure = createAction(
  GET_DIAMETER_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addDiameterElement = createAction(
  ADD_DIAMETER_ELEMENT,
  props<{ payload: DiameterElement }>()
)

export const addDiameterElementSuccess = createAction(
  ADD_DIAMETER_ELEMENT_SUCCESS,
  props<{ payload: DiameterElement }>()
)

export const addDiameterElementFailure = createAction(
  ADD_DIAMETER_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateDiameterElement = createAction(
  UPDATE_DIAMETER_ELEMENT,
  props<{ payload: DiameterElement, id: number }>()
)

export const updateDiameterElementSuccess = createAction(
  UPDATE_DIAMETER_ELEMENT_SUCCESS,
  props<{ payload: DiameterElement }>()
)

export const updateDiameterElementFailure = createAction(
  UPDATE_DIAMETER_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteDiameterElement = createAction(
  DELETE_DIAMETER_ELEMENT,
  props<{ payload: number }>()
)

export const deleteDiameterElementSuccess = createAction(
  DELETE_DIAMETER_ELEMENT_SUCCESS
)

export const deleteDiameterElementFailure = createAction(
  DELETE_DIAMETER_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
