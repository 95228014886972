import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LanguageService } from '../../../api/language.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  CellTypes,
  IStatusValue,
  TableMeta
} from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { billingOrdersTableMeta } from '../../../t4u-admin/pages/pages.constants';
import { IResponse } from '../../../api/api-data/api.interfaces';
import {
  IBillingOrder,
  IBillingTire,
  IOrderParams,
  IOrderStatistic
} from '../../../modules/state/general-admin/orders/orders.model';
import { Store } from '@ngrx/store';
import { getLookupParameters, ILookupParameters } from '../../../modules/state/general-admin/characteristic';
import { selectLookupParams } from '../../../modules/state/general-admin/characteristic/characteristic.selectors';
import {
  changeBillingStatus,
  changeOrderStatus,
  editOrder,
  exportOrdersStatistic,
  getOrdersListAction,
  getOrdersStatistic,
  selectOrdersError,
  selectOrdersList,
  selectOrdersLoader,
  selectOrdersStatistic,
  selectOrdersSuccessLoader
} from '../../../modules/state/general-admin/orders';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderDetailDialogComponent } from './order-detail-dialog/order-detail-dialog.component';
import { EStatisticTitle } from '../../../modules/statistics/components/statistics/statistics.enums';
import { IStatisticParams } from '../../../modules/state/general-admin/leads/leads.model';
import { IParameter } from '../../../modules/state/general-admin/tire-reseller';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { EModalMessage } from '../../../t4u-admin/pages/criteria/components/criteria.constants';
import { NotificationService } from '../../../api/notification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy, AfterViewInit {
  public filterForm: FormGroup;
  public today: Date = new Date();
  public minDate: Date = new Date('2023-08-01');
  public currentPage: number = 1;
  public isTableLoad$: Observable<boolean>;
  public ordersTableMeta: TableMeta[] = billingOrdersTableMeta;
  public orders$: Observable<IResponse<IBillingOrder[]> | null>;
  public apiParams: IOrderParams;
  public statisticParams: IStatisticParams;
  public destroy$: Subject<void> = new Subject<void>();
  public lookupParams$: Observable<ILookupParameters>;
  public readonly EStatisticTitle = EStatisticTitle;
  public statistic$: Observable<IOrderStatistic | null>;
  public isBusiness: boolean;
  public remark: FormControl = new FormControl(['']);
  private dialogRef: MatDialogRef<ElementRef>;
  private currentOrder: IBillingOrder;
  public allTWS: IParameter = {id: '', value: EStatisticTitle.all};
  public paymentSuccess: boolean = false;

  @ViewChild('remarkDialog') remarkDialog: TemplateRef<ElementRef>;
  @ViewChild('paymentDialog', {static: false}) paymentDialog: TemplateRef<ElementRef>;

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              private store$: Store,
              private auth: AuthService,
              private alertService: NotificationService,
              private route: ActivatedRoute,
              public languageService: LanguageService) { }

  ngOnInit(): void {
    this.initForm();
    this.getData();
    this.getFiltersValue();
  }

  ngAfterViewInit(): void {
    const payment = this.route.snapshot.queryParams.success;
    if (payment) {
      this.paymentSuccess = JSON.parse(payment);
      this.dialogRef = this.dialog.open(this.paymentDialog, {
        panelClass: 'admin-dialog',
        minWidth: '500px'
      })
    }
  }

  initForm(): void {
    this.filterForm = this.fb.group({
      OperationalStatus: [''],
      BillingStatus: [''],
      LocationId: [''],
      dateFrom: [''],
      dateTo: [''],
      tireResellerId: ['']
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.isBusiness = this.auth.isReseller;
    this.store$.dispatch(getLookupParameters({payload: 'OrderStatus,BillingStatus,Location,TWSName'}))
    this.apiParams = {page: this.currentPage, pageSize: 20};
    this.orders$ = this.store$.select(selectOrdersList);
    this.statistic$ = this.store$.select(selectOrdersStatistic);
    this.lookupParams$ = this.store$.select(selectLookupParams);
    this.isTableLoad$ = this.store$.select(selectOrdersLoader);
    this.getOrdersData();
  }

  getOrdersData(): void {
    this.store$.dispatch(getOrdersListAction({params: this.apiParams}));
    this.store$.dispatch(getOrdersStatistic({params: this.statisticParams}));
  }

  changeStatus(value: IStatusValue): void {
    if (value.key === 'billingStatus') {
      this.store$.dispatch(changeBillingStatus({id: value.id, status: value.status}));
      this.getOrdersData();
      return;
    }
    this.store$.dispatch(changeOrderStatus({id: value.id, status: value.status}));
    this.getOrdersData();
  }

  getFiltersValue(): void {
    this.filterForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(1000))
      .subscribe({
        next: (filters) => {
          const params = {...filters};
          delete params.dateFrom;
          delete params.dateTo;

          if (filters.dateFrom) {
            const dateFrom = filters.dateFrom;
            dateFrom.setHours(4);
            params.dateFrom = dateFrom.toISOString();
          }
          if (filters.dateTo) {
            const dateTo = filters.dateTo;
            dateTo.setHours(4);
            params.dateTo = dateTo.toISOString();
          }

          const filterQuery = this.transformToFilterString(params);
          this.apiParams = {...params, filters: filterQuery};
          this.statisticParams = {...params, tireResellerId: params?.tireResellerId || '', filters: filterQuery};

          this.getOrdersData();
        }
      });
  }

  transformToFilterString(params: { [key: string]: string }): string {
    let filterString = '';
    if (params.BillingStatus !== '') {
      filterString += `BillingStatus==${params.BillingStatus},`;
    }
    if (params.OperationalStatus !== '') {
      filterString += `OperationalStatus==${params.OperationalStatus},`;
    }
    if (params.LocationId !== '') {
      filterString += `LocationId==${params.LocationId},`;
    }
    if (params.tireResellerId !== '') {
      filterString += `TireResellerId==${params.tireResellerId},`;
    }
    return filterString;
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.getOrdersData();
  }

  openOrderDetails(order: ITableData<IBillingOrder>): void {
    if (order.source === CellTypes.EditableRemark) {
      this.remark.patchValue(order.data.remark);
      this.currentOrder = order.data;
      this.dialogRef = this.dialog.open(this.remarkDialog, {
        data: order.data.remark,
        panelClass: 'admin-dialog',
        minWidth: '800px'
      })
      return;
    }
    this.dialog.open(OrderDetailDialogComponent, {
      data: order.data,
      panelClass: 'admin-dialog',
      minWidth: '800px'
    }).afterClosed().pipe(takeUntil(this.destroy$))
      .subscribe((needReload) => {
        if (!needReload) return;
        this.getOrdersData()
      });
  }

  export(): void {
    this.store$.dispatch(exportOrdersStatistic({params: this.statisticParams, fileName: 'orders-statistics'}));
  }

  sortContent(params: IParameter[]): IParameter[] {
    if (!params?.length) return [];
    let options = [...params];
    return options.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? 1 : -1)
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  changeRemark(): void {
    let newOrderDetails: any = {
      customerName: this.currentOrder.customerName,
      phoneNumber: this.currentOrder.customerPhone,
      remark: this.remark.value,
      orderItems: this.currentOrder.orderItem
    }

    const formData = new FormData();
    for (const key in newOrderDetails) {
      if (key === 'orderItems') {
        newOrderDetails.orderItems.forEach((item: IBillingTire, idx: number) => {
          formData.append(`orderItems[${idx}].quantity`, item.quantity.toString());
          formData.append(`orderItems[${idx}].price`, item.price.toString());
          formData.append(`orderItems[${idx}].articleId`, item.articleId.toString());
          formData.append(`orderItems[${idx}].tireResellerId`, item.tireName);
        })
        continue;
      }
      formData.append(key, newOrderDetails[key]);
    }
    this.store$.dispatch(editOrder({id: this.currentOrder.orderId, payload: newOrderDetails, sendSmsAfterEdit: false}));
    this.checkRequest(EModalMessage.remarkChanged);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(selectOrdersError),
      this.store$.select(selectOrdersSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && !error) {
            this.alertService.onSuccess(message);
            this.closeDialog()
            return;
          }
          if (error && error.error) this.alertService.onError(error.error.message);
        }
      })
  }
}
