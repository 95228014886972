export enum EStatisticTitle {
  phone = 'PHONE_LEAD',
  nav = 'NAV_LEAD',
  gold = 'GOLD_LEAD',
  index = 'INDEX_LEAD',
  converse = 'CONVERSE_LEAD',
  zap = 'ZAP_LEAD',
  rims = 'RIMS_LEAD',
  totalBalance = 'TOTAL_AMOUNT_AND_BALANCE',
  totalPaidBalance = 'TOTAL_PAID_BALANCE',
  all = 'ALL_TWS',
  businessUserTotal = 'TOTAL_AMOUNT',
  totalLeadsNumber = 'TOTAL_NUMBER_OF_LEADS',
  totalAmountBeforeVAT = 'TOTAL_AMOUNT_BEFORE_VAT',
  totalAmountAfterVAT = 'TOTAL_AMOUNT_AFTER_VAT'
}
