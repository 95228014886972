import { HttpErrorResponse } from "@angular/common/http";
import { Action, createReducer, on } from "@ngrx/store";

import { IBusinessCarTire, ICarTireDBData, ManageCarTiresState } from './manage-car-tires.model';
import * as ManageCarTiresActions from './manage-car-tires.actions';
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const initialState: ManageCarTiresState = {
  result: {} as IResponse<ICarTireDBData[]>,
  businessCarTire: {
    tire: {} as IBusinessCarTire,
    isLoader: false,
    isLoadSuccess: false,
    error: {} as HttpErrorResponse
  },
  prices: {
    loader: false,
    loaderSuccess: false,
    error: {} as HttpErrorResponse
  },
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: null,
};

const manageCarTiresReducer = createReducer(
  initialState,
  on(ManageCarTiresActions.getCarTiresList, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(ManageCarTiresActions.getCarTiresListSuccess, (state, {payload}) => ({
    ...state,
    result: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(ManageCarTiresActions.getCarTiresListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ManageCarTiresActions.addCarTire, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(ManageCarTiresActions.addCarTireSuccess, (state, {payload, copyTireId}) => {
    if(copyTireId) {
      const index = state.result.content.map((tire) => tire.id).indexOf(copyTireId);
      const insert = () => [
        ...state.result.content.slice(0, index + 1), payload, ...state.result.content.slice(index + 1)
      ];
      return {
        ...state,
        result: {...state.result, content: insert()},
        isLoading: false,
        isLoadingSuccess: true,
        error: {} as HttpErrorResponse,
      }
    }
    return {
      ...state,
      result: {...state.result, content: [...state.result.content, payload]},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(ManageCarTiresActions.addCarTireFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ManageCarTiresActions.addBusinessCarTire, (state) => ({
    ...state,
    businessCarTire: {
      ...state.businessCarTire,
      isLoader: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(ManageCarTiresActions.addBusinessCarTireSuccess, (state, {payload}) => {
    return {
      ...state,
      businessCarTire: {
        tire: payload,
        isLoader: false,
        isLoadSuccess: true,
        error: {} as HttpErrorResponse
      }
    }
  }),
  on(ManageCarTiresActions.addBusinessCarTireFailure, (state, {payload}) => ({
    ...state,
    businessCarTire: {
      ...state.businessCarTire,
      isLoader: false,
      isLoadSuccess: false,
      error: payload
    }
  })),
  on(ManageCarTiresActions.updateCarTire, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(ManageCarTiresActions.updateCarTireSuccess, (state, {payload}) => {
    const content = [...state.result.content];
    const newData = content.map((tire: ICarTireDBData) => {
      if (tire.id === payload.id) tire = payload;
      return tire;
    });
    return {
      ...state,
      result: {...state.result, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(ManageCarTiresActions.updateCarTireFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ManageCarTiresActions.exportFileAboutCarTire, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(ManageCarTiresActions.exportFileAboutCarTireSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(ManageCarTiresActions.exportFileAboutCarTireFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ManageCarTiresActions.changTireVisibility, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(ManageCarTiresActions.changTireVisibilitySuccess, (state, {id, forReviews}) => {
    let content = [...state.result.content];
    if (forReviews) {
      content = content.filter((tire: ICarTireDBData) => tire.id !== id);
    }
    return {
      ...state,
      result: {...state.result, content: content},
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse
    }
  }),
  on(ManageCarTiresActions.changTireVisibilityFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(ManageCarTiresActions.changTirePrices, (state) => ({
    ...state,
    prices: {
      ...state.prices,
      loader: true,
      loaderSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(ManageCarTiresActions.changTirePricesSuccess, (state) => {
    return {
      ...state,
      prices: {
        ...state.prices,
        loader: false,
        loaderSuccess: true,
        error: {} as HttpErrorResponse
      }
    }
  }),
  on(ManageCarTiresActions.changTirePricesFailure, (state, {payload}) => ({
    ...state,
    prices: {
      ...state.prices,
      loader: false,
      loaderSuccess: false,
      error: payload
    }
  })),
  on(ManageCarTiresActions.changTireBruttoPrice, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(ManageCarTiresActions.changTireBruttoPriceSuccess, (state, {price}) => {
    const content = [...state.result.content];
    const newData = content.map((tire: ICarTireDBData) => {
      if (tire.id === price.articleId) tire = {...tire, bruttoPrice: price.bruttoPrice};
      return tire;
    });
    return {
      ...state,
      result: {...state.result, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(ManageCarTiresActions.changTireBruttoPriceFailure, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  }))
);

export function ManageCarTiresReducer(state: ManageCarTiresState | undefined, action: Action): any {
  return manageCarTiresReducer(state, action);
}



