import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as ArticlePriceActions from "./article-price.action";
import { ArticlePriceService } from '../../../../api/business-user-services/article-price.service';
import { ManageMotoTireService } from '../../../../api/general-admin-services/manage-moto-tire.service';
import { NotificationService } from '../../../../api/notification.service';

@Injectable()
export class ArticlePriceEffects {
  constructor(private actions$: Actions,
              private motoService: ManageMotoTireService,
              private alert: NotificationService,
              private articleService: ArticlePriceService) {
  }

  setArticlePrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.setArticlePrice),
      switchMap(action =>
        this.articleService.setSitePrice(action.payload).pipe(
          map(price => ArticlePriceActions.setArticlePriceSuccess({payload: price})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.setArticlePriceFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  getBrandList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.getArticleBrandList),
      switchMap(action =>
        this.articleService.getBrandsDiscountList(action.payload).pipe(
          map(list => ArticlePriceActions.getArticleBrandListSuccess({payload: list})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.getArticleBrandListFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  setBrandDiscount = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.setBrandDiscount),
      switchMap(action =>
        this.articleService.setBrandDiscount(action.discount).pipe(
          map(brand => ArticlePriceActions.setBrandDiscountSuccess({payload: brand})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.setBrandDiscountFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  getExpectedProfit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.getExpectedProfit),
      switchMap(action =>
        this.articleService.getExpectedProfit(action.params).pipe(
          map(list => ArticlePriceActions.getExpectedProfitSuccess({payload: list})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.getExpectedProfitFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  setExpectedProfit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.setExpectedProfit),
      switchMap(action =>
        this.articleService.setExpectedProfit(action.profit).pipe(
          map(profit => ArticlePriceActions.setExpectedProfitSuccess({profit})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.setExpectedProfitFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  setMultipleExpectedProfit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.setMultipleExpectedProfit),
      switchMap(action =>
        this.articleService.setMultipleExpectedProfit(action.profit).pipe(
          map(profit => ArticlePriceActions.setMultipleExpectedProfitSuccess({profit})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.setMultipleExpectedProfitFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  getInventoryPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.getInventoryPrice),
      switchMap(action =>
        this.articleService.getInventoryPrice(action.params).pipe(
          map(list => ArticlePriceActions.getInventoryPriceSuccess({payload: list})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.getInventoryPriceFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  editInventoryPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.editInventoryPrice),
      switchMap(action =>
        this.articleService.editInventoryPrice(action.id, action.element).pipe(
          map((tire) => ArticlePriceActions.editInventoryPriceSuccess({tire, id: action.id})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.editInventoryPriceFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  getListForSettingPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.getListForSettingPrice),
      switchMap(action =>
        this.articleService.getSettingPriceList(action.params).pipe(
          map((payload) => ArticlePriceActions.getListForSettingPriceSuccess({payload})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.getListForSettingPriceFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  addBusinessMotoTire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.addBusinessMotoTire),
      switchMap(action =>
        this.motoService.addBusinessMotoTire(action.payload).pipe(
          map(tire => ArticlePriceActions.addBusinessMotoTireSuccess({payload: tire})),
          catchError((err: HttpErrorResponse) => {
            this.alert.onError(err.statusText);
            return of(ArticlePriceActions.addBusinessMotoTireFailure({payload: err}))
          })
        )
      )
    )
  )

  resetTirePrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.resetTirePrices),
      switchMap(action =>
        this.articleService.resetPrices({articleIds: action.payload}).pipe(
          map(() => ArticlePriceActions.resetTirePricesSuccess()),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.resetTirePricesFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  resetInventory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.resetInventory),
      switchMap(action =>
        this.articleService.resetInventory({articleIds: action.payload}).pipe(
          map(() => ArticlePriceActions.resetInventorySuccess()),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText);
              return of(ArticlePriceActions.resetInventoryFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  getCarsByPlate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.getCarsByPlateNumber),
      switchMap(action =>
        this.articleService.getCarsByPlate(action.payload).pipe(
          map((payload) => ArticlePriceActions.getCarsByPlateNumberSuccess({payload})),
          catchError((err: HttpErrorResponse) =>
            of(ArticlePriceActions.getCarsByPlateNumberFailure({payload: err}))
          )
        )
      )
    )
  )

  getConsumerPriceList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlePriceActions.getConsumePriceList),
      switchMap(action =>
        this.articleService.getConsumerList(action.params).pipe(
          map((payload) => ArticlePriceActions.getConsumePriceListSuccess({payload})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.statusText)
              return of(ArticlePriceActions.getConsumePriceListFailure({payload: err}))
            }
          )
        )
      )
    )
  )
}
