import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as TireTypeActions from "./tire-type.actions";
import TireTypeApiService from "../../../../../api/general-admin-services/tire-type.api.service";

@Injectable()
export class TireTypeEffects {
  constructor(
    private actions$: Actions,
    private TireTypeService: TireTypeApiService
  ) {
  }

  getTireTypeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireTypeActions.getTireTypeData),
      switchMap(() =>
        this.TireTypeService.getTireTypeData().pipe(
          map(response => TireTypeActions.getTireTypeDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(TireTypeActions.getTireTypeDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createTireTypeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireTypeActions.addTireTypeElement),
      switchMap(action =>
        this.TireTypeService.addTireTypeElement(action.payload).pipe(
          map(response => TireTypeActions.addTireTypeElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(TireTypeActions.addTireTypeElementFailure({payload: err})))
        )
      )
    )
  )

  updateTireTypeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireTypeActions.updateTireTypeElement),
      switchMap(action =>
        this.TireTypeService.updateTireTypeElement(action.payload, action.id).pipe(
          map(response => TireTypeActions.updateTireTypeElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(TireTypeActions.updateTireTypeElementFailure({payload: err})))
        )
      )
    )
  )

  deleteTireTypeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireTypeActions.deleteTireTypeElement),
      switchMap(action =>
        this.TireTypeService.deleteTireTypeElement(action.payload.id).pipe(
          map(() => TireTypeActions.deleteTireTypeElementSuccess()),
          catchError((err: HttpErrorResponse) => of(TireTypeActions.deleteTireTypeElementFailure({payload: err})))
        )
      )
    )
  )
}
