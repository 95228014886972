export enum ERimLabel {
  brand = 'MANUFACTURER',
  diameter = 'RIM_DIAMETER',
  price = 'NETTO_PRICE',
  artNumber = 'ART_NUMBER',
  artName = 'ART_NAME'
}

export enum ERimPlaceholder {
  brand = 'ENTER_MANUFACTURER',
  diameter = 'SELECT_RIM_DIAMETER',
  price = 'ENTER_NETTO_PRICE',
  artNumber = 'ENTER_ART_NUMBER',
  artName = 'ENTER_ART_NAME'
}
