import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as subActions from './subscription.actions';
import { SubscriptionService } from '../../../../../api/general-admin-services/subscription.service';

@Injectable()
export class SubscriptionEffects {
  constructor(
    private actions$: Actions,
    private subService: SubscriptionService
  ) {
  }

  getSpeedCodeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subActions.getSubscriptionData),
      switchMap(() =>
        this.subService.getData().pipe(
          map(response => subActions.getSubscriptionDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(subActions.getSubscriptionDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createSpeedCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subActions.addSubscriptionElement),
      switchMap(action =>
        this.subService.addElement(action.payload).pipe(
          map(response => subActions.addSubElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(subActions.addSubElementFailure({payload: err})))
        )
      )
    )
  )

  updateSpeedCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subActions.updateSubscriptionElement),
      switchMap(action =>
        this.subService.updateElement(action.payload, action.id).pipe(
          map(response => subActions.updateSubElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(subActions.updateSubElementFailure({payload: err})))
        )
      )
    )
  )

  deleteSpeedCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subActions.deleteSubscriptionElement),
      switchMap(action =>
        this.subService.deleteElement(action.payload).pipe(
          map(() => subActions.deleteSubElementSuccess()),
          catchError((err: HttpErrorResponse) => of(subActions.deleteSubElementFailure({payload: err})))
        )
      )
    )
  )
}
