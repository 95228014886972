<div class="content manage-car-tires">
  <div class="content-head-wrap">
    <div class="content-head">
      <div class="head-right">
        <h3 class="head-title">
          {{mainTitle | translate}}
        </h3>
        <div class="search-wrap">
          <mat-form-field class="form-element search" appearance="fill">
            <input [formControl]="searchControl" placeholder="{{'SEARCH' | translate}}" matInput type="text" autocomplete="off">
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="actions">
        <button (click)="resetPrices()" class="btn-admin btn-white export-btn">
          <mat-icon>restart_alt</mat-icon>
          {{'RESET_PRICES' | translate}}
        </button>
      </div>
    </div>
    <div class="head-form">
      <app-filter-selects [consumerList]="isPriceList"
                          (plateNumberChange)="searchByPlate($event)"
        (filtersChange)="getHeadFilters($event)" [filters]="(setPricesList$ | async)?.filters"></app-filter-selects>
    </div>
  </div>
  <div class="content" [ngClass]="!isPriceList ? 'blog_table' : 'consumer-table'" *ngIf="(setPricesList$ | async) as tiresList">
    <starter-table
      [tableMeta]="setPricesTableMeta"
      [tableData]="tiresList.content"
      [language]="languageService.mainLanguage.value"
      [filterData]="tiresList.filters"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [clearSelection]="clearSelection"
      (onClick)="actionDispatch($event)"
      (filters)="getTableFilters($event)"
      (sorts)="sortData($event)"
      (checkboxColumn)="getArticleForReset($event)"
    >
    </starter-table>
    <div class="pagination" *ngIf="tiresList.content">
          <span class="pagination-item"
                *ngFor="let page of tiresList.content | paginate:
                { itemsPerPage: tiresList.pageSize,
                 currentPage: tiresList.currentPage,
                 totalItems: tiresList.rowCount }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>
