import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IManufactureList, ManufacturerElement } from "../../modules/state/general-admin/criteria/manufacturer";

@Injectable({
  providedIn: "root",
})
export default class ManufacturerApiService {
  readonly pathFirst = '/criteria/manufacture';
  readonly path = '/criteria/manufacturer';

  constructor(private apiService: ApiService) {
  }

  getManufacturerData(): Observable<IManufactureList> {
    return this.apiService.get(`${this.pathFirst}`);
  }

  addManufacturerElement(element: FormData): Observable<ManufacturerElement> {
    return this.apiService.post(`${this.path}`, element);
  }

  updateManufacturerElement(element: FormData, id: number): Observable<ManufacturerElement> {
    return this.apiService.put(`${this.path}/${id}`, element);
  }

  deleteManufacturerElement(id: number): Observable<any> {
    return this.apiService.deleteNumber(`${this.path}`, id);
  }

  switchManufacturerStatus(id: number): Observable<any> {
    return this.apiService.put(`${this.path}/${id}/switchstatus`);
  }
}
