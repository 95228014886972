import { ImageModeEnum } from './imageMode.enums';

export enum EImgLoaderType {
  multiple = 'multi',
  one = 'one',
  oneWithIcon = 'oneWithIcon',
  imgWithFileName = 'imgWithFileName'
}

export interface IImageSize {
  width: number;
  height: number;
}

export interface IFileData {
  imgSrc: string;
  isCorrectSize: boolean
}

export interface IImageFile {
  file: File,
  mode: ImageModeEnum
}
