import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as speedCodeActions from "./speed-code.actions";
import { CriteriaService } from '../../../../../api/general-admin-services/criteria.service';
import { speedCodePath } from '../../../../../api/api-data/api.constants';

@Injectable()
export class SpeedCodeEffects {
  constructor(
    private actions$: Actions,
    private criteriaService: CriteriaService
  ) {
  }

  getSpeedCodeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(speedCodeActions.getSpeedCodeData),
      switchMap(() =>
        this.criteriaService.getExpandedCriteriaData(speedCodePath).pipe(
          map(response => speedCodeActions.getSpeedCodeDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(speedCodeActions.getSpeedCodeDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createSpeedCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(speedCodeActions.addSpeedCodeElement),
      switchMap(action =>
        this.criteriaService.addExpandedCriteriaElement(speedCodePath,action.payload).pipe(
          map(response => speedCodeActions.addSpeedCodeElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(speedCodeActions.addSpeedCodeElementFailure({payload: err})))
        )
      )
    )
  )

  updateSpeedCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(speedCodeActions.updateSpeedCodeElement),
      switchMap(action =>
        this.criteriaService.updateExpandedCriteriaElement(action.payload, action.id).pipe(
          map(response => speedCodeActions.updateSpeedCodeElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(speedCodeActions.updateSpeedCodeElementFailure({payload: err})))
        )
      )
    )
  )

  deleteSpeedCodeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(speedCodeActions.deleteSpeedCodeElement),
      switchMap(action =>
        this.criteriaService.deleteCriteria(action.payload).pipe(
          map(() => speedCodeActions.deleteSpeedCodeElementSuccess()),
          catchError((err: HttpErrorResponse) => of(speedCodeActions.deleteSpeedCodeElementFailure({payload: err})))
        )
      )
    )
  )
}
