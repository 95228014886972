import { CellTypes, TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { IParameter } from '../modules/state/general-admin/tire-reseller';

export const monthNames: IParameter[] = [
  {id: 0, value: 'JANUARY'},
  {id: 1, value: 'FEBRUARY'},
  {id: 2, value: 'MARCH'},
  {id: 3, value: 'APRIL'},
  {id: 4, value: 'MAY'},
  {id: 5, value: 'JUNE'},
  {id: 6, value: 'JULY'},
  {id: 7, value: 'AUGUST'},
  {id: 8, value: 'SEPTEMBER'},
  {id: 9, value: 'OCTOBER'},
  {id: 10, value: 'NOVEMBER'},
  {id: 11, value: 'DECEMBER'},
]

export const ArticleBrandTableMeta: TableMeta[] = [
  {
    name: '',
    sortable: false,
    filterable: false,
    type: CellTypes.Checkbox,
    jsonPath: 'isActive'
  },
  {
    name: 'STATUS',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'status'
  },
  {
    name: 'MANUFACTURER',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'brand',
  },
  {
    name: 'DISCOUNT_%',
    sortable: false,
    filterable: false,
    type: CellTypes.BrandInput,
    jsonPath: 'discount',
  },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    brandInput: true,
    actions: [
      {
        action: 'edit',
        icon: 'border_color'
      }
    ]
  }
]

export const ExpectedProfitTableMeta: TableMeta[] = [
  {
    name: 'TIRE_DIAMETER',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'value'
  },
  {
    name: 'EXPECTED_PROFIT',
    sortable: false,
    filterable: false,
    type: CellTypes.ProfitInput,
    jsonPath: 'profit',
  },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    profitInput: true,
    actions: [
      {
        action: 'edit',
        icon: 'border_color'
      }
    ]
  }
]

export const ResellerTableMeta: TableMeta[] = [
  {
    "name": 'TIRE_DETAIL_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'size',
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'manufacture',
  },
  // {
  //   "name": 'LEAD_AMOUNT',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.String,
  //   "jsonPath": 'amount',
  // },
  {
    "name": 'QNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'quantity',
  },
  {
    "name": 'PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'price',
  },
  {
    "name": 'DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'orderDate',
  },
  // {
  //   "name": 'TIME',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.String,
  //   "jsonPath": 'time',
  // },
  {
    "name": 'CONSUMER_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'userName',
  },
  {
    "name": 'PHONE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PhoneWithZero,
    "jsonPath": 'phoneNumber',
  },
  {
    "name": 'INSTALL_DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'installationDate',
  },
  {
    "name": 'TOTAL',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'amount',
  }
]

export const TWSLeadsTableMeta: TableMeta[] = [
  {
    "name": 'LEAD_TYPE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'leadType',
    "filterName": "LeadType"
  },
  {
    "name": 'CODE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'code',
  },
  {
    "name": 'NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.ClickableString,
    "jsonPath": 'name',
  },
  {
    "name": 'DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'date',
    "calendarHeader": true,
    "needCheckRow": true
  },
  {
    "name": 'TIME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'time',
  },
  {
    "name": 'PHONE_NUMBER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PhoneWithZero,
    "jsonPath": 'phoneNumber',
  },
  {
    "name": 'VIRTUAL_PHONE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'virtualPhone',
  },
  {
    "name": 'DURATION',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'duration',
  },
  {
    "name": 'CALL_STATUS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Color,
    "jsonPath": 'callStatus',
  },
  {
    "name": 'TWS_STATUS',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.DropDownMenu,
    "jsonPath": 'twsStatus',
    "filterName": "TwsStatus"
  },
  // {
  //   "name": 'ACTIONS',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.Action,
  //   "jsonPath": 'actions',
  //   actions: [{
  //     action: 'delete',
  //     icon: 'delete'
  //   }]
  // }
]

export const InventorySetUpTableMeta: TableMeta[] = [
  {
    "name": '',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'select',
    "checkboxHeader": true,
    "needCheckRow": true
  },
  {
    "name": 'ID',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'id',
  },
  {
    "name": 'INV',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Icon,
    "jsonPath": 'inv',
  },
  {
    "name": 'NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'name',
    "sortName": 'Name'
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'brand',
    "filterName": 'Manufacture'
  },
  {
    "name": 'BRUTTO_PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'bruttoPrice',
    "sortName": 'BruttoPrice'
  },
  {
    "name": 'SPEED_CODE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'speedCode',
    "filterName": "SpeedCode",
    "sortName": 'SpeedCodeValue'
  },
  {
    "name": 'WEIGHT',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'weight',
    "filterName": 'WeightCode',
    "sortName": 'WeightCodeValue'
  },
  {
    "name": 'DIAMETER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'diameter',
    "filterName": 'Diameter',
    "sortName": 'DiameterValue'
  },
  {
    "name": 'HEIGHT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'height',
    "filterName": 'Height',
    "sortName": 'HeightValue'
  },
  {
    "name": 'WIDTH',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'width',
    "filterName": "Width",
    "sortName": 'WidthValue'
  },
  {
    "name": 'LOCATION_ON_THE_STORE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'warehouseLocation',
  },
  {
    "name": 'YEAR',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'year',
  },
  {
    "name": 'QNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Quantity,
    "jsonPath": 'quantity',
    "needCheckRow": true
  },
  {
    "name": 'COST_PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'costPrice',
  },
  {
    "name": 'AFTER_VAT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PriceWithToolTip,
    "jsonPath": 'afterVat',
    "needCheckRow": true
  },
  {
    "name": 'PRICE_RANGE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PriceRange,
    "jsonPath": 'priceRange',
  },
  {
    "name": 'POSITION_ON_SITE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'positionOnSite'
  },
  {
    "name": 'EDIT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Action,
    "jsonPath": 'actions',
    actions: [{
      action: 'edit',
      icon: 'border_color'
    }]
  }
]

export const SetPriceTableMeta: TableMeta[] = [
  {
    "name": '',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Checkbox,
    "jsonPath": 'select',
    "checkboxHeader": true
  },
  {
    "name": 'ID',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'id',
  },
  {
    "name": 'NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'name',
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'brand',
    "filterName": 'Manufacture'
  },
  {
    "name": 'SPEED_CODE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'speedCode',
    "filterName": "SpeedCode",
    "sortName": 'SpeedCodeValue'
  },
  {
    "name": 'WEIGHT',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'weight',
    "filterName": 'WeightCode',
    "sortName": 'WeightCodeValue'
  },
  {
    "name": 'DIAMETER',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'diameter',
    "filterName": 'Diameter',
    "sortName": 'DiameterValue'
  },
  {
    "name": 'HEIGHT',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'height',
    "filterName": 'Height',
    "sortName": 'HeightValue'
  },
  {
    "name": 'WIDTH',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'width',
    "filterName": "Width",
    "sortName": 'WidthValue'
  },
  {
    "name": 'INV',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Icon,
    "jsonPath": 'inv',
  },
  {
    "name": 'BRUTTO_PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'bruttoPrice',
    "sortName": 'BruttoPrice'
  },
  {
    "name": 'DISCOUNT_BY',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'discountBy',
  },
  {
    "name": 'COST_PRICE_SET_PAGE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'costPrice',
  },
  {
    "name": 'EXPECTED_PROFIT_SET_PAGE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'expexctedPrice',
  },
  {
    "name": 'SITE_PRICE_SET_PAGE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'sitePrice',
  },
  {
    "name": 'AFTER_VAT_SET_PAGE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PriceWithToolTip,
    "jsonPath": 'afterVat',
  },
  {
    "name": 'PRICE_RANGE_SET_PAGE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PriceRange,
    "jsonPath": 'priceRange',
  },
  {
    "name": 'POSITION_ON_SITE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'positionOnSite'
  },
  {
    "name": 'EDIT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.SetPriceAction,
    "jsonPath": 'actions',
    "filterName": 'inv',
    actions: [{
      action: 'edit',
      icon: 'border_color'
    }]
  }
]

export const ConsumerPriceList: TableMeta[] = [
  {
    "name": '',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Checkbox,
    "jsonPath": 'select',
    "checkboxHeader": true
  },
  {
    "name": 'ID',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'id',
  },
  {
    "name": 'NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'name',
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'brand',
    "filterName": 'Manufacture'
  },
  {
    "name": 'SPEED_CODE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'speedCode',
    "filterName": "SpeedCode",
    "sortName": 'SpeedCodeValue'
  },
  {
    "name": 'WEIGHT',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'weight',
    "filterName": 'WeightCode',
    "sortName": 'WeightCodeValue'
  },
  {
    "name": 'DIAMETER',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'diameter',
    "filterName": 'Diameter',
    "sortName": 'DiameterValue'
  },
  {
    "name": 'HEIGHT',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'height',
    "filterName": 'Height',
    "sortName": 'HeightValue'
  },
  {
    "name": 'WIDTH',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'width',
    "filterName": "Width",
    "sortName": 'WidthValue'
  },
  {
    "name": 'AFTER_VAT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PriceWithToolTip,
    "jsonPath": 'afterVat',
  }
]
