import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { ISubDBElement, ISubscriptionElement } from '../../modules/state/general-admin/criteria/subscription';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  readonly PATH = '/criteria/subscription';

  constructor(private apiService: ApiService) {
  }


  getData(): Observable<ISubDBElement[]> {
    return this.apiService.get(`${this.PATH}`);
  }

  addElement(element: ISubscriptionElement): Observable<ISubDBElement> {
    return this.apiService.post(`${this.PATH}`, element);
  }

  updateElement(element: ISubscriptionElement, id: number): Observable<ISubDBElement> {
    return this.apiService.put(`${this.PATH}/${id}`, element);
  }

  deleteElement(id: number): Observable<any> {
    return this.apiService.deleteNumber(`${this.PATH}`, id);
  }
}
