import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import { ManageMotoTireService } from '../../../../api/general-admin-services/manage-moto-tire.service';
import * as MotoTiresActions from './manage-moto-tires.actions';
import ManageCarTiresApiService from '../../../../api/general-admin-services/manage-car-tires.api.service';

@Injectable()
export class ManageMotoTiresEffects {
  constructor(private actions$: Actions,
              private motoService: ManageMotoTireService,
              private carService: ManageCarTiresApiService) {
  }

  getMotoTiresList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MotoTiresActions.getMotoTiresList),
      switchMap(action =>
        this.motoService.getMotorcycleTireList(action.params).pipe(
          map(response => MotoTiresActions.getMotoTiresListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(MotoTiresActions.getMotoTiresListFailure({payload: err}))
          )
        )
      )
    )
  )

  addMotoTire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MotoTiresActions.addMotoTire),
      switchMap(action =>
        this.motoService.addMotoTire(action.payload).pipe(
          map(tire => MotoTiresActions.addMotoTireSuccess({payload: tire.entity})),
          catchError((err: HttpErrorResponse) => of(MotoTiresActions.addMotoTireFailure({payload: err})))
        )
      )
    )
  )

  updateMotoTire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MotoTiresActions.updateMotoTire),
      switchMap(action =>
        this.motoService.updateMotoTire(action.payload).pipe(
          map(tire => MotoTiresActions.updateMotoTireSuccess({payload: tire.entity})),
          catchError((err: HttpErrorResponse) => of(MotoTiresActions.updateMotoTireFailure({payload: err})))
        )
      )
    )
  )

  exportMotoTireFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MotoTiresActions.exportMotoTireFile),
      switchMap(action =>
        this.motoService.exportMotoTireFile(action.filters, action.fileName).pipe(
          map(() => MotoTiresActions.exportMotoTireFileSuccess()),
          catchError((err: HttpErrorResponse) => of(MotoTiresActions.exportMotoTireFileFailure({payload: err})))
        )
      )
    )
  )

  changeMotoTireVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MotoTiresActions.changeMotoTireVisibility),
      switchMap(action =>
        this.motoService.changeVisibility(action.id).pipe(
          map(() => MotoTiresActions.changeMotoTireVisibilitySuccess()),
          catchError((err: HttpErrorResponse) => of(MotoTiresActions.changeMotoTireVisibilityFailure({payload: err})))
        )
      )
    )
  )

  changeTirePrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MotoTiresActions.changeMotoTirePrices),
      switchMap(action =>
        this.carService.changeTirePrices(action.payload).pipe(
          map(() => MotoTiresActions.changeMotoTirePricesSuccess()),
          catchError((err: HttpErrorResponse) => of(MotoTiresActions.changeMotoTirePricesFailure({payload: err})))
        )
      )
    )
  )

  changeMotoTireBruttoPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MotoTiresActions.changeMotoTireBruttoPrice),
      switchMap(action =>
        this.carService.changeTireBruttoPrice(action.id, action.payload).pipe(
          map((price) => MotoTiresActions.changeMotoTireBruttoPriceSuccess({price})),
          catchError((err: HttpErrorResponse) => of(MotoTiresActions.changeMotoTireBruttoPriceFailure({payload: err})))
        )
      )
    )
  )
}
