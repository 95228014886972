import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IMotoTireDBData, IMotoTiresParams } from './manage-moto-tires.model';
import { IResponse } from '../../../../api/api-data/api.interfaces';
import { ITireBruttoPrice } from '../../business-user/article-price';

export const GET_MOTO_TIRES_LIST = '[MANAGE MOTO TIRES] Get moto tires list';
export const GET_MOTO_TIRES_LIST_SUCCESS = '[MANAGE MOTO TIRES] Get moto tires list success';
export const GET_MOTO_TIRES_LIST_FAILURE = '[MANAGE MOTO TIRES] Get moto tires list failure';

export const ADD_MOTO_TIRE = '[MANAGE MOTO TIRES] Add moto tire';
export const ADD_MOTO_TIRE_SUCCESS = '[MANAGE MOTO TIRES] Add moto tire success';
export const ADD_MOTO_TIRE_FAILURE = '[MANAGE MOTO TIRES] Add moto tire failure';

export const UPDATE_MOTO_TIRE = '[MANAGE MOTO TIRES] Update moto tire';
export const UPDATE_MOTO_TIRE_SUCCESS = '[MANAGE MOTO TIRES] Update moto tire success';
export const UPDATE_MOTO_TIRE_FAILURE = '[MANAGE MOTO TIRES] Update moto tire failure';

export const EXPORT_MOTO_TIRE_FILE = '[MANAGE MOTO TIRES] Export file about moto tire';
export const EXPORT_MOTO_TIRE_FILE_SUCCESS = '[MANAGE MOTO TIRES] Export file about moto tire success';
export const EXPORT_MOTO_TIRE_FILE_FAILURE = '[MANAGE MOTO TIRES] Export file about moto tire failure';

export const CHANGE_MOTO_TIRE_VISIBILITY = '[MANAGE MOTO TIRES] Change moto tire visibility';
export const CHANGE_MOTO_TIRE_VISIBILITY_SUCCESS = '[MANAGE MOTO TIRES] Change moto  tire visibility success';
export const CHANGE_MOTO_TIRE_VISIBILITY_FAILURE = '[MANAGE MOTO TIRES] Change moto tire visibility failure';

export const CHANGE_MOTO_TIRE_PRICES = '[MANAGE MOTO TIRES] Change moto tire prices';
export const CHANGE_MOTO_TIRE_PRICES_SUCCESS = '[MANAGE MOTO TIRES] Change moto tire prices success';
export const CHANGE_MOTO_TIRE_PRICES_FAILURE = '[MANAGE MOTO TIRES] Change moto tire prices failure';

export const CHANGE_MOTO_TIRE_BRUTTO_PRICE = '[MANAGE MOTO TIRES] Change moto tire brutto price';
export const CHANGE_MOTO_TIRE_BRUTTO_PRICE_SUCCESS = '[MANAGE MOTO TIRES] Change moto tire brutto price success';
export const CHANGE_MOTO_TIRE_BRUTTO_PRICE_FAILURE = '[MANAGE MOTO TIRES] Change moto tire brutto price failure';

export const getMotoTiresList = createAction(
  GET_MOTO_TIRES_LIST,
  props<{ params?: IMotoTiresParams }>()
)

export const getMotoTiresListSuccess = createAction(
  GET_MOTO_TIRES_LIST_SUCCESS,
  props<{ payload: IResponse<IMotoTireDBData[]> }>()
)

export const getMotoTiresListFailure = createAction(
  GET_MOTO_TIRES_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addMotoTire = createAction(
  ADD_MOTO_TIRE,
  props<{ payload: FormData }>()
)

export const addMotoTireSuccess = createAction(
  ADD_MOTO_TIRE_SUCCESS,
  props<{ payload: IMotoTireDBData }>()
)

export const addMotoTireFailure = createAction(
  ADD_MOTO_TIRE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateMotoTire = createAction(
  UPDATE_MOTO_TIRE,
  props<{ payload: FormData }>()
)

export const updateMotoTireSuccess = createAction(
  UPDATE_MOTO_TIRE_SUCCESS,
  props<{ payload: IMotoTireDBData }>()
)

export const updateMotoTireFailure = createAction(
  UPDATE_MOTO_TIRE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const exportMotoTireFile = createAction(
  EXPORT_MOTO_TIRE_FILE,
  props<{ filters: string, fileName: string }>()
)

export const exportMotoTireFileSuccess = createAction(
  EXPORT_MOTO_TIRE_FILE_SUCCESS
)

export const exportMotoTireFileFailure = createAction(
  EXPORT_MOTO_TIRE_FILE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changeMotoTireVisibility = createAction(
  CHANGE_MOTO_TIRE_VISIBILITY,
  props<{ id: number }>()
)

export const changeMotoTireVisibilitySuccess = createAction(
  CHANGE_MOTO_TIRE_VISIBILITY_SUCCESS
)

export const changeMotoTireVisibilityFailure = createAction(
  CHANGE_MOTO_TIRE_VISIBILITY_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changeMotoTirePrices = createAction(
  CHANGE_MOTO_TIRE_PRICES,
  props<{ payload: FormData }>()
)

export const changeMotoTirePricesSuccess = createAction(
  CHANGE_MOTO_TIRE_PRICES_SUCCESS
)

export const changeMotoTirePricesFailure = createAction(
  CHANGE_MOTO_TIRE_PRICES_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changeMotoTireBruttoPrice = createAction(
  CHANGE_MOTO_TIRE_BRUTTO_PRICE,
  props<{ id: number, payload: FormData }>()
)

export const changeMotoTireBruttoPriceSuccess = createAction(
  CHANGE_MOTO_TIRE_BRUTTO_PRICE_SUCCESS,
  props<{ price: ITireBruttoPrice }>()
)

export const changeMotoTireBruttoPriceFailure = createAction(
  CHANGE_MOTO_TIRE_BRUTTO_PRICE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
