import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IResponse } from '../api-data/api.interfaces';
import { ILookupParameters, IOptions } from '../../modules/state/general-admin/characteristic';

@Injectable({
  providedIn: "root",
})
export default class CharacteristicApiService {
  readonly PATH = '/characteristic';

  constructor(private apiService: ApiService) {
  }

  getCharacteristicData(): Observable<IResponse<any>> {
    return this.apiService.get(`${this.PATH}`);
  }

  getCharacteristicOptionData(id: string): Observable<IOptions> {
    return this.apiService.get(`${this.PATH}/${id}/options`)
  }

  createCharacteristicOption(formData: FormData): Observable<any> {
    return this.apiService.post(`${this.PATH}`, formData)
  }

  editCharacteristicOption(optionId: number, formData: FormData): Observable<any> {
    return this.apiService.put(`${this.PATH}/${optionId}`, formData)
  }

  removeCharacteristicOption(optionId: number): Observable<any> {
    return this.apiService.deleteNumber(this.PATH, optionId)
  }

  getLookupParams(keys: string, isDisplayPrivateBrand: boolean): Observable<ILookupParameters> {
    return this.apiService.get(`/lookup/list/${keys}`, {isDisplayPrivateBrand});
  }
}
