import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IArticlePriceState } from './article-price.model';

export const getArticlePriceState = createFeatureSelector<IArticlePriceState>('articlePrice');

export const selectBrandListForDiscount = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.brandList.list);

export const selectBrandListLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.brandList.isLoad);

export const selectBrandListSuccessLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.brandList.isLoadSuccess);

export const selectBrandListError = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.brandList.error);

export const selectProfitList = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.expectedProfit.list);

export const selectProfitListLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.expectedProfit.isLoad);

export const selectProfitListSuccessLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.expectedProfit.isLoadSuccess);

export const selectProfitError = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.expectedProfit.error);

export const selectArticlePriceSuccessLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.isLoadingSuccess);

export const selectArticlePriceLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.isLoading);

export const selectArticlePriceError = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.error);

export const selectInventoryPriceList = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.inventoryPrice);

export const selectSettingValue = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.settingValue);

export const selectSettingPriceList = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.settingPrice);

export const getBusinessMotoTireLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.businessMotoTire.isLoader);

export const getBusinessMotoTireSuccessLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.businessMotoTire.isLoadSuccess);

export const getBusinessMotoTireError = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.businessMotoTire.error);

export const selectResetPricesError = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.resetPrices.error);

export const selectResetPricesLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.resetPrices.isLoad);

export const selectResetInventoryError = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.resetInventory.error);

export const selectResetInventorySuccessLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.resetInventory.isLoad);

export const selectResetInventoryLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.resetInventory.isLoading);
export const selectSuitablePlates = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.suitableCarByPlate.plates);

export const selectSuitablePlatesLoader = createSelector(getArticlePriceState,
  (state: IArticlePriceState) => state.suitableCarByPlate.isLoad);
