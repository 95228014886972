import { TireTypeElement, TireTypeState } from './tire-type.model';
import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import * as TireTypeActions from './tire-type.actions';
import { EVehicleType } from '../../../../shared/interfaces/table.interfaces';

export const initialState: TireTypeState = {
  current: {} as TireTypeElement,
  deleteId: 0,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  content: {
    carTireType: [],
    motorcycleTireType: [],
  },
  error: {} as HttpErrorResponse,
};

const tireTypeReducer = createReducer(
  initialState,
  on(TireTypeActions.getTireTypeData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireTypeActions.getTireTypeDataSuccess, (state, payload) => ({
    ...state,
    content: payload.payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(TireTypeActions.getTireTypeDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(TireTypeActions.addTireTypeElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    isLoading: true,
    error: {} as HttpErrorResponse,
  })),
  on(TireTypeActions.addTireTypeElementSuccess, (state, {payload}) => {
    let newCarContent = [...state.content.carTireType];
    let newMotoContent = [...state.content.motorcycleTireType];
    if(state.current.vehicleType === EVehicleType.moto) {
      newMotoContent.push(payload)
    } else {
      newCarContent.push(payload);
    }
    return {
      ...state,
      content: {
        carTireType: newCarContent,
        motorcycleTireType: newMotoContent
      },
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(TireTypeActions.addTireTypeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(TireTypeActions.updateTireTypeElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    isLoading: true,
    error: {} as HttpErrorResponse,
  })),
  on(TireTypeActions.updateTireTypeElementSuccess, (state, {payload}) => {
    let newCarContent = [...state.content.carTireType];
    let newMotoContent = [...state.content.motorcycleTireType];
    const motoContent =newMotoContent.map((mnf: TireTypeElement) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    const carContent = newCarContent.map((mnf: TireTypeElement) => {
      if (mnf.id === payload.id) mnf = payload
      return mnf;
    });
    return {
      ...state,
      content: {
        carTireType: carContent,
        motorcycleTireType: motoContent
      },
      current: {} as TireTypeElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(TireTypeActions.updateTireTypeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(TireTypeActions.deleteTireTypeElement, (state, {payload}) => ({
      ...state,
      current: payload,
      deleteId: payload.id,
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse
    })),
  on(TireTypeActions.deleteTireTypeElementSuccess, (state) => {
    let carArr = [...state.content.carTireType];
    let motoArr = [...state.content.motorcycleTireType];
    const moto = motoArr.filter((mnf: TireTypeElement) => mnf.id !== state.deleteId);
    const car = carArr.filter((mnf: TireTypeElement) => mnf.id !== state.deleteId);
    return {
      ...state,
      content: {
        carTireType: car,
        motorcycleTireType: moto
      },
      isLoading: false,
      isLoadingSuccess: true
    }
  }),
  on(TireTypeActions.deleteTireTypeElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function TireTypeReducer(state: TireTypeState | undefined, action: Action): any {
  return tireTypeReducer(state, action);
}



