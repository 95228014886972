import { HttpErrorResponse } from '@angular/common/http';
import { IResponse } from '../../../../api/api-data/api.interfaces';

export interface IBrandArticlePrice {
  id: number,
  brandId: number,
  brand: string,
  discount: number,
  isActive: boolean,
  status: string
}

export interface IExpectedProfit {
  id: number,
  value: string,
  profit: number,
}

export interface ISetProfit {
  tireResellerId: string,
  brandId: number,
  characteristicOptionId: number,
  profit: number,
}

export interface IMultipleSetProfit {
  tireResellerId: string,
  brandIds: number[],
  characteristicOptionIds: number[],
  profit: number,
}

export interface ISetDiscount {
  tireResellerId: string,
  discount: number,
  isActive: boolean,
  brandId: number
}

export interface ISetArticlePrice {
  articleId: number,
  price: number | null,
}

export interface IArticlePrice {
  id: number,
  articleId: number,
  tireResellerId: string,
  price: number
}

export interface IArticlePriceState {
  brandList: { list: IBrandArticlePrice[], isLoad: boolean, isLoadSuccess: boolean, error: HttpErrorResponse };
  inventoryPrice: IResponse<IInventoryTire[]>;
  settingPrice: IResponse<IInventoryTire[]>;
  settingValue: boolean;
  expectedProfit: { list: IExpectedProfit[], isLoad: boolean, isLoadSuccess: boolean, error: HttpErrorResponse };
  businessMotoTire: {
    tire: IInventoryTire,
    isLoader: boolean,
    isLoadSuccess: boolean,
    error: HttpErrorResponse
  };
  articlePrice: IArticlePrice;
  resetPrices: { isLoad: boolean, error: HttpErrorResponse | null },
  resetInventory: { isLoading: boolean, isLoad: boolean, error: HttpErrorResponse | null },
  suitableCarByPlate: { isLoad: boolean, error: HttpErrorResponse | null, plates: IPlateNumber[] };
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  error: HttpErrorResponse
}

export interface IExpectedProfitParams {
  tireResellerId: string,
  brandId: number
}

export interface IBrandListParams {
  tireResellerId: string,
  brandIds: number[]
}

export interface IBrandDiscount {
  tireResellerId: string,
  discount: number,
  isActive: boolean,
  brandId: number
}

export interface IArticlePriceParams {
  page: number,
  sorts?: string,
  filters?: string,
  VehicleType?: string,
  search?: string,
  isOnlyInventory?: boolean,
  isInventoryNotDisplay?: boolean,
  plateNumber?: string
}

export interface IInventoryTire {
  id: number,
  name: string,
  brand: string,
  bruttoPrice: number,
  diameter: string,
  width: string,
  height: string,
  speedCode: string,
  weight: string,
  warehouseLocation: string,
  year: number,
  quantity: number,
  costPrice: number,
  afterVat: number,
  priceRange: {
    from: number,
    to: number
  },
  positionOnSite: number,
  brandId: number,
  discountBy: number,
  expexctedPrice: number,
  sitePrice: number,
  diameterProfit: number,
  includeVAT: boolean,
  inv: boolean,
  visible: boolean,
  modalType?: string,
  search: string,
  isManuallyUpdated: boolean,
  isEdited: boolean,
  invOnly: boolean,
  invNotDisplay: boolean,
  apiFilters: string,
  page: number
}

export interface IBusinessTire {
  yearOfManufacture: number,
  locationOnStore: string,
  quantity: number,
  visibility: boolean,
  costPrice: number,
}

export interface ITireBruttoPrice {
  articleId: number,
  bruttoPrice: number
}

export interface PlateItem {
  width: { id: string, name: string },
  height: { id: string, name: string },
  radius: { id: string, name: string }
}

export interface IPlateNumber {
  front: PlateItem,
  rear: PlateItem,
  name: string,
  pictureUrl: string,
  plateNumber: string,
  engineCode?: string,
  vinCode: string,
}
