<div class="content admin-notifications">
  <div class="content-head">
    <h3 class="head-title">
      {{'ADMIN_NOTIFICATIONS' | translate}}
    </h3>
  </div>
  <div class="content-body">
    <div class="dialog-form-wrap">
      <div class="dialog">
        <div [formGroup]="notificationsForm">
          <div class="form-group row">
            <div class="col-6 form-col">
              <label class="label">
                <bdi>{{'TOPIC' | translate}}*</bdi>
              </label>
              <mat-form-field class="form-element" appearance="fill">
                <input formControlName="topic" placeholder="{{'ENTER_TOPIC' | translate}}" matInput type="text" autocomplete="off">
                <mat-error *ngIf="!notificationsForm.get('topic').valid && notificationsForm.get('topic').touched">
                  {{'THIS_FIELD_IS_REQUIRED' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-6 form-col">
              <label class="label">
                <bdi>{{'MESSAGE' | translate}}*</bdi>
              </label>
              <mat-form-field class="form-element" appearance="fill">
               <textarea
                 formControlName="message"
                 placeholder="{{'ENTER_MESSAGE' | translate}}"
                 matInput
                 cdkTextareaAutosize
                 cdkAutosizeMinRows="1"
                 cdkAutosizeMaxRows="10"></textarea>
                <mat-error *ngIf="!notificationsForm.get('message').valid && notificationsForm.get('message').touched">
                  {{'THIS_FIELD_IS_REQUIRED' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <ng-container *ngFor="let control of carMotoTypes.controls; index as i" formArrayName="carMotoTypes">
            <div [formGroupName]="i">
              <div class="checkbox">
                <mat-checkbox class="form-checkbox" formControlName="status">{{control.value.label | translate}}</mat-checkbox>
              </div>
            </div>
          </ng-container>

          <div class="save-btn">
            <button class="btn-admin" (click)="sendForm()" [disabled]="!notificationsForm.valid">
              {{'SAVE' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>






