import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { MatDialog } from '@angular/material/dialog';

import { getTireTypeData, TireTypeElement } from '../../../../../modules/state/general-admin/criteria/tire-type';
import {
  getCarTireTypeTable,
  getMotoTireTypeTable,
  getTireTypeLoader
} from '../../../../../modules/state/general-admin/criteria/tire-type/tire-type.selector';
import {
  EModalType,
  EVehicleType,
  ICriteriaItem,
  ITableData
} from '../../../../../modules/shared/interfaces/table.interfaces';
import { tireTypeTableMeta } from '../criteria.constants';
import { TiresTypeAddDialogComponent } from './tires-type-add-dialog/tires-type-add-dialog.component';
import { LanguageService } from '../../../../../api/language.service';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tires-type',
  templateUrl: './tires-type.component.html',
  styleUrls: ['./tires-type.component.scss']
})
export class TiresTypeComponent implements OnInit, OnDestroy {
  public tableMeta: TableMeta[] = tireTypeTableMeta;
  public modalType: EModalType = EModalType.add;
  public EVehicleType = EVehicleType;
  public carTableContent$: Observable<TireTypeElement[]>;
  public motoTableContent$: Observable<TireTypeElement[]>;
  public isTableLoad$: Observable<boolean>;
  public carSorts: string = '';
  public motoSorts: string = '';
  public searchCarControl: FormControl = new FormControl('');
  public searchMotoControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();
  public searchCar: string = '';
  public searchMoto: string = '';

  constructor(private store$: Store,
              private dialog: MatDialog,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.store$.dispatch(getTireTypeData());
    this.motoTableContent$ = this.store$.select(getMotoTireTypeTable);
    this.carTableContent$ = this.store$.select(getCarTireTypeTable);
    this.isTableLoad$ = this.store$.select(getTireTypeLoader);

  }

  public openAddDialog(vehicleType: string): void {
    this.dialog.open(TiresTypeAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {type: EModalType.add, vehicleType}
    })
  }

  actionDispatch(table: ITableData<ICriteriaItem>, tireType: string): void {
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete;
    }
    this.changeCriteria(table.data, tireType);
  }

  changeCriteria(item: ICriteriaItem, type: string): void {
    this.dialog.open(TiresTypeAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, type: this.modalType, vehicleType: type}
    })
  }

  getSortValue(sort: string, type: EVehicleType): void {
    if (type === EVehicleType.car) {
      this.carSorts = sort;
      return;
    }
    this.motoSorts = sort;
  }

  sortContent(content: TireTypeElement[], sort: string, type: string): TireTypeElement[] {
    let list = [...content];
    if (!list.length) return [];
    switch (sort) {
      case ('name'):
        const stringValue = list.filter(n => isNaN(+n.name)).sort((a, b) => a.name > b.name ? 1 : -1)
        const integerValue = list.filter(x => !isNaN(+x.name)).sort((a, b) => +a.name - +b.name);
        list = [...integerValue, ...stringValue];
        break;
      case ('-name'):
        const strings = list.filter(n => isNaN(+n.name)).sort((a, b) => a.name > b.name ? -1 : 1)
        const integers = list.filter(x => !isNaN(+x.name)).sort((a, b) => +b.name - +a.name);
        list = [...integers, ...strings];
        break;
      default:
        list = [...content];
    }
    if (type === EVehicleType.car) {
      list = list.filter(item => item.name.toLowerCase().includes(this.searchCar.toLowerCase()));
    } else {
      list = list.filter(item => item.name.toLowerCase().includes(this.searchMoto.toLowerCase()));
    }
    return list;
  }

  getSearchValue(): void {
    this.searchCarControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchCar = value;
      })
    this.searchMotoControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchMoto = value;
      })
  }
}
