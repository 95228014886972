import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  getCarTiresList,
  ICarTire,
  ICarTireDBData,
  ICarTiresParams
} from '../../../../modules/state/general-admin/manage-car-tires';
import { IFilters, IHeadFilters, IResponse } from '../../../../api/api-data/api.interfaces';
import {
  getCarTiresLoader,
  getManageCarTiresData
} from '../../../../modules/state/general-admin/manage-car-tires/manage-car-tires.selectors';
import { getFilterQuery } from '../../../../utils/helpers';

@Component({
  selector: 'app-tire-dialog',
  templateUrl: './tire-dialog.component.html',
  styleUrls: ['./tire-dialog.component.scss']
})
export class TireDialogComponent implements OnInit, OnDestroy {
  public isLoading$: Observable<boolean>;
  public tiresList$: Observable<IResponse<ICarTireDBData[]>>;
  public selectedTire: ICarTire | null = null;
  public currentPage: number = 1;
  public params: ICarTiresParams = {page: this.currentPage};
  public destroy$: Subject<void> = new Subject<void>();
  public filters: string = '';
  public filtersData: IFilters = {};
  public plateNumber: string | null = null;

  constructor(private store$: Store,
              @Inject(MAT_DIALOG_DATA) public data: string,
              private dialogRef: MatDialogRef<TireDialogComponent>) { }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(getCarTiresLoader);
    this.tiresList$ = this.store$.select(getManageCarTiresData);
    this.getTiresList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTiresList(): void {
    this.store$.dispatch(getCarTiresList({params: this.params, url: `/${this.data}/prices`}));
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.getTiresList();
  }

  selectTire(tire: ICarTireDBData): void {
    this.selectedTire = tire;
    this.dialogRef.close(tire);
  }

  getFilters(filters: IHeadFilters): void {
    for (const key in filters) {
      // @ts-ignore
      this.filtersData[key] = {value: [filters[key]], sign: '=='};
      if (!filters[key]) delete this.filtersData[key];
    }
    this.filters = getFilterQuery(this.filtersData);
    this.currentPage = 1;
    this.params = {...this.params, filters: this.filters, page: this.currentPage};
    this.getTiresList();
  }

  searchByPlate(plateValue: string | null) {
    this.plateNumber = !!plateValue ? plateValue : '';
    this.currentPage = 1;
    this.params = {...this.params, plateNumber: this.plateNumber, page: this.currentPage};
    this.getTiresList();
  }
}
