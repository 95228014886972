import { HttpErrorResponse } from "@angular/common/http";
import { IResponse } from '../../../../api/api-data/api.interfaces';
import { IParameter } from '../tire-reseller';
import { IDropdownValue } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';

export interface CharacteristicElement {
  id: number;
  name: string;
  options?: IOptions[]
}

export interface ICharacteristicOption {
  id: number,
  value: string
}

export interface IOptions {
  [key: string]: ICharacteristicOption[]
}

export interface ICharacteristicObject {
  [key: string]: { name: string, label: string, placeholder: string, options: ICharacteristicOption[] }
}

export interface CharacteristicState {
  deleteId: number | null,
  characteristic: IResponse<CharacteristicElement[]>,
  options: IOptions,
  lookupParameters: ILookupParameters,
  isLoading: boolean,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  error: HttpErrorResponse
}

export interface ILookupParameters {
  City: IParameter[],
  Manufacture: IParameter[],
  VehicleType: IParameter[],
  Subscription: IParameter[],
  TWSName: IParameter[],
  LeadType: IParameter[],
  CarTireType: IParameter[],
  MotorcycleTireType: IParameter[],
  TireManufacturer: IParameter[],
  RimManufacturer: IParameter[],
  ModelName: IParameter[],
  SubCarTireType: ISubCarTireType[],
  RimDiameter: IParameter[],
  TWSMoreOptions: IParameter[],
  Location: IParameter[],
  Country: IParameter[],
  TWSTireType: IParameter[],
  TireDiameter: IParameter[],
  BillingStatus: IDropdownValue[],
  OrderStatus: IDropdownValue[]
}

export interface ISubCarTireType {
  id: string | number,
  value: string,
  subTireType: IParameter[]
}
