import { createFeatureSelector, createSelector } from "@ngrx/store";

import { AdminNotificationsState } from './admin-notifications.model';

export const getAdminNotificationState = createFeatureSelector<AdminNotificationsState>('adminNotifications');

export const getNotificationsSuccessLoader = createSelector(getAdminNotificationState,
  (state: AdminNotificationsState): boolean => state.isLoadingSuccess);

export const getNotificationsError = createSelector(getAdminNotificationState,
  (state: AdminNotificationsState) => state.error);

export const getNotificationList = createSelector(getAdminNotificationState,
  (state: AdminNotificationsState) => state.notificationList);

