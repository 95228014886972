<div class="sidebar" [ngClass]="{'active': true}" dir="rtl">
  <div class="tws-name" *ngIf="auth.isReseller && auth.reseller.twsName">{{auth.reseller?.twsName}}</div>
    <div class="header">
        <div class="hamburger" [class.open]="menuToggled" (click)="toggleMenu()">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <a routerLink="/" class="logo">
            <img src="../../../assets/new-logo-t4u.png" alt="">
        </a>
    </div>
    <div class="menu">

      <ng-container *ngIf="userRole === userRoles.AdminUser">
        <a *ngFor="let route of adminRoutes" [routerLink]="['/', adminRoot, route.path]" class="menu-link desktop-only" routerLinkActive="active">
          <span class="item-name">{{route.data?.menuTitle | translate}}</span>
        </a>
      </ng-container>

      <ng-container *ngIf="userRole === userRoles.BusinessUser">
        <a *ngFor="let route of firstRoutesWithId; let i = index" (click)="navigate(route)" class="desktop-only"
           [ngClass]="{'active': routePath === route.path, 'priceList': i === 1, 'menu-link': i === 0}">
          <span class="item-name">{{route.title | translate}}</span>
        </a>
        <a class="menu-link desktop-only"><span></span></a>
        <a *ngFor="let route of routesWithId" (click)="navigate(route)" class="menu-link desktop-only"
           [ngClass]="routePath === route.path ? 'active': ''">
          <span class="item-name">{{route.title | translate}}</span>
        </a>
      </ng-container>
      <div class="log-out">
        <app-lang-switcher></app-lang-switcher>
        <a (click)="onLogout()">
          <svg-icon
            [src]="'assets/icons/svg/log-in.svg'"
          ></svg-icon>
          {{'LOG_OUT' | translate}}
        </a>
      </div>


    </div>

</div>
