import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { IHeadFilters, IResponseFilters } from '../../api/api-data/api.interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EParamsLabel, EParamsPlaceholder } from '../../modules/shared/enums/select.enums';
import { SelectComponent } from '../../modules/shared/components/select/select.component';
import { IPlateNumber } from '../../modules/state/business-user/article-price';

@Component({
  selector: 'app-filter-selects',
  templateUrl: './filter-selects.component.html',
  styleUrls: ['./filter-selects.component.scss']
})
export class FilterSelectsComponent implements OnInit {

  @Input() filters: IResponseFilters | null = null;
  public form: FormGroup;
  public labels = EParamsLabel;
  public placeholders = EParamsPlaceholder;
  @Output() filtersChange = new EventEmitter<IHeadFilters>();
  @Output() plateNumberChange = new EventEmitter<string | null>();
  @ViewChildren('appSelect') appSelectComponents: QueryList<SelectComponent>;
  public changeSelectWidth: boolean = false;
  @Input() consumerList: boolean = false;
  @Input() orderEditing: boolean = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
    this.checkPlateNumber();
  }

  initForm(): void {
    this.form = this.fb.group({
      Width: [],
      Height: [],
      Diameter: [],
      TireType: [],
      Brand: [],
      Manufacture: [],
      plate: []
    });
    this.changeSelectWidth = window.innerWidth < 1500;
  }

  checkPlateNumber(): void {
    this.form.controls['plate'].valueChanges.subscribe({
      next: (plate: IPlateNumber) => this.plateNumberChange.emit(!!plate ? plate.plateNumber : null)
    })
  }

  submit(): void {
    this.filtersChange.emit(this.form.value);
  }

  resetFilters(): void {
    this.form.reset();
    this.filtersChange.emit(this.form.value);
  }

  openNext(controlName: string): void {
    switch (controlName) {
      case this.labels.tireType:
        this.appSelectComponents.get(2)?.open();
        break;
      case this.labels.width:
        this.appSelectComponents.get(1)?.open();
        break;
      case this.labels.height:
        this.appSelectComponents.get(0)?.open();
        break;
    }
  }

  removePlate(): void {
    this.form.controls['plate'].reset();
  }

  selectPlate(plate: IPlateNumber): void {
    this.form.controls['plate'].setValue(plate);
  }
}
