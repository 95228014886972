<div class="dialog manufacturer-add-dialog">
  <div class="dialog-title">
    <h3 class="title">
      {{'HEIGHT' | translate}}
    </h3>
    <div class="title-actions">
      <mat-icon class="close" (click)="closeAddDialog()">
        close
      </mat-icon>
    </div>
  </div>

  <ng-container [ngSwitch]="data.type">
    <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="editCriteria"></ng-container>
    <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="editCriteria"></ng-container>
    <ng-container *ngSwitchCase="EModalType.delete" [ngTemplateOutlet]="deleteCriteria"></ng-container>
  </ng-container>

  <ng-template #editCriteria>
    <mat-dialog-content>
      <div class="dialog-form-wrap">
        <div class="table-loader" *ngIf="(isLoading$ | async)">
          <mat-spinner></mat-spinner>
        </div>
        <form (submit)="submit($event)" [formGroup]="tireWeightForm">
          <div class="form-group">

            <div class="form-col">
              <app-input [isNumber]="true" [label]="ECriteriaLabel.value" formControlName="value"
                         [isRequired]="true" [placeholder]="ECriteriaLabel.value"></app-input>
            </div>
<!--            <div class="col-6 form-col">-->
<!--              <app-input [isNumber]="true" [label]="ECriteriaLabel.priority" formControlName="priority"-->
<!--                         [isRequired]="true" [placeholder]="ECriteriaLabel.priority"></app-input>-->
<!--            </div>-->
          </div>

          <div class="checkbox">
            <mat-checkbox formControlName="isActive" class="form-checkbox">{{'ACTIVE' | translate}}</mat-checkbox>
          </div>

          <div class="save-btn">
            <button class="btn-admin" [disabled]="!tireWeightForm.valid">
              {{'SAVE' | translate}}
            </button>
          </div>
        </form>
      </div>
    </mat-dialog-content>
  </ng-template>
  <ng-template #deleteCriteria>
    <div class="delete-action-wrap">
      <div class="table-loader" *ngIf="(isLoading$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <p class="delete-text">{{'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_CRITERIA_WITH_VALUE' | translate}} {{data.value}}?</p>
      <button (click)="deleteItem(data.id)" class="btn-admin">{{'DELETE' | translate}}</button>
    </div>
  </ng-template>
</div>


