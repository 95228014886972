import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IResponse } from '../../../../api/api-data/api.interfaces';
import {
  ICriteriaLeads,
  ILead,
  ILeadExpanded,
  ILeadsCount,
  ILeadsParams,
  IStatistic,
  IStatisticParams
} from './leads.model';

export const GET_LEADS_LIST = '[STATISTICS] Get leads list';
export const GET_LEADS_LIST_SUCCESS = '[STATISTICS] Get leads list success';
export const GET_LEADS_LIST_FAILURE = '[STATISTICS] Get leads list failure';

export const GET_LEADS_COUNT_STATISTICS = '[STATISTICS] Get leads count statistic list';
export const GET_LEADS_COUNT_STATISTICS_SUCCESS = '[STATISTICS] Get leads count statistic success';
export const GET_LEADS_COUNT_STATISTICS_FAILURE = '[STATISTICS] Get leads count statistic failure';

export const EXPORT_LEADS_STATISTIC = '[STATISTICS] Export leads statistics';
export const EXPORT_LEADS_STATISTIC_SUCCESS = '[STATISTICS] Export leads statistics success';
export const EXPORT_LEADS_STATISTIC_FAILURE = '[STATISTICS] Export leads statistics failure';

export const EXPORT_LEADS_FILE = '[STATISTICS] Export leads file';
export const EXPORT_LEADS_FILE_SUCCESS = '[STATISTICS] Export leads file success';
export const EXPORT_LEADS_FILE_FAILURE = '[STATISTICS] Export leads file failure';

export const GET_LEADS_CRITERIA = '[CRITERIA] Get leads criteria';
export const GET_LEADS_CRITERIA_SUCCESS = '[CRITERIA] Get leads criteria success';
export const GET_LEADS_CRITERIA_FAILURE = '[CRITERIA] Get leads criteria failure';

export const CHANGE_LEAD_TWS_STATUS = '[MANAGE_LEADS] Change lead TWS status';
export const CHANGE_LEAD_TWS_STATUS_SUCCESS = '[MANAGE_LEADS] Change lead TWS status success';
export const CHANGE_LEAD_TWS_STATUS_FAILURE = '[MANAGE_LEADS] Change lead TWS status failure';

export const CHANGE_LEAD_ADMIN_STATUS = '[MANAGE_LEADS] Change lead admin status';
export const CHANGE_LEAD_ADMIN_STATUS_SUCCESS = '[MANAGE_LEADS] Change lead admin status success';
export const CHANGE_LEAD_ADMIN_STATUS_FAILURE = '[MANAGE_LEADS] Change lead admin status failure';

export const GET_STATISTIC = '[STATISTICS] Get leads statistic';
export const GET_STATISTIC_SUCCESS = '[STATISTICS] Get leads statistic success';
export const GET_STATISTIC_FAILURE = '[STATISTICS] Get leads statistic failure';

export const EXPORT_FILE_STATISTIC = '[STATISTICS] Export leads file statistics';
export const EXPORT_FILE_STATISTIC_SUCCESS = '[STATISTICS] Export leads file statistics success';
export const EXPORT_FILE_STATISTIC_FAILURE = '[STATISTICS] Export leads file statistics failure';


export const getLeadsList = createAction(
  GET_LEADS_LIST,
  props<{ payload: ILeadsParams, dateFrom: string, dateTo: string }>()
)

export const getLeadsListSuccess = createAction(
  GET_LEADS_LIST_SUCCESS,
  props<{ payload: IResponse<ILead[]> }>()
)

export const getLeadsListFailure = createAction(
  GET_LEADS_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getLeadsCountStatistics = createAction(
  GET_LEADS_COUNT_STATISTICS,
  props<{ params?: IStatisticParams }>()
)

export const getLeadsCountStatisticsSuccess = createAction(
  GET_LEADS_COUNT_STATISTICS_SUCCESS,
  props<{ payload: ILeadsCount }>()
)

export const getLeadsCountStatisticsFailure = createAction(
  GET_LEADS_COUNT_STATISTICS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const exportLeadsStatistic = createAction(
  EXPORT_LEADS_STATISTIC,
  props<{ params: IStatisticParams, fileName: string }>()
)

export const exportLeadsStatisticSuccess = createAction(
  EXPORT_LEADS_STATISTIC_SUCCESS
)

export const exportLeadsStatisticFailure = createAction(
  EXPORT_LEADS_STATISTIC_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const exportLeadsFile = createAction(
  EXPORT_LEADS_FILE,
  props<{ params: IStatisticParams, fileName: string }>()
)

export const exportLeadsFileSuccess = createAction(
  EXPORT_LEADS_FILE_SUCCESS
)

export const exportLeadsFileFailure = createAction(
  EXPORT_LEADS_FILE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getLeadsCriteria = createAction(
  GET_LEADS_CRITERIA
)

export const getLeadsCriteriaSuccess = createAction(
  GET_LEADS_CRITERIA_SUCCESS,
  props<{ payload: ICriteriaLeads[] }>()
)

export const getLeadsCriteriaFailure = createAction(
  GET_LEADS_CRITERIA_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changeLeadTWSStatus = createAction(
  CHANGE_LEAD_TWS_STATUS,
  props<{ id: number, status: string }>()
)

export const changeLeadTWSStatusSuccess = createAction(
  CHANGE_LEAD_TWS_STATUS_SUCCESS,
  props<{ payload: ILeadExpanded }>()
)

export const changeLeadTWSStatusFailure = createAction(
  CHANGE_LEAD_TWS_STATUS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changeLeadAdminStatus = createAction(
  CHANGE_LEAD_ADMIN_STATUS,
  props<{ id: number, status: string }>()
)

export const changeLeadAdminStatusSuccess = createAction(
  CHANGE_LEAD_ADMIN_STATUS_SUCCESS,
  props<{ payload: ILeadExpanded }>()
)

export const changeLeadAdminStatusFailure = createAction(
  CHANGE_LEAD_ADMIN_STATUS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)


export const getStatistic = createAction(
  GET_STATISTIC,
  props<{ params?: IStatisticParams }>()
)

export const getStatisticSuccess = createAction(
  GET_STATISTIC_SUCCESS,
  props<{ payload: IStatistic }>()
)

export const getStatisticFailure = createAction(
  GET_STATISTIC_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const exportStatisticFile = createAction(
  EXPORT_FILE_STATISTIC,
  props<{ params: IStatisticParams, fileName: string }>()
)

export const exportStatisticFileSuccess = createAction(
  EXPORT_FILE_STATISTIC_SUCCESS
)

export const exportStatisticFileFailure = createAction(
  EXPORT_FILE_STATISTIC_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
