import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";
import { IConnectImageElement, IConnectImageParams } from './connect-image.model';
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const GET_CONNECT_IMAGE_DATA = '[CONNECT_IMAGE] Get data';
export const GET_CONNECT_IMAGE_SUCCESS = '[CONNECT_IMAGE] Get data success';
export const GET_CONNECT_IMAGE_FAILURE = '[CONNECT_IMAGE] Get data failure';

export const CONNECT_IMAGE_FOR_TIRES = '[CONNECT_IMAGE] Connect images for tires';
export const CONNECT_IMAGE_FOR_TIRES_SUCCESS = '[CONNECT_IMAGE] Connect images for tires success';
export const CONNECT_IMAGE_FOR_TIRES_FAILURE = '[CONNECT_IMAGE] Connect images for tires failure';

export const getConnectImageData = createAction(
  GET_CONNECT_IMAGE_DATA,
  props<{ params: IConnectImageParams }>()
)

export const getConnectImageDataSuccess = createAction(
  GET_CONNECT_IMAGE_SUCCESS,
  props<{ payload: IResponse<IConnectImageElement[]> }>()
)

export const getConnectImageDataFailure = createAction(
  GET_CONNECT_IMAGE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const connectImageForTires = createAction(
  CONNECT_IMAGE_FOR_TIRES,
  props<{ payload: FormData, articleIds: number[] }>()
)

export const connectImageForTiresSuccess = createAction(
  CONNECT_IMAGE_FOR_TIRES_SUCCESS,
  props<{ payload: IConnectImageElement[] }>()
)

export const connectImageForTiresFailure = createAction(
  CONNECT_IMAGE_FOR_TIRES_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

