import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

import { AuthService } from "../services/auth.service";
import { UserActionsTypes } from "./user.actions";
import { LoginResponse, UserRoles } from "../interfaces/auth.interface";
import { NotificationService } from '../../../api/notification.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class UserEffects {

  constructor(private actions$: Actions,
              private authService: AuthService,
              private alertService: NotificationService,
              private router: Router,
              @Inject(DOCUMENT) private document: Document) {
  }

  userLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionsTypes.UserLogin),
      mergeMap(({email, password}) => {
        return this.authService.login(email, password).pipe(
          map(data => {
            return ({type: UserActionsTypes.UserLoginSuccess, payload: data})
          }),
          catchError((error: HttpErrorResponse) => {
            this.authService.loader = false;
            const failures = error.error?.failures;
            const errors = [];
            for (const key in failures) {
              errors.push(failures[key][0])
              this.alertService.onError(errors)
            }
            return of({type: UserActionsTypes.UserLoginFailed, payload: error})
          })
        )
      })
    )
  })

  userLoginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionsTypes.UserLoginSuccess),
      map((action: { type: string, payload: LoginResponse }) => action.payload),
      tap((payload: LoginResponse) => {
        if (payload.role === UserRoles.AdminUser && this.document.location.origin.includes('my.tires4u.co.il')) {
          this.alertService.onError('YOU_NEED_TO_HAVE_PERMISSION_AND_CREDENTIALS_FOR_THIS_ADMIN_PANEL');
          this.authService.loader = false;
        }
        if (payload.role === UserRoles.AdminUser && !this.document.location.origin.includes('my.tires4u.co.il')) {
          this.alertService.onSuccess('SUCCESS_LOGIN')
          this.authService.setUser(payload, true);
          this.router.navigate(['/admin', '/statistics']);
          this.authService.user = payload;
        }
        if (payload.role === UserRoles.BusinessUser && !this.document.location.origin.includes('my.tires4u.co.il')) {
          this.alertService.onError('YOU_NEED_TO_HAVE_PERMISSION_AND_CREDENTIALS_FOR_THIS_ADMIN_PANEL');
          this.authService.loader = false;
        }
        if (payload.role === UserRoles.BusinessUser && this.document.location.origin.includes('my.tires4u.co.il')) {
          this.alertService.onSuccess('SUCCESS_LOGIN')
          this.authService.setUser(payload, false);
          this.authService.isReseller = true;
          this.router.navigate(['/business', 'dashboard', payload.tireResellerId]);
          this.authService.reseller = payload;
        }
      })
    )
  }, {dispatch: false})

  userLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionsTypes.UserLogout),
      tap((action: any) => {
        this.authService.logout(action.genAdmin);
      })
    )
  }, {dispatch: false})
}
