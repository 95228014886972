import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { IParameter } from '../../../state/general-admin/tire-reseller';
import { MatDialog } from '@angular/material/dialog';
import {
  ManufacturerAddDialogComponent
} from '../../../../t4u-admin/pages/criteria/components/manufacturer/manufacturer-add-dialog/manufacturer-add-dialog.component';
import { EModalType } from '../../interfaces/table.interfaces';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectComponent),
    multi: true
  },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    }]
})
export class SelectComponent implements ControlValueAccessor, Validator, OnChanges {

  @Input() placeholder: string;
  @Input() label: string;
  @Input() options: IParameter[];
  @Input() isRequired: boolean = false;
  @Input() isSorted: boolean = true;
  @Input() selectSearch: boolean = false;
  @Input() isValueName: boolean = false;
  @Input() brandSelect: boolean = false;
  @Input() resellerId: string = '';
  public control: AbstractControl | null = null;
  @Output() isSelected: EventEmitter<{ status: boolean, id: number | string }> = new EventEmitter<{ status: boolean, id: number | string }>();
  public value: string;
  public onChange: (p: any) => void = () => { };
  public onTouch: () => void = () => { };
  public optionsArr: IParameter[];
  public stringValue: string | number;
  @Output() openNextSelect: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('matSelect') matSelect: MatSelect;

  constructor(private dialog: MatDialog) {}

  ngOnChanges(): void {
    if (this.options?.length) this.optionsArr = [...this.options];
  }

  onKey(value: any): void {
    this.optionsArr = this.search(value.target.value);
  }

  search(value: string): IParameter[] {
    let filter = this.options.filter(item =>
      item.value.toLowerCase().includes(value.toLowerCase())
    );
    return [...filter];
  }

  valueChange(event: MatSelectChange): void {
    this.value = event.value;
    this.isSelected.emit({status: true, id: event.value});
    this.stringValue = this.options?.find(item => item.id === this.value)?.value || '';
    const correctValue = event.value || event.value === 0 ? event.value : '';
    this.onTouch();
    this.onChange(correctValue);
    this.openNextSelect.emit();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  sortOptions(params: IParameter[]): IParameter[] {
    if (!params?.length) return [];
    let options = [...params];
    const privateBrands = options.filter(x => x.isPrivate).sort((a, b) => a.value > b.value ? 1 : -1);
    const allOptions = options.filter(x => !x.isPrivate);
    const stringValue = allOptions.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? 1 : -1)
    const integerValue = allOptions.filter(x => !isNaN(+x.value)).sort((a, b) => +a.value - +b.value);
    return [...integerValue, ...stringValue, ...privateBrands];
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control) return null;
    this.control = control;
    return control.value ? null : {...control.errors};
  }

  editPrivateBrand(event: any, editBrand: boolean, brand?: IParameter): void {
    event.stopPropagation();
    const modalType = editBrand ? EModalType.edit : EModalType.add;
    let data = {
      modalType, resellerPrivateBrand: true,
      tireResellerId: this.resellerId,
      id: brand?.id || '',
      name: brand?.value || ''
    };
    this.dialog.open(ManufacturerAddDialogComponent, {
      panelClass: 'admin-dialog',
      data
    })
  }

  open(): void {
    this.matSelect.open();
  }
}
