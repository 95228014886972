import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  changeLeadAdminStatus,
  changeLeadTWSStatus,
  exportLeadsFile,
  getLeadsCountStatistics,
  getLeadsError,
  getLeadsList,
  getLeadsLoader,
  getLeadsSuccessLoader,
  getLeadsTableData,
  getStatistic,
  getStatisticLoad,
  selectLeadsStatusError,
  selectLeadStatusLoader,
  selectStatistic
} from '../../../modules/state/general-admin/leads';
import {
  IFilterValue,
  ISelectFormValue,
  IStatusValue,
  TableMeta
} from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { ManageLeadsTableMeta } from '../pages.constants';
import { IFilters, IResponse } from '../../../api/api-data/api.interfaces';
import { ILead, IStatistic, IStatisticParams } from '../../../modules/state/general-admin/leads/leads.model';
import { LanguageService } from '../../../api/language.service';
import { getFilterQuery, isEmptyObj } from '../../../utils/helpers';
import { getLookupParameters, ILookupParameters } from '../../../modules/state/general-admin/characteristic';
import { selectLookupParams } from '../../../modules/state/general-admin/characteristic/characteristic.selectors';
import { ELeadsLabel, ELeadsPlaceholder, ELeadsTableFilterKey } from './leads.enum';
import { NotificationService } from '../../../api/notification.service';
import { EModalMessage } from '../criteria/components/criteria.constants';
import { IParameter } from '../../../modules/state/general-admin/tire-reseller';
import { EStatisticTitle } from '../../../modules/statistics/components/statistics/statistics.enums';
import { monthNames } from '../../../business-user/business-user.constants';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-manage-leads',
  templateUrl: './manage-leads.component.html',
  styleUrls: ['./manage-leads.component.scss']
})

export class ManageLeadsComponent implements OnInit, OnDestroy {

  public tableMeta: TableMeta[] = ManageLeadsTableMeta;
  public leadsList$: Observable<IResponse<ILead[]>>;
  public destroy$: Subject<void> = new Subject<void>();
  public currentPage: number = 1;
  public isFiltersShow: boolean = false;
  public filterGroup: FormGroup;
  public filters: string = '';
  public params$: Observable<ILookupParameters>;
  public filtersData: IFilters = {};
  public ELeadsLabel = ELeadsLabel;
  public ELeadsPlaceholder = ELeadsPlaceholder;
  public isTableLoad$: Observable<boolean>;
  public isStatisticLoad$: Observable<boolean>;
  public statistic$: Observable<IStatistic | null>;
  public EStatisticTitle = EStatisticTitle;
  public dateFrom: string;
  public dateTo: string;
  public month: string;
  public today: Date = new Date();
  public resellerId: string = '';
  public formValue: ISelectFormValue = {};
  private fileParams: IStatisticParams;

  constructor(private store$: Store,
              private fb: FormBuilder,
              private alertService: NotificationService,
              public languageService: LanguageService,
              private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.getCurrentMonth(this.today, this.today.getMonth());
    this.initForm();
    this.getData();
    this.getFiltersValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.filterGroup = this.fb.group({
      Category: [''],
      TWSName: [''],
      LeadType: [''],
      dateRange: new DateRange<Date>(new Date(this.dateFrom), new Date()) || ['']
    });
  }

  getData(): void {
    this.store$.dispatch(getLookupParameters({payload: 'TWSName,VehicleType,LeadType'}));
    this.loadLeadList(true);
    this.params$ = this.store$.select(selectLookupParams);
    this.leadsList$ = this.store$.select(getLeadsTableData);
    this.isTableLoad$ = this.store$.select(getLeadsLoader);
    this.isStatisticLoad$ = this.store$.select(getStatisticLoad);
    this.statistic$ = this.store$.select(selectStatistic);
  }

  getCurrentMonth(date: Date, month: number): void {
    date.setFullYear(date.getFullYear(), month, 1);
    this.month = monthNames[this.today.getMonth()].value;
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 11);
    let lastDay;
    this.dateFrom = this.convertTime(firstDay.toISOString());
    if (date.getTime() === this.today.getTime()) {
      lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 11);
      this.dateTo = this.convertTime(lastDay.toISOString());
      return;
    }
    lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 11);
    this.dateTo = this.convertTime(lastDay.toISOString());
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.store$.dispatch(getLeadsList({
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      payload: {page: this.currentPage, filters: this.filters, recordLimit: page * 10}
    }));
  }

  getTableFilters(event: IFilterValue): void {
    if (event.filterKey === ELeadsTableFilterKey.dateFrom && event.date && event.filterName === 'date') {
      this.dateFrom = event.checkedIds[0] as string;
      this.dateTo = event.checkedIds[0] as string;
      // this.dateTo = this.convertTime(new Date().toISOString());
      this.month = `${new Date(event.checkedIds[0]).toLocaleDateString()}`;
      this.filterGroup.controls['dateRange']?.setValue(new DateRange<Date>(new Date(this.dateFrom), new Date(this.dateTo)));
      delete this.filtersData[ELeadsTableFilterKey.dateTo];
      return;
    }
    if (event.filterKey === ELeadsTableFilterKey.dateTo && event.date && event.filterName === 'date') {
      this.dateTo = event.checkedIds[0] as string;
      this.month += ` - ${new Date(event.checkedIds[0]).toLocaleDateString()}`;
      this.filterGroup.controls['dateRange']?.setValue(new DateRange<Date>(new Date(this.dateFrom), new Date(this.dateTo)));
      delete this.filtersData[ELeadsTableFilterKey.dateTo];
      return;
    }
    if (event.filterKey === ELeadsTableFilterKey.dateFrom && event.date && event.filterName === 'installDate') {
      this.filtersData[ELeadsTableFilterKey.fromInstalDate] = {value: event.checkedIds, sign: '=='};
      this.filtersData[ELeadsTableFilterKey.toInstalDate] = {value: event.checkedIds, sign: '=='};
      this.filters = getFilterQuery(this.filtersData)
      delete this.filtersData[ELeadsTableFilterKey.toInstalDate];
      delete this.filtersData[ELeadsTableFilterKey.dateTo];
      this.loadLeadList();
      return;
    }
    if (event.filterKey === ELeadsTableFilterKey.dateTo && event.date && event.filterName === 'installDate') {
      this.filtersData[ELeadsTableFilterKey.toInstalDate] = {value: event.checkedIds, sign: '=='};
      this.filters = getFilterQuery(this.filtersData);
      this.loadLeadList();
      return;
    }
    if (event.filterKey === ELeadsTableFilterKey.TWSName && event.checkedIds?.length) {
      this.resellerId = event.checkedIds.join(',');
      this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
      this.filters = getFilterQuery(this.filtersData);
      this.currentPage = 1;
      this.filterGroup.controls[event.filterKey]?.patchValue(event.checkedIds);
      return;
    }
    if (event.filterKey === ELeadsTableFilterKey.adminStatus || event.filterKey === ELeadsTableFilterKey.TWSStatus) {
      this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
      this.filters = getFilterQuery(this.filtersData);
      this.loadLeadList();
      return;
    }
    this.filterGroup.controls[event.filterKey]?.patchValue(event.checkedIds);
    this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
    if (!event.checkedIds.length) delete this.filtersData[event.filterKey];
    this.filters = getFilterQuery(this.filtersData);
    if (event.filterKey === ELeadsTableFilterKey.TWSName && !event.checkedIds?.length) {
      this.resellerId = '';
    }
    this.currentPage = 1;
  }

  checkTableValue(value: string[] | number[]): (string | number)[] {
    if (!value.length) return [];
    return value.map(item => {
      return isNaN(+item) ? item : +item;
    });
  }

  loadLeadList(loadStatistic?: boolean): void {
    this.store$.dispatch(getLeadsList({
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      payload: {page: this.currentPage, filters: this.filters, recordLimit: 10}
    }));
    if (loadStatistic) {
      this.store$.dispatch(getStatistic({
        params: {
          tireResellerId: this.resellerId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        }
      }));
    }
  }

  getFiltersValue(): void {
    this.filterGroup.valueChanges
      .pipe(takeUntil(this.destroy$),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe({
        next: (formValue) => {
          const params = {...formValue};
          for (const key in params) {
            if (key !== 'dateRange') {
              this.formValue = {
                ...this.formValue,
                [key]: params[key] instanceof Array ? params[key] : []
              }
            }
            this.filtersData[key] = {value: params[key], sign: '=='};
            if (!params[key].length || params[key] === null || !params[key]) delete this.filtersData[key];
          }
          delete this.filtersData['dateRange'];
          if (formValue.dateRange.start) {
            this.month = formValue.dateRange.start.toLocaleDateString();
            this.dateFrom = this.convertTime(params?.dateRange?.start);
            this.dateTo = this.convertTime(params?.dateRange?.start);
          }
          if (formValue.dateRange.end) {
            this.month = `${formValue.dateRange.start.toLocaleDateString()} - ${formValue.dateRange.end.toLocaleDateString()}`;
            this.dateFrom = this.convertTime(params?.dateRange?.start);
            this.dateTo = this.convertTime(params?.dateRange?.end);
          }
          if (!formValue.TWSName.length) this.resellerId = '';
          if (formValue.TWSName.length) this.resellerId = formValue.TWSName.join(',');
          this.filters = getFilterQuery(this.filtersData);
          this.currentPage = 1;
          this.loadLeadList(true);
          this.checkFiltersRequest();
        }
      })
  }

  convertTime(date: string): string {
    if (!date) return '';
    const fullDate = new Date(date);
    const month = (fullDate.getMonth() < 9 ? '0' : '') + (fullDate.getMonth() + 1);
    const day = (fullDate.getDate() < 10 ? '0' : '') + fullDate.getDate();
    const year = fullDate.getFullYear();
    const shortDate = [year, month, day];
    return shortDate.join('-');
  }

  clearFilters(): void {
    this.filtersData = {};
    this.filters = getFilterQuery(this.filtersData);
    this.filterGroup.reset();
    this.currentPage = 1;
    this.store$.dispatch(getLeadsList({
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      payload: {page: this.currentPage, filters: this.filters, recordLimit: 10}
    }));
    this.store$.dispatch(getStatistic({}));
  }

  changeStatus(value: IStatusValue): void {
    if (value.key === 'twsStatus') {
      this.store$.dispatch(changeLeadTWSStatus({id: value.id, status: value.status}));
      this.checkRequest(EModalMessage.statusChanged);
      return;
    }
    this.store$.dispatch(changeLeadAdminStatus({id: value.id, status: value.status}));
    this.checkRequest(EModalMessage.statusChanged);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(selectLeadsStatusError),
      this.store$.select(selectLeadStatusLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.alertService.onSuccess(message);
            this.store$.dispatch(getLeadsCountStatistics({}));
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.error.message);
        }
      })
  }

  checkFiltersRequest(): void {
    combineLatest(
      this.store$.select(getLeadsError),
      this.store$.select(getLeadsSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.error.message);
        }
      })
  }

  sortContent(params: IParameter[]): IParameter[] {
    if (!params?.length) return [];
    let options = [...params];
    return options.filter(n => isNaN(+n.value)).sort((a, b) => a.value > b.value ? 1 : -1)
  }

  filterContent(content: ILead[], totalRecord: number): ILead[] {
    if (!content) return [];
    const pageSize = Math.ceil(totalRecord / 10);
    const lastLeads = (totalRecord / 10).toString().split('.')[1];
    if (this.currentPage === pageSize && this.currentPage !== 1) return content.slice(-(+lastLeads));
    return content.slice(-10);
  }

  handleFilter($event: MouseEvent): void {
    $event.stopPropagation();
    this.isFiltersShow = !this.isFiltersShow
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent): void {
    if (this.elementRef.nativeElement.contains(event.target)) {
      this.isFiltersShow = false;
    }
  }

  changeVirtualNumber(number: string): string {
    if (!number.includes('972')) return number;
    let newNumber = number.split('972')[1];
    const firstLetters = newNumber.slice(0, 2);
    newNumber = newNumber.slice(2, newNumber.length)
    return `0${firstLetters}-${newNumber}`;
  }

  export(): void {
    const fileName = `leads.${this.dateFrom}-${this.dateTo}`;
    this.fileParams = {...this.fileParams, dateTo: this.dateTo, dateFrom: this.dateFrom, filters: this.filters};
    this.store$.dispatch(exportLeadsFile({params: this.fileParams, fileName}));
  }

  opent4uLink(event: any): void {
    if (!event.data.converseUrl) return;
    const url = decodeURI(event.data.converseUrl);
    window.open(url, '_blank')
  }
}
