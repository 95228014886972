import { CellTypes, TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';

export enum ECriteriaControls {
  value = 'VALUE',
  priority = 'PRIORITY',
  code = 'CODE',
  weight = 'WEIGHT_KG',
  speed = 'SPEED_KMH',
  name = 'NAME',
  private = 'PRIVATE',
  title = 'TITLE',
  country = 'COUNTRY',
  selectCountry = 'SELECT_COUNTRY'
}

export const radiusTableMeta: TableMeta[] = [
  {
    name: 'VALUE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'value',
    sortName: 'value'
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive'
  },
  // {
  //   name: 'PRIORITY',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'priority',
  // },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      },
      {
        action: 'delete',
        icon: 'delete'
      }
    ]
  }
]

export const speedCodeTableMeta: TableMeta[] = [
  {
    name: 'CODE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'value',
    sortName: 'value'
  },
  {
    name: 'SPEED_KM',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'additionalValue',
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive'
  },
  // {
  //   name: 'PRIORITTY',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'priority',
  // },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      },
      {
        action: 'delete',
        icon: 'delete'
      }
    ]
  },
]

export const WeightCodeTableMeta: TableMeta[] = [
  {
    name: 'CODE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'value',
    sortName: 'value'
  },
  {
    name: 'WEIGHT_KG',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'additionalValue'
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive'
  },
  // {
  //   name: 'PRIORITY',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'priority',
  // },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      },
      {
        action: 'delete',
        icon: 'delete'
      }
    ]
  },
]

export const HeightTableMeta: TableMeta[] = [
  {
    name: 'VALUE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'value',
    sortName: 'value'
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive'
  },
  // {
  //   name: 'PRIORITY',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'priority',
  // },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      },
      {
        action: 'delete',
        icon: 'delete'
      },
    ]
  },
]

export const WidthTableMeta: TableMeta[] = [
  {
    name: 'VALUE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'value',
    sortName: 'value'
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive'
  },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      },
      {
        action: 'delete',
        icon: 'delete'
      },
    ]
  },
]

export const tireTypeTableMeta: TableMeta[] = [
  {
    name: 'TITLE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'name',
    sortName: 'name'
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive'
  },
  // {
  //   name: 'PRIORITY',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'priority',
  // },
  // {
  //   name: 'Parent tire type',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'parentTireTypeId',
  // },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      }
    ]
  },
]

export const ManufactureTableMeta: TableMeta[] = [
  {
    name: 'TITLE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'name',
    sortName: 'title'
  },
  // {
  //   name: 'ACTIVE',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'isActive'
  // },
  // {
  //   name: 'PRIORITY',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'priority',
  // },
  {
    name: 'LOGO',
    sortable: false,
    filterable: false,
    type: CellTypes.Image,
    jsonPath: 'logo',
  },
  {
    name: 'COUNTRY',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'country.value',
  },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      },
      // {
      //   action: 'delete',
      //   icon: 'delete'
      // },
    ]
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.ToggleSlider,
    jsonPath: 'isActive',
  }
]

export const SubscriptionTableMeta: TableMeta[] = [
  {
    name: 'TITLE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'name',
    sortName: 'title'
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive',
  },
  // {
  //   name: 'PRIORITY',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'priority',
  // },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      }
    ]
  },
]

export const LocationTableMeta: TableMeta[] = [
  {
    name: 'TITLE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'name',
    sortName: 'Name'
  },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      }
    ]
  },
]

export const LeadsCriteriaTableMeta = [
  {
    name: 'TITLE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'name',
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive',
  }
]

export const LeadStatusTableMeta: TableMeta[] = [
  {
    name: 'TITLE',
    sortable: true,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'name',
    sortName: 'title'
  },
  {
    name: 'ACTIVE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'isActive',
  },
  // {
  //   name: 'PRIORITY',
  //   sortable: false,
  //   filterable: false,
  //   type: CellTypes.String,
  //   jsonPath: 'priority',
  // },
  {
    name: 'ACTIONS',
    sortable: false,
    filterable: false,
    type: CellTypes.Action,
    jsonPath: 'actions',
    actions: [
      {
        action: 'edit',
        icon: 'edit'
      },
      // {
      //   action: 'delete',
      //   icon: 'delete'
      // }
    ]
  },
]

export enum EModalMessage {
  add = 'ADDING_SUCCESS',
  edit = 'EDITING_SUCCESS',
  delete = 'DELETING_SUCCESS',
  notificationAdd = 'NOTIFICATION_SUCCESSFULLY_SEND',
  pricesChanged = 'PRICES_WAS_SUCCESSFUL_CHANGED',
  statusChanged = 'STATUS_UPDATE_SUCCESSFUL',
  setDiscount = 'DISCOUNT_HAS_BEEN_CHANGED_SUCCESSFULLY',
  setProfit = 'PROFIT_HAS_BEEN_CHANGED_SUCCESSFULLY',
  deleteSitePrice = 'PRICE_HAS_BEEN_DELETED',
  resetSitePrice = 'PRICES_HAS_BEEN_RESET_SUCCESSFULLY',
  setArticlesToReset = 'SELECT_TIRES_FOR_PRICE_RESET',
  resetInventory = 'INVENTORY_HAS_BEEN_RESET_SUCCESSFULLY',
  setArticlesToResetInv = 'SELECT_TIRES_FOR_INVENTORY_RESET',
  moreThenThreeFiles = 'YOU_CAN_LOAD_LESS_THEN_THREE_IMAGES',
  selectCorrectImageSize = 'CHOOSE_AN_IMAGE_WITH_THE_CORRECT_DIMENSIONS',
  imageSizeWrong = 'THE_IMAGE_SIZE_IS_TOO_LARGE',
  payedOrder = 'ORDER_WAS_PAYED',
  remarkChanged = 'REMARK_WAS_CHANGED',
  smsWasSend = 'SMS_SEND_SUCCESSFULLY',
  failedPayment = 'PAYMENT_FAILED'
}
