import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { IConnectImageElement, ConnectImageState } from './connect-image.model';
import * as connectImageActions from './connect-image.actions';
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const initialState: ConnectImageState = {
  result: {} as IResponse<IConnectImageElement[]>,
  checkedTires: [],
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: null
};

const connectImageReducer = createReducer(initialState,
  on(connectImageActions.getConnectImageData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(connectImageActions.getConnectImageDataSuccess, (state, {payload}) => {
    return {
      ...state,
      result: payload,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(connectImageActions.getConnectImageDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(connectImageActions.connectImageForTires, (state, {articleIds}) => ({
      ...state,
      checkedTires: articleIds,
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingFailure: false,
      error: null
  })),
  on(connectImageActions.connectImageForTiresSuccess, (state, {payload}) => {
    const content = [...state.result.content];
    const newData = content.map((tire: IConnectImageElement) => {
      payload.forEach(item => {
        if (item.id === tire.id) tire = item;
      })
      return tire;
    });
    return {
      ...state,
      result: {...state.result, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(connectImageActions.connectImageForTiresFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
);

export function ConnectImageReducer(state: ConnectImageState | undefined, action: Action): any {
  return connectImageReducer(state, action);
}



