import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { select, Store } from "@ngrx/store";

import { AuthService } from "../services/auth.service";
import { selectUser } from "../reducers/user.selectors";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private authService: AuthService,
              private store$: Store) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([
      this.store$.pipe(select(selectUser)),
      this.authService.getUser(),
      this.authService.getBusinessUser()]
    ).pipe(
      switchMap(([storageUser, admin, business]) => {
        let token = storageUser?.token || admin?.token || business?.token;
        if(this.authService.isReseller) token = business.token;
        const clonedRequest = request.clone({
          headers: request.headers
            .set('Authorization', `Bearer ${token}`)
        });
        return next.handle(clonedRequest).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              const genAdmin = !!business.token;
              this.authService.logout(genAdmin);
              this.router.navigate(['/auth/login']);
            }
            /*
            TODO Need to install `toastr` notifications
             */
            return throwError(error);
          })
        );
      })
    )

  }
}
