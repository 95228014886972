<div class="dialog-title" mat-dialog-title>
  <h2 class="title">{{mainTitle | translate}}</h2>
  <div class="title-actions">
    <mat-icon class="close" (click)="closeAddDialog()">close</mat-icon>
  </div>
</div>

<ng-container [ngSwitch]="data.modalType">
  <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="changeTire"></ng-container>
  <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="changeTire"></ng-container>
  <ng-container *ngSwitchCase="EModalType.prices" [ngTemplateOutlet]="changeTirePrices"></ng-container>
</ng-container>

<ng-template #changeTire>
  <mat-dialog-content class="dialog-content">
    <div class="table-loader" *ngIf="!(isParamsLoad$ | async) || (loader$ | async)">
      <mat-spinner></mat-spinner>
    </div>
    <div class="dialog-form-wrap" *ngIf="(isParamsLoad$ | async)">
      <form action="" [formGroup]="tireForm">
        <div class="row" *ngIf="(params$ | async) as params">
          <div class="col-4 border-left">
            <div class="form-group">
              <app-select
                [placeholder]="ECarTirePlaceholder.brand"
                [isRequired]="true"
                [label]="ECarTireLabel.brand"
                [options]="params.TireManufacturer"
                formControlName="BrandId"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.diameter.placeholder"
                [isRequired]="true"
                [label]="characteristicList.diameter.label"
                [options]="characteristicList.diameter.options"
                formControlName="diameter"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.height.placeholder"
                [isRequired]="true"
                [label]="characteristicList.height.label"
                [options]="characteristicList.height.options"
                formControlName="height"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.width.placeholder"
                [isRequired]="true"
                [label]="characteristicList.width.name"
                [options]="characteristicList.width.options"
                formControlName="width"
              ></app-select>
            </div>
            <div class="form-group">
              <app-input
                [onlyInteger]="true"
                [isNumber]="true"
                [placeholder]="ECarTirePlaceholder.importerPrice"
                [label]="ECarTireLabel.importerPrice"
                formControlName="BruttoPrice"
              ></app-input>
            </div>
            <div class="form-group tireName">
              <app-input
                [isRequired]="true"
                [placeholder]="ECarTirePlaceholder.name"
                [label]="ECarTireLabel.name"
                formControlName="Name"
              ></app-input>
            </div>
          </div>
          <div class="col-4 border-left">
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.weightCode.placeholder"
                [label]="characteristicList.weightCode.label"
                [options]="characteristicList.weightCode.options"
                formControlName="weightCode"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.speedCode.placeholder"
                [label]="characteristicList.speedCode.label"
                [options]="characteristicList.speedCode.options"
                formControlName="speedCode"
              ></app-select>
            </div>
            <div class="form-group">
              <app-multiple-select
                (selectedValue)="isSelected($event)"
                [placeholder]="ECarTirePlaceholder.type"
                [label]="ECarTireLabel.type"
                [options]="params.CarTireType"
                formControlName="TireTypes"
              ></app-multiple-select>
            </div>
            <div class="form-group" *ngIf="checkSubCategory(params.SubCarTireType)?.length && typeSelect">
              <app-select
                [placeholder]="ECarTirePlaceholder.subcategory"
                [label]="ECarTireLabel.subcategory"
                [options]="checkSubCategory(params.SubCarTireType)"
                formControlName="SubTireTypeId"
              ></app-select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.roadGrip.placeholder"
                [label]="characteristicList.roadGrip.label"
                [options]="characteristicList.roadGrip.options"
                formControlName="roadGrip"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.fuelConsumption.placeholder"
                [label]="characteristicList.fuelConsumption.label"
                [options]="characteristicList.fuelConsumption.options"
                formControlName="fuelConsumption"
              ></app-select>
            </div>
            <div class="form-group">
              <app-select
                [placeholder]="characteristicList.noiseLevel.placeholder"
                [label]="characteristicList.noiseLevel.label"
                [options]="characteristicList.noiseLevel.options"
                formControlName="noiseLevel"
              ></app-select>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="RunFlate" class="form-checkbox">{{'RUN_FLAT' | translate}}</mat-checkbox>
            </div>
          </div>
        </div>
      </form>

    </div>
    <div class="save-btn">
      <button *ngIf="data.modalType === EModalType.add" [ngClass]="!tireForm.valid ? 'disabled' : ''"
              class="btn-admin" (click)="submitForm(EModalType.add)">{{'SAVE' | translate}}
      </button>
      <button *ngIf="data.modalType === EModalType.edit" [ngClass]="!tireForm.valid ? 'disabled' : ''"
              class="btn-admin" (click)="submitForm(EModalType.edit)">{{'SAVE' | translate}}
      </button>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #changeTirePrices>
  <mat-dialog-content>
    <div class="dialog-form-wrap">
      <div class="table-loader" *ngIf="(pricesLoader$ | async) && (params$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <div [formGroup]="priceFrom">
        <div class="form-group row">
          <div class="col-6 form-col" *ngIf="(params$ | async) as params">
            <app-select [label]="ETirePricesLabel.manufacturer" [placeholder]="ETirePricesPlaceholder.manufacturer"
                        [options]="params.TireManufacturer"
                        formControlName="BrandId"></app-select>
          </div>
          <div class="col-6 form-col">
            <app-input [isNumber]="true" [label]="ETirePricesLabel.percent"
                       [placeholder]="ETirePricesPlaceholder.percent"
                       [onlyInteger]="true" formControlName="Percentage"></app-input>
          </div>
        </div>

        <div class="col-6 action-col">
          <app-select [label]="ETirePricesLabel.action" [placeholder]="ETirePricesPlaceholder.action"
                      [options]="priceActions" formControlName="Navigation"></app-select>
        </div>

        <div class="save-btn">
          <button (click)="submitPriceForm()" class="btn-admin" [disabled]="!priceFrom.valid">
            {{'SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
