import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as LeadsActions from "./leads.actions";
import StatisticsApiService from '../../../../api/general-admin-services/statistics.api.service';
import { NotificationService } from '../../../../api/notification.service';

@Injectable()
export class LeadsEffects {
  constructor(private actions$: Actions,
              private alert: NotificationService,
              private statisticService: StatisticsApiService) {
  }

  getLeadsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.getLeadsList),
      switchMap(action =>
        this.statisticService.getLeadsList(action.dateFrom, action.dateTo, action.payload).pipe(
          map(response => LeadsActions.getLeadsListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => {
              this.alert.onError(err.message)
              return of(LeadsActions.getLeadsListFailure({payload: err}))
            }
          )
        )
      )
    )
  )

  getLeadsCountStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.getLeadsCountStatistics),
      switchMap(action =>
        this.statisticService.getStatisticsData(action.params).pipe(
          map(response => LeadsActions.getLeadsCountStatisticsSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(LeadsActions.getLeadsCountStatisticsFailure({payload: err}))
          )
        )
      )
    )
  )

  exportLeadsStatistic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.exportLeadsStatistic),
      switchMap(action =>
        this.statisticService.exportLeadsStatistic(action.params, action.fileName).pipe(
          map(() => LeadsActions.exportLeadsStatisticSuccess()),
          catchError((err: HttpErrorResponse) =>
            of(LeadsActions.exportLeadsStatisticFailure({payload: err}))
          )
        )
      )
    )
  )

  exportLeadsFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.exportLeadsFile),
      switchMap(action =>
        this.statisticService.exportLeadsFile(action.params, action.fileName).pipe(
          map(() => LeadsActions.exportLeadsFileSuccess()),
          catchError((err: HttpErrorResponse) =>
            of(LeadsActions.exportLeadsFileFailure({payload: err}))
          )
        )
      )
    )
  )

  getLeadsCriteria$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.getLeadsCriteria),
      switchMap(() =>
        this.statisticService.getLeadsCriteria().pipe(
          map(response => LeadsActions.getLeadsCriteriaSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(LeadsActions.getLeadsCriteriaFailure({payload: err}))
          )
        )
      )
    )
  )

  changeLeadTWSStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.changeLeadTWSStatus),
      switchMap(action =>
        this.statisticService.changeLeadTWSStatus(action.id, action.status).pipe(
          map(lead => LeadsActions.changeLeadTWSStatusSuccess({payload: lead})),
          catchError((err: HttpErrorResponse) =>
            of(LeadsActions.changeLeadTWSStatusFailure({payload: err}))
          )
        )
      )
    )
  )

  changeLeadAdminStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.changeLeadAdminStatus),
      switchMap(action =>
        this.statisticService.changeLeadAdminStatus(action.id, action.status).pipe(
          map(lead => LeadsActions.changeLeadAdminStatusSuccess({payload: lead})),
          catchError((err: HttpErrorResponse) =>
            of(LeadsActions.changeLeadAdminStatusFailure({payload: err}))
          )
        )
      )
    )
  )

  getStatistic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.getStatistic),
      switchMap(action =>
        this.statisticService.getStatistic(action.params).pipe(
          map((payload) => LeadsActions.getStatisticSuccess({payload})),
          catchError((err: HttpErrorResponse) =>
            of(LeadsActions.getStatisticFailure({payload: err}))
          )
        )
      )
    )
  )

  exportStatisticFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.exportStatisticFile),
      switchMap(action =>
        this.statisticService.exportStatisticFile(action.params, action.fileName).pipe(
          map(() => LeadsActions.exportStatisticFileSuccess()),
          catchError((err: HttpErrorResponse) =>
            of(LeadsActions.exportStatisticFileFailure({payload: err}))
          )
        )
      )
    )
  )
}
