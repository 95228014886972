import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Observable, Subject } from 'rxjs';

import { TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { WeightCodeAddDialogComponent } from "./weight-code-add-dialog/weight-code-add-dialog.component";
import { getWeightCodeData, WeightCodeElement } from "../../../../../modules/state/general-admin/criteria/weight-code";
import { WeightCodeTableMeta } from '../criteria.constants';
import { EModalType, ICriteriaItem, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import {
  getWeightCodeLoader,
  getWeightCodeTable
} from '../../../../../modules/state/general-admin/criteria/weight-code/weight-code.selectors';
import { LanguageService } from '../../../../../api/language.service';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { sortContentByValue } from '../../../../../utils/helpers';

@Component({
  selector: 'app-weight-code',
  templateUrl: './weight-code.component.html',
  styleUrls: ['./weight-code.component.scss']
})
export class WeightCodeComponent implements OnInit, OnDestroy {
  public tableMeta: TableMeta[] = WeightCodeTableMeta;
  public modalType: EModalType = EModalType.add;
  public tableContent$: Observable<WeightCodeElement[]>;
  public isTableLoad$: Observable<boolean>;
  public sorts: string = '';
  public searchControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();
  public searchQuery: string = '';
  public sortContent: Function = sortContentByValue;

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  public openAddDialog(): void {
    this.dialog.open(WeightCodeAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {type: EModalType.add}
    })
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.store$.dispatch(getWeightCodeData());
    this.tableContent$ = this.store$.select(getWeightCodeTable);
    this.isTableLoad$ = this.store$.select(getWeightCodeLoader);
  }

  actionDispatch(table: ITableData<ICriteriaItem>): void {
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete
    }
    this.changeCriteria(table.data);
  }

  changeCriteria(item: ICriteriaItem): void {
    this.dialog.open(WeightCodeAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, type: this.modalType}
    })
  }

  getSortValue(sort: string) {
    this.sorts = sort;
  }

  // sortContent(content: WeightCodeElement[]): WeightCodeElement[] {
  //   let list = [...content];
  //   if (!list.length) return [];
  //   switch (this.sorts) {
  //     case ('value'):
  //       list = list.sort((a, b) => +a.value - +b.value);
  //       break;
  //     case ('-value'):
  //       list = list.sort((a, b) => +b.value - +a.value);
  //       break;
  //     default:
  //       list = [...content];
  //   }
  //   list = list.filter(item => item.value.includes(this.searchQuery));
  //   return list;
  // }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchQuery = value;
      })
  }
}
