import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IBillingOrder, IOrderEdit, IOrderParams, IOrderPaymentDetails, IOrderStatistic } from './orders.model';
import { IResponse } from '../../../../api/api-data/api.interfaces';
import { IStatisticParams } from '../leads/leads.model';

export const GET_ORDERS = '[ORDERS] Get orders list';
export const GET_ORDERS_SUCCESS = '[ORDERS] Get orders list success';
export const GET_ORDERS_FAILED = '[ORDERS] Get orders list failed';
export const CHANGE_ORDER_STATUS = '[ORDERS] Change order status';
export const CHANGE_ORDER_STATUS_SUCCESS = '[ORDERS] Change order status success';
export const CHANGE_ORDER_STATUS_FAILED = '[ORDERS] Change order status failed';
export const CHANGE_BILLING_STATUS = '[ORDERS] Change billing status';
export const CHANGE_BILLING_STATUS_SUCCESS = '[ORDERS] Change billing status success';
export const CHANGE_BILLING_STATUS_FAILED = '[ORDERS] Change billing status failed';

export const GET_PAYMENT_ORDER_LINK = '[ORDERS] Get payment order link';
export const GET_PAYMENT_ORDER_LINK_SUCCESS = '[ORDERS] Get payment order link success';
export const GET_PAYMENT_ORDER_LINK_FAILED = '[ORDERS] Get payment order link failed';

export const EDIT_ORDER = '[ORDERS] Edit order';
export const EDIT_ORDER_SUCCESS = '[ORDERS]  Edit order success';
export const EDIT_ORDER_FAILED = '[ORDERS]  Edit order failed';

export const CHARGE_ORDER = '[ORDERS] Charge order';
export const CHARGE_ORDER_SUCCESS = '[ORDERS]  Charge order success';
export const CHARGE_ORDER_FAILED = '[ORDERS]  Charge order failed';

export const SEND_PAYMENT_LINK = '[ORDERS] Send payment link';
export const SEND_PAYMENT_LINK_SUCCESS = '[ORDERS] Send payment link success';
export const SEND_PAYMENT_LINK_FAILED = '[ORDERS] Send payment link failed';

export const GET_ORDERS_STATISTIC = '[ORDERS] Get order statistic';
export const GET_ORDERS_STATISTIC_SUCCESS = '[ORDERS]  Get order statistic success';
export const GET_ORDERS_STATISTIC_FAILED = '[ORDERS]  Get order statistic failed';
export const EXPORT_ORDERS_STATISTIC = '[ORDERS] Export order statistic';
export const EXPORT_ORDERS_STATISTIC_SUCCESS = '[ORDERS]  Export order statistic success';
export const EXPORT_ORDERS_STATISTIC_FAILED = '[ORDERS]  Export order statistic failed';

export const getOrdersListAction = createAction(
  GET_ORDERS,
  props<{ params: IOrderParams }>()
)

export const getOrdersListSuccessAction = createAction(
  GET_ORDERS_SUCCESS,
  props<{ payload: IResponse<IBillingOrder[]> }>()
)

export const getOrdersListFailureAction = createAction(
  GET_ORDERS_FAILED,
  props<{ payload: HttpErrorResponse }>()
)
export const changeOrderStatus = createAction(
  CHANGE_ORDER_STATUS,
  props<{ id: string | number, status: string }>()
)

export const changeOrderStatusSuccess = createAction(
  CHANGE_ORDER_STATUS_SUCCESS
)

export const changeOrderStatusFailed = createAction(
  CHANGE_ORDER_STATUS_FAILED,
  props<{ payload: HttpErrorResponse }>()
)
export const changeBillingStatus = createAction(
  CHANGE_BILLING_STATUS,
  props<{ id: string | number, status: string }>()
)

export const changeBillingStatusSuccess = createAction(
  CHANGE_BILLING_STATUS_SUCCESS
)

export const changeBillingStatusFailed = createAction(
  CHANGE_BILLING_STATUS_FAILED,
  props<{ payload: HttpErrorResponse }>()
)

export const getPaymentOrderInfo = createAction(
  GET_PAYMENT_ORDER_LINK,
  props<{ orderId: number }>()
)

export const getPaymentOrderInfoSuccess = createAction(
  GET_PAYMENT_ORDER_LINK_SUCCESS,
  props<{ payload: IOrderPaymentDetails }>()
)

export const getPaymentOrderInfoFailed = createAction(
  GET_PAYMENT_ORDER_LINK_FAILED,
  props<{ payload: HttpErrorResponse }>()
)

export const editOrder = createAction(
  EDIT_ORDER,
  props<{ payload: IOrderEdit, id: number, sendSmsAfterEdit: boolean }>()
)

export const editOrderSuccess = createAction(
  EDIT_ORDER_SUCCESS
)

export const editOrderFailed = createAction(
  EDIT_ORDER_FAILED,
  props<{ payload: HttpErrorResponse }>()
)

export const chargeOrder = createAction(
  CHARGE_ORDER,
  props<{ payload: number }>()
)

export const chargeOrderSuccess = createAction(
  CHARGE_ORDER_SUCCESS,
  props<{ payload: { isSuccess: boolean; message: string } }>()
)

export const chargeOrderFailed = createAction(
  CHARGE_ORDER_FAILED,
  props<{ payload: HttpErrorResponse }>()
)

export const sendPaymentLink = createAction(
  SEND_PAYMENT_LINK,
  props<{ orderId: number }>()
)

export const sendPaymentLinkSuccess = createAction(
  SEND_PAYMENT_LINK_SUCCESS,
  props<{ payload: { isSuccess: boolean; message: string } }>()
)

export const sendPaymentLinkFailed = createAction(
  SEND_PAYMENT_LINK_FAILED,
  props<{ payload: HttpErrorResponse }>()
)

export const getOrdersStatistic = createAction(
  GET_ORDERS_STATISTIC,
  props<{ params: IStatisticParams }>()
)

export const getOrdersStatisticSuccess = createAction(
  GET_ORDERS_STATISTIC_SUCCESS,
  props<{ payload: IOrderStatistic }>()
)

export const getOrdersStatisticFailed = createAction(
  GET_ORDERS_STATISTIC_FAILED,
  props<{ payload: HttpErrorResponse }>()
)
export const exportOrdersStatistic = createAction(
  EXPORT_ORDERS_STATISTIC,
  props<{ params: IStatisticParams, fileName: string }>()
)

export const exportOrdersStatisticSuccess = createAction(
  EXPORT_ORDERS_STATISTIC_SUCCESS
)

export const exportOrdersStatisticFailed = createAction(
  EXPORT_ORDERS_STATISTIC_FAILED,
  props<{ payload: HttpErrorResponse }>()
)

