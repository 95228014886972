import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../modules/auth/services/auth.service';
import { UserRoles } from '../../modules/auth/interfaces/auth.interface';
import { NavigationEnd, Router } from '@angular/router';
import { ADMIN_ROUTE_ROOT } from '../../app.constats';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  public userRole: string;

  constructor(public auth: AuthService,
              public router: Router) {
    this.router.events.subscribe((params) => {
      if (params instanceof NavigationEnd) {
        params.url === '/' ? this.router.navigate([`/${ADMIN_ROUTE_ROOT}`, 'statistics']) : null;
      }
    })
  }

  ngOnInit(): void {
    if (!this.auth.isReseller) {
      this.userRole = UserRoles.AdminUser;
      return;
    }
    this.userRole = UserRoles.BusinessUser;
  }
}
