<div class="content manufacturer">
  <div class="content-head">
    <h3 *ngIf="typeId === EBannerTypeId.carTires" class="head-title">{{'TIRES_DESKTOP_BANNERS' | translate}}</h3>
    <h3 *ngIf="typeId === EBannerTypeId.motoTires" class="head-title">{{'MOTORCYCLES_DESKTOP_BANNERS' | translate}}</h3>
    <h3 *ngIf="typeId === EBannerTypeId.rims" class="head-title">{{'RIMS_DESKTOP_BANNERS' | translate}}</h3>
    <!--    <div class="actions">-->
    <!--      <button class="btn-admin" (click)="openAddDialog()">-->
    <!--        <mat-icon>add</mat-icon>-->
    <!--        {{'ADD' | translate}}-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
  <div class="content-body table" *ngIf="(desktopTableContent$ | async) as desktopData">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="desktopData"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [language]="languageService.mainLanguage.value"
      (onClick)="dispatchEvent($event, EBannerType.desktop)"
    >
    </starter-table>
  </div>
</div>

<div class="content manufacturer">
  <div class="content-head">
  <h3 *ngIf="typeId === EBannerTypeId.carTires" class="head-title">{{'TIRES_MOBILE_BANNERS' | translate}}</h3>
  <h3 *ngIf="typeId === EBannerTypeId.motoTires" class="head-title">{{'MOTORCYCLES_MOBILE_BANNERS' | translate}}</h3>
  <h3 *ngIf="typeId === EBannerTypeId.rims" class="head-title">{{'RIMS_MOBILE_BANNERS' | translate}}</h3>
    <!--    <div class="actions">-->
    <!--      <button class="btn-admin" (click)="openAddDialog()">-->
    <!--        <mat-icon>add</mat-icon>-->
    <!--        {{'ADD' | translate}}-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
  <div class="content-body table" *ngIf="(mobileTableContent$ | async) as mobileData">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="mobileData"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [language]="languageService.mainLanguage.value"
      (onClick)="dispatchEvent($event, EBannerType.mobile)"
    >
    </starter-table>
  </div>
</div>




