import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { combineLatest, Subject } from 'rxjs';

import { addAdminNotification } from "../../../modules/state/general-admin/admin-notifications";
import { adminTypesArray, IAdminControlValue } from './admin-notification.constants';
import {
  getNotificationsError,
  getNotificationsSuccessLoader
} from '../../../modules/state/general-admin/admin-notifications/admin-notifications.selectors';
import { takeUntil } from 'rxjs/operators';
import { isEmptyObj } from '../../../utils/helpers';
import { EModalMessage } from '../criteria/components/criteria.constants';
import { NotificationService } from '../../../api/notification.service';

@Component({
  selector: 'app-admin-notifications',
  templateUrl: './admin-notifications.component.html',
  styleUrls: ['./admin-notifications.component.scss']
})
export class AdminNotificationsComponent implements OnInit {
  public notificationsForm: FormGroup;
  public shopTypes = adminTypesArray;
  public destroy$: Subject<void> = new Subject<void>();

  constructor(private store$: Store,
              private fb: FormBuilder,
              private alertService: NotificationService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.notificationsForm = this.fb.group({
      topic: ['', [Validators.required]],
      message: ['', [Validators.required]],
      carMotoTypes: this.fb.array([])
    });
    this.buildFormArray();
  }

  get carMotoTypes(): FormArray {
    return this.notificationsForm.controls["carMotoTypes"] as FormArray;
  }

  buildFormArray(): void {
    this.shopTypes.forEach(option => {
      this.carMotoTypes.push(
        this.fb.group({
          label: [option.label],
          value: [option.value],
          status: [false]
        })
      )
    })
  }

  getNotificationTypes(arr: IAdminControlValue[]): string[] {
    const typesValue: string[] = [];
    arr.filter((item) => item.status).forEach(item => typesValue.push(item.value));
    return typesValue;
  }

  sendForm(): void {
    const newNotification = {
      ...this.notificationsForm.value,
      carMotoTypes: this.getNotificationTypes(this.notificationsForm.value.carMotoTypes)
    };
    this.store$.dispatch(addAdminNotification({payload: newNotification}));
    this.checkRequest(EModalMessage.notificationAdd);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getNotificationsError),
      this.store$.select(getNotificationsSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.notificationsForm.controls['topic'].reset();
            this.notificationsForm.controls['message'].reset();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }
}
