import { createFeatureSelector, createSelector } from "@ngrx/store";
import { HeightElement, HeightState } from "./height.model";
import { HttpErrorResponse } from '@angular/common/http';

export const getHeightState = createFeatureSelector<HeightState>('height');

export const getHeightTable = createSelector(getHeightState,
  (state: HeightState): HeightElement[] => state.content);

export const getHeightSuccessLoader = createSelector(getHeightState,
  (state: HeightState): boolean => state.isLoadingSuccess);

export const getHeightLoader = createSelector(getHeightState,
  (state: HeightState): boolean => state.isLoading);

export const getHeightError = createSelector(getHeightState,
  (state: HeightState): HttpErrorResponse => state.error);
