import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ILeadsState } from './leads.model';

export const getLeadsState = createFeatureSelector<ILeadsState>('leads');

export const getLeadsTableData = createSelector(getLeadsState,
  (state: ILeadsState) => state.response);

export const getLeadsStatistic = createSelector(getLeadsState,
  (state: ILeadsState) => state.leadsStatistics);

export const getCriteriaLeads = createSelector(getLeadsState,
  (state: ILeadsState) => state.leadsCriteria);

export const getLeadsError = createSelector(getLeadsState,
  (state: ILeadsState) => state.error);

export const getLeadsSuccessLoader = createSelector(getLeadsState,
  (state: ILeadsState) => state.isLoadingSuccess);

export const getLeadsLoader = createSelector(getLeadsState,
  (state: ILeadsState) => state.isLoading);

export const selectLeadStatusLoader = createSelector(getLeadsState,
  (state: ILeadsState) => state.status.isLoad);

export const selectLeadsStatusError = createSelector(getLeadsState,
  (state: ILeadsState) => state.status.error);

export const selectStatistic = createSelector(getLeadsState,
  (state: ILeadsState) => state.statistic);

export const getStatisticLoad = createSelector(getLeadsState,
  (state: ILeadsState) => state.isStatisticLoad);

