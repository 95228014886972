import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { fileListToBase64, getBase64 } from '../../utils/helpers';
import { EImgLoaderType, IFileData, IImageFile, IImageSize } from '../../modules/shared/enums/imageLoader.enums';
import { NotificationService } from '../../api/notification.service';
import { EModalMessage } from '../../t4u-admin/pages/criteria/components/criteria.constants';
import { IAdditionalPictures } from '../../modules/state/general-admin/tire-reseller';
import { ImageModeEnum } from '../../modules/shared/enums/imageMode.enums';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit, AfterViewInit {
  @Input() showImage: boolean = false;
  @Input() showRemoveButton: boolean = true;
  @Output() uploadImage = new EventEmitter<File>();
  @Output() uploadImageArray = new EventEmitter<IImageFile[]>();
  @Output() removeImages = new EventEmitter<number[]>();
  @Input() imgSrc: string;
  @Input() previousPictures: IAdditionalPictures[];
  @Input() loaderType: EImgLoaderType = EImgLoaderType.one;
  @Input() isMultiple: boolean = false;
  @Input() imageSize: IImageSize;
  @Input() isBanner: boolean = false;
  public uploadedFile?: File;
  public imagePreview: string = '';
  public loadedImages: string[] = [];
  public imagesList: File[] = [];
  public EImgLoaderType = EImgLoaderType;
  public moreThenThreeFiles: boolean = false;
  public image: IFileData;
  public isImageSizeCorrect: boolean = true;
  public isImageDimensionsCorrect: boolean = true;
  public imagesToRemove: number[] = [];
  public fileAccepting: string[] = ["image/png","image/jpeg"];
  @ViewChild("fileUpload") imgFileRef: ElementRef;

  constructor(private sanitizer: DomSanitizer, private alert: NotificationService) {
  }

  ngOnInit(): void {
    this.getImages();
  }

  ngAfterViewInit() {
    if (this.isBanner) this.fileAccepting = ["image/png","image/jpeg","image/gif"];
  }

  getImages(): void {
    if (this.imgSrc) this.imagePreview = this.imgSrc;
    this.moreThenThreeFiles = this.previousPictures?.length >= 3;
  }

  async uploadImageFile(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (!input.files) return;
    if (input.files.length === 1 && !this.isMultiple) {
      this.uploadedFile = input.files[0];
      this.image = await getBase64(this.uploadedFile, this.imageSize);
      if (!this.image.isCorrectSize && Object.keys(this.imageSize).length) {
        this.isImageDimensionsCorrect = false;
        this.alert.onError(EModalMessage.selectCorrectImageSize);
        return;
      }
      if (this.image?.isCorrectSize) this.isImageDimensionsCorrect = true;
      if (this.uploadedFile.size >= 1000000) {
        this.alert.onError(EModalMessage.imageSizeWrong);
        this.isImageSizeCorrect = false;
        return;
      }
      this.uploadImage.emit(this.uploadedFile);
      this.imagePreview = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.uploadedFile)) as string;
      return;
    }
    const newList = Array.from(input.files);
    if ((newList?.length + this.imagesList.length) > 3) {
      this.alert.onError(EModalMessage.moreThenThreeFiles);
      return;
    }
    this.imagesList = [...this.imagesList, ...Array.from(input.files)];
    const list = this.imagesList.map(file => {return {file, mode: ImageModeEnum.Replace}});
    this.uploadImageArray.emit(list);
    const loaded = await fileListToBase64(this.imagesList);
    this.loadedImages = [...loaded, ...this.loadedImages.filter(img => img.includes('https'))];
    if (this.loadedImages.length + this.previousPictures?.length >= 3) this.moreThenThreeFiles = true;
  }

  removeImage(): void {
    this.uploadedFile = undefined;
    this.uploadImage.emit(this.uploadedFile);
    if (this.imgSrc) {
      this.imagePreview = this.imgSrc;
      return;
    }
    this.imagePreview = '';
  }

  async removeImg(idx: number) {
    this.imagesList.splice(idx, 1);
    this.loadedImages.splice(idx, 1);
    this.loadedImages = await fileListToBase64(this.imagesList);
    const list = this.imagesList.map(file => {return {file, mode: ImageModeEnum.Replace}});
    this.uploadImageArray.emit(list);
    let loaded = await fileListToBase64(this.imagesList);
    this.loadedImages = [...loaded, ...this.loadedImages.filter(img => img.includes('https'))];
    if (this.loadedImages.length + this.previousPictures?.length <= 3) this.moreThenThreeFiles = false;
  }

  removePrevious(id: number): void {
    this.previousPictures = this.previousPictures.filter(img => img.id !== id);
    this.imagesToRemove.push(id);
    this.removeImages.emit(this.imagesToRemove);
    if (this.loadedImages.length + this.previousPictures.length <= 3) this.moreThenThreeFiles = false;
  }
}
