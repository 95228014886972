import { createAction, props } from '@ngrx/store';
import { IManufactureList, ManufacturerElement } from "./manufacturer.model";
import { HttpErrorResponse } from "@angular/common/http";

export const GET_MANUFACTURER_DATA = '[MANUFACTURER] Get data';
export const GET_MANUFACTURER_SUCCESS = '[MANUFACTURER] Get data success';
export const GET_MANUFACTURER_FAILURE = '[MANUFACTURER] Get data failure';

export const ADD_MANUFACTURER_ELEMENT = '[MANUFACTURER] Add element';
export const ADD_MANUFACTURER_ELEMENT_SUCCESS = '[MANUFACTURER] Add element success';
export const ADD_MANUFACTURER_ELEMENT_FAILURE = '[MANUFACTURER] Add element failure';

export const UPDATE_MANUFACTURER_ELEMENT = '[MANUFACTURER] Update element';
export const UPDATE_MANUFACTURER_ELEMENT_SUCCESS = '[MANUFACTURER] Update element success';
export const UPDATE_MANUFACTURER_ELEMENT_FAILURE = '[MANUFACTURER] Update element failure';

export const DELETE_MANUFACTURER_ELEMENT = '[MANUFACTURER] Delete element';
export const DELETE_MANUFACTURER_ELEMENT_SUCCESS = '[MANUFACTURER] Delete element success';
export const DELETE_MANUFACTURER_ELEMENT_FAILURE = '[MANUFACTURER] Delete element failure';

export const SWITCH_MANUFACTURER_STATUS = '[MANUFACTURER] Switch manufacturer status';
export const SWITCH_MANUFACTURER_STATUS_SUCCESS = '[MANUFACTURER] Switch manufacturer status success';
export const SWITCH_MANUFACTURER_STATUS_FAILURE = '[MANUFACTURER] Switch manufacturer status failure';

export const getManufacturerData = createAction(
  GET_MANUFACTURER_DATA,
)

export const getManufacturerDataSuccess = createAction(
  GET_MANUFACTURER_SUCCESS,
  props<{ payload: IManufactureList }>()
)

export const getManufacturerDataFailure = createAction(
  GET_MANUFACTURER_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addManufacturerElement = createAction(
  ADD_MANUFACTURER_ELEMENT,
  props<{ payload: FormData, brandType: string }>()
)

export const addManufacturerElementSuccess = createAction(
  ADD_MANUFACTURER_ELEMENT_SUCCESS,
  props<{ payload: ManufacturerElement }>()
)

export const addManufacturerElementFailure = createAction(
  ADD_MANUFACTURER_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateManufacturerElement = createAction(
  UPDATE_MANUFACTURER_ELEMENT,
  props<{ payload: FormData, id: number, brandType: string }>()
)

export const updateManufacturerElementSuccess = createAction(
  UPDATE_MANUFACTURER_ELEMENT_SUCCESS,
  props<{ payload: ManufacturerElement }>()
)

export const updateManufacturerElementFailure = createAction(
  UPDATE_MANUFACTURER_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteManufacturerElement = createAction(
  DELETE_MANUFACTURER_ELEMENT,
  props<{ payload: number }>()
)

export const deleteManufacturerElementSuccess = createAction(
  DELETE_MANUFACTURER_ELEMENT_SUCCESS
)

export const deleteManufacturerElementFailure = createAction(
  DELETE_MANUFACTURER_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const switchManufacturerStatus = createAction(
  SWITCH_MANUFACTURER_STATUS,
  props<{ id: number }>()
)

export const switchManufacturerStatusSuccess = createAction(
  SWITCH_MANUFACTURER_STATUS_SUCCESS,
  props<{ id: number }>()
)

export const switchManufacturerStatusFailure = createAction(
  SWITCH_MANUFACTURER_STATUS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
