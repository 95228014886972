import {Component, Input, ViewChild} from "@angular/core";
import {Routes} from "@angular/router";
import {MatTabNav} from "@angular/material/tabs";


@Component({
  selector: 'app-nav-tabs',
  templateUrl: 'nav-tabs.component.html',
  styleUrls: ['nav-tabs.component.scss']
})
export class NavTabsComponent{
  @Input() navTabList: Routes = [];
  @ViewChild('navTabs') navTabs!: MatTabNav;

  constructor() {}
}
