import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";
import {
  IArticlePrice,
  IArticlePriceParams,
  IBrandArticlePrice,
  IBrandDiscount,
  IBrandListParams,
  IBusinessTire,
  IExpectedProfit,
  IExpectedProfitParams,
  IInventoryTire, IMultipleSetProfit, IPlateNumber,
  ISetArticlePrice,
  ISetProfit
} from './article-price.model';
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const SET_ARTICLE_PRICE = '[SET_PRICE] Set article price';
export const SET_ARTICLE_PRICE_SUCCESS = '[SET_PRICE] Set article price success';
export const SET_ARTICLE_PRICE_FAILURE = '[SET_PRICE] Set article price failure';
export const GET_ARTICLE_PRICE_BRAND = '[MANAGE_PRICES] Get article prices brand';
export const GET_ARTICLE_PRICE_BRAND_SUCCESS = '[MANAGE_PRICES] Get article prices brand success';
export const GET_ARTICLE_PRICE_BRAND_FAILURE = '[MANAGE_PRICES] Get article prices brand failure';

export const SET_BRAND_DISCOUNT = '[MANAGE_PRICES] Set brand discount';
export const SET_BRAND_DISCOUNT_SUCCESS = '[MANAGE_PRICES] Set brand discount success';
export const SET_BRAND_DISCOUNT_FAILURE = '[MANAGE_PRICES] Set brand discount failure';

export const GET_EXPECTED_PROFIT_LIST = '[EXPECTED_PROFIT] Get expected-profit list';
export const GET_EXPECTED_PROFIT_LIST_SUCCESS = '[EXPECTED_PROFIT] Get expected-profit list success';
export const GET_EXPECTED_PROFIT_LIST_FAILURE = '[EXPECTED_PROFIT] Get expected-profit list failure';

export const SET_EXPECTED_PROFIT = '[EXPECTED_PROFIT] Set brands expected-profit';
export const SET_EXPECTED_PROFIT_SUCCESS = '[EXPECTED_PROFIT] Set brands expected-profit success';
export const SET_EXPECTED_PROFIT_FAILURE = '[EXPECTED_PROFIT] Set brands expected-profit failure';

export const GET_INVENTORY_PRICE = '[MANAGE_PRICES] Get inventory price';
export const GET_INVENTORY_PRICE_SUCCESS = '[MANAGE_PRICES] Get inventory price success';
export const GET_INVENTORY_PRICE_FAILURE = '[MANAGE_PRICES] Get inventory price failure';

export const EDIT_INVENTORY_PRICE = '[MANAGE_PRICES] Edit inventory price';
export const EDIT_INVENTORY_PRICE_SUCCESS = '[MANAGE_PRICES] Edit inventory price success';
export const EDIT_INVENTORY_PRICE_FAILURE = '[MANAGE_PRICES] Edit inventory price failure';

export const GET_LIST_FOR_SETTING_PRICE = '[SET_PRICE] Edit inventory price';
export const GET_LIST_FOR_SETTING_PRICE_SUCCESS = '[SET_PRICE] Edit inventory price success';
export const GET_LIST_FOR_SETTING_PRICE_FAILURE = '[SET_PRICE] Edit inventory price failure';

export const ADD_BUSINESS_MOTO_TIRE = '[INVENTORY_BUSINESS] Add moto tire';
export const ADD_BUSINESS_MOTO_TIRE_SUCCESS = '[INVENTORY_BUSINESS] Add moto tire success';
export const ADD_BUSINESS_MOTO_TIRE_FAILURE = '[INVENTORY_BUSINESS] Add moto tire failure';

export const RESET_ARTICLE_PRICES = '[SET_PRICES] Reset tire prices';
export const RESET_ARTICLE_PRICES_SUCCESS = '[SET_PRICES] Reset tire prices success';
export const RESET_ARTICLE_PRICES_FAILURE = '[SET_PRICES] Reset tire prices failure';

export const RESET_INVENTORY = '[INVENTORY_SET_UP] Reset inventory';
export const RESET_INVENTORY_SUCCESS = '[INVENTORY_SET_UP] Reset inventory success';
export const RESET_INVENTORY_FAILURE = '[INVENTORY_SET_UP] Reset inventory failure';

export const SET_MULTIPLE_EXPECTED_PROFIT = '[EXPECTED_PROFIT] Set multiple brands expected-profit';
export const SET_MULTIPLE_EXPECTED_PROFIT_SUCCESS = '[EXPECTED_PROFIT] Set multiple brands expected-profit success';
export const SET_MULTIPLE_EXPECTED_PROFIT_FAILURE = '[EXPECTED_PROFIT] Set multiple brands expected-profit failure';

export const GET_CARS_BY_PLATE = '[CONSUMER_LIST] Get cars by plate number';
export const GET_CARS_BY_PLATE_SUCCESS = '[CONSUMER_LIST] Get cars by plate number success';
export const GET_CARS_BY_PLATE_FAILURE = '[CONSUMER_LIST] Get cars by plate number failure';

export const GET_CONSUMER_PRICE_LIST = '[CONSUMER_LIST] Get consumer price list';
export const GET_CONSUMER_PRICE_LIST_SUCCESS = '[CONSUMER_LIST] Get consumer price list success';
export const GET_CONSUMER_PRICE_LIST_FAILURE = '[CONSUMER_LIST] Get consumer price list failure';

export const REMOVE_SUITABLE_PLATES = '[CONSUMER_LIST] remove suitable plates';

export const setArticlePrice = createAction(
  SET_ARTICLE_PRICE,
  props<{ payload: ISetArticlePrice }>()
)

export const setArticlePriceSuccess = createAction(
  SET_ARTICLE_PRICE_SUCCESS,
  props<{ payload: IArticlePrice }>()
)

export const setArticlePriceFailure = createAction(
  SET_ARTICLE_PRICE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getArticleBrandList = createAction(
  GET_ARTICLE_PRICE_BRAND,
  props<{ payload: IBrandListParams }>()
)

export const getArticleBrandListSuccess = createAction(
  GET_ARTICLE_PRICE_BRAND_SUCCESS,
  props<{ payload: IBrandArticlePrice[] }>()
)

export const getArticleBrandListFailure = createAction(
  GET_ARTICLE_PRICE_BRAND_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const setBrandDiscount = createAction(
  SET_BRAND_DISCOUNT,
  props<{ discount: IBrandDiscount }>()
)

export const setBrandDiscountSuccess = createAction(
  SET_BRAND_DISCOUNT_SUCCESS,
  props<{ payload: IBrandArticlePrice }>()
)

export const setBrandDiscountFailure = createAction(
  SET_BRAND_DISCOUNT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getExpectedProfit = createAction(
  GET_EXPECTED_PROFIT_LIST,
  props<{ params: IExpectedProfitParams }>()
)

export const getExpectedProfitSuccess = createAction(
  GET_EXPECTED_PROFIT_LIST_SUCCESS,
  props<{ payload: IExpectedProfit[] }>()
)

export const getExpectedProfitFailure = createAction(
  GET_EXPECTED_PROFIT_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const setExpectedProfit = createAction(
  SET_EXPECTED_PROFIT,
  props<{ profit: ISetProfit }>()
)

export const setExpectedProfitSuccess = createAction(
  SET_EXPECTED_PROFIT_SUCCESS,
  props<{ profit: IExpectedProfit }>()
)

export const setExpectedProfitFailure = createAction(
  SET_EXPECTED_PROFIT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getInventoryPrice = createAction(
  GET_INVENTORY_PRICE,
  props<{ params: IArticlePriceParams }>()
)

export const getInventoryPriceSuccess = createAction(
  GET_INVENTORY_PRICE_SUCCESS,
  props<{ payload: IResponse<IInventoryTire[]> }>()
)

export const getInventoryPriceFailure = createAction(
  GET_INVENTORY_PRICE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const editInventoryPrice = createAction(
  EDIT_INVENTORY_PRICE,
  props<{ id: number, element: IBusinessTire }>()
)

export const editInventoryPriceSuccess = createAction(
  EDIT_INVENTORY_PRICE_SUCCESS,
  props<{ tire: IInventoryTire, id: number }>()
)

export const editInventoryPriceFailure = createAction(
  EDIT_INVENTORY_PRICE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getListForSettingPrice = createAction(
  GET_LIST_FOR_SETTING_PRICE,
  props<{ params: IArticlePriceParams }>()
)

export const getListForSettingPriceSuccess = createAction(
  GET_LIST_FOR_SETTING_PRICE_SUCCESS,
  props<{ payload: IResponse<IInventoryTire[]> }>()
)

export const getListForSettingPriceFailure = createAction(
  GET_LIST_FOR_SETTING_PRICE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addBusinessMotoTire = createAction(
  ADD_BUSINESS_MOTO_TIRE,
  props<{ payload: FormData }>()
)

export const addBusinessMotoTireSuccess = createAction(
  ADD_BUSINESS_MOTO_TIRE_SUCCESS,
  props<{ payload: IInventoryTire }>()
)

export const addBusinessMotoTireFailure = createAction(
  ADD_BUSINESS_MOTO_TIRE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const resetTirePrices = createAction(
  RESET_ARTICLE_PRICES,
  props<{ payload: number[] }>()
)

export const resetTirePricesSuccess = createAction(
  RESET_ARTICLE_PRICES_SUCCESS,
)

export const resetTirePricesFailure = createAction(
  RESET_ARTICLE_PRICES_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const resetInventory = createAction(
  RESET_INVENTORY,
  props<{ payload: number[] }>()
)

export const resetInventorySuccess = createAction(
  RESET_INVENTORY_SUCCESS,
)

export const resetInventoryFailure = createAction(
  RESET_INVENTORY_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const setMultipleExpectedProfit = createAction(
  SET_MULTIPLE_EXPECTED_PROFIT,
  props<{ profit: IMultipleSetProfit }>()
)

export const setMultipleExpectedProfitSuccess = createAction(
  SET_MULTIPLE_EXPECTED_PROFIT_SUCCESS,
  props<{ profit: any }>()
)

export const setMultipleExpectedProfitFailure = createAction(
  SET_MULTIPLE_EXPECTED_PROFIT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getCarsByPlateNumber = createAction(
  GET_CARS_BY_PLATE,
  props<{ payload: string }>()
)

export const getCarsByPlateNumberSuccess = createAction(
  GET_CARS_BY_PLATE_SUCCESS,
  props<{ payload: IPlateNumber[] }>()
)

export const getCarsByPlateNumberFailure = createAction(
  GET_CARS_BY_PLATE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getConsumePriceList = createAction(
  GET_CONSUMER_PRICE_LIST,
  props<{ params: IArticlePriceParams }>()
)

export const getConsumePriceListSuccess = createAction(
  GET_CONSUMER_PRICE_LIST_SUCCESS,
  props<{ payload: IResponse<IInventoryTire[]> }>()
)

export const getConsumePriceListFailure = createAction(
  GET_CONSUMER_PRICE_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const removeSuitablePlates = createAction(
  REMOVE_SUITABLE_PLATES
)
