import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from 'rxjs';
import { Store } from "@ngrx/store";

import { TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { DiameterAddDialogComponent } from "./diameter-add-dialog/diameter-add-dialog.component";
import { DiameterElement, getDiameterData } from "../../../../../modules/state/general-admin/criteria/diameter";
import {
  getDiameterLoader,
  getDiameterTable
} from "../../../../../modules/state/general-admin/criteria/diameter/diameter.selectors";
import { EModalType, ICriteriaItem, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import { radiusTableMeta } from '../criteria.constants';
import { LanguageService } from '../../../../../api/language.service';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { sortContentByValue } from '../../../../../utils/helpers';

@Component({
  selector: 'app-diameter',
  templateUrl: './diameter.component.html',
  styleUrls: ['./diameter.component.scss']
})
export class DiameterComponent implements OnInit, OnDestroy {
  public modalType: EModalType = EModalType.add;
  public tableContent$: Observable<DiameterElement[]>;
  public tableMeta: TableMeta[] = radiusTableMeta;
  public isTableLoad$: Observable<boolean>;
  public sorts: string = '';
  public searchControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();
  public searchQuery: string = '';
  public currentPage: number = 1;
  public sortContent: Function = sortContentByValue;

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  public openAddDialog(): void {
    this.dialog.open(DiameterAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {type: EModalType.add}
    })
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.store$.dispatch(getDiameterData());
    this.tableContent$ = this.store$.select(getDiameterTable);
    this.isTableLoad$ = this.store$.select(getDiameterLoader);
  }

  actionDispatch(table: ITableData<ICriteriaItem>): void {
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete
    }
    this.changeCriteria(table.data);
  }

  changeCriteria(item: ICriteriaItem): void {
    this.dialog.open(DiameterAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, type: this.modalType}
    })
  }

  sortValue(sort: string): void {
    this.sorts = sort;
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchQuery = value;
      })
  }
}
