import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { getInventoryPrice, IInventoryTire, resetInventory } from '../../../modules/state/business-user/article-price';
import { IFilters, IHeadFilters, IResponse } from '../../../api/api-data/api.interfaces';
import {
  CellTypes,
  IFilterValue,
  ISelectFormValue,
  TableMeta
} from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { InventorySetUpTableMeta } from '../../business-user.constants';
import { LanguageService } from '../../../api/language.service';
import {
  selectArticlePriceLoader,
  selectInventoryPriceList,
  selectResetInventoryError,
  selectResetInventorySuccessLoader
} from '../../../modules/state/business-user/article-price/article-price.selectors';
import { getFilterQuery, isEmptyObj } from '../../../utils/helpers';
import { EModalType, IFilterChips, ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { InventorySetUpDialogComponent } from './inventory-set-up-dialog/inventory-set-up-dialog.component';
import { getCharacteristicOptionsList, getLookupParameters } from '../../../modules/state/general-admin/characteristic';
import { EPricesTitle } from '../../business-user.enums';
import { getTireResellerById } from '../../../modules/state/general-admin/tire-reseller';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EModalMessage } from '../../../t4u-admin/pages/criteria/components/criteria.constants';
import { NotificationService } from '../../../api/notification.service';

@Component({
  selector: 'app-inventory-set-up',
  templateUrl: './inventory-set-up.component.html',
  styleUrls: ['./inventory-set-up.component.scss']
})
export class InventorySetUpComponent implements OnInit, OnDestroy {
  public currentPage: number = 1;
  public inventoryList$: Observable<IResponse<IInventoryTire[]>>;
  public inventoryTableMeta: TableMeta[] = InventorySetUpTableMeta;
  public filtersData: IFilters = {};
  public filters: string = '';
  public isTableLoad$: Observable<boolean>;
  public activeRoute: string;
  public mainTitle: string = EPricesTitle.inventoryCar;
  public sortingData: string[] = [];
  public sorts: string = 'afterVat';
  public vehicleType: string;
  public searchControl: FormControl = new FormControl('');
  public searchQuery: string = '';
  public destroy$: Subject<void> = new Subject<void>();
  public articleIds: number[] = [];
  public clearSelection: boolean = false;
  public isOnlyINV: boolean = false;
  public userId: string;
  public isINVNotDisplay: boolean = false;
  public formValue: ISelectFormValue = {};
  public chipsValue: IFilterChips = {};

  constructor(private store$: Store,
              private dialog: MatDialog,
              public route: ActivatedRoute,
              private auth: AuthService,
              private alertService: NotificationService,
              private router: Router,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.userId = this.auth.reseller?.tireResellerId;
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    this.activeRoute = this.route.snapshot.url[0].path;
    if (this.auth.reseller.vehicleType === 0) this.vehicleType = 'car';
    if (this.auth.reseller.vehicleType === 1) this.vehicleType = 'motorcycle';
    this.store$.dispatch(getCharacteristicOptionsList({id: '1,2,4,5,7,8,9,12'}));
    this.store$.dispatch(getLookupParameters({payload: 'TireManufacturer,Manufacture,TireType,SubCarTireType'}));
    this.store$.dispatch(getTireResellerById({payload: this.userId}));
    this.inventoryList$ = this.store$.select(selectInventoryPriceList);
    this.isTableLoad$ = this.store$.select(selectArticlePriceLoader);
    this.filters = getFilterQuery(this.filtersData);
    this.getInventoryList();
    if (this.activeRoute.includes('motorcycles')) this.mainTitle = EPricesTitle.inventoryMoto;
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.getInventoryList();
  }

  getInventoryList(): void {
    this.store$.dispatch(getInventoryPrice({
      params: {
        page: this.currentPage,
        sorts: this.sorts,
        filters: this.filters,
        VehicleType: this.vehicleType,
        search: this.searchQuery,
        isOnlyInventory: this.isOnlyINV,
        isInventoryNotDisplay: this.isINVNotDisplay
      }
    }));
  }

  openAddDialog(): void {
    this.dialog.open(InventorySetUpDialogComponent, {
      panelClass: 'admin-dialog',
      width: '970px',
      minHeight: '300px',
      data: {
        page: this.currentPage,
        search: this.searchQuery,
        modalType: EModalType.add, route: this.activeRoute,
        invOnly: this.isOnlyINV,
        invNotDisplay: this.isINVNotDisplay,
        apiFilters: this.filters,
      }
    })
  }

  actionDispatch(table: ITableData<IInventoryTire>): void {
    if (table.source === CellTypes.ClickableString) {
      this.dialog.open(InventorySetUpDialogComponent, {
        panelClass: 'admin-dialog',
        data: {
          ...table.data, modalType: EModalType.editPrice,
          search: this.searchQuery,
          invOnly: this.isOnlyINV,
          invNotDisplay: this.isINVNotDisplay,
          apiFilters: this.filters,
          page: this.currentPage,
        },
        width: '570px',
        minHeight: '300px',
      })
      return;
    }
    this.dialog.open(InventorySetUpDialogComponent, {
      panelClass: 'admin-dialog',
      data: {
        ...table.data, modalType: EModalType.edit,
        search: this.searchQuery,
        invOnly: this.isOnlyINV,
        invNotDisplay: this.isINVNotDisplay,
        apiFilters: this.filters,
        page: this.currentPage
      },
      width: '570px',
      minHeight: '300px'
    })
  }

  getTableFilters(event: IFilterValue): void {
    this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
    if (!event.checkedIds.length) delete this.filtersData[event.filterKey];
    this.filters = getFilterQuery(this.filtersData);
    this.getInventoryList();
  }

  sortData(sortValue: string): void {
    if (sortValue.includes('-')) {
      this.sortingData = this.sortingData.filter(item => item !== sortValue.slice(1));
    } else {
      this.sortingData = this.sortingData.filter(item => item !== `-${sortValue}`);
    }
    this.sortingData.push(sortValue);
    this.sorts = this.sortingData.join();
    this.getInventoryList();
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchQuery = value;
        this.currentPage = 1;
        this.getInventoryList();
      })
  }

  getCheckedTires(rows: IInventoryTire[]): void {
    const articleIds: number[] = [];
    rows.forEach(item => articleIds.push(item.id));
    this.articleIds = articleIds;
  }

  resetInventory(): void {
    if (!this.articleIds.length) {
      this.dialog.open(InventorySetUpDialogComponent, {
        panelClass: 'admin-dialog',
        width: '670px',
        data: {
          modalType: EModalType.delete,
          route: this.activeRoute,
          search: this.searchQuery,
          invOnly: this.isOnlyINV,
          invNotDisplay: this.isINVNotDisplay,
          page: this.currentPage
        }
      })
      return;
    }
    this.store$.dispatch(resetInventory({payload: this.articleIds}));
    this.checkRequest(EModalMessage.resetInventory);
    this.clearSelection = true;
    this.getInventoryList();
    this.articleIds = [];
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(selectResetInventoryError),
      this.store$.select(selectResetInventorySuccessLoader)
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
            this.clearSelection = false;
          }
          if (!loader && error && !isEmptyObj(error)) {
            const errorObj = error.error.errors;
            const failuresObj = error.error.failures;
            const errors = [];
            for (const key in errorObj) errors.push(errorObj[key][0]);
            for (const key in failuresObj) errors.push(failuresObj[key][0]);
            if (!errors.length) errors.push(error.statusText);
            this.alertService.onError(errors);
          }
        }
      })
  }

  getINVOnlyTires(): void {
    this.isOnlyINV = !this.isOnlyINV;
    this.isINVNotDisplay = false;
    this.currentPage = 1;
    this.getInventoryList();
  }

  getINVNotDisplayTires(): void {
    this.isINVNotDisplay = !this.isINVNotDisplay;
    this.isOnlyINV = false;
    this.currentPage = 1;
    this.getInventoryList();
  }

  createChips(event: IFilterValue): void {
    if (!event.checkedIds?.length) {
      delete this.chipsValue[event.filterKey];
      return;
    }
    this.chipsValue[event.filterKey] = event.checkedIds.join(', ');
    this.chipsValue = {...this.chipsValue};
  }

  removeFilter(key: string): void {
    delete this.chipsValue[key];
    for (const key in this.filtersData) {
      this.formValue = {...this.formValue, [key]: []}
    }
    delete this.filtersData[key];
    this.filters = getFilterQuery(this.filtersData);
    this.getInventoryList();
  }

  resetAllFilters(): void {
    this.isOnlyINV = this.isINVNotDisplay = false;
    for (const key in this.filtersData) {
      this.formValue = {...this.formValue, [key]: []}
    }
    this.filtersData = {};
    this.filters = getFilterQuery(this.filtersData);
    this.currentPage = 1;
    this.chipsValue = {};
    this.getInventoryList();
  }

  getHeadFilters(filters: IHeadFilters): void {
    for (const key in filters) {
      // @ts-ignore
      this.filtersData[key] = {value: [filters[key]], sign: '=='};
      if (!filters[key]) delete this.filtersData[key];
    }
    this.filters = getFilterQuery(this.filtersData);
    this.currentPage = 1;
    this.getInventoryList();
  }
}
