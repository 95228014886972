import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';

import * as PaymentsActions from './payments.actions';
import { IPaymentsLeadsCount, IPaymentsState } from './payments.model';

export const initialState: IPaymentsState = {
  paymentsLeads: {} as IPaymentsLeadsCount,
  goldLeadsStatistics: {
    list: {
      totalPrice: 0,
      totalGoldPrice: 0,
      result: []
    },
    isLoader: false,
    isLoadSuccess: false,
    error: {} as HttpErrorResponse
  },
  monthlyLeadsList: {
    list: {
      totalAmount: 0,
      totalAmountVat: 0,
      uniqueTotalAmount: 0,
      uniqueTotalAmountVat: 0,
      result: []
    },
    isLoader: false,
    isLoadSuccess: false,
    error: {} as HttpErrorResponse
  },
  goldLeadsPerMonth: {
    list: {} as IPaymentsLeadsCount,
    isLoader: false,
    isLoadSuccess: false,
    error: {} as HttpErrorResponse
  },
  goldDetailsByMonth: {
    list: {
      totalPrice: 0,
      totalGoldPrice: 0,
      result: []
    },
    isLoader: false,
    isLoadSuccess: false,
    error: null
  },
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse
};

const paymentsReducer = createReducer(
  initialState,
  on(PaymentsActions.getGoldLeadsStatistic, (state) => ({
    ...state,
    goldLeadsStatistics: {
      ...state.goldLeadsStatistics,
      isLoader: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(PaymentsActions.getGoldLeadsStatisticsSuccess, (state, {payload}) => ({
    ...state,
    goldLeadsStatistics: {
      ...state.goldLeadsStatistics,
      list: payload,
      isLoader: false,
      isLoadSuccess: true,
      error: {} as HttpErrorResponse
    }
  })),
  on(PaymentsActions.getGoldLeadsStatisticsFailure, (state, {payload}) => ({
    ...state,
    goldLeadsStatistics: {
      ...state.goldLeadsStatistics,
      isLoader: false,
      isLoadSuccess: false,
      error: payload
    }
  })),
  on(PaymentsActions.getGoldLeadsPerMonth, (state) => ({
    ...state,
    goldLeadsPerMonth: {
      ...state.goldLeadsPerMonth,
      isLoader: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(PaymentsActions.getGoldLeadsPerMonthSuccess, (state, {payload}) => ({
    ...state,
    goldLeadsPerMonth: {
      ...state.goldLeadsPerMonth,
      list: payload,
      isLoader: false,
      isLoadSuccess: true,
      error: {} as HttpErrorResponse
    }
  })),
  on(PaymentsActions.getGoldLeadsPerMonthFailure, (state, {payload}) => ({
    ...state,
    goldLeadsPerMonth: {
      ...state.goldLeadsPerMonth,
      isLoader: false,
      isLoadSuccess: false,
      error: payload
    }
  })),
  on(PaymentsActions.exportPaymentsStatistic, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(PaymentsActions.exportPaymentsStatisticSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(PaymentsActions.exportPaymentsStatisticFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(PaymentsActions.getMonthlyLeadsList, (state) => ({
    ...state,
    monthlyLeadsList: {
      ...state.monthlyLeadsList,
      isLoader: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(PaymentsActions.getMonthlyLeadsListSuccess, (state, {payload}) => ({
    ...state,
    monthlyLeadsList: {
      list: payload,
      isLoader: false,
      isLoadSuccess: true,
      error: {} as HttpErrorResponse
    }
  })),
  on(PaymentsActions.getMonthlyLeadsListFailure, (state, {payload}) => ({
    ...state,
    monthlyLeadsList: {
      ...state.monthlyLeadsList,
      isLoader: false,
      isLoadSuccess: false,
      error: payload
    }
  })),
  on(PaymentsActions.getPaymentsLeadsCount, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(PaymentsActions.getPaymentsLeadsCountSuccess, (state, {payload}) => ({
    ...state,
    paymentsLeads: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(PaymentsActions.getPaymentsLeadsCountFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(PaymentsActions.changeDealerShipStatus, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(PaymentsActions.changeDealerShipStatusSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse
  })),
  on(PaymentsActions.changeDealerShipStatusFailure, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(PaymentsActions.getGoldDetailsByMonth, (state) => ({
    ...state,
    goldDetailsByMonth: {
      ...state.goldDetailsByMonth,
      isLoader: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(PaymentsActions.getGoldDetailsByMonthSuccess, (state, {payload}) => ({
    ...state,
    goldDetailsByMonth: {
      ...state.goldDetailsByMonth,
      list: payload,
      isLoader: false,
      isLoadSuccess: true,
      error: {} as HttpErrorResponse
    }
  })),
  on(PaymentsActions.getGoldDetailsByMonthFailure, (state, {payload}) => ({
    ...state,
    goldDetailsByMonth: {
      ...state.goldDetailsByMonth,
      isLoader: false,
      isLoadSuccess: false,
      error: payload
    }
  })),
);

export function PaymentsReducer(state: IPaymentsState | undefined, action: Action): any {
  return paymentsReducer(state, action);
}
