import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

import { HeightElement } from "./height.model";

export const GET_HEIGHT_DATA = '[HEIGHT] Get data';
export const GET_HEIGHT_SUCCESS = '[HEIGHT] Get data success';
export const GET_HEIGHT_FAILURE = '[HEIGHT] Get data failure';

export const ADD_HEIGHT_ELEMENT = '[HEIGHT] Add element';
export const ADD_HEIGHT_ELEMENT_SUCCESS = '[HEIGHT] Add element success';
export const ADD_HEIGHT_ELEMENT_FAILURE = '[HEIGHT] Add element failure';

export const UPDATE_HEIGHT_ELEMENT = '[HEIGHT] Update element';
export const UPDATE_HEIGHT_ELEMENT_SUCCESS = '[HEIGHT] Update element success';
export const UPDATE_HEIGHT_ELEMENT_FAILURE = '[HEIGHT] Update element failure';

export const DELETE_HEIGHT_ELEMENT = '[HEIGHT] Delete element';
export const DELETE_HEIGHT_ELEMENT_SUCCESS = '[HEIGHT] Delete element success';
export const DELETE_HEIGHT_ELEMENT_FAILURE = '[HEIGHT] Delete element failure';

export const getHeightData = createAction(
  GET_HEIGHT_DATA,
)

export const getHeightDataSuccess = createAction(
  GET_HEIGHT_SUCCESS,
  props<{ payload: HeightElement[] }>()
)

export const getHeightDataFailure = createAction(
  GET_HEIGHT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addHeightElement = createAction(
  ADD_HEIGHT_ELEMENT,
  props<{ payload: HeightElement }>()
)

export const addHeightElementSuccess = createAction(
  ADD_HEIGHT_ELEMENT_SUCCESS,
  props<{ payload: HeightElement }>()
)

export const addHeightElementFailure = createAction(
  ADD_HEIGHT_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateHeightElement = createAction(
  UPDATE_HEIGHT_ELEMENT,
  props<{ payload: HeightElement, id: number }>()
)

export const updateHeightElementSuccess = createAction(
  UPDATE_HEIGHT_ELEMENT_SUCCESS,
  props<{ payload: HeightElement }>()
)

export const updateHeightElementFailure = createAction(
  UPDATE_HEIGHT_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteHeightElement = createAction(
  DELETE_HEIGHT_ELEMENT,
  props<{ payload: number }>()
)

export const deleteHeightElementSuccess = createAction(
  DELETE_HEIGHT_ELEMENT_SUCCESS
)

export const deleteHeightElementFailure = createAction(
  DELETE_HEIGHT_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
