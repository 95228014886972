import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {
  IArticlePrice,
  IArticlePriceParams,
  IBrandArticlePrice,
  IBrandDiscount,
  IBrandListParams,
  IBusinessTire,
  IExpectedProfit,
  IExpectedProfitParams,
  IInventoryTire,
  IMultipleSetProfit,
  IPlateNumber,
  ISetArticlePrice,
  ISetProfit
} from '../../modules/state/business-user/article-price';
import { IResponse } from '../api-data/api.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ArticlePriceService {
  readonly PATH = '/articleprice';

  constructor(private apiService: ApiService) {
  }

  setSitePrice(params: ISetArticlePrice): Observable<IArticlePrice> {
    return this.apiService.post(`${this.PATH}`, params);
  }

  getBrandsDiscountList(params: IBrandListParams): Observable<IBrandArticlePrice[]> {
    return this.apiService.post(`${this.PATH}/branddiscount`, params);
  }

  setBrandDiscount(params: IBrandDiscount): Observable<IBrandArticlePrice> {
    return this.apiService.post(`${this.PATH}/brand`, params);
  }

  getExpectedProfit(params: IExpectedProfitParams): Observable<IExpectedProfit[]> {
    return this.apiService.get(`${this.PATH}/profit`, {...params});
  }

  setExpectedProfit(profit: ISetProfit): Observable<IExpectedProfit> {
    return this.apiService.post(`${this.PATH}/profit`, profit);
  }

  setMultipleExpectedProfit(profit: IMultipleSetProfit): Observable<IExpectedProfit> {
    return this.apiService.post(`${this.PATH}/brandexpectedprofit`, profit);
  }

  getInventoryPrice(params: IArticlePriceParams): Observable<IResponse<IInventoryTire[]>> {
    return this.apiService.get(`${this.PATH}/inventory`, {...params});
  }

  editInventoryPrice(id: number, tire: IBusinessTire): Observable<IInventoryTire> {
    return this.apiService.post(`${this.PATH}/inventory/${id}`, {...tire});
  }

  getSettingPriceList(params: IArticlePriceParams): Observable<IResponse<IInventoryTire[]>> {
    return this.apiService.get(`${this.PATH}/setpriceiinventory`, {...params});
  }

  resetPrices(params: { articleIds: number[] }): Observable<any> {
    return this.apiService.post(`${this.PATH}/resetsiteprice`, params);
  }

  resetInventory(params: { articleIds: number[] }): Observable<any> {
    return this.apiService.post(`${this.PATH}/resetinventoryprice`, params);
  }

  getCarsByPlate(plateNumber: string): Observable<IPlateNumber[]> {
    return this.apiService.get(`/carsearch/platenumber/${plateNumber}`);
  }

  getConsumerList(params: IArticlePriceParams): Observable<IResponse<IInventoryTire[]>> {
    return this.apiService.get(`${this.PATH}/customerprice`, {...params});
  }
}
