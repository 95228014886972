export const ADMIN_ROUTE_ROOT = 'admin';
export const BUSINESS_ROUTE_ROOT = 'business';
export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
  // parse: {
  //   dateInput: 'LL',
  // },
  // display: {
  //   dateInput: 'DD/MM/YYYY',
  //   monthYearLabel: 'MMMM YYYY',
  //   dateA11yLabel: 'LL',
  //   monthYearA11yLabel: 'MMMM YYYY'
  // },
};
