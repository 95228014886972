<div class="content statistics" dir="rtl">
  <div class="content-head">
    <div class="head-title">{{'STATISTICS' | translate}}</div>
    <div class="btn-block">
      <button (click)="export()" class="btn-admin btn-white export-btn">
        <mat-icon class="icon-img" svgIcon="upload"></mat-icon>
        {{'EXPORT' | translate}}
      </button>
      <button (click)="print()" class="btn-admin btn-white export-btn">
        <mat-icon class="icon-img" svgIcon="print"></mat-icon>
        {{'PRINT' | translate}}
      </button>
    </div>
  </div>
  <div class="content-body statistic-count" [formGroup]="filterGroup">
    <div class="title-block">
      <div class="title">{{mainTitle | translate}}</div>
      <div class="btn-block" *ngIf="(params$ | async) as params">
        <div class="select">
          <div>{{'TWS' | translate}}</div>
          <div class="input">
            <mat-select formControlName="tireResellerId" placeholder="{{'SELECT_TWS' | translate}}">
              <mat-option [value]="allTWS">{{allTWS.value | translate}}</mat-option>
              <mat-option
                *ngFor="let reseller of sortContent(params.TWSName)"
                [value]="reseller"
              >{{ reseller.value }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="select">
          <div>{{'PERIOD' | translate}}</div>
          <div class="input">
            <mat-date-range-input [rangePicker]="picker" [max]="today">
              <input matStartDate formControlName="dateFrom" placeholder="{{'START_DATE' | translate}}">
              <input matEndDate formControlName="dateTo" placeholder="{{'END_DATE' | translate}}">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
        </div>
        <div class="period">
          <div>{{'PERIOD' | translate}}</div>
          <div>{{convertTime(apiParams?.dateFrom)}}-{{convertTime(this.apiParams?.dateTo)}}</div>
        </div>
      </div>
    </div>
    <div class="leads-wrap">
<!--      <div class="table-loader" *ngIf="(isLoad$ | async)">-->
<!--        <mat-spinner></mat-spinner>-->
<!--      </div>-->
      <div class="info-cards" *ngIf="(statistic$ | async) as list">
        <div class="card" *ngFor="let item of list.leads" [ngClass]="{'item-virtual': item.virtualNumber}">
          <div class="name">{{item.leadsTitle | translate}}</div>
          <div class="card-info">
            <div class="count">{{item.leadsCost | currency : 'ILS' : 'symbol': '1.0-1'}}</div>
            <div *ngIf="isOneReseller && item.leadsTitle === EStatisticTitle.gold" class="link" (click)="openDetails()">{{'PRICE_DETAILS' | translate}}</div>
            <span [ngClass]="isOneReseller ? 'alone' : ''"></span>
            <div class="count">
              {{item.leadsNumber | number}}
              <span *ngIf="!!item.uniqueLeadsNumber" class="count-number">({{item.uniqueLeadsNumber | number}})</span>
              <span *ngIf="!!item.nettoLeadsNumber" class="count-number">({{item.nettoLeadsNumber | number}})</span>
              <mat-icon *ngIf="!!item.nettoLeadsNumber" matTooltip="{{'NETTO_LEAD_NOTIFICATION' | translate}}"
                        matTooltipPosition="above"
                        class="info">info
              </mat-icon>
            </div>
          </div>
            <div class="virtual-phone" *ngIf="item.virtualNumber">{{changeVirtualNumber(item.virtualNumber)}}</div>
        </div>
        <div class="card total">
          <div class="name">{{EStatisticTitle.totalBalance | translate}}</div>
          <div class="card-info">
            <div class="count">
              {{list.total.leadsNumber | number}}
              <span class="count-number">({{list.total.uniqueLeadsNumber | number}})</span>
              <mat-icon *ngIf="!!list.total.leadsNumber" matTooltip="{{'DUPLICATED_LEAD_NOTIFICATION' | translate}}"
                        matTooltipPosition="above"
                        class="info">info
              </mat-icon>
            </div>
            <span class="alone"></span>
            <div class="count">{{list.total.uniqueLeadBalanceBeforeVat.toFixed(0) | currency : 'ILS' : 'symbol': '1.0-1'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
