import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { DiameterElement, DiameterState } from './diameter.model';
import * as diameterActions from './diameter.actions';

export const initialState: DiameterState = {
  current: {} as DiameterElement,
  deleteId: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  content: [],
  error: {} as HttpErrorResponse,
};

const diameterReducer = createReducer(
  initialState,
  on(diameterActions.getDiameterData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
    content: [],
  })),
  on(diameterActions.getDiameterDataSuccess, (state, payload) => ({
    ...state,
    content: payload.payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(diameterActions.getDiameterDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(diameterActions.addDiameterElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    isLoading: true,
    error: {} as HttpErrorResponse,
  })),
  on(diameterActions.addDiameterElementSuccess, (state, {payload}) => {
    return {
      ...state,
      content: [...state.content, payload],
      current: {} as DiameterElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(diameterActions.addDiameterElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(diameterActions.updateDiameterElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    isLoading: true,
    error: {} as HttpErrorResponse,
  })),
  on(diameterActions.updateDiameterElementSuccess, (state, {payload}) => {
    const radiusData = [...state.content];
    const newRadiusContent = radiusData.map((mnf: DiameterElement) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    return {
      ...state,
      content: newRadiusContent,
      current: {} as DiameterElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(diameterActions.updateDiameterElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(diameterActions.deleteDiameterElement, (state, {payload}) => ({
    ...state,
    deleteId: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(diameterActions.deleteDiameterElementSuccess, (state) => {
    const radiusData = [...state.content];
    const newRadiusContent = radiusData.filter((mnf: DiameterElement) => mnf.id !== state.deleteId);
    return {
      ...state,
      content: newRadiusContent,
      deleteId: null,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(diameterActions.deleteDiameterElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function DiameterReducer(state: DiameterState | undefined, action: Action): any {
  return diameterReducer(state, action);
}



