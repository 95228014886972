import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as PaymentsActions from "./payments.actions";
import StatisticsApiService from '../../../../api/general-admin-services/statistics.api.service';

@Injectable()
export class PaymentsEffects {
  constructor(private actions$: Actions,
              private statisticService: StatisticsApiService) {
  }

  getGoldLeadsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getGoldLeadsStatistic),
      switchMap(action =>
        this.statisticService.getGoldLeadsList(action.params).pipe(
          map(response => PaymentsActions.getGoldLeadsStatisticsSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(PaymentsActions.getGoldLeadsStatisticsFailure({payload: err}))
          )
        )
      )
    )
  )

  getGoldLeadsPerMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getGoldLeadsPerMonth),
      switchMap(action =>
        this.statisticService.getPaymentsLeadsCount(action.params).pipe(
          map(response => PaymentsActions.getGoldLeadsPerMonthSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(PaymentsActions.getGoldLeadsPerMonthFailure({payload: err}))
          )
        )
      )
    )
  )

  exportPaymentsStatistic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.exportPaymentsStatistic),
      switchMap(action =>
        this.statisticService.exportPaymentsStatistic(action.params, action.fileName).pipe(
          map(() => PaymentsActions.exportPaymentsStatisticSuccess()),
          catchError((err: HttpErrorResponse) =>
            of(PaymentsActions.exportPaymentsStatisticFailure({payload: err}))
          )
        )
      )
    )
  )

  getMonthlyLeadsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getMonthlyLeadsList),
      switchMap(action =>
        this.statisticService.getMonthlyPaymentsLeads(action.params).pipe(
          map(response => PaymentsActions.getMonthlyLeadsListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(PaymentsActions.getMonthlyLeadsListFailure({payload: err}))
          )
        )
      )
    )
  )

  getPaymentsLeadsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getPaymentsLeadsCount),
      switchMap(action =>
        this.statisticService.getPaymentsLeadsCount(action.params).pipe(
          map(response => PaymentsActions.getPaymentsLeadsCountSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(PaymentsActions.getPaymentsLeadsCountFailure({payload: err}))
          )
        )
      )
    )
  )

  changeLeadStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.changeDealerShipStatus),
      switchMap(action =>
        this.statisticService.changeLeadTWSStatus(action.payload.id, action.payload.status).pipe(
          map((lead) => PaymentsActions.changeDealerShipStatusSuccess({payload: lead})),
          catchError((err: HttpErrorResponse) =>
            of(PaymentsActions.changeDealerShipStatusFailure({payload: err}))
          )
        )
      )
    )
  )

  getGoldDetailsByMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getGoldDetailsByMonth),
      switchMap(action =>
        this.statisticService.getGoldLeadsList(action.params).pipe(
          map(response => PaymentsActions.getGoldDetailsByMonthSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(PaymentsActions.getGoldDetailsByMonthFailure({payload: err}))
          )
        )
      )
    )
  )
}
