import { HttpErrorResponse } from '@angular/common/http';
import { IResponse } from '../../../../../api/api-data/api.interfaces';

export interface ILocation {
  name: string,
  modalType?:string
}

export interface IDbLocation extends ILocation {
  id: number
}

export interface ILocationState {
  isLoading: boolean,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  data: IResponse<IDbLocation[]>,
  error: HttpErrorResponse
}

export interface ILocationParams {
  page: number,
  filters?: string,
  sorts: string
  search:string
}
