<div class="select-wrap">
  <label class="label">
    {{label | translate}}
    <span *ngIf="isRequired">*</span>
  </label>
  <mat-form-field class="form-element" appearance="fill"
                  [ngClass]="{'errorInput': !control.valid && (control.dirty || control.touched)}">
    <mat-select #matSelect placeholder="{{placeholder | translate}}"
                (selectionChange)="valueChange($event)" [value]="value">
      <input *ngIf="selectSearch" class="input-search-filter" autocomplete="off" placeholder="search"
             (keyup)="onKey($event)">
      <mat-select-trigger *ngIf="stringValue && brandSelect">
        {{stringValue}}
      </mat-select-trigger>
      <ng-container *ngIf="isValueName && isSorted">
        <mat-option *ngFor="let option of optionsArr" [value]="option.value.toLowerCase()">
          {{option.value | translate}}
        </mat-option>
        <mat-option *ngIf="optionsArr && !optionsArr.length">
          {{"NOT_FOUND" | translate}}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="isSorted && !isValueName">
        <mat-option class="option-action" (click)="editPrivateBrand($event,false)" *ngIf="brandSelect">
          {{'ADD_PRIVATE_MANUFACTURER' | translate}}
        </mat-option>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let option of sortOptions(optionsArr)" [value]="+option.id">
          <div class="option-wrap">
            {{option.value | translate}}
            <ng-container *ngIf="option?.isPrivate">
              <div class="option-notification">{{'PRIVATE' | translate}}</div>
              <div class="option-action" (click)="editPrivateBrand($event,true, option)">
                <mat-icon>edit</mat-icon>
              </div>
            </ng-container>
          </div>
        </mat-option>
        <mat-option *ngIf="optionsArr && !optionsArr.length">
          {{"NOT_FOUND" | translate}}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="!isSorted && !isValueName">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let option of optionsArr" [value]="+option.id">
          {{option.value | translate}}
        </mat-option>
        <mat-option *ngIf="optionsArr && !optionsArr.length">
          {{"NOT_FOUND" | translate}}
        </mat-option>
      </ng-container>

    </mat-select>
    <mat-hint>
      <mat-error *ngIf="control.hasError('required') && (control.dirty || control.touched)">
        {{'THIS_FIELD_IS_REQUIRED' | translate}}
      </mat-error>
    </mat-hint>
  </mat-form-field>
</div>
