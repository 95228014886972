<div class="custom-input">
  <label class="label">
    {{label | translate}}
    <span *ngIf="isRequired">*</span>
    <span *ngIf="subLabel" class="sub-label">{{subLabel | translate}}</span>
  </label>
  <mat-form-field class="form-element" appearance="fill"
                  [ngClass]="{'errorInput': !control.valid && (control.dirty || control.touched)}">
    <input dir="rtl" *ngIf="!isTextArea && !isPhone" [disabled]="isDisabled" placeholder="{{placeholder | translate}}" (input)="valueChange($event)"
           matInput [type]="isNumber ? 'number': 'text'" autocomplete="off" [value]="value"
           (paste)="checkPasteHandler($event)" (keydown)="checkInteger($event)">
    <input dir="rtl" class="input-phone" [disabled]="isDisabled" *ngIf="isPhone" placeholder="{{placeholder | translate}}" (input)="phoneValueChange($event)"
           matInput type="text" mask="000000000 || 0000000000" autocomplete="off" [(ngModel)]="value">
    <textarea dir="rtl" *ngIf="isTextArea" placeholder="{{placeholder | translate}}" (input)="valueChange($event)"
              matInput autocomplete="off" [value]="value"></textarea>
    <mat-hint>
      <mat-error *ngIf="control.hasError('required') && (control.dirty || control.touched)">
        {{'THIS_FIELD_IS_REQUIRED' | translate}}
      </mat-error>
      <mat-error *ngIf="(control.hasError('max') || control.hasError('min'))
                && (control.dirty || control.touched)">
        {{'WRONG_PERCENT' | translate}}
      </mat-error>
    </mat-hint>
  </mat-form-field>
</div>
