import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  addDiameterElement,
  deleteDiameterElement,
  updateDiameterElement
} from "../../../../../../modules/state/general-admin/criteria/diameter";
import { EModalType, ICriteriaItem } from '../../../../../../modules/shared/interfaces/table.interfaces';
import {
  getDiameterError,
  getDiameterLoader,
  getDiameterSuccessLoader
} from '../../../../../../modules/state/general-admin/criteria/diameter/diameter.selectors';
import { NotificationService } from '../../../../../../api/notification.service';
import { isEmptyObj } from '../../../../../../utils/helpers';
import { ECriteriaControls, EModalMessage } from '../../criteria.constants';

@Component({
  selector: 'app-diameter-add-dialog',
  templateUrl: './diameter-add-dialog.component.html',
  styleUrls: ['./diameter-add-dialog.component.scss']
})
export class DiameterAddDialogComponent implements OnInit, OnDestroy {
  public radiusForm: FormGroup;
  public EModalType = EModalType;
  public destroy$: Subject<void> = new Subject<void>();
  public isLoading$: Observable<boolean>;
  public ECriteriaLabel = ECriteriaControls;

  constructor(private dialog: MatDialog,
              private store$: Store,
              private fb: FormBuilder,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: ICriteriaItem) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    if (this.data.type === EModalType.add) {
      this.radiusForm = this.fb.group({
        value: ['', [Validators.required]],
        // priority: ['', [Validators.required]],
        isActive: [false]
      });
      return;
    }
    this.radiusForm = this.fb.group({
      value: [this.data.value, [Validators.required]],
      // priority: [this.data.priority, [Validators.required]],
      isActive: [this.data.isActive]
    });
    this.isLoading$ = this.store$.select(getDiameterLoader);
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  submit(event: Event): void {
    event.preventDefault();
    if (this.data.type === EModalType.add) {
      this.store$.dispatch(addDiameterElement({payload: this.radiusForm.value}));
      this.checkRequest(EModalMessage.add);
      return;
    }
    if (this.data.type === EModalType.edit) {
      this.store$.dispatch(updateDiameterElement({payload: this.radiusForm.value, id: this.data.id}));
      this.checkRequest(EModalMessage.edit);
    }
  }

  deleteItem(itemId: number): void {
    this.store$.dispatch(deleteDiameterElement({payload: itemId}));
    this.checkRequest(EModalMessage.delete);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getDiameterError),
      this.store$.select(getDiameterSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }
}
