import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { StatisticsComponent } from "../modules/statistics/components/statistics/statistics.component";
import { ManageTwsComponent } from "./pages/manage-tws/manage-tws.component";
import { ManageMotorcycleTiresComponent } from "./pages/manage-motorcycle-tires/manage-motorcycle-tires.component";
import { ManageRimsComponent } from "./pages/manage-rims/manage-rims.component";
import { ManageLeadsComponent } from "./pages/manage-leads/manage-leads.component";
import { ConnectImageComponent } from "./pages/connect-image/connect-image.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { AdminNotificationsComponent } from "./pages/admin-notifications/admin-notifications.component";
import { CriteriaComponent } from "./pages/criteria/criteria.component";
import { CarTiresComponent } from './pages/manage-car-tires/components/car-tires-main/car-tires.component';
import { PaymentsComponent } from '../modules/payments/components/payments/payments.component';
import { BannersListComponent } from './pages/banners/banners-list/banners-list.component';
import { ResellerResolver } from '../business-user/resolvers/reseller.resolver';
import { OrdersComponent } from '../business-user/pages/orders/orders.component';

export const adminRoutes: Routes = [
  {
    path: 'statistics',
    loadChildren: () => import('./../modules/statistics/statistics.module').then(m => m.StatisticsModule),
    component: StatisticsComponent,
    data: {
      menuTitle: 'STATISTICS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'payments',
    component: PaymentsComponent,
    data: {
      menuTitle: 'PAYMENTS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'orders',
    component: OrdersComponent,
    data: {
      menuTitle: 'ORDERS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'manage-tws',
    loadChildren: () => import('./pages/manage-tws/manage-tws.module').then(m => m.ManageTwsModule),
    component: ManageTwsComponent,
    data: {
      menuTitle: 'MANAGE_TWS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'manage-car-tires',
    loadChildren: () => import('./pages/manage-car-tires/manage-car-tires.module').then(m => m.ManageCarTiresModule),
    component: CarTiresComponent,
    data: {
      menuTitle: 'MANAGE_CAR_TIRES'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'manage-motorcycle-tires',
    component: ManageMotorcycleTiresComponent,
    data: {
      menuTitle: 'MANAGE_MOTO_TIRES'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'manage-rims',
    component: ManageRimsComponent,
    data: {
      menuTitle: 'MANAGE_RIMS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'manage-leads',
    component: ManageLeadsComponent,
    data: {
      menuTitle: 'MANAGE_LEADS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'connect-image',
    component: ConnectImageComponent,
    loadChildren: () => import('./pages/connect-image/connect-image.module').then(m => m.ConnectImageModule),
    data: {
      menuTitle: 'CONNECT_IMAGE'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'blog',
    component: BlogComponent,
    loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule),
    data: {
      menuTitle: 'BLOG'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'admin-notifications',
    component: AdminNotificationsComponent,
    loadChildren: () => import('./pages/admin-notifications/admin-notifications.module').then(m => m.AdminNotificationsModule),
    data: {
      menuTitle: 'ADMIN_NOTIFICATIONS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'criteria',
    component: CriteriaComponent,
    loadChildren: () => import('./pages/criteria/criteria.module').then(m => m.CriteriaModule),
    data: {
      menuTitle: 'CRITERIA'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: 'banners',
    component: BannersListComponent,
    loadChildren: () => import('./pages/banners/banners.module').then(m => m.BannersModule),
    data: {
      menuTitle: 'BANNERS'
    },
    resolve: {Reseller: ResellerResolver}
  }
];

@NgModule({
  imports: [RouterModule.forChild([...adminRoutes, {path: '**', redirectTo: 'statistics'}])],
  exports: [RouterModule]
})
export class t4uAdminRoutingModule {
}
