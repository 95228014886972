import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as characteristicActions from './characteristic.actions'
import CharacteristicApiService from "../../../../api/general-admin-services/characteristic-api.service";

@Injectable()
export class CharacteristicEffects {
  constructor(private actions$: Actions,
              private characteristicService: CharacteristicApiService) {
  }

  getCharacteristicData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(characteristicActions.getCharacteristicData),
      switchMap(() => {
        return this.characteristicService.getCharacteristicData().pipe(
          map(resp => {
            resp.content = resp.content.map((item: any) => ({...item, options: []}))
            return resp
          }),
          map(resp => characteristicActions.getCharacteristicDataSuccess({payload: resp})),
          catchError((err: HttpErrorResponse) => of(characteristicActions.getCharacteristicDataFailure({payload: err})))
        )
      })
    )
  })

  getCharacteristicOptionsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(characteristicActions.getCharacteristicOptionsList),
      switchMap(action => {
        return this.characteristicService.getCharacteristicOptionData(action.id).pipe(
          map(resp => characteristicActions.getCharacteristicOptionsListSuccess({payload: resp, id: action.id})),
          catchError((err: HttpErrorResponse) => of(characteristicActions.getCharacteristicOptionsListFailure({payload: err})))
        )
      })
    )
  })

  createCharacteristicOption$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(characteristicActions.addCharacteristicOption),
      switchMap(action => {
        return this.characteristicService.createCharacteristicOption(action.payload).pipe(
          map(() => characteristicActions.addCharacteristicOptionSuccess()),
          catchError((err: HttpErrorResponse) => of(characteristicActions.addCharacteristicOptionFailure({payload: err})))
        )
      })
    )
  })

  updateCharacteristicOption$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(characteristicActions.updateCharacteristicOption),
      switchMap(action => {
        return this.characteristicService.editCharacteristicOption(action.id, action.payload).pipe(
          map(() => characteristicActions.updateCharacteristicOptionSuccess()),
          catchError((err: HttpErrorResponse) => of(characteristicActions.updateCharacteristicOptionFailure({payload: err})))
        )
      })
    )
  })

  deleteCharacteristicOption$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(characteristicActions.deleteCharacteristicOption),
      switchMap(action => {
        return this.characteristicService.removeCharacteristicOption(action.payload).pipe(
          map(() => characteristicActions.deleteCharacteristicOptionSuccess()),
          catchError((err: HttpErrorResponse) => of(characteristicActions.deleteCharacteristicOptionFailure({payload: err})))
        )
      })
    )
  })

  getLookupParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(characteristicActions.getLookupParameters),
      switchMap(action =>
        this.characteristicService.getLookupParams(action.payload, action.isDisplayPrivateBrand || false).pipe(
          map(params => characteristicActions.getLookupParametersSuccess({payload: params})),
          catchError((err: HttpErrorResponse) =>
            of(characteristicActions.getLookupParametersFailure({payload: err}))
          )
        )
      )
    )
  )

}
