export const criteriaEndpoint = '/criteria';
export const defaultCriteriaApi = '/criteria/default/';
export const diameterPath = `/criteria/diameter`;
export const speedCodePath = `/criteria/speedcode`;
export const weightCodePath = `/criteria/weightcode`;
export const heightPath = `/criteria/height`;
export const leadsCodePath = '/criteria/lead';
export const widthPath = '/criteria/width';
export const stagingWebSiteUrl = 'https://tires4u.staging.starteril.com';
export const stagingAdminSiteUrl = 'https://admin.tires4u.staging.starteril.com';
export const prodWebSiteUrl = 'https://tires4u.co.il';
export const prodAdminUrl = 'https://shoef.tires4u.co.il';
