import { HttpErrorResponse } from "@angular/common/http";
import { Action, createReducer, on } from "@ngrx/store";

import * as LocationActions from './location.action';
import { IDbLocation, ILocationState } from './location.model';
import { IResponse } from '../../../../../api/api-data/api.interfaces';

export const initialState: ILocationState = {
  data: {} as IResponse<IDbLocation[]>,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse,
};

const locationReducer = createReducer(
  initialState,
  on(LocationActions.getLocationData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LocationActions.getLocationDataSuccess, (state, {payload}) => ({
    ...state,
    data: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(LocationActions.getLocationDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(LocationActions.addLocation, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LocationActions.addLocationSuccess, (state, {payload}) => {
    return {
      ...state,
      data: {...state.data, content: [...state.data.content, payload]},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(LocationActions.addLocationFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(LocationActions.updateLocation, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LocationActions.updateLocationSuccess, (state, {payload}) => {
    const content = [...state.data.content];
    const newData = content.map((mnf: IDbLocation) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    return {
      ...state,
      data: {...state.data, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(LocationActions.updateLocationFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  }))
);

export function LocationReducer(state: ILocationState | undefined, action: Action): any {
  return locationReducer(state, action);
}



