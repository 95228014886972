import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { ADMIN_ROUTE_ROOT, BUSINESS_ROUTE_ROOT } from "./app.constats";
import { AdminGuard } from "./t4u-admin/guards/admin.guard";
import { BusinessGuard } from './business-user/guards/business.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'index.php',
    pathMatch: 'full',
    redirectTo: 'auth/login'
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: ADMIN_ROUTE_ROOT,
        loadChildren: () => import('./t4u-admin/t4u-admin.module').then(m => m.T4uAdminModule),
        canActivate: [AdminGuard]
      },
      {
        path: BUSINESS_ROUTE_ROOT,
        loadChildren: () => import('./business-user/business-user.module').then(m => m.BusinessUserModule),
        canActivate: [BusinessGuard]
      },
      {
        path: 'statistics',
        loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule)
      },
      {
        path: 'payments',
        loadChildren: () => import('./modules/payments/payments.module').then(m => m.PaymentsModule)
      },
      // {
      //   path: 'manage-tws',
      //   loadChildren: () => import('./modules/manage-tws/manage-tws.module').then(m => m.ManageTwsModule)
      // },
      {
        path: '',
        loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
