import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from 'rxjs';

import { TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { ManufacturerAddDialogComponent } from "./manufacturer-add-dialog/manufacturer-add-dialog.component";
import {
  getManufacturerData,
  ManufacturerElement,
  switchManufacturerStatus
} from "../../../../../modules/state/general-admin/criteria/manufacturer";
import { EModalMessage, ManufactureTableMeta } from '../criteria.constants';
import { EBrandType, EModalType, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import {
  getManufacturerError,
  getManufacturerLoader, getManufacturerSuccessLoader,
  getRimManufactureTable,
  getTireManufactureTable
} from '../../../../../modules/state/general-admin/criteria/manufacturer/manufacturer.selectors';
import { LanguageService } from '../../../../../api/language.service';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { isEmptyObj } from '../../../../../utils/helpers';
import { NotificationService } from '../../../../../api/notification.service';

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss']
})
export class ManufacturerComponent implements OnInit, OnDestroy {
  public tableMeta: TableMeta[] = ManufactureTableMeta;
  public modalType: EModalType = EModalType.add;
  public rimTableContent$: Observable<ManufacturerElement[]>;
  public tireTableContent$: Observable<ManufacturerElement[]>;
  public EBrandType = EBrandType;
  public isTableLoad$: Observable<boolean>;
  public tireSorts: string = '';
  public rimSorts: string = '';
  public searchTireControl: FormControl = new FormControl('');
  public searchRimControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();
  public searchTireBrand: string = '';
  public searchRimBrand: string = '';

  constructor(private dialog: MatDialog,
              private store$: Store,
              private alertService: NotificationService,
              public languageService: LanguageService) {
  }

  public openAddDialog(brandType: EBrandType): void {
    this.dialog.open(ManufacturerAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {modalType: EModalType.add, brandType}
    })
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.store$.dispatch(getManufacturerData());
    this.rimTableContent$ = this.store$.select(getRimManufactureTable);
    this.tireTableContent$ = this.store$.select(getTireManufactureTable);
    this.isTableLoad$ = this.store$.select(getManufacturerLoader);
  }

  dispatchEvent(table: ITableData<ManufacturerElement>, type: string): void {
    if (table.source === 'ToggleSlider') {
      this.store$.dispatch(switchManufacturerStatus({id: table.data.id}));
      this.checkRequest(EModalMessage.statusChanged);
      return;
    }
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete
    }
    this.changeCriteria(table.data, type);
  }

  changeCriteria(item: ManufacturerElement, brandType: string): void {
    this.dialog.open(ManufacturerAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, modalType: this.modalType, brandType}
    })
  }

  getSortValue(sort: string, type: EBrandType): void {
    if (type === EBrandType.tire) {
      this.tireSorts = sort;
      return;
    }
    this.rimSorts = sort;
  }

  sortContent(content: ManufacturerElement[], sort: string, type: string): ManufacturerElement[] {
    let list = [...content];
    if (!list.length) return [];
    switch (sort) {
      case ('title'):
        const stringValue = list.filter(n => isNaN(+n.name)).sort((a, b) => a.name > b.name ? 1 : -1)
        const integerValue = list.filter(x => !isNaN(+x.name)).sort((a, b) => +a.name - +b.name);
        list = [...integerValue, ...stringValue];
        break;
      case ('-title'):
        const strings = list.filter(n => isNaN(+n.name)).sort((a, b) => a.name > b.name ? -1 : 1)
        const integers = list.filter(x => !isNaN(+x.name)).sort((a, b) => +b.name - +a.name);
        list = [...integers, ...strings];
        break;
      default:
        list = [...content];
    }
    if (type === EBrandType.tire) {
      list = list.filter(item => item.name.toLowerCase().includes(this.searchTireBrand.toLowerCase()));
    } else {
      list = list.filter(item => item.name.toLowerCase().includes(this.searchRimBrand.toLowerCase()));
    }
    return list;
  }

  getSearchValue(): void {
    this.searchTireControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchTireBrand = value;
      })
    this.searchRimControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchRimBrand = value;
      })
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getManufacturerError),
      this.store$.select(getManufacturerSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.error.message);
        }
      })
  }
}
