import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as diameterActions from "./diameter.actions";
import { CriteriaService } from '../../../../../api/general-admin-services/criteria.service';
import { diameterPath } from '../../../../../api/api-data/api.constants';

@Injectable()
export class DiameterEffects {
  constructor(private actions$: Actions,
              private criteriaService: CriteriaService) {
  }

  getDiameterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(diameterActions.getDiameterData),
      switchMap(() =>
        this.criteriaService.getCriteriaData(diameterPath).pipe(
          map(response => diameterActions.getDiameterDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(diameterActions.getDiameterDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createDiameterElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(diameterActions.addDiameterElement),
      switchMap(action =>
        this.criteriaService.addCriteriaElement(diameterPath, action.payload).pipe(
          map(response => diameterActions.addDiameterElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(diameterActions.addDiameterElementFailure({payload: err})))
        )
      )
    )
  )

  updateDiameterElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(diameterActions.updateDiameterElement),
      switchMap(action =>
        this.criteriaService.updateCriteriaElement(action.payload, action.id).pipe(
          map(response => diameterActions.updateDiameterElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(diameterActions.updateDiameterElementFailure({payload: err})))
        )
      )
    )
  )

  deleteDiameterElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(diameterActions.deleteDiameterElement),
      switchMap(action =>
        this.criteriaService.deleteCriteria(action.payload).pipe(
          map(() => diameterActions.deleteDiameterElementSuccess()),
          catchError((err: HttpErrorResponse) => of(diameterActions.deleteDiameterElementFailure({payload: err})))
        )
      )
    )
  )
}
