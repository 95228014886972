import { IFilters } from '../api/api-data/api.interfaces';
import { IFileData, IImageSize } from '../modules/shared/enums/imageLoader.enums';

export async function fileListToBase64(fileList: File[]): Promise<string[]> {
  // here will be array of promisified functions
  const promises = [];
  // loop through fileList with for loop
  for (let i = 0; i < fileList.length; i++) {
    promises.push(getListBase64(fileList[i]))
  }
  // array with base64 strings
  return await Promise.all(promises);
}

export function getListBase64(file: File): Promise<string> {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target?.result as string)
    }
    reader.readAsDataURL(file)
  })
}

export function getBase64(file: File, size: IImageSize): Promise<IFileData> {
  // create function which return resolved promise
  // with data:base64 string
  const reader = new FileReader();
  return new Promise(resolve => {
    let isCorrectSize: boolean = true;
    reader.onload = async ev => {
      isCorrectSize = await getImageSize(ev.target?.result as string, size);
      resolve({imgSrc: ev.target?.result as string, isCorrectSize})
    }
    reader.readAsDataURL(file)
  })
}

export function getImageSize(src: string, size?: IImageSize): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    const image = new Image();
    image.src = src;
    if (size) {
      const width = size.width;
      const height = size.height;
      image.onload = function (result) {
        resolve(image.width <= width && image.height <= height);
      }
    }
  });
}

export function isEmptyObj(obj: object): boolean {
  return Object.keys(obj).length == 0;
}

export function getFilterQuery(data: IFilters): string {
  let query = '';
  for (const key in data) {
    let value = data[key].value;
    let sign = data[key].sign;
    query += key + sign + value.join(';');
    if (Object.keys(data).length > 1) {
      query = query + ',';
    }
  }
  return query;
}

export function sortContentByValue(content: any[], sort: string, search: string): any[] {
  if (!content) return [];
  let list = [...content];
  switch (sort) {
    case ('value'):
      list = list.sort((a, b) => +a.value - +b.value);
      break;
    case ('-value'):
      list = list.sort((a, b) => +b.value - +a.value);
      break;
    default:
      list = [...content];
  }
  list = list.filter(item => item.value.includes(search));
  return list;
}

export function sortContentByName(content: any[], sort: string): any[] {
  let list = [...content];
  if (!list.length) return [];
  switch (sort) {
    case ('title'):
      const stringValue = list.filter(n => isNaN(+n.name)).sort((a, b) => a.name > b.name ? 1 : -1)
      const integerValue = list.filter(x => !isNaN(+x.name)).sort((a, b) => +a.name - +b.name);
      list = [...integerValue, ...stringValue];
      break;
    case ('-title'):
      const strings = list.filter(n => isNaN(+n.name)).sort((a, b) => a.name > b.name ? -1 : 1)
      const integers = list.filter(x => !isNaN(+x.name)).sort((a, b) => +b.name - +a.name);
      list = [...integers, ...strings];
      break;
    default:
      list = [...content];
  }
  return list;
}
