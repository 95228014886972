import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as locationActions from './location.action';
import { LocationService } from '../../../../../api/general-admin-services/location.service';

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private service: LocationService
  ) {
  }

  getLocationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.getLocationData),
      switchMap((action) =>
        this.service.getLocationData(action.payload).pipe(
          map(response => locationActions.getLocationDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(locationActions.getLocationDataFailure({payload: err}))
          )
        )
      )
    )
  )

  addLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.addLocation),
      switchMap(action =>
        this.service.addLocation(action.payload).pipe(
          map(response => locationActions.addLocationSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(locationActions.addLocationFailure({payload: err})))
        )
      )
    )
  )

  updateLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.updateLocation),
      switchMap(action =>
        this.service.updateLocation(action.payload).pipe(
          map(response => locationActions.updateLocationSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(locationActions.updateLocationFailure({payload: err})))
        )
      )
    )
  )
}
