import { Injectable } from '@angular/core';
import {
  IBillingOrder,
  IOrderEdit,
  IOrderParams,
  IOrderPaymentDetails,
  IOrderStatistic
} from '../modules/state/general-admin/orders/orders.model';
import { Observable } from 'rxjs';
import { IResponse } from './api-data/api.interfaces';
import { ApiService } from './api.service';
import { IStatisticParams } from '../modules/state/general-admin/leads/leads.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  readonly PATH = '/order';

  constructor(private apiService: ApiService) {
  }

  getOrdersList(params: IOrderParams): Observable<IResponse<IBillingOrder[]>> {
    return this.apiService.get(`${this.PATH}/list`, {...params});
  }

  changeOrderStatus(id: string | number, status: string): Observable<{}> {
    const formData: FormData = new FormData();
    formData.append('OrderId', id.toString());
    formData.append('OrderStatus', status);
    return this.apiService.post(`${this.PATH}/changeorderstatus`, formData);
  }

  changeBillingStatus(id: string | number, status: string): Observable<{}> {
    const formData: FormData = new FormData();
    formData.append('OrderId', id.toString());
    formData.append('BillingStatus', status);
    return this.apiService.post(`${this.PATH}/changebillingstatus`, formData);
  }

  editOrder(id: number, data: IOrderEdit): Observable<{}> {
    return this.apiService.put(`${this.PATH}/${id}`, data);
  }

  chargeOrder(id: number): Observable<{ isSuccess: boolean, message: string }> {
    return this.apiService.get(`${this.PATH}/growsettlesuspendedtransaction/${id}`);
  }

  getGrowPaymentLink(orderId: number): Observable<IOrderPaymentDetails> {
    return this.apiService.get(`${this.PATH}/getpaymentlink/${orderId}`);
  }

  sendPaymentLink(orderId: number): Observable<{ isSuccess: boolean, message: string }> {
    return this.apiService.get(`${this.PATH}/resendpaymentsms/${orderId}`);
  }

  getOrderStatistic(params: IStatisticParams): Observable<IOrderStatistic> {
    return this.apiService.get(`/statistic/orders`, {...params});
  }

  exportOrderStatistic(params: IStatisticParams, fileName: string): Observable<{}> {
    return this.apiService.exportFile(`/statistic/orders/export`, {...params}, fileName);
  }
}
