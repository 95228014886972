import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

import { TireTypeElement, TireTypeTable } from "./tire-type.model";

export const GET_TIRE_TYPE_DATA = '[TIRE_TYPE] Get data';
export const GET_TIRE_TYPE_SUCCESS = '[TIRE_TYPE] Get data success';
export const GET_TIRE_TYPE_FAILURE = '[TIRE_TYPE] Get data failure';

export const ADD_TIRE_TYPE_ELEMENT = '[TIRE_TYPE] Add element';
export const ADD_TIRE_TYPE_ELEMENT_SUCCESS = '[TIRE_TYPE] Add element success';
export const ADD_TIRE_TYPE_ELEMENT_FAILURE = '[TIRE_TYPE] Add element failure';

export const UPDATE_TIRE_TYPE_ELEMENT = '[TIRE_TYPE] Update element';
export const UPDATE_TIRE_TYPE_ELEMENT_SUCCESS = '[TIRE_TYPE] Update element success';
export const UPDATE_TIRE_TYPE_ELEMENT_FAILURE = '[TIRE_TYPE] Update element failure';

export const DELETE_TIRE_TYPE_ELEMENT = '[TIRE_TYPE] Delete element';
export const DELETE_TIRE_TYPE_ELEMENT_SUCCESS = '[TIRE_TYPE] Delete element success';
export const DELETE_TIRE_TYPE_ELEMENT_FAILURE = '[TIRE_TYPE] Delete element failure';

export const getTireTypeData = createAction(
  GET_TIRE_TYPE_DATA
)

export const getTireTypeDataSuccess = createAction(
  GET_TIRE_TYPE_SUCCESS,
  props<{ payload: TireTypeTable }>()
)

export const getTireTypeDataFailure = createAction(
  GET_TIRE_TYPE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addTireTypeElement = createAction(
  ADD_TIRE_TYPE_ELEMENT,
  props<{ payload: TireTypeElement }>()
)

export const addTireTypeElementSuccess = createAction(
  ADD_TIRE_TYPE_ELEMENT_SUCCESS,
  props<{ payload: TireTypeElement }>()
)

export const addTireTypeElementFailure = createAction(
  ADD_TIRE_TYPE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateTireTypeElement = createAction(
  UPDATE_TIRE_TYPE_ELEMENT,
  props<{ payload: TireTypeElement, id: number }>()
)

export const updateTireTypeElementSuccess = createAction(
  UPDATE_TIRE_TYPE_ELEMENT_SUCCESS,
  props<{ payload: TireTypeElement }>()
)

export const updateTireTypeElementFailure = createAction(
  UPDATE_TIRE_TYPE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteTireTypeElement = createAction(
  DELETE_TIRE_TYPE_ELEMENT,
  props<{payload: TireTypeElement }>()
)

export const deleteTireTypeElementSuccess = createAction(
  DELETE_TIRE_TYPE_ELEMENT_SUCCESS
)

export const deleteTireTypeElementFailure = createAction(
  DELETE_TIRE_TYPE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
