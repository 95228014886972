<div class="content manufacturer">
  <div class="content-head">
    <h3 class="head-title">{{'LEADS_STATUS' | translate}}</h3>
    <div class="actions">
      <button class="btn-admin" (click)="openAddDialog()">
        <mat-icon>add</mat-icon>
        {{'ADD' | translate}}
      </button>
    </div>
  </div>
  <div class="content-body" *ngIf="(tableContent$ | async) as content">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="sortContent(content, sorts)"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [language]="languageService.mainLanguage.value"
      (onClick)="actionDispatch($event)"
      (sorts)="getSortValue($event)"
    >
    </starter-table>
  </div>
</div>




