import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';

import { IStatistic, IStatisticLead, IStatisticParams } from '../../../modules/state/general-admin/leads/leads.model';
import { getStatistic, selectStatistic, } from '../../../modules/state/general-admin/leads';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { EModalType } from '../../../modules/shared/interfaces/table.interfaces';
import { MatDialog } from '@angular/material/dialog';
import { getTireResellerById, IParameter, TireResellerFull } from '../../../modules/state/general-admin/tire-reseller';
import {
  getResellerLoader,
  getTireReseller
} from '../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import { DashboardDialogComponent } from './dashboard-dialog/dashboard-dialog.component';
import { monthNames, ResellerTableMeta } from '../../business-user.constants';
import { LanguageService } from '../../../api/language.service';
import { TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { getAdminNotification, IAdminNotification } from '../../../modules/state/general-admin/admin-notifications';
import {
  getNotificationList
} from '../../../modules/state/general-admin/admin-notifications/admin-notifications.selectors';
import { EStatisticTitle } from '../../../modules/statistics/components/statistics/statistics.enums';
import { IOrdersExtended } from '../../../modules/state/general-admin/payments/payments.model';
import { getGoldLeadsStatistic, selectGoldLeadStatistic } from '../../../modules/state/general-admin/payments';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public resellerShopName: string = '';
  public reseller: TireResellerFull;
  public destroy$: Subject<void> = new Subject<void>();
  public isLoading$: Observable<boolean>;
  public orderTableMeta: TableMeta[] = ResellerTableMeta;
  public ordersTableContent$: Observable<IOrdersExtended>;
  public today: Date = new Date();
  public month: string;
  public dateForm: FormGroup;
  public params: IStatisticParams;
  public monthes: IParameter[];
  public notificationList$: Observable<IAdminNotification[]>;
  public statistic$: Observable<IStatistic | null>;
  public EStatisticTitle = EStatisticTitle;
  public leadsFoAllTime: boolean = false;
  public userId: string;

  constructor(private store$: Store,
              private auth: AuthService,
              private fb: FormBuilder,
              public languageService: LanguageService,
              private dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit(): void {
    this.validateInitialMonth();
    this.getData();
    this.initForm();
    this.getDateFilter();
    this.getCurrentMonth(this.today, this.today.getMonth());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  validateInitialMonth(): void {
    const currentMonth = this.today.getMonth();
    const currentYear = this.today.getFullYear();
    let startMonth = currentMonth - 1;
    if (startMonth < 7) {
      startMonth = 7;
    }

    if (currentYear === 2023) {
      this.monthes = monthNames.filter(item => item.id >= startMonth && item.id <= currentMonth);
      return;
    }
    this.monthes = monthNames.filter(item => item.id <= currentMonth);
  }

  initForm(): void {
    const month = monthNames[this.today.getMonth()]?.id;
    this.dateForm = this.fb.group({month: [month]});
  }

  getData(): void {
    this.userId = this.auth.reseller?.tireResellerId;
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    this.store$.dispatch(getTireResellerById({payload: this.userId}));
    this.store$.dispatch(getAdminNotification());
    const params: IStatisticParams = {tireResellerId: this.userId};
    this.getAmounts(params);
    this.isLoading$ = this.store$.select(getResellerLoader);
    this.ordersTableContent$ = this.store$.select(selectGoldLeadStatistic);
    this.notificationList$ = this.store$.select(getNotificationList);
    this.store$.select(getTireReseller).pipe(takeUntil(this.destroy$))
      .subscribe(reseller => {
        this.resellerShopName = reseller.twsName;
        this.reseller = reseller;
      });
    this.statistic$ = this.store$.select(selectStatistic);
    const goldParams: IStatisticParams = {tireResellerId: this.userId, dateFrom: new Date().toISOString()};
    this.store$.dispatch(getGoldLeadsStatistic({params: goldParams}));
  }

  getCurrentMonth(date: Date, month: number): void {
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    date.setFullYear(date.getFullYear(), month, 1);
    this.month = monthNames[this.today.getMonth()].value;
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 11);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 11);
    this.params = {
      tireResellerId: this.userId,
      dateFrom: firstDay.toISOString(),
      dateTo: lastDay.toISOString()
    }
    this.getAmounts(this.params);
  }

  getYearDays(): void {
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    let firstDay = new Date(new Date().getFullYear(), 0, 1, 10);
    let lastDay = new Date(new Date().getFullYear(), 11, 31, 10);
    this.params = {
      tireResellerId: this.userId,
      dateFrom: firstDay.toISOString(),
      dateTo: lastDay.toISOString()
    }
    this.getAmounts(this.params);
  }

  getAmounts(params: IStatisticParams): void {
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    this.store$.dispatch(getStatistic({params}));
  }

  getDateFilter(): void {
    this.dateForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (date) => {
          if (date.month === '') {
            this.leadsFoAllTime = true;
            this.getYearDays()
            return;
          }
          this.leadsFoAllTime = false;
          this.getCurrentMonth(this.today, date.month);
        }
      })
  }

  openDetails(): void {
    this.dialog.open(DashboardDialogComponent, {
      panelClass: 'admin-manufacturer-add-dialog',
      data: {...this.reseller, modalType: EModalType.edit},
      maxWidth: '950px',
    })
  }

  sortNotification(notifications: IAdminNotification[]): IAdminNotification[] {
    let list = [...notifications];
    list = list.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateB - dateA;
    });
    return list;
  }

  checkOrdersTableShowing(): boolean {
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return false;
    }
    return this.auth.reseller.subscriptionType.id === 1 || this.auth.reseller.subscriptionType.id === 3;
  }

  checkLeadsBySubscription(leads: IStatisticLead[]): IStatisticLead[] {
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return [];
    }
    const sub = this.auth.reseller.subscriptionType.id;
    let list = [...leads];
    switch (sub) {
      case (4):
        const rimLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.rims);
        const converse = list.filter(lead => lead.leadsTitle === EStatisticTitle.converse);
        list = [...rimLeads, ...converse];
        break;
      case (2):
        const indexLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.index);
        const navLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.nav);
        const converseL = list.filter(lead => lead.leadsTitle === EStatisticTitle.converse);
        list = [...indexLeads, ...navLeads, ...converseL];
        break;
      case (5):
        const zapLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.zap);
        const converseLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.converse);
        list = [...zapLeads, ...converseLeads];
        break;
      case (3):
        const goldLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.gold);
        const converseLead = list.filter(lead => lead.leadsTitle === EStatisticTitle.converse);
        list = [...goldLeads, ...converseLead];
        break;
      case (6):
        list = leads.filter(lead => lead.leadsTitle !== EStatisticTitle.rims);
        break;
      default:
        list = [...leads]
    }
    return list;
  }

  convertPhone(contactPhone: string): string {
    if (!contactPhone) return '';
    return contactPhone.includes("+9720") ? contactPhone?.replace('+972', '') : contactPhone?.replace('+972', '0');
  }

  changeVirtualNumber(number: string): string {
    if(!number.includes('972')) return number;
    let newNumber = number.split('972')[1];
    const firstLetters = newNumber.slice(0, 2);
    newNumber = newNumber.slice(2, newNumber.length)
    return `0${firstLetters}-${newNumber}`;
  }
}
