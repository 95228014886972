export enum EBlogInputTitle {
  title = 'TITLE',
  type = 'TYPE',
  auditor = 'AUDITOR',
  description = 'DESCRIPTION'
}

export enum EBlogInputPlaceholder {
  title = 'ENTER_TITLE',
  type = 'SELECT_TYPE',
  auditor = 'ENTER_AUDITOR',
  description = 'ENTER_ARTICLE_TEXT'
}

export enum EBlogMainLabel {
  add = 'ADD_POST',
  edit = 'EDIT_POST',
  delete = 'DELETE_POST'
}

export const EditorConfig = {
  editable: true,
  spellcheck: true,
  toolbarHiddenButtons: [
    [
      'fontSize',
      'textColor',
      'backgroundColor',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      'removeFormat',
      'toggleEditorMode'
    ],
    [
      'undo',
      'redo',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent',
      'insertOrderedList',
      'heading',
      'fontName'
    ]
  ]
}
