import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';

import { ILead, ILeadsCount, ILeadsState } from './leads.model';
import * as LeadsActions from './leads.actions';
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const initialState: ILeadsState = {
  response: {} as IResponse<ILead[]>,
  filters: '',
  leadsStatistics: {} as ILeadsCount,
  leadsCriteria: [],
  status: {isLoad: false, error: null},
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse,
  statistic: null,
  isStatisticLoad: false
};

const leadsReducer = createReducer(
  initialState,
  on(LeadsActions.getLeadsList, (state, {payload}) => ({
    ...state,
    isLoading: true,
    filters: payload.filters,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.getLeadsListSuccess, (state, {payload}) => {
    return {
      ...state,
      response: payload,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(LeadsActions.getLeadsListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(LeadsActions.getLeadsCountStatistics, (state) => ({
    ...state,
    leadsStatistics: {} as ILeadsCount,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.getLeadsCountStatisticsSuccess, (state, {payload}) => {
    return {
      ...state,
      leadsStatistics: payload,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(LeadsActions.getLeadsListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(LeadsActions.exportLeadsStatistic, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.exportLeadsStatisticSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.exportLeadsStatisticFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(LeadsActions.getLeadsCriteria, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.getLeadsCriteriaSuccess, (state, {payload}) => ({
    ...state,
    leadsCriteria: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.getLeadsCriteriaFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(LeadsActions.changeLeadTWSStatus, (state) => ({
    ...state,
    status: {error: null, isLoad: false}
  })),
  on(LeadsActions.changeLeadTWSStatusSuccess, (state) => ({
    ...state,
    status: {error: {} as HttpErrorResponse, isLoad: true}
  })),
  on(LeadsActions.changeLeadTWSStatusFailure, (state, {payload}) => ({
    ...state,
    status: {isLoad: false, error: payload}
  })),
  on(LeadsActions.changeLeadAdminStatus, (state) => ({
    ...state,
    status: {error: null, isLoad: false}
  })),
  on(LeadsActions.changeLeadAdminStatusSuccess, (state) => ({
    ...state,
    status: {error: {} as HttpErrorResponse, isLoad: true}
  })),
  on(LeadsActions.changeLeadAdminStatusFailure, (state, {payload}) => ({
    ...state,
    status: {isLoad: false, error: payload}
  })),
  on(LeadsActions.getStatistic, (state) => ({
    ...state,
    statistic: null,
    isLoading: true,
    isStatisticLoad: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.getStatisticSuccess, (state, {payload}) => {
    return {
      ...state,
      statistic: payload,
      isLoading: false,
      isStatisticLoad: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(LeadsActions.getLeadsListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(LeadsActions.exportStatisticFile, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.exportStatisticFileSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(LeadsActions.exportStatisticFileFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
);

export function LeadsReducer(state: ILeadsState | undefined, action: Action): any {
  return leadsReducer(state, action);
}
