import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  addBusinessMotoTire,
  editInventoryPrice,
  getInventoryPrice,
  getListForSettingPrice,
  IInventoryTire,
  ISetArticlePrice,
  resetInventory,
  setArticlePrice
} from '../../../../modules/state/business-user/article-price';
import { EModalType } from '../../../../modules/shared/interfaces/table.interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EInventorySetUpLabel, EInventorySetUpPlaceholder } from '../../../business-user.enums';
import { IParameter, TireResellerFull } from '../../../../modules/state/general-admin/tire-reseller';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  ICharacteristicObject,
  ILookupParameters,
  IOptions,
  ISubCarTireType
} from '../../../../modules/state/general-admin/characteristic';
import {
  getOptionsList,
  selectCharacteristicSuccessLoader,
  selectLookupParams
} from '../../../../modules/state/general-admin/characteristic/characteristic.selectors';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { isEmptyObj } from '../../../../utils/helpers';
import { ECarTireLabel, ECarTirePlaceholder } from '../../../../t4u-admin/pages/manage-car-tires/manage-car.enums';
import {
  getBusinessMotoTireError,
  getBusinessMotoTireLoader,
  getBusinessMotoTireSuccessLoader,
  selectArticlePriceError,
  selectArticlePriceLoader,
  selectArticlePriceSuccessLoader,
  selectResetInventoryError,
  selectResetInventoryLoader,
  selectResetInventorySuccessLoader
} from '../../../../modules/state/business-user/article-price/article-price.selectors';
import { NotificationService } from '../../../../api/notification.service';
import { EModalMessage } from '../../../../t4u-admin/pages/criteria/components/criteria.constants';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { addBusinessCarTire } from '../../../../modules/state/general-admin/manage-car-tires';
import {
  getBusinessCarTireError,
  getBusinessCarTireLoader,
  getBusinessCarTireSuccessLoader
} from '../../../../modules/state/general-admin/manage-car-tires/manage-car-tires.selectors';
import { getTireReseller } from '../../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import { refactorCharacteristicList } from '../../../../utils/converting.functions';
import { HttpErrorResponse } from '@angular/common/http';
import { EVehicleType } from '../../../../t4u-admin/pages/manage-tws/tws.enums';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory-set-up-dialog',
  templateUrl: './inventory-set-up-dialog.component.html',
  styleUrls: ['./inventory-set-up-dialog.component.scss']
})
export class InventorySetUpDialogComponent implements OnInit, OnDestroy {

  public EModalType = EModalType;
  public editTireForm: FormGroup;
  public addTireForm: FormGroup;
  public tireSitePrice: FormGroup;
  public EFormLabel = EInventorySetUpLabel;
  public EFormPlaceholder = EInventorySetUpPlaceholder;
  public yearOptions: IParameter[] = [];
  public params$: Observable<ILookupParameters>;
  public options$: Observable<IOptions>;
  public characteristicList: ICharacteristicObject;
  public isParamsLoad$: Observable<boolean>;
  public destroy$: Subject<void> = new Subject<void>();
  public ECarTirePlaceholder = ECarTirePlaceholder;
  public ECarTireLabel = ECarTireLabel;
  public isTypeSelected: boolean = false;
  public tireTypes: number[];
  public articlePriceLoader$: Observable<boolean>;
  public addModalCarLoader$: Observable<boolean>;
  public addModalMotoLoader$: Observable<boolean>;
  public invLoader$: Observable<boolean>;
  public reseller$: Observable<TireResellerFull>;
  public vehicleType: string;
  public currentYear: IParameter;
  public userId: string;

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private alertService: NotificationService,
              private store$: Store,
              public auth: AuthService,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: IInventoryTire) {
  }

  ngOnInit(): void {
    this.generateArrayOfYears();
    this.initForm();
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.userId = this.auth.reseller?.tireResellerId;
    if(!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    if (this.data.modalType === EModalType.edit) {
      this.editTireForm = this.fb.group({
        yearOfManufacture: [this.data.year || this.currentYear.id, Validators.required],
        locationOnStore: [this.data.warehouseLocation],
        quantity: [this.data.quantity, Validators.required],
        visibility: [this.data.visible || false],
        costPrice: [this.data.costPrice, Validators.required],
        sitePrice: [this.data.afterVat]
      });
      this.calculateAfterVatPrice();
      return;
    }
    if (this.data.modalType === EModalType.editPrice) {
      this.tireSitePrice = this.fb.group({
        sitePrice: [this.data.afterVat]
      })
      return;
    }
    this.addTireForm = this.fb.group({
      TireResellerId: [this.userId],
      Name: ['', Validators.required],
      BrandId: ['', Validators.required],
      CostPrice: ['', Validators.required],
      NetPrice: [''],
      TireTypes: [''],
      SubTireTypeId: [''],
      width: ['', Validators.required],
      height: ['', Validators.required],
      diameter: ['', Validators.required],
      weightCode: [''],
      speedCode: [''],
      noiseLevel: [''],
      roadGrip: [''],
      fuelConsumption: [''],
      // TireModel: [''],
      NumberOfTire: ['', Validators.required],
      YearOfManufacture: [this.currentYear.id, Validators.required],
      LocationOnStore: [''],
      Active: [false],
      RunFlate: [false]
    });
  }

  getData(): void {
    this.vehicleType = this.auth.reseller.vehicleType === 0 ? EVehicleType.car : EVehicleType.moto;
    this.params$ = this.store$.select(selectLookupParams);
    this.options$ = this.store$.select(getOptionsList);
    this.reseller$ = this.store$.select(getTireReseller);
    this.articlePriceLoader$ = this.store$.select(selectArticlePriceLoader);
    this.isParamsLoad$ = this.store$.select(selectCharacteristicSuccessLoader);
    this.addModalCarLoader$ = this.store$.select(getBusinessCarTireLoader);
    this.addModalMotoLoader$ = this.store$.select(getBusinessMotoTireLoader);
    this.invLoader$ = this.store$.select(selectResetInventoryLoader);
    this.store$.select(getOptionsList).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (list) => this.characteristicList = refactorCharacteristicList(list)
      });
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  addBusinessTire(): void {
    if (this.addTireForm.invalid) return this.addTireForm.markAllAsTouched();
    if (this.auth.reseller.vehicleType === 1) {
      this.store$.dispatch(addBusinessMotoTire({payload: this.createFormData()}));
      const error = this.store$.select(getBusinessMotoTireError);
      const loader = this.store$.select(getBusinessMotoTireSuccessLoader);
      this.checkRequest(EModalMessage.add, error, loader, true);
      return;
    }
    this.store$.dispatch(addBusinessCarTire({payload: this.createFormData()}));
    const error = this.store$.select(getBusinessCarTireError);
    const loader = this.store$.select(getBusinessCarTireSuccessLoader);
    this.checkRequest(EModalMessage.add, error, loader, true);
  }

  createFormData(): FormData {
    const formValue = {...this.addTireForm.value};
    const formData = new FormData();
    const formKeys = ['Name', 'BrandId', 'CostPrice', 'TireTypes', 'SubTireTypeId', 'Active', 'NetPrice', 'TireModel',
      'NumberOfTire', 'YearOfManufacture', 'LocationOnStore', 'TireResellerId', 'RunFlate'];
    let characteristics = [];
    let optionIds = '';
    for (const key in formValue) {
      if (formKeys.includes(key)) {
        formData.append(key, formValue[key])
      } else {
        characteristics.push(formValue[key])
        optionIds = characteristics.filter(value => value !== null).join();
      }
    }
    formData.append('CharacteristicsOptionId', optionIds);
    return formData;
  }

  generateArrayOfYears(): void {
    const years: IParameter[] = [];
    const min = new Date().getFullYear() - 2;
    const max = min + 9;
    for (let i = max; i >= min; i--) {
      years.push({id: i, value: i.toString()})
    }
    const current = new Date().getFullYear();
    this.currentYear = {id: current, value: current.toString()};
    this.yearOptions = years;
  }

  isSelected(value: { status: boolean; ids: number[] }): void {
    if (!value.status) return;
    this.isTypeSelected = true;
    if(!value.ids.includes(97)) this.addTireForm.get('SubTireTypeId')?.setValue('');
    this.tireTypes = value.ids;
  }

  checkSubCategory(types: ISubCarTireType[]): IParameter[] {
    const subcategory = types.find(type => type.id === '97');
    const idInclude = this.tireTypes.includes(97);
    return subcategory && idInclude ? subcategory.subTireType : [];
  }

  deleteTirePrice(): void {
    const payload: ISetArticlePrice = {articleId: this.data.id, price: 0};
    this.tireSitePrice.reset();
    this.store$.dispatch(setArticlePrice({payload}));
    const error = this.store$.select(selectArticlePriceError);
    const loader = this.store$.select(selectArticlePriceSuccessLoader);
    this.checkRequest(EModalMessage.deleteSitePrice, error, loader, false, true);
  }

  setTirePrice(): void {
    const afterVat = this.tireSitePrice.value.sitePrice;
    if (!afterVat || afterVat == this.data.afterVat) {
      this.dialog.closeAll();
      return;
    }
    const payload: ISetArticlePrice = {articleId: this.data.id, price: +this.tireSitePrice.value.sitePrice};
    this.store$.dispatch(setArticlePrice({payload}));
    const error = this.store$.select(selectArticlePriceError);
    const loader = this.store$.select(selectArticlePriceSuccessLoader);
    this.checkRequest(EModalMessage.pricesChanged, error, loader, false, true);
  }

  checkRequest(message: string, error: Observable<HttpErrorResponse | null>, loader: Observable<boolean>, adding?: boolean, sitePrice?: boolean): void {
    combineLatest(error, loader)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
            if (adding) this.loadInventoryList();
            if (sitePrice) this.loadSitePriceList();
          }
          if (!loader && error && !isEmptyObj(error)) {
            const errorObj = error.error.errors;
            const failuresObj = error.error.failures;
            const errors = [];
            for (const key in errorObj) errors.push(errorObj[key][0]);
            for (const key in failuresObj) errors.push(failuresObj[key][0]);
            if (!errors.length) errors.push(error.message);
            this.alertService.onError(errors);
          }
        }
      })
  }

  deleteTireQuantity(): void {
    this.editTireForm.controls['quantity'].setValue(0);
  }

  editBusinessTire(): void {
    const element = this.editTireForm.value;
    delete element['sitePrice']
    this.store$.dispatch(editInventoryPrice({id: this.data.id, element}));
    const error = this.store$.select(selectArticlePriceError);
    const loader = this.store$.select(selectArticlePriceSuccessLoader);
    this.checkRequest(EModalMessage.edit, error, loader, true);
  }

  loadInventoryList(): void {
    this.store$.dispatch(getInventoryPrice({
      params: {
        page: this.data.page,
        VehicleType: this.vehicleType,
        search: this.data.search,
        isOnlyInventory: this.data.invOnly,
        isInventoryNotDisplay: this.data.invNotDisplay,
        filters: this.data.apiFilters,
        sorts: 'BruttoPrice'
      }
    }));
  }

  loadSitePriceList(): void {
    this.store$.dispatch(getListForSettingPrice({
      params: {
        page: this.data.page,
        VehicleType: this.vehicleType,
        search: this.data.search,
        filters: this.data.apiFilters,
        sorts: 'BruttoPrice'
      }
    }));
  }

  calculateAfterVatPrice(): void {
    let cost = 0;
    this.editTireForm.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe({
        next: form => {
          cost = form.costPrice;
          if (this.data.includeVAT) {
            let sitePrice = Math.round((+cost + this.data.diameterProfit) * 1.17);
            this.editTireForm.patchValue({...this.editTireForm, sitePrice});
            return;
          }
          let sitePrice = Math.round(+cost + this.data.diameterProfit);
          this.editTireForm.patchValue({...this.editTireForm, sitePrice});
        }
      });
  }

  resetInventory(): void {
    this.store$.dispatch(resetInventory({payload: []}));
    const error = this.store$.select(selectResetInventoryError);
    const loader = this.store$.select(selectResetInventorySuccessLoader);
    this.checkRequest(EModalMessage.resetInventory, error, loader, true);
  }
}
