import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

import * as fromUser from './user.reducers'
import { AuthState } from '../interfaces/auth.interface';

export const reducers: ActionReducerMap<AuthState> = {
  user: fromUser.reducer
}

export const getAuthState = createFeatureSelector<AuthState>('AUTH')
