import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { ManufacturerEffects, ManufacturerReducer } from './general-admin/criteria/manufacturer';
import { DiameterEffects, DiameterReducer } from "./general-admin/criteria/diameter";
import { SpeedCodeEffects, SpeedCodeReducer } from "./general-admin/criteria/speed-code";
import { WeightCodeEffects, WeightCodeReducer } from "./general-admin/criteria/weight-code";
import { HeightEffects, HeightReducer } from "./general-admin/criteria/height";
import { UserEffects } from "../auth/reducers/user.effects";
import * as fromUser from "../auth/reducers/user.reducers";
import { AdminNotificationReducer, AdminNotificationsEffects } from "./general-admin/admin-notifications";
import { ConnectImageEffects, ConnectImageReducer } from "./general-admin/connect-image";
import { TireTypeEffects, TireTypeReducer } from "./general-admin/criteria/tire-type";
import { TireResellerEffects, TireResellerReducer } from "./general-admin/tire-reseller";
import { ManageCarTiresEffects, ManageCarTiresReducer } from "./general-admin/manage-car-tires";
import { SubscriptionEffects, SubscriptionReducer } from './general-admin/criteria/subscription';
import { CharacteristicEffects, CharacteristicReducer } from "./general-admin/characteristic";
import { RimsEffects, RimsReducer } from './general-admin/rims';
import { LeadsEffects, LeadsReducer } from './general-admin/leads';
import { BlogEffects, BlogReducer } from './general-admin/blog';
import { ManageMotoTiresEffects, ManageMotoTiresReducer } from './general-admin/manage-moto-tires';
import { BannersEffects, BannersReducer } from './general-admin/banners';
import { ArticlePriceEffects, ArticlePriceReducer } from './business-user/article-price';
import { LeadsStatusEffects, LeadsStatusReducer } from './general-admin/criteria/leads-status';
import { PaymentsEffects, PaymentsReducer } from './general-admin/payments';
import { WidthEffects, WidthReducer } from './general-admin/criteria/width';
import { LocationEffects, LocationReducer } from './general-admin/criteria/location';
import { OrdersEffects, OrdersReducer } from './general-admin/orders';

@NgModule({
  imports: [
    EffectsModule.forRoot([
      ManufacturerEffects,
      DiameterEffects,
      SpeedCodeEffects,
      WeightCodeEffects,
      HeightEffects,
      AdminNotificationsEffects,
      ConnectImageEffects,
      TireTypeEffects,
      UserEffects,
      ManageCarTiresEffects,
      TireResellerEffects,
      SubscriptionEffects,
      CharacteristicEffects,
      SubscriptionEffects,
      RimsEffects,
      LeadsEffects,
      BlogEffects,
      ManageMotoTiresEffects,
      BannersEffects,
      ArticlePriceEffects,
      LeadsStatusEffects,
      PaymentsEffects,
      WidthEffects,
      LocationEffects,
      OrdersEffects
    ]),
    StoreModule.forRoot({
      subscription: SubscriptionReducer,
      manufacturer: ManufacturerReducer,
      diameter: DiameterReducer,
      speedCode: SpeedCodeReducer,
      weightCode: WeightCodeReducer,
      width: WidthReducer,
      height: HeightReducer,
      adminNotifications: AdminNotificationReducer,
      connectImage: ConnectImageReducer,
      tireType: TireTypeReducer,
      manageCarTires: ManageCarTiresReducer,
      tireReseller: TireResellerReducer,
      rims: RimsReducer,
      characteristic: CharacteristicReducer,
      leads: LeadsReducer,
      blog: BlogReducer,
      AUTH: fromUser.reducer,
      manageMotoTires: ManageMotoTiresReducer,
      banners: BannersReducer,
      articlePrice: ArticlePriceReducer,
      leadStatus: LeadsStatusReducer,
      payments: PaymentsReducer,
      location: LocationReducer,
      orders: OrdersReducer
    }),
    StoreDevtoolsModule.instrument({maxAge: 25})
  ],
  providers: [
    ManufacturerEffects,
    DiameterEffects,
    SpeedCodeEffects,
    WeightCodeEffects,
    HeightEffects,
    ConnectImageEffects,
    TireTypeEffects,
    AdminNotificationsEffects,
    ManageCarTiresEffects,
    TireResellerEffects,
    SubscriptionEffects,
    CharacteristicEffects,
    LeadsEffects,
    BlogEffects,
    ManageMotoTiresEffects,
    BannersEffects,
    ArticlePriceEffects,
    LeadsStatusEffects,
    PaymentsEffects,
    WidthEffects,
    LocationEffects,
    OrdersEffects
  ],
})
export class AppStateModule {
}
