import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import * as ArticlePriceActions from './article-price.action';
import {
  IArticlePrice,
  IArticlePriceState,
  IBrandArticlePrice,
  IExpectedProfit,
  IInventoryTire
} from './article-price.model';
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const initialState: IArticlePriceState = {
  brandList: {
    list: [],
    isLoad: false,
    isLoadSuccess: false,
    error: {} as HttpErrorResponse
  },
  inventoryPrice: {} as IResponse<IInventoryTire[]>,
  settingPrice: {} as IResponse<IInventoryTire[]>,
  expectedProfit: {
    list: [],
    isLoad: false,
    isLoadSuccess: false,
    error: {} as HttpErrorResponse
  },
  businessMotoTire: {
    tire: {} as IInventoryTire,
    isLoader: false,
    isLoadSuccess: false,
    error: {} as HttpErrorResponse
  },
  resetPrices: {isLoad: false, error: null},
  resetInventory: {isLoad: false, isLoading: false, error: null},
  settingValue: false,
  articlePrice: {} as IArticlePrice,
  suitableCarByPlate: {isLoad: false, error: null, plates: []},
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse,
};

const articlePriceReducer = createReducer(
  initialState,
  on(ArticlePriceActions.setArticlePrice, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse
  })),
  on(ArticlePriceActions.setArticlePriceSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse
    }
  }),
  on(ArticlePriceActions.setArticlePriceFailure, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false,
    error: payload
  })),
  on(ArticlePriceActions.getArticleBrandList, (state) => ({
    ...state,
    settingValue: false,
    brandList: {
      ...state.brandList,
      isLoad: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    },
  })),
  on(ArticlePriceActions.getArticleBrandListSuccess, (state, {payload}) => ({
    ...state,
    settingValue: false,
    brandList: {
      ...state.brandList,
      list: payload,
      isLoad: false,
      isLoadSuccess: true
    },
  })),
  on(ArticlePriceActions.getArticleBrandListFailure, (state, {payload}) => ({
    ...state,
    settingValue: false,
    brandList: {
      ...state.brandList,
      isLoad: false,
      isLoadSuccess: false,
      error: payload
    }
  })),
  on(ArticlePriceActions.setBrandDiscount, (state) => ({
    ...state,
    settingValue: true,
    brandList: {
      ...state.brandList,
      isLoad: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    },
  })),
  on(ArticlePriceActions.setBrandDiscountSuccess, (state, {payload}) => {
    const list = [...state.brandList.list];
    const newContent = list.map((brand: IBrandArticlePrice) => {
      if (brand.brandId === payload.brandId) brand = payload;
      return brand;
    });
    return {
      ...state,
      settingValue: false,
      brandList: {
        ...state.brandList,
        list: newContent,
        isLoad: false,
        isLoadSuccess: true
      },
    }
  }),
  on(ArticlePriceActions.setBrandDiscountFailure, (state, {payload}) => ({
    ...state,
    settingValue: false,
    brandList: {
      ...state.brandList,
      isLoad: false,
      isLoadSuccess: false,
      error: payload
    }
  })),
  on(ArticlePriceActions.getExpectedProfit, (state) => ({
    ...state,
    settingValue: false,
    expectedProfit: {
      ...state.expectedProfit,
      isLoadSuccess: false,
      isLoad: true,
      error: {} as HttpErrorResponse
    }
  })),
  on(ArticlePriceActions.getExpectedProfitSuccess, (state, {payload}) => ({
    ...state,
    settingValue: false,
    expectedProfit: {
      ...state.expectedProfit,
      list: payload,
      isLoad: false,
      isLoadSuccess: true
    },
  })),
  on(ArticlePriceActions.getExpectedProfitFailure, (state, {payload}) => ({
    ...state,
    settingValue: false,
    expectedProfit: {
      ...state.expectedProfit,
      isLoad: false,
      isLoadSuccess: false,
      error: payload
    },
  })),
  on(ArticlePriceActions.setExpectedProfit, (state) => ({
    ...state,
    settingValue: true,
    expectedProfit: {
      ...state.expectedProfit,
      isLoad: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(ArticlePriceActions.setExpectedProfitSuccess, (state, {profit}) => {
    const list = [...state.expectedProfit.list];
    const newContent = list.map((brand: IExpectedProfit) => {
      if (brand.id === profit.id) brand = profit;
      return brand;
    });
    return {
      ...state,
      settingValue: false,
      expectedProfit: {
        ...state.expectedProfit,
        list: newContent,
        isLoad: false,
        isLoadSuccess: true
      },
    }
  }),
  on(ArticlePriceActions.setExpectedProfitFailure, (state, {payload}) => ({
    ...state,
    settingValue: false,
    expectedProfit: {...state.expectedProfit, isLoad: false, error: payload, isLoadSuccess: false},
  })),
  on(ArticlePriceActions.getInventoryPrice, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse
  })),
  on(ArticlePriceActions.getInventoryPriceSuccess, (state, {payload}) => ({
    ...state,
    inventoryPrice: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  })),
  on(ArticlePriceActions.getInventoryPriceFailure, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(ArticlePriceActions.editInventoryPrice, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse
  })),
  on(ArticlePriceActions.editInventoryPriceSuccess, (state, {tire, id}) => {
    const list = [...state.inventoryPrice.content];
    const newContent = list.map((tireInventory: IInventoryTire) => {
      if (tireInventory.id === id) tireInventory = tire
      return tireInventory;
    });
    return {
      ...state,
      inventoryPrice: {...state.inventoryPrice, content: newContent},
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
    }
  }),
  on(ArticlePriceActions.editInventoryPriceFailure, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(ArticlePriceActions.getListForSettingPrice, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse
  })),
  on(ArticlePriceActions.getListForSettingPriceSuccess, (state, {payload}) => {
    const response = [...payload.content];
    const content = response.map((tireInventory: IInventoryTire) => {
      if (tireInventory.inv) tireInventory = {...tireInventory, costPrice: 0};
      return tireInventory;
    });
    return {
      ...state,
      settingPrice: {...payload, content},
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false
    }
  }),
  on(ArticlePriceActions.getListForSettingPriceFailure, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(ArticlePriceActions.addBusinessMotoTire, (state) => ({
    ...state,
    businessMotoTire: {
      ...state.businessMotoTire,
      isLoader: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(ArticlePriceActions.addBusinessMotoTireSuccess, (state, {payload}) => {
    return {
      ...state,
      inventoryPrice: {...state.inventoryPrice, content: [...state.inventoryPrice.content, payload]},
      businessMotoTire: {
        tire: payload,
        isLoader: false,
        isLoadSuccess: true,
        error: {} as HttpErrorResponse
      }
    }
  }),
  on(ArticlePriceActions.addBusinessMotoTireFailure, (state, {payload}) => ({
    ...state,
    businessMotoTire: {
      ...state.businessMotoTire,
      isLoader: false,
      isLoadSuccess: false,
      error: payload
    }
  })),
  on(ArticlePriceActions.resetTirePrices, (state) => ({
    ...state,
    resetPrices: {
      isLoad: false,
      error: null
    }
  })),
  on(ArticlePriceActions.resetTirePricesSuccess, (state) => ({
    ...state,
    resetPrices: {
      isLoad: true,
      error: {} as HttpErrorResponse
    }
  })),
  on(ArticlePriceActions.resetTirePricesFailure, (state, {payload}) => ({
    ...state,
    resetPrices: {
      isLoad: false,
      error: payload
    }
  })),
  on(ArticlePriceActions.resetInventory, (state) => ({
    ...state,
    resetInventory: {
      isLoading: true,
      isLoad: false,
      error: null
    }
  })),
  on(ArticlePriceActions.resetInventorySuccess, (state) => ({
    ...state,
    resetInventory: {
      isLoading: false,
      isLoad: true,
      error: {} as HttpErrorResponse
    }
  })),
  on(ArticlePriceActions.resetInventoryFailure, (state, {payload}) => ({
    ...state,
    resetInventory: {
      isLoading: false,
      isLoad: false,
      error: payload
    }
  })),
  on(ArticlePriceActions.setMultipleExpectedProfit, (state) => ({
    ...state,
    settingValue: true,
    expectedProfit: {
      ...state.expectedProfit,
      isLoad: true,
      isLoadSuccess: false,
      error: {} as HttpErrorResponse
    }
  })),
  on(ArticlePriceActions.setMultipleExpectedProfitSuccess, (state) => {
    return {
      ...state,
      settingValue: false,
      expectedProfit: {
        ...state.expectedProfit,
        isLoad: false,
        isLoadSuccess: true
      },
    }
  }),
  on(ArticlePriceActions.setMultipleExpectedProfitFailure, (state, {payload}) => ({
    ...state,
    settingValue: false,
    expectedProfit: {...state.expectedProfit, isLoad: false, error: payload, isLoadSuccess: false},
  })),
  on(ArticlePriceActions.getCarsByPlateNumber, (state) => ({
    ...state,
    suitableCarByPlate: {
      ...state.suitableCarByPlate
    }
  })),
  on(ArticlePriceActions.getCarsByPlateNumberSuccess, (state, {payload}) => {
    return {
      ...state,
      suitableCarByPlate: {
        ...state.suitableCarByPlate,
        isLoad: true,
        error: null,
        plates: payload
      }
    }
  }),
  on(ArticlePriceActions.getCarsByPlateNumberFailure, (state, {payload}) => ({
    ...state,
    suitableCarByPlate: {
      ...state.suitableCarByPlate,
      isLoad: false,
      error: payload,
      plates: []
    }
  })),
  on(ArticlePriceActions.getConsumePriceList, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse
  })),
  on(ArticlePriceActions.getConsumePriceListSuccess, (state, {payload}) => {
    const response = [...payload.content];
    const content = response.map((tireInventory: IInventoryTire) => {
      if (tireInventory.inv) tireInventory = {...tireInventory, costPrice: 0};
      return tireInventory;
    });
    return {
      ...state,
      settingPrice: {...payload, content},
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false
    }
  }),
  on(ArticlePriceActions.getConsumePriceListFailure, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(ArticlePriceActions.removeSuitablePlates, (state) => ({
    ...state,
    suitableCarByPlate: {...state.suitableCarByPlate, plates: []},
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
);

export function ArticlePriceReducer(state: IArticlePriceState | undefined, action: Action): any {
  return articlePriceReducer(state, action);
}
