<div class="content blog_table">
  <div class="page-head-wrap">
    <div class="content-head" [formGroup]="filterForm">
      <h3 class="head-title">
        {{ 'ORDERS' | translate }}
      </h3>
      <div class="filters" *ngIf="(lookupParams$ | async) as params">
        <app-select [options]="params.OrderStatus"
                    formControlName="OperationalStatus"
                    [placeholder]="'ORDER_STATUS'"></app-select>
        <app-select [options]="params.BillingStatus"
                    formControlName="BillingStatus"
                    [placeholder]="'BILLING_STATUS'"></app-select>
        <app-select [options]="params.Location"
                    formControlName="LocationId"
                    [placeholder]="'LOCATION'"></app-select>
        <div class="select" *ngIf="!isBusiness">
          <div class="input">
            <mat-select formControlName="tireResellerId" placeholder="{{'SELECT_TWS' | translate}}">
              <mat-option [value]="''">{{allTWS.value | translate}}</mat-option>
              <mat-option *ngFor="let reseller of sortContent(params.TWSName)" [value]="reseller.id">{{ reseller.value
                }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="btn-block">
          <div class="select">
            <div class="input">
              <mat-date-range-input [rangePicker]="picker" [max]="today" [min]="minDate">
                <input matStartDate formControlName="dateFrom" placeholder="{{'START_DATE' | translate}}">
                <input matEndDate formControlName="dateTo" placeholder="{{'END_DATE' | translate}}">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker [disabled]="false" #picker></mat-date-range-picker>
            </div>
          </div>
        </div>
        <button (click)="export()" class="btn-admin btn-white export-btn">
          <mat-icon class="icon-img" svgIcon="upload"></mat-icon>
          {{ 'EXPORT' | translate }}
        </button>
      </div>

    </div>
    <div class="content-body list" *ngIf="!isBusiness">
      <div class="info-cards" *ngIf="(statistic$ | async) as list">
        <div class="card" *ngFor="let item of list.orders">
          <div class="name">{{ item.title | translate }}</div>
          <div class="card-info">
            <div class="count">
              {{ item.totalCount }}
            </div>

          </div>
        </div>
        <div class="card total">
          <div class="name">{{ EStatisticTitle.businessUserTotal | translate }}</div>
          <div class="card-info">
            <div class="count">
              {{ list.totalAmount | currency : 'ILS' : 'symbol': '1.0-1' }}
            </div>
            <span class="alone"></span>
            <div class="count">{{ list.totalCount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content blog_table" *ngIf="(orders$ | async) as orders">
    <starter-table
      [tableMeta]="ordersTableMeta"
      [tableData]="orders.content"
      [language]="languageService.mainLanguage.value"
      [billingStatus]="(lookupParams$ | async)?.BillingStatus"
      [orderStatus]="(lookupParams$ | async)?.OrderStatus"
      [isLoadingTable]="!(isTableLoad$ | async)"
      (onClick)="openOrderDetails($event)"
      (dropdownValue)="changeStatus($event)"
    >
    </starter-table>
    <div *ngIf="orders.content && !orders.content.length" class="no-result-table">
      {{ 'NO_DATA_MATCHING_THE_FILTER' | translate }}
    </div>
    <div class="pagination" *ngIf="orders.content">
          <span class="pagination-item"
                *ngFor="let page of orders.content | paginate:
                { itemsPerPage: orders.pageSize,
                 currentPage: orders.currentPage,
                 totalItems: orders.rowCount }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>


<ng-template #remarkDialog>
  <div class="dialog manufacturer-add-dialog">
    <div class="dialog-title">
      <h3 class="title">{{ 'REMARK' | translate }}</h3>
      <div class="title-actions">
        <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
      </div>
    </div>
    <mat-dialog-content>
      <div class="dialog-form-wrap">
        <app-input [formControl]="remark"
                   [placeholder]="'ENTER_REMARK'"></app-input>
        <div class="save-btn">
          <button class="btn-admin" (click)="changeRemark()">{{'SAVE' | translate}}</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</ng-template>

<ng-template #paymentDialog>
  <div class="dialog manufacturer-add-dialog">
    <div class="dialog-title">
      <div class="title-actions">
        <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
      </div>
    </div>
    <mat-dialog-content>
      <span class="result" *ngIf="paymentSuccess">{{'PAYMENT_SUCCESS' | translate}}</span>
      <span class="result" *ngIf="!paymentSuccess">{{'PAYMENT_FAILED' | translate}}</span>
    </mat-dialog-content>
  </div>
</ng-template>
