import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ManufacturerComponent } from "./components/manufacturer/manufacturer.component";
import { SpeedCodeComponent } from "./components/speed-code/speed-code.component";
import { WeightCodeComponent } from "./components/weight-code/weight-code.component";
import { HeightComponent } from "./components/height/height.component";
import { DiameterComponent } from "./components/diameter/diameter.component";
import { LeadsStatusComponent } from "./components/leads-status/leads-status.component";
import { SubscriptionComponent } from "./components/subscription/subscription.component";
import { TiresTypeComponent } from "./components/tires-type/tires-type.component";
import { WidthComponent } from './components/width/width.component';
import { LocationComponent } from './components/location/location.component';

export const criteriaRoutes: Routes = [
  // {
  //   path: 'vat-history',
  //   component: VatHistoryComponent,
  //   data: {
  //     menuTitle: 'VAT_HISTORY'
  //   }
  // },
  {
    path: 'leads-status',
    component: LeadsStatusComponent,
    data: {
      menuTitle: 'LEADS_STATUS'
    }
  },
  // {
  //   path: 'leads',
  //   component: LeadsComponent,
  //   data: {
  //     menuTitle: 'LEADS'
  //   }
  // },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    data: {
      menuTitle: 'SUBSCRIPTION'
    }
  },
  {
    path: 'manufacturer',
    component: ManufacturerComponent,
    data: {
      menuTitle: 'MANUFACTURER'
    }
  },
  {
    path: 'tiers-type',
    component: TiresTypeComponent,
    data: {
      menuTitle: 'TIERS_TYPE'
    }
  },
  {
    path: 'speed-code',
    component: SpeedCodeComponent,
    data: {
      menuTitle: 'SPEED_CODE'
    }
  },
  {
    path: 'weight-code',
    component: WeightCodeComponent,
    data: {
      menuTitle: 'WEIGHT_CODE'
    }
  },
  {
    path: 'height',
    component: HeightComponent,
    data: {
      menuTitle: 'HEIGHT'
    }
  },
  {
    path: 'width',
    component: WidthComponent,
    data: {
      menuTitle: 'WIDTH'
    }
  },
  {
    path: 'diameter',
    component: DiameterComponent,
    data: {
      menuTitle: 'DIAMETER'
    }
  },
  {
    path: 'location',
    component: LocationComponent,
    data: {
      menuTitle: 'LOCATION'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild([...criteriaRoutes, {path: '**', redirectTo: 'leads-status'}])],
  exports: [RouterModule],
})
export class CriteriaRoutingModule {
}
