export enum StatisticCardDataItemTypes {
  Default = 'default',
  Currency = 'currency',
  Text = 'text'
}


export interface StatisticCardDataItem {
  type: StatisticCardDataItemTypes.Default | StatisticCardDataItemTypes.Currency | StatisticCardDataItemTypes.Text;
  value: string | null
}


export interface StatisticCard {
  bgSecondary?: boolean;
  title: string;
  data: StatisticCardDataItem[]

}
