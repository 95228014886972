import { HttpErrorResponse } from "@angular/common/http";

export interface SpeedCodeElement {
  id: number,
  value: string,
  isActive: boolean,
  priority: string,
  additionalValue: string
}

export interface SpeedCodeState {
  current: SpeedCodeElement,
  deleteId: number | null,
  result: any,
  isLoading: boolean,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  content: SpeedCodeElement[],
  error: HttpErrorResponse
}
