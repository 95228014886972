  <div class="dialog-title" mat-dialog-title>
    <h2 class="title">{{'MANAGE_TIRE_PRICES' | translate}}</h2>
    <div class="title-actions">
      <mat-icon class="close" (click)="closeAddDialog()">close</mat-icon>
    </div>
  </div>
  <mat-dialog-content [formGroup]="profitForm">
    <div class="table-loader" *ngIf="(pricesLoader$ | async)">
      <mat-spinner></mat-spinner>
    </div>
    <div class="price-wrap">
      <div class="price-column-wrap">
        <div class="price-colum">
          <div class="price-section">
            <div class="price-title">{{'TARGET_PROFIT_ASK' | translate}}*</div>
            <div class="price-title">{{'TIRE_DIAMETER' | translate}}*</div>
          </div>
          <ng-container *ngFor="let control of firstProfit.controls; index as i" formArrayName="firstProfit">
            <div [formGroupName]="i" class="price">
              <div class="price-section">
                <app-input class="price-profit" [isNumber]="true" [placeholder]="'ENTER_TARGET_PROFIT'" formControlName="profit"></app-input>
                <app-input [isDisabled]="true" class="price-value" formControlName="value"></app-input>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="price-column">
          <div class="price-section">
            <div class="price-title">{{'TARGET_PROFIT_ASK' | translate}}*</div>
            <div class="price-title">{{'TIRE_DIAMETER' | translate}}*</div>
          </div>
          <ng-container *ngFor="let control of secondProfit.controls; index as i" formArrayName="secondProfit">
            <div [formGroupName]="i" class="price">
              <div class="price-section">
                <app-input class="price-profit" [isNumber]="true" [placeholder]="'ENTER_TARGET_PROFIT'" formControlName="profit"></app-input>
                <app-input [isDisabled]="true" class="price-value" formControlName="value"></app-input>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="save-btn">
        <button [disabled]="!profitForm.valid"
                class="btn-admin" (click)="changeTirePrices()">{{'SAVE' | translate}}
        </button>
      </div>
    </div>
  </mat-dialog-content>
