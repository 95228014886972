import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { ECriteriaControls, EModalMessage } from '../../criteria.constants';
import { NotificationService } from '../../../../../../api/notification.service';
import { EModalType, ICriteriaItem } from '../../../../../../modules/shared/interfaces/table.interfaces';
import { isEmptyObj } from '../../../../../../utils/helpers';
import {
  getWidthError,
  getWidthLoader,
  getWidthSuccessLoader
} from '../../../../../../modules/state/general-admin/criteria/width/width.selectors';
import {
  addWidthElement,
  deleteWidthElement,
  updateWidthElement
} from '../../../../../../modules/state/general-admin/criteria/width';

@Component({
  selector: 'app-width-dialog',
  templateUrl: './width-dialog.component.html',
  styleUrls: ['./width-dialog.component.scss']
})
export class WidthDialogComponent implements OnInit {
  public widthForm: FormGroup;
  public EModalType = EModalType;
  public destroy$: Subject<void> = new Subject<void>();
  public isLoading$: Observable<boolean>;
  public ECriteriaLabel = ECriteriaControls;

  constructor(private dialog: MatDialog,
              private store$: Store,
              private fb: FormBuilder,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: ICriteriaItem) {
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    if (this.data.type === EModalType.add) {
      this.widthForm = this.fb.group({
        value: ['', [Validators.required]],
        isActive: [false]
      });
      return;
    }
    this.widthForm = this.fb.group({
      value: [this.data.value, [Validators.required]],
      isActive: [this.data.isActive]
    });
    this.isLoading$ = this.store$.select(getWidthLoader);
  }

  submit(event: Event): void {
    event.preventDefault();
    if (this.data.type === EModalType.add) {
      this.store$.dispatch(addWidthElement({payload: this.widthForm.value}));
      this.checkRequest(EModalMessage.add);
      return;
    }
    if (this.data.type === EModalType.edit) {
      this.store$.dispatch(updateWidthElement({
        payload: this.widthForm.value,
        id: this.data.id
      }));
    }
    this.checkRequest(EModalMessage.edit);
  }

  deleteItem(itemId: number): void {
    this.store$.dispatch(deleteWidthElement({payload: itemId}));
    this.checkRequest(EModalMessage.delete);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getWidthError),
      this.store$.select(getWidthSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }
}
