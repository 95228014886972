import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ILeadExpanded, IStatisticParams } from '../leads/leads.model';
import { IMonthlyLeadsListExtended, IOrdersExtended, IPaymentsLeadsCount } from './payments.model';

export const GET_GOLD_LEADS_LIST = '[PAYMENTS] Get gold leads list';
export const GET_GOLD_LEADS_LIST_SUCCESS = '[PAYMENTS] Get gold leads list success';
export const GET_GOLD_LEADS_LIST_FAILURE = '[PAYMENTS] Get gold leads list failure';

export const GET_GOLD_LEADS_PER_MONTH = '[PAYMENTS] Get gold leads per month';
export const GET_GOLD_LEADS_PER_MONTH_SUCCESS = '[PAYMENTS] Get gold leads per month success';
export const GET_GOLD_LEADS_PER_MONTH_FAILURE = '[PAYMENTS] Get gold leads per month failure';

export const EXPORT_PAYMENTS_STATISTIC = '[PAYMENTS] Export payments statistics';
export const EXPORT_PAYMENTS_STATISTIC_SUCCESS = '[PAYMENTS] Export payments statistics success';
export const EXPORT_PAYMENTS_STATISTIC_FAILURE = '[PAYMENTS] Export payments statistics failure';

export const GET_MONTHLY_LEADS_LIST = '[PAYMENTS] Get monthly leads list';
export const GET_MONTHLY_LEADS_LIST_SUCCESS = '[PAYMENTS] Get monthly leads list success';
export const GET_MONTHLY_LEADS_LIST_FAILURE = '[PAYMENTS] Get monthly leads list failure';

export const GET_PAYMENTS_LEADS_COUNT = '[PAYMENTS] Get payments leads count';
export const GET_PAYMENTS_LEADS_COUNT_SUCCESS = '[PAYMENTS] Get payments leads count success';
export const GET_PAYMENTS_LEADS_COUNT_FAILURE = '[PAYMENTS] Get payments leads count failure';

export const CHANGE_DEALERSHIP_STATUS = '[GOLD_LEADS] Change dealership status';
export const CHANGE_DEALERSHIP_STATUS_SUCCESS = '[GOLD_LEADS] Change dealership status success';
export const CHANGE_DEALERSHIP_STATUS_FAILURE = '[GOLD_LEADS] Change dealership status failure';

export const GET_GOLD_DETAILS_BY_MONTH = '[PAYMENTS] Get gold details by month';
export const GET_GOLD_DETAILS_BY_MONTH_SUCCESS = '[PAYMENTS] Get gold details by month success';
export const GET_GOLD_DETAILS_BY_MONTH_FAILURE = '[PAYMENTS] Get gold details by month failure';

export const getGoldLeadsStatistic = createAction(
  GET_GOLD_LEADS_LIST,
  props<{ params?: IStatisticParams }>()
)

export const getGoldLeadsStatisticsSuccess = createAction(
  GET_GOLD_LEADS_LIST_SUCCESS,
  props<{ payload: IOrdersExtended }>()
)

export const getGoldLeadsStatisticsFailure = createAction(
  GET_GOLD_LEADS_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getGoldLeadsPerMonth = createAction(
  GET_GOLD_LEADS_PER_MONTH,
  props<{ params?: IStatisticParams }>()
)

export const getGoldLeadsPerMonthSuccess = createAction(
  GET_GOLD_LEADS_PER_MONTH_SUCCESS,
  props<{ payload: IPaymentsLeadsCount }>()
)

export const getGoldLeadsPerMonthFailure = createAction(
  GET_GOLD_LEADS_PER_MONTH_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const exportPaymentsStatistic = createAction(
  EXPORT_PAYMENTS_STATISTIC,
  props<{ params: IStatisticParams, fileName: string }>()
)

export const exportPaymentsStatisticSuccess = createAction(
  EXPORT_PAYMENTS_STATISTIC_SUCCESS
)

export const exportPaymentsStatisticFailure = createAction(
  EXPORT_PAYMENTS_STATISTIC_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getMonthlyLeadsList = createAction(
  GET_MONTHLY_LEADS_LIST,
  props<{ params?: IStatisticParams }>()
)

export const getMonthlyLeadsListSuccess = createAction(
  GET_MONTHLY_LEADS_LIST_SUCCESS,
  props<{ payload: IMonthlyLeadsListExtended }>()
)

export const getMonthlyLeadsListFailure = createAction(
  GET_MONTHLY_LEADS_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getPaymentsLeadsCount = createAction(
  GET_PAYMENTS_LEADS_COUNT,
  props<{ params?: IStatisticParams }>()
)

export const getPaymentsLeadsCountSuccess = createAction(
  GET_PAYMENTS_LEADS_COUNT_SUCCESS,
  props<{ payload: IPaymentsLeadsCount }>()
)

export const getPaymentsLeadsCountFailure = createAction(
  GET_PAYMENTS_LEADS_COUNT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changeDealerShipStatus = createAction(
  CHANGE_DEALERSHIP_STATUS,
  props<{ payload: { id: number, status: string } }>()
)

export const changeDealerShipStatusSuccess = createAction(
  CHANGE_DEALERSHIP_STATUS_SUCCESS,
  props<{ payload: ILeadExpanded }>()
)

export const changeDealerShipStatusFailure = createAction(
  CHANGE_DEALERSHIP_STATUS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const getGoldDetailsByMonth = createAction(
  GET_GOLD_DETAILS_BY_MONTH,
  props<{ params?: IStatisticParams }>()
)

export const getGoldDetailsByMonthSuccess = createAction(
  GET_GOLD_DETAILS_BY_MONTH_SUCCESS,
  props<{ payload: IOrdersExtended }>()
)

export const getGoldDetailsByMonthFailure = createAction(
  GET_GOLD_DETAILS_BY_MONTH_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
