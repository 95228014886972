<ng-container [ngSwitch]="data.modalType">
  <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="manageTWS"></ng-container>
  <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="manageTWS"></ng-container>
</ng-container>

<ng-template #manageTWS>
  <div class="dialog-title" mat-dialog-title>
    <ng-container [ngSwitch]="data.modalType">
      <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="addTitle"></ng-container>
      <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="editTitle"></ng-container>
    </ng-container>

    <ng-template #addTitle>
      <h2 *ngIf="data.vehicleType.id === 0" class="title">{{EVehicleType.carTitle | translate}}</h2>
      <h2 *ngIf="data.vehicleType.id === 1" class="title">{{EVehicleType.motoTitle | translate}}</h2>
    </ng-template>
    <ng-template #editTitle>
      <h2 *ngIf="data.vehicleType.id === 0" class="title">{{EVehicleType.editCarTitle | translate}}</h2>
      <h2 *ngIf="data.vehicleType.id === 1"
          class="title">{{EVehicleType.editMotoTitle | translate}}</h2>
    </ng-template>

    <div class="title-actions">
      <div class="actions-wrap" *ngIf="data.modalType !== EModalType.add
                                  && (data.subscriptionType.id === 1 || data.subscriptionType.id === 3 || data.subscriptionType.id === 6)">
        <div (click)="changePrices()" class="action">
          {{'MANAGE_TIRE_PRICES' | translate}}
        </div>
      </div>
      <mat-icon class="close" (click)="closeAddDialog()">
        close
      </mat-icon>
    </div>
  </div>
  <mat-dialog-content class="dialog-content">
    <div class="dialog-form-wrap" *ngIf="(TWSParams$ | async) as params">
      <div class="table-loader" *ngIf="!(TWSParams$ | async) || (loader$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <div [formGroup]="twsForm">
        <div class="row">
          <div class="col-6 form-col">
            <div class="form-group">
              <h4 class="form-group-title">
                {{'GENERAL_INFORMATION' | translate}}
              </h4>
            </div>
            <div class="form-group row">

              <div class="col-6 form-col">
                <app-input [isRequired]="true" [label]="ETWSLabel.userName" [placeholder]="ETWSPlaceholder.userName"
                           formControlName="UserName"></app-input>
              </div>

              <div class="col-6 form-col">
                <app-input [isRequired]="true" [label]="ETWSLabel.shopName" [placeholder]="ETWSPlaceholder.shopName"
                           formControlName="TWSName"></app-input>
              </div>
            </div>
            <div class="form-group row">

              <div class="col-6 form-col">
                <label class="label">
                  {{'PASSWORD' | translate}}<span *ngIf="isPasswordRequired">*</span>
                </label>
                <mat-form-field class="form-element" appearance="fill">
                  <input placeholder="{{'PASSWORD' | translate}}" matInput
                         type="password" autocomplete="new-password" formControlName="Password">
                </mat-form-field>
              </div>
              <div class="col-6 form-col">
                <label class="label">
                  {{'CONFIRM_PASSWORD' | translate}}<span *ngIf="isPasswordRequired">*</span>
                </label>
                <mat-form-field class="form-element" appearance="fill"
                                [ngClass]="{'errorInput': twsForm.hasError('passwordError') &&
                              (twsForm.get('ConfirmPassword').dirty || twsForm.get('ConfirmPassword').touched)}">
                  <input placeholder="{{'CONFIRM_PASSWORD' | translate}}" matInput
                         type="password" autocomplete="new-password" formControlName="ConfirmPassword">
                </mat-form-field>
                <div class="error-wrap" *ngIf="twsForm.hasError('passwordError') &&
                    (twsForm.get('ConfirmPassword').dirty || twsForm.get('ConfirmPassword').touched)"
                >{{twsForm.errors.passwordError | translate}}</div>
              </div>

            </div>
            <div class="form-group row">
              <div class="col-6 isActive">
                <mat-checkbox formControlName="IsActive"
                              class="form-checkbox">{{'ACTIVE' | translate}}</mat-checkbox>
              </div>
              <div class="col-6">
                <div class="phone-input">
                  <app-input [label]="'ZAP_TWS_ID'" [placeholder]="'ZAP_TWS_ID'" [isDisabled]="true"
                             formControlName="ZapTireShopId">{{'ACTIVE' | translate}}</app-input>
                </div>
                <div class="phone-input" *ngIf="data.modalType === EModalType.edit">
                  <app-input [label]="'ZAP_VIRTUAL_PHONE'" [isDisabled]="true"
                             formControlName="ZapPhone">{{'ACTIVE' | translate}}</app-input>
                </div>
              </div>
            </div>
            <div class="form-group row">

              <div class="col-6 form-col time-box">
                <label class="label">
                  {{'WORKING_HOURS_SU_THU' | translate}}
                </label>
                <div class="form-col time-wrap">
                  <div class="time-input">
                    <label class="label">
                      {{'FROM' | translate}}
                    </label>
                    <mat-form-field [ngClass]="(twsForm.hasError(ETWSControls.regularError) ||
                                      twsForm.hasError(ETWSControls.regularErrorTime)) ? 'errorInput' : ''"
                                    class="form-element time-elem" appearance="fill">
                      <input matInput type="time" #time
                             formControlName="RegularWorkingHours.OpenTime">
                    </mat-form-field>
                    <div *ngIf="twsForm.hasError(ETWSControls.regularError)" class="time-error">
                      {{twsForm.errors.regularError?.openingTime | translate}}
                    </div>
                  </div>

                  <div class="time-input">
                    <label class="label">
                      {{'TO' | translate}}
                    </label>
                    <mat-form-field [ngClass]="(twsForm.hasError(ETWSControls.regularError) ||
                                      twsForm.hasError(ETWSControls.regularErrorTime)) ? 'errorInput' : ''"
                                    class="form-element time-elem" appearance="fill">
                      <input matInput type="time" autocomplete="off" #time
                             formControlName="RegularWorkingHours.CloseTime">
                    </mat-form-field>
                    <div *ngIf="twsForm.hasError(ETWSControls.regularError)" class="time-error">
                      {{twsForm.errors.regularError?.closingTime | translate}}
                    </div>
                  </div>
                  <div *ngIf="twsForm.hasError(ETWSControls.regularErrorTime)"
                       class="time-error big-error">
                    {{twsForm.errors.regularErrorTime.timeError | translate}}
                  </div>
                </div>
              </div>
              <div class="col-6 form-col time-box">
                <label class="label">
                  {{'WORKING_HOURS_FRI' | translate}}
                </label>
                <div class="form-col time-wrap">
                  <div class="time-input">
                    <label class="label">
                      {{'FROM' | translate}}
                    </label>
                    <mat-form-field [ngClass]="(twsForm.hasError(ETWSControls.fridayError) ||
                                      twsForm.hasError(ETWSControls.fridayErrorTime)) ? 'errorInput' : ''"
                                    class="form-element time-elem" appearance="fill">
                      <input matInput type="time" autocomplete="off" #time
                             formControlName="FridayWorkingHours.OpenTime">
                    </mat-form-field>
                    <div *ngIf="twsForm.hasError(ETWSControls.fridayError)" class="time-error">
                      {{twsForm.errors.fridayError.openingTime | translate}}
                    </div>
                  </div>
                  <div class="time-input">
                    <label class="label">
                      {{'TO' | translate}}
                    </label>
                    <mat-form-field [ngClass]="(twsForm.hasError(ETWSControls.fridayError) ||
                                      twsForm.hasError(ETWSControls.fridayErrorTime)) ? 'errorInput' : ''"
                                    class="form-element time-elem" appearance="fill">
                      <input matInput type="time" autocomplete="off" #time
                             formControlName="FridayWorkingHours.CloseTime">
                    </mat-form-field>
                    <div *ngIf="twsForm.hasError(ETWSControls.fridayError)" class="time-error">
                      {{twsForm.errors.fridayError.closingTime | translate}}
                    </div>
                  </div>
                  <div *ngIf="twsForm.hasError(ETWSControls.fridayErrorTime)"
                       class="time-error big-error">
                    {{twsForm.errors.fridayErrorTime.timeError | translate}}
                  </div>
                </div>
              </div>

              <div class="col-6 form-col" [ngClass]="worksInSabbath ? 'time-show' : 'time-hidden'">
                <label class="label">
                  {{'WORKING_HOURS_SABBATH' | translate}}
                </label>
                <div class="form-col time-wrap">
                  <div class="time-input">
                    <label class="label">
                      {{'FROM' | translate}}
                    </label>
                    <mat-form-field [ngClass]="(twsForm.hasError(ETWSControls.sabbathError) ||
                                      twsForm.hasError(ETWSControls.sabbathErrorTime)) ? 'errorInput' : ''"
                                    class="form-element time-elem" appearance="fill">
                      <input matInput type="time" autocomplete="off" #sabbathTime
                             formControlName="SabbathWorkingHours.OpenTime">
                    </mat-form-field>
                    <div *ngIf="twsForm.hasError(ETWSControls.sabbathError)" class="time-error">
                      {{twsForm.errors.sabbathError.openingTime | translate}}
                    </div>
                  </div>
                  <div class="time-input">
                    <label class="label">
                      {{'TO' | translate}}
                    </label>
                    <mat-form-field [ngClass]="(twsForm.hasError(ETWSControls.sabbathError) ||
                                      twsForm.hasError(ETWSControls.sabbathErrorTime)) ? 'errorInput' : ''"
                                    class="form-element time-elem" appearance="fill">
                      <input matInput type="time" autocomplete="off" #sabbathTime
                             formControlName="SabbathWorkingHours.CloseTime">
                    </mat-form-field>
                    <div *ngIf="twsForm.hasError(ETWSControls.sabbathError)" class="time-error">
                      {{twsForm.errors.sabbathError.closingTime | translate}}
                    </div>
                  </div>
                  <div *ngIf="twsForm.hasError(ETWSControls.sabbathErrorTime)"
                       class="time-error big-error">
                    {{twsForm.errors.sabbathErrorTime.timeError | translate}}
                  </div>
                </div>
              </div>
              <!--              <div class="col-6 form-col dayOff">-->
              <!--                <app-select formControlName="DayOff" [label]="'DAY_OFF'" [placeholder]="'SELECT_DAY_OFF'"-->
              <!--                            [options]="daysOff"></app-select>-->
              <!--              </div>-->
              <div class="col-12">
                <mat-checkbox class="form-checkbox" (change)="checkOpenTime($event)" formControlName="IsOpenAllTime"
                >{{'OPEN_24' | translate}}/7
                </mat-checkbox>
              </div>
              <div class="col-12">
                <mat-checkbox class="form-checkbox" [(ngModel)]="worksInSabbath" [ngModelOptions]="{standalone: true}"
                              (change)="checkSaturdayTime()">{{'WORKS_ON_SABBATH' | translate}}</mat-checkbox>
              </div>
            </div>
            <div class="form-group row">

              <div class="col-6 form-col button-wrap">
                <app-select [isRequired]="true" [label]="ETWSLabel.location" [placeholder]="ETWSPlaceholder.location"
                            [options]="params.Location"
                            formControlName="AddressViewModel.LocationId"></app-select>
                <app-input style="margin-top: 10px" formControlName="GoogleSearchText" [label]="ETWSLabel.googleSearch"
                            [placeholder]="ETWSPlaceholder.googleSearch"></app-input>
              </div>
              <div class="col-6 form-col button-wrap">
                <!--                <div class="inputs-wrap">-->
                  <app-input [label]="ETWSLabel.street" [isRequired]="true"
                             [isDisabled]="true" formControlName="AddressViewModel.Street"></app-input>
                  <button class="btn-admin" (click)="openMap()">{{'SELECT_ADDRESS' | translate}}</button>
                  <!--                  <app-input class="custom-square" [placeholder]="ETWSPlaceholder.number"-->
                  <!--                             [isRequired]="true" formControlName="AddressViewModel.BuildingNumber"></app-input>-->
              </div>


            </div>
            <div class="from-group row">
              <div class="col-12">
                <app-input [label]="ETWSLabel.description" [placeholder]="ETWSPlaceholder.description"
                           [isTextArea]="true" formControlName="Description"></app-input>
              </div>
            </div>

            <div class="form-group row">

              <div class="col-12 form-col">
                <app-input [label]="ETWSLabel.coupon" [placeholder]="ETWSPlaceholder.coupon"
                           formControlName="Coupon"></app-input>
              </div>

            </div>

            <div class="image-loader">

              <app-image-uploader [loaderType]="EImgLoaderType.oneWithIcon" [showImage]="true" [imageSize]="imageSize"
                                  (uploadImage)="updateCompanyLogo($event)" [imgSrc]="data.logoUrl">
                <ng-container>
                  {{'COMPANY_LOGO' | translate}}
                </ng-container>
                <ng-container requirements>
                  {{'FILE_SIZE_MUST_BE_234PX82PX' | translate}}
                </ng-container>
              </app-image-uploader>
            </div>

            <div class="image-loader">

              <app-image-uploader [loaderType]="EImgLoaderType.multiple" [isMultiple]="true" [imageSize]="imageSize"
                                  [previousPictures]="data.additionalPictures" [showImage]="true"
                                  (removeImages)="getImagesToRemove($event)"
                                  (uploadImageArray)="updateAdditionalPhoto($event)">
                <ng-container>
                  {{'ADDITIONAL_PHOTO' | translate}}
                </ng-container>
                <ng-container requirements>
                  {{'YOU_CAN_UPLOAD_UP_TO_3_PHOTOS' | translate}}
                </ng-container>
              </app-image-uploader>
            </div>
          </div>
          <div class="col-6 form-col">
            <div class="form-group">
              <h4 class="form-group-title">
                {{'DETAILED_INFORMATION' | translate}}
              </h4>
            </div>

            <div class="form-group row">

              <div class="col-6 form-col">
                <app-input [isRequired]="true" [label]="ETWSLabel.contactName"
                           [placeholder]="ETWSPlaceholder.contactName"
                           formControlName="ContactName"></app-input>
              </div>

              <div class="col-6 form-col">
                <app-select [isRequired]="true" [label]="ETWSLabel.subType" [placeholder]="ETWSPlaceholder.subType"
                            [options]="params.Subscription" formControlName="Subscription"></app-select>
              </div>
            </div>


            <div class="form-group row">

              <div class="col-6 form-col">
                <app-input [isRequired]="subscription !== 7" [label]="ETWSLabel.secondContactPhone"
                           [placeholder]="ETWSPlaceholder.contactPhone"
                           [includePhoneCode]="true"
                           [isPhone]="true" formControlName="ContactPhoneNumber"></app-input>
              </div>
              <div class="col-6 form-col h-100">
                <app-input [label]="ETWSLabel.officePhone" [placeholder]="ETWSPlaceholder.officePhone"
                           [includePhoneCode]="true" [isPhone]="true" formControlName="OfficePhone"></app-input>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.contactPhone"
                           [placeholder]="ETWSPlaceholder.secondContactPhone"
                           formControlName="OwnerPhoneNumber"></app-input>
              </div>
            </div>


            <div class="form-group row">

              <div class="col-6 form-col">
                <app-input [isRequired]="true" [label]="ETWSLabel.email" [placeholder]="ETWSPlaceholder.email"
                           formControlName="Email"></app-input>
              </div>
              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.fax" [placeholder]="ETWSPlaceholder.fax"
                           formControlName="Fax"></app-input>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12">
                <mat-checkbox formControlName="SendSMS"
                              class="form-checkbox">{{'SEND_SMS_TO_TWS' | translate}}</mat-checkbox>
              </div>
            </div>

            <div class="form-group row">

              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.rimsPrice" [placeholder]="ETWSPlaceholder.rimsPrice"
                           [isRequired]="subscription === 4 || subscription === 1"
                           [isNumber]="true" formControlName="RimsLeadPrice"></app-input>
              </div>
              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.phonePrice" [placeholder]="ETWSPlaceholder.phonePrice"
                           [isRequired]="checkPriceMandatory()" [isNumber]="true"
                           formControlName="PhoneLeadPrice"></app-input>
              </div>
            </div>

            <div class="form-group row">

              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.reversePrice" [placeholder]="ETWSPlaceholder.reversePrice"
                           [isRequired]="checkPriceMandatory()" [isNumber]="true"
                           formControlName="ConverseLeadPrice"></app-input>
              </div>
              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.indexPrice" [placeholder]="ETWSPlaceholder.indexPrice"
                           [isNumber]="true" formControlName="IndexLeadPrice"
                           [isRequired]="subscription === 2 || checkPriceMandatory()"></app-input>
              </div>

            </div>

            <div class="form-group row">

              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.zapPrice" [placeholder]="ETWSPlaceholder.zapPrice"
                           [isRequired]="subscription === 5 || checkPriceMandatory()"
                           [isNumber]="true" formControlName="ZapLeadPrice"></app-input>
              </div>
              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.navPrice" [placeholder]="ETWSPlaceholder.navPrice"
                           [isRequired]="subscription === 2 || checkPriceMandatory()" [isNumber]="true"
                           formControlName="NavigationLeadPrice"></app-input>
              </div>
            </div>
            <div class="form-group row">

              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.starterPrice" [placeholder]="ETWSLabel.starterPrice"
                           [isNumber]="true" formControlName="StarterLeadPrice"></app-input>
              </div>

            </div>

            <div class="form-group row">
              <div class="col-6 form-col">
                <app-input [label]="ETWSLabel.waze" [placeholder]="ETWSPlaceholder.waze"
                           formControlName="WazeNavigation"></app-input>
              </div>
              <div class="col-6 form-col" *ngIf="data.vehicleType.id === 0">
                <app-multiple-select [label]="ETWSLabel.vehicleType" [placeholder]="ETWSPlaceholder.vehicleType"
                                     [options]="params.TWSTireType" formControlName="TireTypes"></app-multiple-select>
              </div>
              <div class="col-6 form-col" *ngIf="data.vehicleType.id === 1">
                <app-multiple-select [label]="ETWSLabel.vehicleType" [placeholder]="ETWSPlaceholder.vehicleType"
                                     [options]="params.MotorcycleTireType"
                                     formControlName="TireTypes"></app-multiple-select>
              </div>
            </div>

            <div class="form-group row">
<!--              <div class="col-6 form-col checkbox-row">-->
                <div class="col-6">
                  <app-input style="margin-top: 10px" formControlName="SubLocation" [label]="ETWSLabel.subLocation"
                             [placeholder]="ETWSPlaceholder.subLocation"></app-input>
                  <mat-checkbox class="form-checkbox" formControlName="IncludeVAT"
                  >{{'INCLUDED_VAT' | translate}}</mat-checkbox>
                </div>
                <!--                <div class="col-6">-->
                <!--                  <mat-checkbox class="form-checkbox" formControlName="CoveredLead"-->
                <!--                  >{{'COVERED_LEAD' | translate}}</mat-checkbox>-->
                <!--                </div>-->
<!--              </div>-->
              <div class="col-6 form-col" *ngIf="data.vehicleType.id === 0">
                <app-multiple-select [label]="ETWSLabel.moreOptions" [placeholder]="ETWSPlaceholder.moreOptions"
                                     [options]="params.TWSMoreOptions"
                                     formControlName="MoreOptions"></app-multiple-select>
              </div>
            </div>

            <div class="form-group row">

<!--              <div class="col-12 form-col">-->
<!--                <label class="label">-->
<!--                  {{'MANUFACTURERS' | translate}}*-->
<!--                </label>-->
<!--                <app-chips [dataList]="params.TireManufacturer"-->
<!--                           [checkedIds]="checkedBrands"-->
<!--                           (selectedIds)="getBrandsIds($event)"></app-chips>-->
<!--              </div>-->

            </div>

          </div>
        </div>

        <div class="save-btn">
          <button *ngIf="data.modalType === EModalType.add" [ngClass]="!twsForm.valid ? 'disabled' : ''"
                  class="btn-admin" (click)="submitForm(EModalType.add)">{{'SAVE' | translate}}
          </button>
          <button *ngIf="data.modalType === EModalType.edit" [ngClass]="!twsForm.valid ? 'disabled' : ''"
                  class="btn-admin" (click)="submitForm(EModalType.edit)">
            {{'SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>

  </mat-dialog-content>

</ng-template>
