<div class="plate-search-wrapper">
  <div class="plate-input">
    <input matInput
           [placeholder]="'ENTER_PLATE_NUMBER' | translate"
           [formControl]="plateControl"
           [mask]="selectedMask"
           (input)="updateMask($event)"
           type="text">
  </div>
  <div class="plate-search-result">
    <ng-container *ngIf="!selectedCar && (suitablePlates$ | async) as plates" >
      <ng-container *ngIf="(suitablePlatesLoading$ | async) && !plates.length && isPlateNumberCorrect">
        <div class="search-option empty">{{ 'SPECIFIED_PLATE_NUMBER_DONT_EXIST' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="!(suitablePlatesLoading$ | async) && plateControl?.dirty && !isPlateNumberCorrect">
        <div class="search-option empty">{{ 'PLEASE_ENTER_A_MINIMUM_OF_SIX_DIGITS' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="plates.length">
        <div class="search-option" *ngFor="let plate of plates" (click)="selectHandler(plate)">
          <div style="display: flex;align-items: center;">
            <img imgDir onErrorSrc="assets/icons/003-cogwheel.svg" [src]="plate.pictureUrl"
                 [alt]="plate.name">
            <div class="text">
              <span class="name">{{ plate.name }}</span>
            </div>
          </div>
          <button class="search-btn" data-i18n="SEARCH">
            {{ 'SEARCH' | translate }}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
