import { Component, OnInit } from '@angular/core';
import {
  IFilterValue,
  IStatusValue,
  TableMeta
} from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { combineLatest, Observable, Subject } from 'rxjs';
import { IFilters, IResponse } from '../../../api/api-data/api.interfaces';
import {
  ILead,
  IStatistic,
  IStatisticLead,
  IStatisticParams
} from '../../../modules/state/general-admin/leads/leads.model';
import { Store } from '@ngrx/store';
import { LanguageService } from '../../../api/language.service';
import {
  changeLeadTWSStatus,
  getLeadsList,
  getLeadsLoader,
  getLeadsTableData,
  getStatistic,
  selectLeadsStatusError,
  selectLeadStatusLoader,
  selectStatistic
} from '../../../modules/state/general-admin/leads';
import { takeUntil } from 'rxjs/operators';
import { getFilterQuery, isEmptyObj } from '../../../utils/helpers';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { monthNames, TWSLeadsTableMeta } from '../../business-user.constants';
import { EModalMessage } from '../../../t4u-admin/pages/criteria/components/criteria.constants';
import { NotificationService } from '../../../api/notification.service';
import { EStatisticTitle } from '../../../modules/statistics/components/statistics/statistics.enums';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { environment } from '../../../../environments/environment';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {
  public destroy$: Subject<void> = new Subject<void>();
  public tableMeta: TableMeta[];
  public leadsList$: Observable<IResponse<ILead[]>>;
  public filtersData: IFilters = {};
  public filters: string;
  public currentPage: number = 1;
  public isTableLoad$: Observable<boolean>;
  public statistic$: Observable<IStatistic | null>;
  public EStatisticTitle = EStatisticTitle;
  public userId: string;
  public params: IStatisticParams;
  public dateFrom: string;
  public dateTo: string;
  public month: string;
  public today: Date = new Date();
  public initialDateRange: DateRange<Date>;

  constructor(private store$: Store,
              private auth: AuthService,
              public languageService: LanguageService,
              public alertService: NotificationService,
              private router: Router,
              private _adapter: DateAdapter<Date>,
              public translate: TranslateService) {
    this._adapter.setLocale(localStorage.getItem('langCode') || environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.getCurrentMonth(this.today, this.today.getMonth());
    this.getData();
    this.checkLeadFilters();
  }

  getData(): void {
    this.userId = this.auth.reseller?.tireResellerId;
    if (!this.userId) {
      this.router.navigate(['auth/login']);
      return;
    }
    this.filtersData['TWSName'] = {value: [this.userId], sign: '=='};
    this.params = {tireResellerId: this.userId};
    this.filters = getFilterQuery(this.filtersData);
    this.loadLeadList(true);
    this.leadsList$ = this.store$.select(getLeadsTableData);
    this.isTableLoad$ = this.store$.select(getLeadsLoader);
    this.statistic$ = this.store$.select(selectStatistic);
  }

  getAmounts(params?: IStatisticParams): void {
    this.store$.dispatch(getStatistic({params}));
  }

  getTableFilters(event: IFilterValue): void {
    if (event.filterKey === 'FromDate' && event.date) {
      this.dateFrom = event.checkedIds[0] as string;
      this.dateTo = event.checkedIds[0] as string;
      // this.dateTo = this.convertTime(new Date().toISOString());
      this.month = `${new Date(event.checkedIds[0]).toLocaleDateString()}`;
      this.loadLeadList(true);
      return;
    }
    if (event.filterKey === 'ToDate' && event.date) {
      this.dateTo = event.checkedIds[0] as string;
      this.month += ` - ${new Date(event.checkedIds[0]).toLocaleDateString()}`;
      this.loadLeadList(true);
      return;
    }
    if (event.filterKey === 'TWSName' && event.checkedIds?.length) {
      this.userId = event.checkedIds[0] as string;
      this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
      this.filters = getFilterQuery(this.filtersData);
      this.currentPage = 1;
      this.loadLeadList(true);
      return;
    }
    if (event.filterKey === 'TWSName' && !event.checkedIds?.length) {
      this.userId = '';
      this.loadLeadList(true);
    }
    this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
    if (!event.checkedIds.length) delete this.filtersData[event.filterKey];
    this.filters = getFilterQuery(this.filtersData);
    this.currentPage = 1;
    this.loadLeadList();
  }

  loadLeadList(loadStatistic?: boolean): void {
    this.store$.dispatch(getLeadsList({
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      payload: {page: this.currentPage, filters: this.filters, recordLimit: 10}
    }));
    if (loadStatistic) {
      this.store$.dispatch(getStatistic({
        params: {
          tireResellerId: this.userId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        }
      }));
    }
  }

  changeStatus(value: IStatusValue): void {
    if (value.key === 'twsStatus') {
      this.store$.dispatch(changeLeadTWSStatus({id: value.id, status: value.status}));
      this.checkRequest(EModalMessage.statusChanged);
    }
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(selectLeadsStatusError),
      this.store$.select(selectLeadStatusLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.error.message);
        }
      })
  }

  getCurrentMonth(date: Date, month: number): void {
    date.setFullYear(date.getFullYear(), month, 1);
    this.month = monthNames[this.today.getMonth()].value;
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 11);
    let lastDay;
    this.dateFrom = this.convertTime(firstDay.toISOString());
    this.initialDateRange = new DateRange<Date>(new Date(this.dateFrom), new Date());
    if (date.getTime() === this.today.getTime()) {
      lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 11);
      this.dateTo = this.convertTime(lastDay.toISOString());
      return;
    }
    lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 11);
    this.dateTo = this.convertTime(lastDay.toISOString());
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.store$.dispatch(getLeadsList({
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      payload: {page: this.currentPage, filters: this.filters, recordLimit: page * 10}
    }));
  }

  checkLeadFilters(): void {
    if (!this.auth.reseller) {
      this.router.navigate(['auth/login']);
      return;
    }
    const sub = this.auth.reseller.subscriptionType.id;
    switch (sub) {
      case (4):
        this.tableMeta = TWSLeadsTableMeta.filter(col => col.name !== 'LEAD_TYPE');
        break;
      case (5):
        this.tableMeta = TWSLeadsTableMeta.filter(col => col.name !== 'LEAD_TYPE');
        break;
      default:
        this.tableMeta = TWSLeadsTableMeta;
    }
  }

  checkLeadsBySubscription(leads: IStatisticLead[]): IStatisticLead[] {
    if (!this.auth.reseller) {
      this.router.navigate(['auth/login']);
      return [];
    }
    const sub = this.auth.reseller.subscriptionType.id;
    let list = [...leads];
    switch (sub) {
      case (4):
        const rimLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.rims);
        const converse = list.filter(lead => lead.leadsTitle === EStatisticTitle.converse);
        list = [...rimLeads, ...converse];
        break;
      case (2):
        const indexLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.index);
        const navLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.nav);
        const converseL = list.filter(lead => lead.leadsTitle === EStatisticTitle.converse);
        list = [...indexLeads, ...navLeads, ...converseL];
        break;
      case (5):
        const zapLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.zap);
        const converseLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.converse);
        list = [...zapLeads, ...converseLeads];
        break;
      case (3):
        const goldLeads = list.filter(lead => lead.leadsTitle === EStatisticTitle.gold);
        const converseLead = list.filter(lead => lead.leadsTitle === EStatisticTitle.converse);
        list = [...goldLeads, ...converseLead];
        break;
      default:
        list = [...leads]
    }
    return list;
  }

  convertTime(date: string): string {
    if (!date) return '';
    const fullDate = new Date(date);
    const month = (fullDate.getMonth() < 9 ? '0' : '') + (fullDate.getMonth() + 1);
    const day = (fullDate.getDate() < 10 ? '0' : '') + fullDate.getDate();
    const year = fullDate.getFullYear();
    const shortDate = [year, month, day];
    return shortDate.join('-');
  }

  filterContent(content: ILead[], totalRecord: number): ILead[] {
    if (!content) return [];
    const pageSize = Math.ceil(totalRecord / 10);
    const lastLeads = (totalRecord / 10).toString().split('.')[1];
    if (this.currentPage === pageSize && this.currentPage !== 1) return content.slice(-(+lastLeads));
    return content.slice(-10);
  }

  changeVirtualNumber(number: string): string {
    if(!number.includes('972')) return number;
    let newNumber = number.split('972')[1];
    const firstLetters = newNumber.slice(0, 2);
    newNumber = newNumber.slice(2, newNumber.length)
    return `0${firstLetters}-${newNumber}`;
  }

  opent4uLink(event: any): void {
    if (!event.data.converseUrl) return;
    const url = decodeURI(event.data.converseUrl);
    window.open(url, '_blank')
  }
}
