import { HttpErrorResponse } from "@angular/common/http";

export interface WeightCodeElement {
  id: number,
  value: string,
  isActive: boolean,
  priority: string,
  additionalValue: string
}

export interface WeightCodeState {
  current: WeightCodeElement,
  deleteId: number | null,
  result: any,
  isLoading: boolean,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  content: WeightCodeElement[],
  error: HttpErrorResponse
}
