import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  IEditablePrice,
  IFilterValue,
  TableMeta
} from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { ManageCarTiresDialogComponent } from "../dialog-manage-car-tires/manage-car-tires-dialog.component";
import {
  changTireBruttoPrice,
  changTireVisibility,
  exportFileAboutCarTire,
  getCarTiresList,
  ICarTireDBData,
  ICarTiresParams
} from "../../../../../modules/state/general-admin/manage-car-tires";
import {
  getCarTiresError,
  getCarTiresLoader,
  getCarTiresSuccessLoader,
  getManageCarTiresData
} from "../../../../../modules/state/general-admin/manage-car-tires/manage-car-tires.selectors";
import { ManageCarTableMeta } from '../../../pages.constants';
import {
  getCharacteristicOptionsList,
  getLookupParameters
} from "../../../../../modules/state/general-admin/characteristic";
import { LanguageService } from '../../../../../api/language.service';
import { IFilters, IHeadFilters, IResponse } from '../../../../../api/api-data/api.interfaces';
import {
  EModalType,
  EVehicleType,
  ITableCheckData,
  ITableData
} from '../../../../../modules/shared/interfaces/table.interfaces';
import { getFilterQuery, isEmptyObj } from '../../../../../utils/helpers';
import { ETireRoute } from '../../manage-car.enums';
import { NotificationService } from '../../../../../api/notification.service';
import { EModalMessage } from '../../../criteria/components/criteria.constants';

@Component({
  selector: 'app-manage-car-tires',
  templateUrl: './manage-car-tires.component.html',
  styleUrls: ['./manage-car-tires.component.scss']
})
export class ManageCarTiresComponent implements OnInit, OnDestroy {
  public tableMeta: TableMeta[] = ManageCarTableMeta;
  public carTiresList$: Observable<IResponse<ICarTireDBData[]>>;
  public currentPage: number = 1;
  public filtersData: IFilters = {};
  public filters: string = '';
  public searchControl: FormControl = new FormControl('');
  public searchQuery: string = '';
  public destroy$: Subject<void> = new Subject<void>();
  public route: string;
  public isTableLoad$: Observable<boolean>;
  public sortingData: string[] = [];
  public sorts: string = 'BruttoPrice';

  constructor(private dialog: MatDialog,
              private store$: Store,
              private activatedRoute: ActivatedRoute,
              private alertService: NotificationService,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.route = this.activatedRoute.snapshot.url[0].path;
    this.carTiresList$ = this.store$.select(getManageCarTiresData);
    this.isTableLoad$ = this.store$.select(getCarTiresLoader);
    if (this.route === ETireRoute.review) {
      this.filtersData['isActive'] = {value: ['false'], sign: '=='};
      this.filters = getFilterQuery(this.filtersData);
      this.store$.dispatch(getCarTiresList({params: {page: 1, filters: this.filters, sorts: this.sorts}}));
      return;
    }
    this.store$.dispatch(getCarTiresList({params: {page: 1, sorts: this.sorts}}));
    this.store$.dispatch(getCharacteristicOptionsList({id: '1,2,4,5,7,8,9,12'}));
    this.store$.dispatch(getLookupParameters({payload: 'TireManufacturer,TireType,SubCarTireType'}));
  }

  getCarTireList(): void {
    const params: ICarTiresParams = {
      page: this.currentPage,
      filters: getFilterQuery(this.filtersData),
      search: this.searchQuery,
      sorts: this.sorts
    };
    this.store$.dispatch(getCarTiresList({params}));
  }

  openAddDialog(): void {
    this.dialog.open(ManageCarTiresDialogComponent, {
      panelClass: 'dialog-manage-car-tires',
      width: '926px',
      data: {modalType: EModalType.add}
    })
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.getCarTireList();
  }

  dispatchEvent(table: ITableData<ICarTireDBData>): void {
    if (table.action.action === 'copy') {
      this.copyTire(table.data);
      return;
    }
    this.dialog.open(ManageCarTiresDialogComponent, {
      panelClass: 'dialog-manage-car-tires',
      width: '926px',
      data: {...table.data, modalType: EModalType.edit}
    })
  }

  copyTire(tire: ICarTireDBData): void {
    const newTire = {
      id: tire.id,
      name: tire.name,
      brand: {id: tire.brand.id, value: ''},
      bruttoPrice: tire.bruttoPrice,
      tireType: tire.tireType,
      subTireType: tire.subTireType || '',
      runFlate: tire.runFlate,
      diameter: tire.diameter,
      height: tire.height,
      speedCode: tire.speedCode,
      weightCode: tire.weightCode,
      width: tire.width,
      noiseLevel: tire.noiseLevel,
      roadGrip: tire.roadGrip,
      fuelConsumption: tire.fuelConsumption,
    }
    this.dialog.open(ManageCarTiresDialogComponent, {
      panelClass: 'dialog-manage-car-tires',
      width: '926px',
      data: {...newTire, modalType: EModalType.edit, copyTire: true}
    })
  }

  getFilters(filtersValue: IFilterValue): void {
    this.filtersData[filtersValue.filterKey] = {value: filtersValue.checkedIds, sign: '=='};
    if (!filtersValue.checkedIds.length) delete this.filtersData[filtersValue.filterKey];
    this.filters = getFilterQuery(this.filtersData);
    this.currentPage = 1;
    this.getCarTireList();
  }

  getHeadFilters(filters: IHeadFilters): void {
    for (const key in filters) {
      // @ts-ignore
      this.filtersData[key] = {value: [filters[key]], sign: '=='};
      if (!filters[key]) delete this.filtersData[key];
    }
    this.filters = getFilterQuery(this.filtersData);
    this.currentPage = 1;
    this.getCarTireList();
  }

  export(): void {
    this.store$.dispatch(exportFileAboutCarTire({filters: this.filters, fileName: 'Car_Tires'}));
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$),
      switchMap((value) => {
        this.searchQuery = value;
        this.store$.dispatch(getCarTiresList({
          params: {
            page: 1,
            search: value,
            filters: this.filters,
            sorts: this.sorts
          }
        }));
        return this.store$.select(getManageCarTiresData);
      })).pipe(takeUntil(this.destroy$)).subscribe();
  }

  changeVisibility(event: ITableCheckData<ICarTireDBData>): void {
    const isForReview = this.route === ETireRoute.review;
    this.store$.dispatch(changTireVisibility({id: event.target.id, forReviews: isForReview}));
    this.checkRequest(EModalMessage.statusChanged)
  }

  changePrices(): void {
    const params = {
      page: this.currentPage,
      search: this.searchQuery,
      filters: this.filters,
      sorts: this.sorts
    }
    this.dialog.open(ManageCarTiresDialogComponent, {
      panelClass: 'dialog-manage-car-tires',
      width: '580px',
      data: {modalType: EModalType.prices, params},
    })
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getCarTiresError),
      this.store$.select(getCarTiresSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }

  sortData(sortValue: string): void {
    if (sortValue.includes('-')) {
      this.sortingData = this.sortingData.filter(item => item !== sortValue.slice(1));
    } else {
      this.sortingData = this.sortingData.filter(item => item !== `-${sortValue}`);
    }
    this.sortingData.push(sortValue);
    this.sorts = this.sortingData.join();
    this.getCarTireList();
  }

  getNewBruttoPrice(event: IEditablePrice, list: ICarTireDBData[]): void {
    const content = [...list];
    const item = content.find(tire => tire.id === event.id);
    let same: boolean = item?.id === event.id && item?.bruttoPrice === event.price;
    if (same) return;
    const value: any = {
      ArticleId: event.id.toString(),
      BruttoPrice: event.price.toString(),
      VehicleType: EVehicleType.car
    };
    const payload = new FormData();
    for (const key in value) payload.append(key, value[key]);
    this.store$.dispatch(changTireBruttoPrice({id: event.id, payload}));
    this.checkRequest(EModalMessage.pricesChanged);
  }
}
