<div class="dialog-title" mat-dialog-title>

  <h2 class="title">
    {{ 'SEARCH_TIRE' | translate }}
  </h2>

  <div class="title-actions">
    <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="dialog-content">
  <div class="filters">
    <app-filter-selects [filters]="(tiresList$ | async)?.filters"
                        (filtersChange)="getFilters($event)"
                        (plateNumberChange)="searchByPlate($event)"
                        [orderEditing]="true"
    ></app-filter-selects>
  </div>
  <div class="table-loader" *ngIf="(isLoading$ | async)">
    <mat-spinner></mat-spinner>
  </div>

  <ng-container *ngIf="(tiresList$ | async) as tires">
    <div class="tires-table-container">
      <table class="tires-table">
        <thead>
        <tr>
          <th>{{ 'TIRE_NAME' | translate }}</th>
          <th>{{ 'MANUFACTURER' | translate }}</th>
          <th>{{ 'PRICE' | translate }}</th>
          <th>{{ 'WEIGHT_CODE' | translate }}</th>
          <th>{{ 'DIAMETER' | translate }}</th>
          <th>{{ 'HEIGHT' | translate }}</th>
          <th>{{ 'WIDTH' | translate }}</th>
          <th>{{ 'TIRE_TYPE' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tire of tires.content" (click)="selectTire(tire)">
          <td>{{ tire.name }}</td>
          <td>{{ tire.brand.value }}</td>
          <td>{{ tire.price }}</td>
          <td>{{ tire.weightCode.value }}</td>
          <td>{{ tire.diameter.value }}</td>
          <td>{{ tire.height.value }}</td>
          <td>{{ tire.width.value }}</td>
          <td><span *ngFor="let type of tire.tireType">, {{ type.value }} </span></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="tires.content && !tires.content.length" class="no-result-table">
      {{ 'NO_DATA_MATCHING_THE_FILTER' | translate }}
    </div>
    <div class="pagination" *ngIf="tires.content && tires.pageCount > 1">
          <span class="pagination-item"
                *ngFor="let page of tires.content | paginate:
                { itemsPerPage: 10,
                 currentPage: currentPage,
                 totalItems: tires.rowCount }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </ng-container>
</mat-dialog-content>
