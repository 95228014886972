export enum EAdminNotificationLabel {
  carTWS = 'SEND_TO_TWS_TIRE_SHOPS',
  motoTWS = 'SEND_TO_TWS_MOTORCYCLE_SHOPS'
}

export enum EAdminTWSTypes {
  car = 'car',
  moto = 'motorcycle'
}

export interface IAdminControlValue {
  label: string,
  value: string,
  status: boolean
}

export const adminTypesArray = [
  {value: EAdminTWSTypes.car, label: EAdminNotificationLabel.carTWS},
  {value: EAdminTWSTypes.moto, label: EAdminNotificationLabel.motoTWS}
];
