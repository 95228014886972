import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../../../../api/language.service';
import { sortContentByValue } from '../../../../../utils/helpers';
import { TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { WidthTableMeta } from '../criteria.constants';
import { EModalType, ICriteriaItem, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import { getWidthData, WidthElement } from '../../../../../modules/state/general-admin/criteria/width';
import { WidthDialogComponent } from './width-dialog/width-dialog.component';
import {
  getWidthLoader,
  getWidthTable
} from '../../../../../modules/state/general-admin/criteria/width/width.selectors';

@Component({
  selector: 'app-width',
  templateUrl: './width.component.html',
  styleUrls: ['./width.component.scss']
})
export class WidthComponent implements OnInit {

  public tableMeta: TableMeta[] = WidthTableMeta;
  public modalType: EModalType = EModalType.add;
  public tableContent$: Observable<WidthElement[]>;
  public isTableLoad$: Observable<boolean>;
  public sorts: string = '';
  public searchControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();
  public searchQuery: string = '';
  public sortContent: Function = sortContentByValue;

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  public openAddDialog(): void {
    this.dialog.open(WidthDialogComponent, {
      panelClass: 'admin-dialog',
      data: {type: EModalType.add}
    })
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.store$.dispatch(getWidthData());
    this.tableContent$ = this.store$.select(getWidthTable);
    this.isTableLoad$ = this.store$.select(getWidthLoader);
  }

  actionDispatch(table: ITableData<ICriteriaItem>): void {
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete
    }
    this.changeCriteria(table.data);
  }

  changeCriteria(item: ICriteriaItem): void {
    this.dialog.open(WidthDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, type: this.modalType}
    })
  }

  getSortValue(sorts: string): void {
    this.sorts = sorts;
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchQuery = value;
      })
  }
}
