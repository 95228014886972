import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

import { IResponse } from '../../../../../api/api-data/api.interfaces';
import { IDbLocation, ILocation, ILocationParams } from './location.model';

export const GET_LOCATION_DATA = '[LOCATION] Get data';
export const GET_LOCATION_DATA_SUCCESS = '[LOCATION] Get location data success';
export const GET_LOCATION_DATA_FAILURE = '[LOCATION] Get location data failure';

export const ADD_LOCATION = '[LOCATION] Add location';
export const ADD_LOCATION_SUCCESS = '[LOCATION] Add location success';
export const ADD_LOCATION_FAILURE = '[LOCATION] Add location failure';

export const UPDATE_LOCATION = '[LOCATION] Update location';
export const UPDATE_LOCATION_SUCCESS = '[LOCATION] Update location success';
export const UPDATE_LOCATION_FAILURE = '[LOCATION] Update location failure';

export const getLocationData = createAction(
  GET_LOCATION_DATA,
  props<{ payload: ILocationParams }>()
)

export const getLocationDataSuccess = createAction(
  GET_LOCATION_DATA_SUCCESS,
  props<{ payload: IResponse<IDbLocation[]> }>()
)

export const getLocationDataFailure = createAction(
  GET_LOCATION_DATA_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addLocation = createAction(
  ADD_LOCATION,
  props<{ payload: ILocation }>()
)

export const addLocationSuccess = createAction(
  ADD_LOCATION_SUCCESS,
  props<{ payload: IDbLocation }>()
)

export const addLocationFailure = createAction(
  ADD_LOCATION_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateLocation = createAction(
  UPDATE_LOCATION,
  props<{ payload: IDbLocation }>()
)

export const updateLocationSuccess = createAction(
  UPDATE_LOCATION_SUCCESS,
  props<{ payload: IDbLocation }>()
)

export const updateLocationFailure = createAction(
  UPDATE_LOCATION_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
