import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";

import { IFilterValue, TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { BlogAddDialogComponent } from "./blog-add-dialog/blog-add-dialog.component";
import { blogTableMeta } from '../pages.constants';
import { IFilters, IResponse } from '../../../api/api-data/api.interfaces';
import { BlogDBElement, changeElementVisibility, getBlogData } from '../../../modules/state/general-admin/blog';
import { EModalType, ITableCheckData, ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { getBlogLoader, getBlogTable } from '../../../modules/state/general-admin/blog/blog.selectors';
import { LanguageService } from '../../../api/language.service';
import { getFilterQuery } from '../../../utils/helpers';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  public tableMeta: TableMeta[] = blogTableMeta;
  public blogList$: Observable<IResponse<BlogDBElement[]>>;
  public currentPage: number = 1;
  public modalType: string = EModalType.add;
  public filtersData: IFilters = {};
  public isTableLoad$: Observable<boolean>;

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.store$.dispatch(getBlogData({params: {page: this.currentPage}}));
    this.blogList$ = this.store$.select(getBlogTable);
    this.isTableLoad$ = this.store$.select(getBlogLoader);
  }

  openAddDialog(): void {
    this.dialog.open(BlogAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {modalType: EModalType.add}
    })
  }

  paginate(page: number): void {
    this.currentPage = page;
    const filters = getFilterQuery(this.filtersData);
    this.store$.dispatch(getBlogData({params: {page: this.currentPage, filters}}));
  }

  changeVisibility(event: ITableCheckData<BlogDBElement>): void {
    this.store$.dispatch(changeElementVisibility({id: event.target.id}));
  }

  dispatchEvent(table: ITableData<BlogDBElement>): void {
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete
    }
    this.changePost(table.data);
  }

  changePost(item: BlogDBElement): void {
    this.dialog.open(BlogAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, modalType: this.modalType}
    })
  }

  getFilters(filtersValue: IFilterValue): void {
    this.filtersData[filtersValue.filterKey] = {value: filtersValue.checkedIds, sign: '=='};
    if (!filtersValue.checkedIds.length) delete this.filtersData[filtersValue.filterKey];
    const filters = getFilterQuery(this.filtersData);
    this.store$.dispatch(getBlogData({params: {page: this.currentPage, filters}}));
  }
}
