import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";
import { WidthElement } from './width.model';

export const GET_WIDTH_DATA = '[WIDTH] Get data';
export const GET_WIDTH_DATA_SUCCESS = '[WIDTH] Get data success';
export const GET_WIDTH_DATA_FAILURE = '[WIDTH] Get data failure';

export const ADD_WIDTH_ELEMENT = '[WIDTH] Add element';
export const ADD_WIDTH_ELEMENT_SUCCESS = '[WIDTH] Add element success';
export const ADD_WIDTH_ELEMENT_FAILURE = '[WIDTH] Add element failure';

export const UPDATE_WIDTH_ELEMENT = '[WIDTH] Update element';
export const UPDATE_WIDTH_ELEMENT_SUCCESS = '[WIDTH] Update element success';
export const UPDATE_WIDTH_ELEMENT_FAILURE = '[WIDTH] Update element failure';

export const DELETE_WIDTH_ELEMENT = '[WIDTH] Delete element';
export const DELETE_WIDTH_ELEMENT_SUCCESS = '[WIDTH] Delete element success';
export const DELETE_WIDTH_ELEMENT_FAILURE = '[WIDTH] Delete element failure';

export const getWidthData = createAction(
  GET_WIDTH_DATA,
)

export const getWidthDataSuccess = createAction(
  GET_WIDTH_DATA_SUCCESS,
  props<{ payload: WidthElement[] }>()
)

export const getWidthDataFailure = createAction(
  GET_WIDTH_DATA_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addWidthElement = createAction(
  ADD_WIDTH_ELEMENT,
  props<{ payload: WidthElement }>()
)

export const addWidthElementSuccess = createAction(
  ADD_WIDTH_ELEMENT_SUCCESS,
  props<{ payload: WidthElement }>()
)

export const addWidthElementFailure = createAction(
  ADD_WIDTH_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateWidthElement = createAction(
  UPDATE_WIDTH_ELEMENT,
  props<{ payload: WidthElement, id: number }>()
)

export const updateWidthElementSuccess = createAction(
  UPDATE_WIDTH_ELEMENT_SUCCESS,
  props<{ payload: WidthElement }>()
)

export const updateWidthElementFailure = createAction(
  UPDATE_WIDTH_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteWidthElement = createAction(
  DELETE_WIDTH_ELEMENT,
  props<{ payload: number }>()
)

export const deleteWidthElementSuccess = createAction(
  DELETE_WIDTH_ELEMENT_SUCCESS
)

export const deleteWidthElementFailure = createAction(
  DELETE_WIDTH_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
