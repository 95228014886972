import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ILeadsStatusState, ILeadStatusDB } from './leads-status.model';

export const getLeadsStatusState = createFeatureSelector<ILeadsStatusState>('leadStatus');

export const getLeadStatusTable = createSelector(getLeadsStatusState,
  (state: ILeadsStatusState): ILeadStatusDB[] => state.content);

export const getLeadStatusSuccessLoader = createSelector(getLeadsStatusState,
  (state: ILeadsStatusState) => state.isLoadingSuccess);

export const getLeadStatusLoader = createSelector(getLeadsStatusState,
  (state: ILeadsStatusState) => state.isLoading);

export const getLeadStatusError = createSelector(getLeadsStatusState,
  (state: ILeadsStatusState) => state.error);
