import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {
  IDbLocation,
  ILocation,
  ILocationParams
} from '../../modules/state/general-admin/criteria/location';
import { IResponse } from '../api-data/api.interfaces';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  readonly PATH = '/location';

  constructor(private apiService: ApiService) {
  }

  getLocationData(params: ILocationParams): Observable<IResponse<IDbLocation[]>> {
    return this.apiService.get(this.PATH, {...params});
  }

  addLocation(element: ILocation): Observable<IDbLocation> {
    return this.apiService.post(this.PATH, element);
  }

  updateLocation(element: IDbLocation): Observable<IDbLocation> {
    return this.apiService.put(`${ this.PATH }`, element);
  }
}
