<div class="content statistics" dir="rtl" [formGroup]="dateFilterForm">
  <div class="content-head">
    <div class="head-title">{{'GOLD_LEAD' | translate}}</div>
    <div class="btn-block">
      <div class="select">
        <div class="input">
          <mat-date-range-input [rangePicker]="picker" [max]="today" [min]="minDate">
            <input matStartDate formControlName="dateFrom" placeholder="{{'START_DATE' | translate}}">
            <input matEndDate formControlName="dateTo" placeholder="{{'END_DATE' | translate}}">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker [disabled]="false" #picker></mat-date-range-picker>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content-wrapper " *ngIf="(goldLeadsContent$ | async) as content">
  <div class="content-wrapper-item">
    <div>
      <div class="content-body table">
        <starter-table
          [tableMeta]="goldLeadsTableMeta"
          [tableData]="content.result"
          [language]="languageService.mainLanguage.value"
          [isLoadingTable]="!(isTableLoad$ | async)"
          (dropdownValue)="changeStatus($event)"
        >
        </starter-table>
<!--        <div *ngIf="content && !content.result.length" class="no-result-table">-->
<!--          {{'NO_DATA_MATCHING_THE_FILTER' | translate}}-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>


