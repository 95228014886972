import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import { NotificationService } from '../../../../api/notification.service';
import {
  EBannerType,
  getBannersList,
  IBanner,
  IBannerFormControl,
  updateBanner
} from '../../../../modules/state/general-admin/banners';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEmptyObj } from '../../../../utils/helpers';
import { EModalMessage } from '../../criteria/components/criteria.constants';
import {
  getBannersError,
  getBannersLoader,
  getBannersSuccessLoader
} from '../../../../modules/state/general-admin/banners/banners.selectors';
import { IImageSize } from '../../../../modules/shared/enums/imageLoader.enums';
import { ImageModeEnum } from '../../../../modules/shared/enums/imageMode.enums';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-banners-add-dialog',
  templateUrl: './banners-add-dialog.component.html',
  styleUrls: ['./banners-add-dialog.component.scss']
})
export class BannersAddDialogComponent implements OnInit, OnDestroy {

  public bannerForm: FormGroup;
  public destroy$: Subject<void> = new Subject<void>();
  public isLoad$: Observable<boolean>;
  public imageSize: IImageSize;
  public saveAsGoogleAds: boolean = false;
  public removingBanners: number[] = [];

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private store$: Store,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: IBanner) {
  }

  ngOnInit(): void {
    this.initForm();
    this.checkImageSize();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.isLoad$ = this.store$.select(getBannersLoader);
    this.bannerForm = this.fb.group({
      Id: [this.data.id],
      Visibility: [this.data.visibility],
      BannerImages: this.fb.array([]),
    })
    const images = this.data.banner.images;

    for (const image of images) {
      this.bannersArray.push(this.fb.group({
        id: [image.id],
        HayperLink: [image.clipUrl || ''],
        TagName: [image.tagName || ''],
        GoogleAdCode: [image.googleAdCode || ''],
        "Image.Mode": [ImageModeEnum.Replace],
        "Image.File": [null],
        saveAsGoogleAds: [!!image.googleAdCode]
      }))
    }
  }

  addBanner(): void {
    this.bannersArray.push(this.fb.group({
      HayperLink: [''],
      TagName: [''],
      GoogleAdCode: [''],
      "Image.Mode": [ImageModeEnum.Replace],
      "Image.File": [null],
      saveAsGoogleAds: [false]
    }));
  }

  removeBanner(index: number): void {
    const formGroupAtIndex = this.bannersArray.at(index) as FormGroup;
    const id = formGroupAtIndex.value?.id;
    this.bannersArray.removeAt(index);

    if (!id) return;
    this.removingBanners.push(id);
  }

  get bannersArray(): FormArray {
    return this.bannerForm.controls["BannerImages"] as FormArray;
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  checkImageSize(): void {
    if (this.data.bannerType === EBannerType.mobile) {
      this.imageSize = {width: 300, height: 50};
      return;
    }
    if (this.saveAsGoogleAds && this.data.bannerType === EBannerType.desktop) {
      switch (this.data.location) {
        case ('Right'):
          this.imageSize = {width: 160, height: 600}
          break;
        case ('Bottom'):
          this.imageSize = {width: 970, height: 250}
          break;
        case ('Top'):
          this.imageSize = {width: 250, height: 250}
          break;
        default:
          this.imageSize = {width: 1300, height: 510}
      }
      return;
    }
    switch (this.data.location) {
      case ('Right'):
        this.imageSize = {width: 140, height: 510}
        break;
      case ('Bottom'):
        this.imageSize = {width: 1280, height: 280}
        break;
      case ('Top'):
        this.imageSize = {width: 210, height: 340}
        break;
      default:
        this.imageSize = {width: 1300, height: 510}
    }
  }

  submit(): void {
    this.bannerForm.markAllAsTouched();
    if (!this.bannerForm.valid) return;
    const payload = new FormData();

    payload.append('Id', this.bannerForm.value.Id);
    payload.append('Visibility', this.bannerForm.value.Visibility);

    const images = this.bannersArray.value as IBannerFormControl[];
    images.forEach((file, idx) => {
      payload.append(`BannerImages[${idx}].Image.Mode`, file['Image.Mode']);
      payload.append(`BannerImages[${idx}].Image.File`, file['Image.File']);
      payload.append(`BannerImages[${idx}].HayperLink`, file.HayperLink);
      payload.append(`BannerImages[${idx}].TagName`, file.TagName);
      payload.append(`BannerImages[${idx}].GoogleAdCode`, file.GoogleAdCode);
    });

    if (!this.removingBanners.length) {
      this.store$.dispatch(updateBanner({payload}));
      this.checkRequest(EModalMessage.edit);
      return;
    }

    this.removingBanners.forEach((item, idx) => {
      payload.append(`RemoveBannerImages[${idx}]`, item.toString());
    })
    this.store$.dispatch(updateBanner({payload}));
    this.checkRequest(EModalMessage.edit);
  }

  changeImg(image: File, idx: number): void {
    const formGroupAtIndex = this.bannersArray.at(idx) as FormGroup;
    if (!!formGroupAtIndex.value?.id) {
      this.removingBanners.push(formGroupAtIndex.value.id);
    }
    formGroupAtIndex.controls['Image.File'].setValue(image);
    formGroupAtIndex.controls['Image.Mode'].setValue(ImageModeEnum.Replace);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getBannersError),
      this.store$.select(getBannersSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.store$.dispatch(getBannersList({typeId: this.data.typeId}));
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) {
            this.alertService.onError(error.statusText);
          }
        }
      })
  }

  changeImageToGoogleAd(event: MatCheckboxChange, idx: number): void {
    this.checkImageSize();
    const formGroupAtIndex = this.bannersArray.at(idx) as FormGroup;
    formGroupAtIndex.controls['GoogleAdCode'].setValue('');
  }
}
