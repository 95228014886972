import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPaymentsState } from './payments.model';

export const getPaymentsState = createFeatureSelector<IPaymentsState>('payments');

export const selectPaymentsLeadsCount = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.paymentsLeads);

export const selectPaymentsLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.isLoading);

export const selectPaymentsSuccessLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.isLoadingSuccess);

export const selectPaymentsError = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.error);

export const selectMonthlyLeadsList = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.monthlyLeadsList.list);

export const selectMonthlyLeadsLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.monthlyLeadsList.isLoader);

export const selectMonthlyLeadsSuccessLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.monthlyLeadsList.isLoadSuccess);

export const selectGoldLeadStatistic = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldLeadsStatistics.list);

export const selectGoldLeadsLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldLeadsStatistics.isLoader);

export const selectGoldLeadsSuccessLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldLeadsStatistics.isLoadSuccess);

export const selectGoldLeadPerMonth = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldLeadsPerMonth.list);

export const selectGoldLeadsPerMonthLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldLeadsPerMonth.isLoader);

export const selectGoldLeadsPerMonthSuccessLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldLeadsPerMonth.isLoadSuccess);

export const selectGoldLeadsPerMonthError = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldLeadsPerMonth.error);

export const selectGoldDetailsByMonth = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldDetailsByMonth.list);

export const selectGoldDetailsByMonthLoader = createSelector(getPaymentsState,
  (state: IPaymentsState) => state.goldDetailsByMonth.isLoader);
