import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import {
  IChangeTWSProfit,
  IResellerParams,
  IResellerResExtended,
  IResselerByTire,
  ITWSPriceProfit,
  TireResellerFull
} from "../../modules/state/general-admin/tire-reseller";
import { IResponse } from '../api-data/api.interfaces';
import { LoginResponse } from '../../modules/auth/interfaces/auth.interface';
import { IPrivateBrand, ManufacturerElement } from '../../modules/state/general-admin/criteria/manufacturer';

@Injectable({
  providedIn: "root",
})
export default class TireResellerApiService {
  readonly PATH = `/tirereseller`;

  constructor(private apiService: ApiService) {
  }

  getTireResellerData(params?: IResellerParams): Observable<IResponse<TireResellerFull[]>> {
    return this.apiService.get(`${this.PATH}`, {...params});
  }

  getTireInfoById(tireId: number): Observable<IResselerByTire[]> {
    return this.apiService.get(`${this.PATH}/article/${tireId}`);
  }

  addTireResellerElement(element: FormData): Observable<TireResellerFull> {
    return this.apiService.post(`${this.PATH}`, element);
  }

  updateTireResellerElement(element: FormData): Observable<TireResellerFull> {
    return this.apiService.put(`${this.PATH}`, element);
  }

  editTireResellerMaskyooNumber(data: FormData): Observable<boolean> {
    return this.apiService.put(`${this.PATH}/changetireresellercontactnumber`, data);
  }

  getTireResellerById(id: string): Observable<TireResellerFull> {
    return this.apiService.get(`${this.PATH}/${id}`);
  }

  getTireResellerPrices(id: string): Observable<ITWSPriceProfit[]> {
    return this.apiService.get(`${this.PATH}/${id}/articleprice/profit`);
  }

  changeTireResellerPrices(id: string, profit: IChangeTWSProfit): Observable<any> {
    return this.apiService.put(`${this.PATH}/${id}/articleprice/profit`, profit);
  }

  getResellerToken(tireResellerId: string): Observable<LoginResponse> {
    return this.apiService.post(`/auth/admintwslogin`, {tireResellerId});
  }

  changeTWSShowing(id: string, active: boolean): Observable<IResellerResExtended> {
    return this.apiService.get(`${this.PATH}/${id}/changestatus`, {id, active});
  }

  addPrivateBrand(element: IPrivateBrand): Observable<ManufacturerElement> {
    return this.apiService.post('/brand/private', element);
  }

  updatePrivateBrand(element: IPrivateBrand, id: number): Observable<ManufacturerElement> {
    return this.apiService.put(`/brand/private/${id}`, element);
  }

  getPrivateBrandList(resellerId: string): Observable<ManufacturerElement[]> {
    return this.apiService.get(`/brand/private/${resellerId}/list`);
  }

  getPrivateBrand(resellerId: string, brandId: number): Observable<ManufacturerElement> {
    return this.apiService.get(`/brand/private/${resellerId}/${brandId}`);
  }
}
