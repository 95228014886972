import { HttpErrorResponse } from "@angular/common/http";
import { Action, createReducer, on } from "@ngrx/store";

import { CharacteristicElement, CharacteristicState, ILookupParameters } from "./characteristic.model";
import * as characteristicActions from './characteristic.actions'
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const initialState: CharacteristicState = {
  deleteId: 0,
  characteristic: {} as IResponse<CharacteristicElement[]>,
  options: {},
  lookupParameters: {} as ILookupParameters,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse,
}

const characteristicReducer = createReducer(
  initialState,
  on(characteristicActions.getCharacteristicData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(characteristicActions.getCharacteristicDataSuccess, (state, {payload}) => ({
    ...state,
    characteristic: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(characteristicActions.getCharacteristicDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(characteristicActions.getCharacteristicOptionsList, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(characteristicActions.getCharacteristicOptionsListSuccess, (state, {payload}) => {
    return {
      ...state,
      options: payload,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(characteristicActions.getCharacteristicOptionsListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(characteristicActions.getCharacteristicOptionsListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),

  on(characteristicActions.addCharacteristicOption, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(characteristicActions.addCharacteristicOptionSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(characteristicActions.addCharacteristicOptionFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(characteristicActions.updateCharacteristicOption, (state, {payload, id}) => ({
      ...state,
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    })),
  on(characteristicActions.updateCharacteristicOptionSuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(characteristicActions.updateCharacteristicOptionFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(characteristicActions.deleteCharacteristicOption, (state, {payload}) => ({
    ...state,
    deleteId: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(characteristicActions.deleteCharacteristicOptionSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(characteristicActions.deleteCharacteristicOptionFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(characteristicActions.getLookupParameters, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(characteristicActions.getLookupParametersSuccess, (state, {payload}) => {
    return {
      ...state,
      lookupParameters: payload,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(characteristicActions.deleteCharacteristicOptionFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
);

export const CharacteristicReducer = (state: CharacteristicState | undefined, action: Action): any => {
  return characteristicReducer(state, action);
}
