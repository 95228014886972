import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ManagePricesComponent } from "./pages/manage-prices/manage-prices.component";
import { DiscountComponent } from "./pages/expected-profit/discount.component";
import { InventorySetUpComponent } from "./pages/inventory-set-up/inventory-set-up.component";
import { LeadsComponent } from "./pages/leads/leads.component";
import { GoldLeadsComponent } from "./pages/gold-leads/gold-leads.component";
import { EBusinessRoutes } from './business-user.enums';
import { SetPriceComponent } from './pages/set-price/set-price.component';
import { PaymentsComponent } from '../modules/payments/components/payments/payments.component';
import { ResellerResolver } from './resolvers/reseller.resolver';
import { OrdersComponent } from './pages/orders/orders.component';

export const businessRoutes: Routes = [

  {
    path: EBusinessRoutes.dashboard,
    component: DashboardComponent,
    data: {
      menuTitle: 'DASHBOARD',
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.priceListCars,
    component: SetPriceComponent,
    data: {
      menuTitle: 'CONSUMER_PRICE_LIST',
      consumer: true
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.priceListMoto,
    component: SetPriceComponent,
    data: {
      menuTitle: 'CONSUMER_PRICE_LIST',
      consumer: true
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.managePrices,
    component: ManagePricesComponent,
    data: {
      menuTitle: 'MANAGE_PRICES'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.expectedProfit,
    component: DiscountComponent,
    data: {
      menuTitle: 'EXPECTED_PROFIT'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.setPriceCars,
    component: SetPriceComponent,
    data: {
      menuTitle: 'SET_PRICE_CARS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.setPriceMoto,
    component: SetPriceComponent,
    data: {
      menuTitle: 'SET_PRICE_MOTO'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.leads,
    component: LeadsComponent,
    data: {
      menuTitle: 'LEADS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.inventoryCars,
    component: InventorySetUpComponent,
    data: {
      menuTitle: 'INVENTORY_SET_UP_CARS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.inventoryMoto,
    component: InventorySetUpComponent,
    data: {
      menuTitle: 'INVENTORY_SET_UP_MOTO'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.orders,
    component: OrdersComponent,
    data: {
      menuTitle: 'ORDERS'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.goldLeads,
    component: GoldLeadsComponent,
    data: {
      menuTitle: 'GOLD_LEAD'
    },
    resolve: {Reseller: ResellerResolver}
  },
  {
    path: EBusinessRoutes.payments,
    component: PaymentsComponent,
    data: {
      menuTitle: 'PAYMENTS'
    },
    resolve: {Reseller: ResellerResolver}
  },
];

@NgModule({
  imports: [RouterModule.forChild([...businessRoutes, {path: '**', redirectTo: 'dashboard'}])],
  exports: [RouterModule]
})
export class businessUserRoutingModule {
}
