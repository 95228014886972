import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";

import { LoginResponse } from "../interfaces/auth.interface";
import { environment } from "../../../../environments/environment";
import { initialUserState } from "../reducers/user.reducers";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  public loader: boolean = false;
  public user: LoginResponse;
  public reseller: LoginResponse;
  public isReseller: boolean = false;

  constructor(private http: HttpClient,
              private router: Router) {
  }

  login(email: string, password: string): Observable<LoginResponse> {
    this.loader = true;
    return this.http.post<LoginResponse>(`${this.apiUrl}/auth/login`, {email, password});
  }

  setUser(user: LoginResponse, generalAdmin: boolean): void {
    if (generalAdmin) {
      localStorage.setItem('user', JSON.stringify(user));
      return;
    }
    this.isReseller = true;
    localStorage.setItem('reseller', JSON.stringify(user));
  }

  getUser(): Observable<LoginResponse> {
    const UserString = localStorage.getItem('user');
    if (UserString) {
      this.user = JSON.parse(UserString);
      return of(JSON.parse(UserString));
    } else {
      return of(initialUserState.user);
    }
  }

  getBusinessUser(): Observable<LoginResponse> {
    const Reseller = localStorage.getItem('reseller');
    if (Reseller) {
      this.reseller = JSON.parse(Reseller);
      return of(JSON.parse(Reseller));
    } else {
      return of(initialUserState.user);
    }
  }

  logout(generalAdmin: boolean): void {
    if (generalAdmin) {
      localStorage.removeItem('user');
      this.router.navigate(['auth/login']);
      this.loader = false;
      return;
    }
    localStorage.removeItem('reseller');
    this.router.navigate(['auth/login']);
    this.loader = false;
  }
}
