import { Component, OnInit } from '@angular/core';
import { IFilterValue, TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { ManageRimsTableMeta } from '../pages.constants';
import { IFilters, IResponse } from '../../../api/api-data/api.interfaces';
import { EModalType, ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { IRim } from '../../../modules/state/general-admin/rims/rims.model';
import {
  changRimVisibility,
  getRimsList,
  getRimsLoader,
  getRimsTableData
} from '../../../modules/state/general-admin/rims';
import { ManageRimsDialogComponent } from './manage-rims-dialog/manage-rims-dialog.component';
import { LanguageService } from '../../../api/language.service';
import { getFilterQuery } from '../../../utils/helpers';
import { getCharacteristicOptionsList, getLookupParameters } from '../../../modules/state/general-admin/characteristic';

@Component({
  selector: 'app-manage-rims',
  templateUrl: './manage-rims.component.html',
  styleUrls: ['./manage-rims.component.scss']
})
export class ManageRimsComponent implements OnInit {

  public tableMeta: TableMeta[] = ManageRimsTableMeta;
  public rimsList$: Observable<IResponse<IRim[]>>;
  public currentPage: number = 1;
  public filtersData: IFilters = {};
  public isTableLoad$: Observable<boolean>;
  public modalType: EModalType = EModalType.add;

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.store$.dispatch(getRimsList({params: {page: this.currentPage}}));
    this.store$.dispatch(getLookupParameters({payload: 'RimManufacturer,RimDiameter'}));
    this.store$.dispatch(getCharacteristicOptionsList({id: '3'}));
    this.rimsList$ = this.store$.select(getRimsTableData);
    this.isTableLoad$ = this.store$.select(getRimsLoader);
  }

  openAddDialog(): void {
    this.dialog.open(ManageRimsDialogComponent, {
      panelClass: 'admin-dialog',
      data: {modalType: EModalType.add}
    })
  }

  dispatchEvent(table: ITableData<IRim>): void {
    this.modalType = table.action.action === EModalType.delete ? EModalType.delete : EModalType.edit;
    this.dialog.open(ManageRimsDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...table.data, modalType: this.modalType}
    })
  }

  paginate(page: number): void {
    this.currentPage = page;
    const filters = getFilterQuery(this.filtersData);
    this.store$.dispatch(getRimsList({params: {page: this.currentPage, filters}}));
  }

  changeVisibility(event: any): void {
    this.store$.dispatch(changRimVisibility({id: event.target.id}));
  }

  getFilters(event: IFilterValue): void {
    this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
    if (!event.checkedIds.length) delete this.filtersData[event.filterKey];
    const filters = getFilterQuery(this.filtersData);
    this.store$.dispatch(getRimsList({params: {page: this.currentPage, filters}}));
  }
}

