import { createAction, props } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { CharacteristicElement, ILookupParameters, IOptions } from "./characteristic.model";
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const GET_CHARACTERISTICS = '[CHARACTERISTICS] Get data';
export const GET_CHARACTERISTICS_SUCCESS = '[CHARACTERISTICS] Get data success';
export const GET_CHARACTERISTICS_FAILURE = '[CHARACTERISTICS] Get data failure';

export const GET_CHARACTERISTIC_OPTIONS = '[CHARACTERISTICS] Get options';
export const GET_CHARACTERISTIC_OPTIONS_SUCCESS = '[CHARACTERISTICS] Get options success';
export const GET_CHARACTERISTIC_OPTIONS_FAILURE = '[CHARACTERISTICS] Get options failure';

export const ADD_CHARACTERISTIC_OPTION = '[CHARACTERISTICS] Add option';
export const ADD_CHARACTERISTIC_OPTION_SUCCESS = '[CHARACTERISTICS] Add option success';
export const ADD_CHARACTERISTIC_OPTION_FAILURE = '[CHARACTERISTICS] Add option failure';

export const UPDATE_CHARACTERISTIC_OPTION = '[CHARACTERISTICS] Update option';
export const UPDATE_CHARACTERISTIC_OPTION_SUCCESS = '[CHARACTERISTICS] Update option success';
export const UPDATE_CHARACTERISTIC_OPTION_FAILURE = '[CHARACTERISTICS] Update option failure';

export const DELETE_CHARACTERISTIC_OPTION = '[CHARACTERISTICS] Delete option';
export const DELETE_CHARACTERISTIC_OPTION_SUCCESS = '[CHARACTERISTICS] Delete option success';
export const DELETE_CHARACTERISTIC_OPTION_FAILURE = '[CHARACTERISTICS] Delete option failure';

export const GET_CHARACTERISTICS_FULL_DATA = '[CHARACTERISTICS] Get full data';

export const GET_LOOKUP_PARAMETERS = '[CHARACTERISTICS] Get lookup parameters';
export const GET_LOOKUP_PARAMETERS_SUCCESS = '[CHARACTERISTICS] Get lookup parameters success';
export const GET_LOOKUP_PARAMETERS_FAILURE = '[CHARACTERISTICS] Get lookup parameters failure';

export const getCharacteristicData = createAction(
  GET_CHARACTERISTICS,
);

export const getCharacteristicDataSuccess = createAction(
  GET_CHARACTERISTICS_SUCCESS,
  props<{ payload: IResponse<CharacteristicElement[]> }>()
);

export const getCharacteristicDataFailure = createAction(
  GET_CHARACTERISTICS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
);

export const getCharacteristicOptionsList = createAction(
  GET_CHARACTERISTIC_OPTIONS,
  props<{ id: string }>()
);

export const getCharacteristicOptionsListSuccess = createAction(
  GET_CHARACTERISTIC_OPTIONS_SUCCESS,
  props<{ payload: IOptions, id: string }>()
);

export const getCharacteristicOptionsListFailure = createAction(
  GET_CHARACTERISTIC_OPTIONS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
);

export const addCharacteristicOption = createAction(
  ADD_CHARACTERISTIC_OPTION,
  props<{ payload: FormData }>()
);

export const addCharacteristicOptionSuccess = createAction(
  ADD_CHARACTERISTIC_OPTION_SUCCESS
);

export const addCharacteristicOptionFailure = createAction(
  ADD_CHARACTERISTIC_OPTION_FAILURE,
  props<{ payload: HttpErrorResponse }>()
);

export const updateCharacteristicOption = createAction(
  UPDATE_CHARACTERISTIC_OPTION,
  props<{ payload: FormData, id: number }>()
);

export const updateCharacteristicOptionSuccess = createAction(
  UPDATE_CHARACTERISTIC_OPTION_SUCCESS
);

export const updateCharacteristicOptionFailure = createAction(
  UPDATE_CHARACTERISTIC_OPTION_FAILURE,
  props<{ payload: HttpErrorResponse }>()
);

export const deleteCharacteristicOption = createAction(
  DELETE_CHARACTERISTIC_OPTION,
  props<{ payload: number }>()
);

export const deleteCharacteristicOptionSuccess = createAction(
  DELETE_CHARACTERISTIC_OPTION_SUCCESS,
);

export const deleteCharacteristicOptionFailure = createAction(
  DELETE_CHARACTERISTIC_OPTION_FAILURE,
  props<{ payload: HttpErrorResponse }>()
);

export const getCharacteristicFullData = createAction(
  GET_CHARACTERISTICS_FULL_DATA
);

export const getLookupParameters = createAction(
  GET_LOOKUP_PARAMETERS,
  props<{ payload: string, isDisplayPrivateBrand?: boolean }>()
)

export const getLookupParametersSuccess = createAction(
  GET_LOOKUP_PARAMETERS_SUCCESS,
  props<{ payload: ILookupParameters }>()
)

export const getLookupParametersFailure = createAction(
  GET_LOOKUP_PARAMETERS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

