import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { takeUntil } from 'rxjs/operators';

import {
  addTireReseller,
  editTireResellerMaskyooNumber,
  getTireResellerList,
  INumberParam,
  IParameter,
  ITWSValidator,
  TireResellerFull,
  updateTireReseller
} from '../../../../modules/state/general-admin/tire-reseller';
import {
  CDayOff,
  ETWSControls,
  ETWSLabel,
  ETWSPlaceholder,
  EVehicleType,
  SabbathTimeControls,
  TimeControlsWithoutSabbath,
  TimeFormControlWithSabbath,
  validatorsFields
} from '../tws.enums';
import { EModalType } from '../../../../modules/shared/interfaces/table.interfaces';
import { EImgLoaderType, IImageFile, IImageSize } from '../../../../modules/shared/enums/imageLoader.enums';
import {
  getResellerError,
  getResellerLoader,
  getResellerSuccessLoader,
} from '../../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import { ImageModeEnum } from '../../../../modules/shared/enums/imageMode.enums';
import { isEmptyObj } from '../../../../utils/helpers';
import { NotificationService } from '../../../../api/notification.service';
import { EModalMessage } from '../../criteria/components/criteria.constants';
import { selectLookupParams } from '../../../../modules/state/general-admin/characteristic/characteristic.selectors';
import { ILookupParameters } from '../../../../modules/state/general-admin/characteristic';
import { ChangePricesDialogComponent } from '../change-prices-dialog/change-prices-dialog.component';
import { passwordValidator, timeValidator } from '../../../../utils/validators';
import { GoogleMapComponent } from '../../../../modules/google-map/component/google-map.component';
import { IMapsMarker } from '../../../../modules/google-map/maps.interface';

@Component({
  selector: 'app-manage-tws-dialog',
  templateUrl: './manage-tws-dialog.component.html',
  styleUrls: ['./manage-tws-dialog.component.scss']
})
export class ManageTwsDialogComponent implements OnInit, OnDestroy {
  @ViewChildren("time") times: QueryList<ElementRef>;
  @ViewChildren("sabbathTime") sabbathTime: QueryList<ElementRef>;
  public twsForm: FormGroup;
  public ETWSLabel = ETWSLabel;
  public ETWSPlaceholder = ETWSPlaceholder;
  public EModalType = EModalType;
  public EImgLoaderType = EImgLoaderType;
  public EVehicleType = EVehicleType;
  public tireReseller: TireResellerFull;
  public TWSParams$: Observable<ILookupParameters>;
  public isLoad$: Observable<boolean>;
  public destroy$: Subject<void> = new Subject<void>();
  public additionalFiles: IImageFile[] = [];
  public vehicleId: number;
  public imgMode: string = ImageModeEnum.None;
  public ETWSControls = ETWSControls;
  public validatorFields: ITWSValidator[] = validatorsFields;
  public isPasswordRequired: boolean = true;
  public loader$: Observable<boolean>;
  public subscription: number;
  public checkedBrands: number[];
  public imageSize: IImageSize = {width: 234, height: 82};
  public imagesToRemove: number[] = [];
  public daysOff: IParameter[] = CDayOff;
  public worksInSabbath: boolean = false;
  public addressAvailable: boolean = false;

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private store$: Store,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: TireResellerFull) {
  }

  ngOnInit(): void {
    this.getData();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSubscriptionId(): void {
    this.getControl('Subscription').valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: value => {
          this.subscription = value;
          this.addPriceValidator();
        }
      });
  }

  initForm(): void {
    if (this.data.modalType === EModalType.add) {
      this.twsForm = this.fb.group({
        UserName: ['', [Validators.required]],
        Password: ['', [Validators.required]],
        ConfirmPassword: ['', [Validators.required]],
        Email: ['', [Validators.email, Validators.required]],
        IsActive: [false],
        TWSName: ['', [Validators.required]],
        ContactPhoneNumber: ['', [Validators.required]],
        "Logo.File": [null],
        "Logo.Mode": [this.imgMode],
        ContactName: ['', [Validators.required]],
        OfficePhone: [''],
        Fax: [''],
        WazeNavigation: [''],
        Subscription: ['', Validators.required],
        Description: [''],
        Coupon: [''],
        // CoveredLead: [false],
        IncludeVAT: [true],
        PhoneLeadPrice: [''],
        RimsLeadPrice: [''],
        IndexLeadPrice: [''],
        ConverseLeadPrice: [''],
        NavigationLeadPrice: [''],
        ZapLeadPrice: [''],
        StarterLeadPrice: [''],
        TireTypes: [''],
        "AddressViewModel.LocationId": ['', [Validators.required]],
        "AddressViewModel.Street": ['', [Validators.required]],
        "AddressViewModel.BuildingNumber": [''],
        "RegularWorkingHours.OpenTime": [''],
        "RegularWorkingHours.CloseTime": [''],
        "FridayWorkingHours.OpenTime": [''],
        "FridayWorkingHours.CloseTime": [''],
        "SabbathWorkingHours.OpenTime": [''],
        "SabbathWorkingHours.CloseTime": [''],
        // BrandIds: [''],
        MoreOptions: [''],
        SendSMS: [false],
        IsOpenAllTime: [false],
        VehicleType: [this.data.vehicleType.value.toLowerCase()],
        ZapPhone: [''],
        Latitude: [''],
        Longitude: [''],
        GoogleSearchText: [''],
        ZapTireShopId: [''],
        SubLocation: [''],
        OwnerPhoneNumber: ['']
      });
      this.addValidator();
      this.getSubscriptionId();
      return;
    }
    this.subscription = this.data.subscriptionType.id;
    this.isPasswordRequired = false;
    this.worksInSabbath = !!this.data.sabbathWorkingHours.closeTime && !!this.data.sabbathWorkingHours.openTime;
    const more: number[] = [];
    const tireTypes: number[] = [];
    this.data.moreOptions?.forEach(item => more.push(item.id));
    this.data.tireTypes?.forEach(item => tireTypes.push(item.id));
    this.twsForm = this.fb.group({
      UserName: [this.data.userName, [Validators.required]],
      Password: [''],
      ConfirmPassword: [''],
      Email: [this.data.email, [Validators.email, Validators.required]],
      IsActive: [this.data.active || false],
      TWSName: [this.data.twsName, [Validators.required]],
      ContactPhoneNumber: [this.data.contactPhone, [Validators.required]],
      "Logo.File": [''],
      "Logo.Mode": [this.imgMode],
      ContactName: [this.data.contactName, [Validators.required]],
      OfficePhone: [this.data.officePhone || ''],
      Fax: [this.data.fax || ''],
      WazeNavigation: [this.data.wazeNavigation || ''],
      Subscription: [this.data.subscriptionType.id, Validators.required],
      Description: [this.data.description || ''],
      Coupon: [this.data.coupon || ''],
      // CoveredLead: [this.data.coveredLead],
      IncludeVAT: [this.data.includeVAT],
      PhoneLeadPrice: [this.data.phoneLeadPrice || ''],
      RimsLeadPrice: [this.data.rimsLeadPrice || ''],
      IndexLeadPrice: [this.data.indexLeadPrice || ''],
      ConverseLeadPrice: [this.data.converseLeadPrice || ''],
      NavigationLeadPrice: [this.data.navigationLeadPrice || ''],
      ZapLeadPrice: [this.data.zapLeadPrice || ''],
      StarterLeadPrice: [this.data.starterLeadPrice || ''],
      TireTypes: [tireTypes],
      "AddressViewModel.LocationId": [this.data.address.city?.id || '', [Validators.required]],
      "AddressViewModel.Street": [this.data.address.street || ''],
      "AddressViewModel.BuildingNumber": [''],
      "RegularWorkingHours.OpenTime": [this.convertTime(this.data.regularWorkingHours.openTime)],
      "RegularWorkingHours.CloseTime": [this.convertTime(this.data.regularWorkingHours.closeTime)],
      "FridayWorkingHours.OpenTime": [this.convertTime(this.data.fridayWorkingHours.openTime)],
      "FridayWorkingHours.CloseTime": [this.convertTime(this.data.fridayWorkingHours.closeTime)],
      "SabbathWorkingHours.OpenTime": [this.convertTime(this.data.sabbathWorkingHours.openTime)],
      "SabbathWorkingHours.CloseTime": [this.convertTime(this.data.sabbathWorkingHours.closeTime)],
      // BrandIds: [this.convertBrandData(this.data.brands)],
      IsOpenAllTime: [this.data.isOpenAllTime],
      MoreOptions: [more],
      SendSMS: [this.data.sendSMS || false],
      VehicleType: [this.data.vehicleType.value.toLowerCase()],
      ZapPhone: [this.data.zapPhone],
      Latitude: [+this.data.latitude, [Validators.required]],
      Longitude: [+this.data.longitude, [Validators.required]],
      GoogleSearchText: [this.data.googleSearchText || ''],
      ZapTireShopId: [this.data?.zapTireShopId || ''],
      SubLocation: [this.data?.subLocation || ''],
      OwnerPhoneNumber: [this.data?.ownerPhoneNumber || this.data.contactPhone]
    });
    this.addValidator();
    this.getSubscriptionId();
  }

  addValidator(): void {
    this.twsForm.addValidators(passwordValidator(this.getControl(ETWSControls.password), this.getControl(ETWSControls.confirmPassword)));
    this.validatorFields.forEach(validator => {
      this.twsForm.addValidators(timeValidator(
        validator.firstError, validator.secondError,
        this.getControl(validator.controlNameFirst),
        this.getControl(validator.controlNameSecond)))
    });
  }

  getControl(control: string): AbstractControl {
    return this.twsForm?.controls[control] as AbstractControl;
  }

  convertTime(date: Date | null): string {
    if (!date) return '';
    const fullDate = new Date(date);
    const hours = (fullDate.getUTCHours() < 10 ? '0' : '') + fullDate.getUTCHours();
    const minutes = (fullDate.getUTCMinutes() < 10 ? '0' : '') + fullDate.getUTCMinutes();
    return hours + ':' + minutes;
  }

  convertBrandData(brands: INumberParam[]): number[] {
    const selectedBrands: number[] = [];
    if (!brands) return [];
    brands.forEach(brand => selectedBrands.push(brand.id));
    return selectedBrands;
  }

  getData(): void {
    this.TWSParams$ = this.store$.select(selectLookupParams);
    this.isLoad$ = this.store$.select(getResellerSuccessLoader);
    this.loader$ = this.store$.select(getResellerLoader);
    this.vehicleId = this.data.vehicleType.id;
    this.checkedBrands = this.convertBrandData(this.data.brands);
  }

  updateCompanyLogo(image: File): void {
    this.twsForm.controls['Logo.File'].setValue(image);
    this.twsForm.controls['Logo.Mode'].setValue(ImageModeEnum.Replace);
  }

  updateAdditionalPhoto(images: IImageFile[]): void {
    this.additionalFiles = images;
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  submitForm(type: string): void {
    if (this.twsForm.invalid) return this.twsForm.markAllAsTouched();
    if (type === EModalType.edit) {
      const maskyooData: any = {
        TireResellerId: this.data.id,
        ContactNumber: this.twsForm.value.ContactPhoneNumber
      }
      const formData = new FormData();
      for (const key in maskyooData) formData.append(key, maskyooData[key]);

      this.store$.dispatch(updateTireReseller({payload: this.createFormData()}));
      this.store$.dispatch(editTireResellerMaskyooNumber({payload: formData}));
      this.checkRequest(EModalMessage.edit);
      return;
    }
    this.store$.dispatch(addTireReseller({payload: this.createFormData()}));
    this.checkRequest(EModalMessage.add);
  }

  createFormData(): FormData {
    const formData = new FormData();
    const MoreOptions = Array.isArray(this.twsForm.value.MoreOptions) ? this.twsForm.value.MoreOptions.join() : '';
    const TireTypes = Array.isArray(this.twsForm.value.TireTypes) ? this.twsForm.value.TireTypes.join() : '';
    // const OfficePhone = this.checkPhoneValue('OfficePhone');
    // const ContactPhoneNumber = this.checkPhoneValue('ContactPhoneNumber');
    const formValue = {...this.twsForm.value, MoreOptions, TireTypes};
    for (const key in formValue) formData.append(key, formValue[key]);
    if (this.data.modalType === EModalType.edit) formData.append("Id", this.data.id);
    if (this.imagesToRemove.length) this.imagesToRemove.forEach((id, idx) => formData.append(`RemoveAdditionalPictures[${idx}]`, id.toString()))
    if (!this.additionalFiles.length) {
      formData.append(`AdditionalPictures`, '');
      return formData;
    }
    this.additionalFiles.forEach((file, idx) => {
      formData.append(`AdditionalPictures[${idx}].mode`, file.mode);
      formData.append(`AdditionalPictures[${idx}].file`, file.file);
    });
    return formData;
  }

  checkPhoneValue(controlName: string): string {
    if (this.twsForm.get(controlName)?.value === '') return '';
    return this.twsForm.get(controlName)?.value.includes('+972') ? this.twsForm.get(controlName)?.value : `+972${this.twsForm.get(controlName)?.value}`
  }

  getBrandsIds(brandIds: number[]): void {
    this.checkedBrands = brandIds;
    this.twsForm.get('BrandIds')?.setValue(brandIds);
  }

  checkOpenTime(event: MatCheckboxChange): void {
    if (event.checked && this.worksInSabbath) {
      this.times.forEach((time) => time.nativeElement.value = '00:00');
      this.sabbathTime.forEach((time) => time.nativeElement.value = '00:00');
      TimeFormControlWithSabbath.forEach((item) => this.twsForm.controls[item]?.setValue('00:00'));
    }
    if (event.checked && !this.worksInSabbath) {
      this.sabbathTime.forEach((time) => time.nativeElement.value = '');
      SabbathTimeControls.forEach(item => this.twsForm.controls[item]?.setValue(''));
      this.times.forEach((time) => time.nativeElement.value = '00:00');
      TimeControlsWithoutSabbath.forEach(item => this.twsForm.controls[item]?.setValue('00:00'));
    }
    if (!event.checked) {
      TimeFormControlWithSabbath.forEach((item) => this.twsForm.controls[item]?.setValue(''));
      this.times.forEach(time => time.nativeElement.value = '');
      this.sabbathTime.forEach(time => time.nativeElement.value = '');
    }
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getResellerError),
      this.store$.select(getResellerSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
            this.store$.dispatch(getTireResellerList({
              payload: {
                page: this.data.page,
                filters: this.data.filters,
                search: this.data.search || ''
              }
            }));
          }
          if (!loader && !isEmptyObj(error)) {
            if (error.error.internalErrorName === 'DUPLICATE_ENTITY') {
              this.alertService.onError(error?.error?.message);
              return;
            }
            const failures = error.error.failures;
            const errors = [];
            for (const key in failures) {
              errors.push(failures[key][0]);
              this.alertService.onError(errors);
            }
            this.alertService.onError(error.message);
          }
        }
      })
  }

  changePrices(): void {
    this.dialog.open(ChangePricesDialogComponent, {
      panelClass: 'admin-manufacturer-add-dialog',
      data: this.data.id,
      width: '950px',
    })
  }

  addPriceValidator(): void {
    const controls: AbstractControl[] = [];
    const controlNames = ['ZapLeadPrice', 'NavigationLeadPrice', 'IndexLeadPrice', 'ConverseLeadPrice', 'RimsLeadPrice', 'PhoneLeadPrice'];
    controlNames.forEach((control) => controls.push(this.getControl(control)));
    controls.forEach(control => {
      control.removeValidators([Validators.required]);
      control.markAsPristine();
      control.markAsUntouched();
      control.updateValueAndValidity();
    });
    let subControls: string[] = [];
    switch (this.subscription) {
      case (4):
        subControls = ['RimsLeadPrice'];
        break;
      case (2):
        subControls = ['IndexLeadPrice', 'NavigationLeadPrice']
        break;
      case (5):
        subControls = ['ZapLeadPrice']
        break;
      case (1):
        subControls = controlNames;
        break;
      case (7):
        this.getControl('ContactPhoneNumber').removeValidators([Validators.required]);
        this.getControl('ContactPhoneNumber').markAsPristine();
        this.getControl('ContactPhoneNumber').markAsUntouched();
        this.getControl('ContactPhoneNumber').updateValueAndValidity();
        break;
      case (6):
        subControls = controlNames.filter(control => control !== 'RimsLeadPrice');
        break;
    }
    if (!subControls.length) return;
    subControls.forEach(item => this.updateControl(item));
  }

  updateControl(name: string): void {
    this.getControl(name).setValidators([Validators.required]);
    this.getControl(name).updateValueAndValidity();
  }

  checkPriceMandatory(): boolean {
    return this.subscription === 1 || this.subscription === 6;
  }

  getImagesToRemove(idxArr: number[]): void {
    this.imagesToRemove = idxArr;
  }

  checkSaturdayTime(): void {
    if (!this.worksInSabbath) {
      this.sabbathTime.forEach((time) => time.nativeElement.value = '');
      SabbathTimeControls.forEach(item => this.twsForm.controls[item]?.setValue(''));
    }
  }

  openMap(): void {
    const data = this.data.modalType === EModalType.add ? null :
      {
        latitude: +this.data.latitude,
        longitude: +this.data.longitude,
        address: this.data.address?.street || ''
      }
    this.dialog.open(GoogleMapComponent, {
      panelClass: 'admin-manufacturer-add-dialog',
      width: '950px',
      data
    }).afterClosed().subscribe({
      next: (address: IMapsMarker) => {
        if (!address) return;
        const street = address.address.split(',').slice(0, -1).join();
        this.twsForm.get('Latitude')?.setValue(address.latitude);
        this.twsForm.get('Longitude')?.setValue(address.longitude);
        this.twsForm.controls['AddressViewModel.Street']?.setValue(street);
        // this.twsForm.controls['GoogleSearchText']?.setValue(address.googleSearchText);
      }
    })
  }
}

