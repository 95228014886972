import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import {
  connectImageForTires,
  getConnectImageData,
  IConnectImageElement,
  IConnectImageParams
} from "../../../modules/state/general-admin/connect-image";
import {
  getConnectImageTableData,
  getImageConnectError,
  getImageConnectLoader,
  getImageConnectSuccessLoader
} from "../../../modules/state/general-admin/connect-image/connect-image.selectors";
import { getLookupParameters, ILookupParameters } from '../../../modules/state/general-admin/characteristic';
import {
  selectCharacteristicSuccessLoader,
  selectLookupParams
} from '../../../modules/state/general-admin/characteristic/characteristic.selectors';
import { ConnectImageTableMeta } from '../pages.constants';
import { LanguageService } from '../../../api/language.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EConnectImageLabel, EConnectImagePlaceholder } from './connect-image.enums';
import { EImgLoaderType, IImageSize } from '../../../modules/shared/enums/imageLoader.enums';
import { IResponse } from '../../../api/api-data/api.interfaces';
import { isEmptyObj } from '../../../utils/helpers';
import { NotificationService } from '../../../api/notification.service';
import { EModalMessage } from '../criteria/components/criteria.constants';
import { ImageUploaderComponent } from '../../../components/image-uploader/image-uploader.component';

@Component({
  selector: 'app-connect-image',
  templateUrl: './connect-image.component.html',
  styleUrls: ['./connect-image.component.scss']
})
export class ConnectImageComponent implements OnInit, OnDestroy {
  public searchParams$: Observable<ILookupParameters>;
  public tableMeta: TableMeta[] = ConnectImageTableMeta;
  public submitStep: boolean = false;
  public imageForm: FormGroup;
  public searchForm: FormGroup;
  public EConnectImageLabel = EConnectImageLabel;
  public EConnectImagePlaceholder = EConnectImagePlaceholder;
  public EImgLoaderType = EImgLoaderType;
  public destroy$: Subject<void> = new Subject<void>();
  public tableContent$: Observable<IResponse<IConnectImageElement[]>>;
  public isLoad$: Observable<boolean>;
  public currentPage: number = 1;
  public firstStep: boolean = true;
  public articleIds: number[] = [];
  public isParamsLoad$: Observable<boolean>;
  public imageSize: IImageSize = {width: 1234, height: 1234};
  public searchSubmit: boolean = false;
  public clearSelection: boolean = false;
  @ViewChild('loader') loader: ImageUploaderComponent;

  constructor(private store$: Store,
              private fb: FormBuilder,
              public languageService: LanguageService,
              private alertService: NotificationService) {
  }

  ngOnInit(): void {
    this.getData();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.searchForm = this.fb.group({
      vehicleType: ['', [Validators.required]],
      brandId: ['', [Validators.required]],
      query: ['', []]
    })
    this.imageForm = this.fb.group({
      Image: ['']
    })
  }

  getData(): void {
    this.store$.dispatch(getLookupParameters({payload: 'Manufacture,VehicleType'}));
    this.isParamsLoad$ = this.store$.select(selectCharacteristicSuccessLoader);
    this.searchParams$ = this.store$.select(selectLookupParams);
    this.isLoad$ = this.store$.select(getImageConnectLoader);
  }

  updateSubmitStep(newValue: boolean): void {
    this.firstStep = newValue;
  }

  updateImage(image: File): void {
    this.imageForm.controls['Image'].setValue(image);
    this.submitStep = true;
  }

  sendSearchForm(): void {
    if(this.searchSubmit) {
      this.loader.removeImage();
      this.firstStep = true;
    }
    this.searchSubmit = true;
    let params: IConnectImageParams = {...this.searchForm.value};
    this.store$.dispatch(getConnectImageData({params}));
    this.tableContent$ = this.store$.select(getConnectImageTableData);
  }

  getCheckedArticles(rows: IConnectImageElement[]): void {
    this.articleIds = [];
    const articleIds: number[] = [];
    rows.forEach(item => articleIds.push(item.id));
    this.articleIds = articleIds;
  }

  updateArticleImages(): void {
    const payload = new FormData();
    payload.append('Image', this.imageForm.controls['Image'].value);
    this.articleIds.forEach((id, index) => {
      payload.append(`ArticleIds[${index}]`, id.toString());
    });
    this.store$.dispatch(connectImageForTires({payload, articleIds: this.imageForm.value.ArticleIds}));
    this.checkRequest(EModalMessage.edit);
    this.clearSelection = true;
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getImageConnectError),
      this.store$.select(getImageConnectSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.alertService.onSuccess(message);
            this.clearSelection = false;
            this.articleIds = [];
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.message);
        }
      })
  }

  paginate(page: number): void {
    this.currentPage = page;
    const params = {...this.searchForm.value, page: this.currentPage};
    this.store$.dispatch(getConnectImageData({params}));
  }
}
