import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TireResellersState } from "./tire-reseller.model";

export const getTireResellerState = createFeatureSelector<TireResellersState>('tireReseller');

export const getTireResellerResponse = createSelector(getTireResellerState,
  (state: TireResellersState) => state.response);

export const getTireReseller = createSelector(getTireResellerState,
  (state: TireResellersState) => state.tireReseller);

export const getResellerSuccessLoader = createSelector(getTireResellerState,
  (state: TireResellersState) => state.isLoadingSuccess);

export const getResellerLoader = createSelector(getTireResellerState,
  (state: TireResellersState) => state.isLoading);

export const getResellerError = createSelector(getTireResellerState,
  (state: TireResellersState) => state.error);

export const getResellerPrices = createSelector(getTireResellerState,
  (state: TireResellersState) => state.pricesProfit.list);

export const getResellerPricesLoader = createSelector(getTireResellerState,
  (state: TireResellersState) => state.pricesProfit.isLoader);

export const getResellerPricesSuccessLoader = createSelector(getTireResellerState,
  (state: TireResellersState) => state.pricesProfit.isLoaderSuccess);

export const getResellerPricesError = createSelector(getTireResellerState,
  (state: TireResellersState) => state.pricesProfit.error);

export const getBusinessUserToken = createSelector(getTireResellerState,
  (state: TireResellersState) => state.businessUserData);

export const selectPrivateBrandList = createSelector(getTireResellerState,
  (state: TireResellersState) => state.privateBrandList);

export const selectPrivateBrand = createSelector(getTireResellerState,
  (state: TireResellersState) => state.privateBrand);

export const selectTWSListByTire = createSelector(getTireResellerState,
  (state: TireResellersState) => state.resellersByTire);
