import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { getResellerToken } from '../../modules/state/general-admin/tire-reseller';
import { Store } from '@ngrx/store';
import { getBusinessUserToken } from '../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import { take } from 'rxjs/operators';
import { AuthService } from '../../modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResellerResolver implements Resolve<boolean> {

  constructor(private store$: Store,
              private auth: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any | null> | Observable<never> {
    const resellerId = route.paramMap.get('resellerId');

    if(!resellerId) {
      this.auth.isReseller = false;
      return of(false);
    }

    this.store$.dispatch(getResellerToken({id: resellerId}));
    this.auth.isReseller = true;

    this.store$.select(getBusinessUserToken).pipe(take(2)).subscribe({
      next: (user) => {
        if (!user) return;
        this.auth.setUser(user, false);
      }
    })
    return of({resellerId})
  }
}
