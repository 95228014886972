import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { HeightElement, HeightState } from './height.model';
import * as HeightActions from './height.actions';

export const initialState: HeightState = {
  current: {} as HeightElement,
  deleteId: null,
  result: '',
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  content: [],
  error: {} as HttpErrorResponse,
};

const heightReducer = createReducer(
  initialState,
  on(HeightActions.getHeightData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(HeightActions.getHeightDataSuccess, (state, {payload}) => ({
    ...state,
    content: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(HeightActions.getHeightDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(HeightActions.addHeightElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(HeightActions.addHeightElementSuccess, (state, {payload}) => {
    return {
      ...state,
      content: [...state.content, payload],
      current: {} as HeightElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(HeightActions.addHeightElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(HeightActions.updateHeightElement, (state, {payload}) => ({
    ...state,
    current: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(HeightActions.updateHeightElementSuccess, (state, {payload}) => {
    const tireWeightData = [...state.content];
    const newContent = tireWeightData.map((mnf: HeightElement) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    return {
      ...state,
      content: newContent,
      current: {} as HeightElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(HeightActions.updateHeightElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(HeightActions.deleteHeightElement, (state, {payload}) => ({
    ...state,
    deleteId: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(HeightActions.deleteHeightElementSuccess, (state) => {
    const tireWeightData = [...state.content];
    const newContent = tireWeightData.filter((mnf: HeightElement) => mnf.id !== state.deleteId);
    return {
      ...state,
      content: newContent,
      deleteId: null,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(HeightActions.deleteHeightElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function HeightReducer(state: HeightState | undefined, action: Action): any {
  return heightReducer(state, action);
}



