import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as leadsStatusActions from "./leads-status.actions";
import { LeadStatusService } from '../../../../../api/general-admin-services/lead-status.service';

@Injectable()
export class LeadsStatusEffects {
  constructor(private actions$: Actions,
              private service: LeadStatusService) {
  }

  getSpeedCodeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leadsStatusActions.getLeadsStatusList),
      switchMap(() =>
        this.service.getLeadStatusData().pipe(
          map(response => leadsStatusActions.getLeadsStatusListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(leadsStatusActions.getLeadsStatusListFailure({payload: err}))
          )
        )
      )
    )
  )

  createLeadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leadsStatusActions.addLeadStatus),
      switchMap(action =>
        this.service.addLeadStatus(action.payload).pipe(
          map(response => leadsStatusActions.addLeadStatusSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(leadsStatusActions.addLeadStatusFailure({payload: err})))
        )
      )
    )
  )

  updateLeadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leadsStatusActions.updateLeadStatus),
      switchMap(action =>
        this.service.updateLeadStatus(action.payload, action.id).pipe(
          map(response => leadsStatusActions.updateLeadStatusSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(leadsStatusActions.updateLeadStatusFailure({payload: err})))
        )
      )
    )
  )

  deleteLeadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leadsStatusActions.deleteLeadStatus),
      switchMap(action =>
        this.service.deleteLeadStatus(action.id).pipe(
          map(() => leadsStatusActions.deleteLeadStatusSuccess()),
          catchError((err: HttpErrorResponse) => of(leadsStatusActions.deleteLeadStatusFailure({payload: err})))
        )
      )
    )
  )
}
