<div [formGroup]="form" [ngClass]="consumerList ? 'form-wrapper' : ''" *ngIf="filters">
  <div class="form-wrap">
    <div class="btn-wrap">
      <button class="btn-admin btn-white" (click)="resetFilters()">{{'RESET_FILTERS' | translate}}</button>
      <button class="btn-admin" (click)="submit()">{{'SEARCH' | translate}}</button>
    </div>
    <div class="select-wrap" [ngClass]="changeSelectWidth ? 'small-selects' : ''">
      <app-select *ngIf="filters?.Brand" formControlName="Brand"
                  [placeholder]="placeholders.brand"
                  [label]="labels.brand"
                  [options]="filters?.Brand"
      >
      </app-select>
      <app-select *ngIf="filters?.Manufacture" formControlName="Manufacture"
                  [placeholder]="placeholders.brand"
                  [label]="labels.brand"
                  [options]="filters?.Manufacture"
      >
      </app-select>
      <app-select formControlName="Diameter" #appSelect
                  [placeholder]="placeholders.diameter"
                  [label]="labels.diameter"
                  [options]="filters?.Diameter"
      >
      </app-select>
      <app-select formControlName="Height" #appSelect
                  [placeholder]="placeholders.height"
                  [label]="labels.height"
                  [options]="filters?.Height"
                  (openNextSelect)="openNext(labels.height)"
      >
      </app-select>
      <app-select formControlName="Width" #appSelect
                  [placeholder]="placeholders.width"
                  [label]="labels.width"
                  [options]="filters?.Width"
                  (openNextSelect)="openNext(labels.width)"
      >
      </app-select>
      <app-select *ngIf="filters?.TireType" formControlName="TireType" #appSelect
                  [placeholder]="placeholders.tireType"
                  [label]="labels.tireType"
                  [options]="filters?.TireType"
                  (openNextSelect)="openNext(labels.tireType)"
      >
      </app-select>
    </div>
  </div>
  <div *ngIf="consumerList || orderEditing" class="plate-search">
    <app-plate-search (selectCar)="selectPlate($event)"></app-plate-search>
    <div class="selected-plate">
      <div *ngIf="form.controls['plate'].value" class="selected-plate-wrap">
        <div (click)="removePlate()">
          <mat-icon>close</mat-icon>
        </div>
        <div class="selected-plate-text">{{'SELECTED_PLATE' | translate}}:</div>
        <div class="selected-plate-name">{{form.controls['plate'].value?.name}}</div>
        <div class="selected-plate-img">
          <img imgDir onErrorSrc="assets/icons/svg/error-img.svg"
               [src]="form.controls['plate'].value?.pictureUrl">
        </div>
      </div>
    </div>
  </div>
</div>
