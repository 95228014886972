<div class="content manufacturer">
  <div class="content-head">
    <h3 class="head-title">
      {{'EXPECTED_PROFIT' | translate}}
    </h3>
  </div>
  <div class="content-body" *ngIf="(reseller$ | async) as reseller">
    <div class="content-main">
      <div>{{'MANUFACTURER' | translate}}</div>
      <div class="content-progress" *ngIf="(isResellerBrandsLoad$ | async)">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </div>
      <app-chips [isMultiple]="false" [dataList]="filterBrands(reseller.brands)"
                 (selectedIds)="getSelectedBrand($event)"></app-chips>
    </div>
  </div>
</div>

<div class="content manufacturer" *ngIf="(reseller$ | async) as reseller">
  <div class="content-head">
    <h3 class="head-title">
      {{'SET_EXPECTED_PROFIT_FOR_ALL' | translate}}
    </h3>
  </div>
  <div class="content-body multiple">
    <div class="loader" *ngIf="(isTableLoad$ | async)">
      <mat-spinner></mat-spinner>
    </div>
      <div class="content-form" [formGroup]="profitForm">
        <app-multiple-select [allowAllSelection]="true" formControlName="brandIds"
                             [label]="'MANUFACTURER'" [placeholder]="'SELECT_MANUFACTURER'"
                             [options]="filterBrands(reseller.brands)"></app-multiple-select>
        <app-multiple-select [allowAllSelection]="true" formControlName="characteristicOptionIds"
                             [label]="'TIRE_DIAMETER'" [placeholder]="'SELECT_DIAMETER'"
                             [options]="(params$ | async)?.TireDiameter"></app-multiple-select>
        <app-input [isNumber]="true" formControlName="profit" [label]="'EXPECTED_PROFIT'"
                   [placeholder]="'ENTER_EXPECTED_PROFIT'"></app-input>
        <button class="btn-admin" [disabled]="!profitForm.valid"
                (click)="setMultipleProfit()">{{'SAVE' | translate}}</button>
      </div>
  </div>
</div>

<div class="content manufacturer">
  <div *ngIf="!firstStep && (expectedProfit$ | async) as content">
    <starter-table
      [tableMeta]="expectedProfitTableMeta"
      [tableData]="sortContent(content)"
      [language]="languageService.mainLanguage.value"
      [isLoadingTable]="!(isTableLoad$ | async)"
      (onClick)="actionDispatch($event)"
      (inputValue)="getProfitValue($event)"
    >
    </starter-table>
  </div>
</div>

