<div class="dialog manufacturer-add-dialog">
  <div class="dialog-title">
    <h3 *ngIf="data.modalType === EModalType.add" class="title">{{'ADD_LOCATION' | translate}}</h3>
    <h3 *ngIf="data.modalType === EModalType.edit" class="title">{{'EDIT_LOCATION' | translate}}</h3>
    <div class="title-actions">
      <mat-icon class="close" (click)="closeAddDialog()">
        close
      </mat-icon>
    </div>
  </div>

  <mat-dialog-content>
    <div class="dialog-form-wrap">
      <div class="table-loader" *ngIf="(isLoading$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <form (submit)="submit($event)" [formGroup]="locationForm">
        <div class="form-group">
          <div class="form-col">
            <app-input [label]="'LOCATION'" formControlName="name"
                       [isRequired]="true" [placeholder]="'ENTER_LOCATION'"></app-input>
          </div>
        </div>

        <div class="save-btn">
          <button class="btn-admin" [disabled]="!locationForm.valid">
            {{'SAVE' | translate}}
          </button>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</div>


