import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

import { LanguageService } from '../../../../api/language.service';
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {
  currentLang = environment.defaultLanguage;
  languages: string[] = environment.languages;

  constructor(private translate: TranslateService,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
  }

  changeLang(ln: string): void {
    this.languageService.changeLanguage(ln);
    this.translate.use(ln);
    this.translate.currentLang = ln;
    window.localStorage.setItem("langCode", ln);
    this.currentLang = ln;
  }
}
