import { ReactiveFormsModule } from "@angular/forms";
import { NgModule } from '@angular/core';

import { StatisticsComponent } from './components/statistics/statistics.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    StatisticsComponent
  ],
  exports: [
    StatisticsComponent
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class StatisticsModule {
}
