import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { catchError, exhaustMap, map, switchMap } from "rxjs/operators";

import * as AdminNotificationActions from "./admin-notifications.actions";
import AdminNotificationsApiService from "../../../../api/general-admin-services/admin-notifications.api.service";

@Injectable()
export class AdminNotificationsEffects {
  constructor(private actions$: Actions,
              private adminNotificationService: AdminNotificationsApiService) {
  }

  getAdminNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminNotificationActions.getAdminNotification),
      switchMap(() =>
        this.adminNotificationService.getNotificationList().pipe(
          map(response => AdminNotificationActions.getAdminNotificationSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(AdminNotificationActions.getAdminNotificationFailure({payload: err}))
          )
        )
      )
    )
  )

  addAdminNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminNotificationActions.addAdminNotification),
      exhaustMap(action =>
        this.adminNotificationService.addNotification(action.payload).pipe(
          map(response => AdminNotificationActions.addAdminNotificationSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(AdminNotificationActions.addAdminNotificationFailure({payload: err})))
        )
      )
    )
  )

  dismissAdminNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminNotificationActions.dismissAdminNotification),
      exhaustMap(action =>
        this.adminNotificationService.dismissNotification(action.id).pipe(
          map(response => AdminNotificationActions.dismissAdminNotificationSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(AdminNotificationActions.dismissAdminNotificationFailure({payload: err})))
        )
      )
    )
  )
}
