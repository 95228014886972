import { createSelector } from "@ngrx/store";

import { initialUserState } from "./user.reducers";
import { AuthState } from '../interfaces/auth.interface';
import * as fromFeature from './root'

const getUserState = createSelector(fromFeature.getAuthState,
  (state: AuthState) => {
    if (!state) {
      // on page load, the state variable is undefined
      return false;
    }
    return state.user;
  }
);

export const selectUser = createSelector(getUserState, (state) => {
  if (!state) {
    return initialUserState.user;
  }
  return state.user;
})

export const selectAuthError = createSelector(getUserState, (state) => {
  if (!state) {
    return null;
  }
  return state.error;
})
