<div class="title-actions">
  <mat-icon class="close" (click)="closeAddDialog()">
    close
  </mat-icon>
</div>

<div class="form-group">
  <mat-form-field class="form-element" appearance="fill">
    <input autocapitalize="off" #search placeholder="{{'ENTER_TWS_ADDRESS' | translate}}"
          class="search-input" matInput type="text" autocomplete="off" (keydown.enter)="$event.preventDefault()">
  </mat-form-field>
</div>

<agm-map
  [latitude]="marker.latitude"
  [longitude]="marker.longitude"
  [zoom]="zoom"
>
  <agm-marker [latitude]="marker.latitude" [longitude]="marker.longitude" [markerDraggable]="true"
              (dragEnd)="markerDragEnd($event)"></agm-marker>
</agm-map>

<div class="btn-wrap">
  <button class="btn-admin" (click)="saveAddress()">{{'SAVE' | translate}}</button>
</div>
