import { createFeatureSelector, createSelector } from "@ngrx/store";
import { WeightCodeElement, WeightCodeState } from "./weight-code.model";
import { HttpErrorResponse } from '@angular/common/http';

export const getWeightCodeState = createFeatureSelector<WeightCodeState>('weightCode');

export const getWeightCodeTable = createSelector(getWeightCodeState,
  (state: WeightCodeState): WeightCodeElement[] => state.content);

export const getWeightCodeSuccessLoader = createSelector(getWeightCodeState,
  (state: WeightCodeState) => state.isLoadingSuccess);

export const getWeightCodeLoader = createSelector(getWeightCodeState,
  (state: WeightCodeState) => state.isLoading);

export const getWeightCodeError = createSelector(getWeightCodeState,
  (state: WeightCodeState): HttpErrorResponse => state.error);
