import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { INumberParam, TireResellerFull, TireResellersState } from './tire-reseller.model';
import * as TireResellerActions from './tire-reseller.actions';
import { IResponse } from '../../../../api/api-data/api.interfaces';
import { ManufacturerElement } from '../criteria/manufacturer';

export const initialState: TireResellersState = {
  tireReseller: {} as TireResellerFull,
  response: {} as IResponse<TireResellerFull[]>,
  pricesProfit: {
    list: [],
    isLoader: false,
    isLoaderSuccess: false,
    error: {} as HttpErrorResponse
  },
  resellersByTire: null,
  privateBrand: {} as ManufacturerElement,
  privateBrandList: [],
  businessUserData: null,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse
};

const tireResellerReducer = createReducer(
  initialState,
  on(TireResellerActions.getTireResellerList, (state, {payload}) => ({
    ...state,
    currentPage: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.getTireResellerListSuccess, (state, {payload}) => {
    return {
      ...state,
      response: payload,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(TireResellerActions.getTireResellerListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(TireResellerActions.addTireReseller, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.addTireResellerSuccess, (state, {payload}) => {
    return {
      ...state,
      response: {...state.response, content: [...state.response.content, payload]},
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      error: {} as HttpErrorResponse,
    }
  }),
  on(TireResellerActions.addTireResellerFailure, (state, {payload}) => {
    return {
      ...state,
      isLoading: false,
      isLoadingSuccess: false,
      isLoadingFailure: true,
      error: payload,
    }
  }),
  on(TireResellerActions.updateTireReseller, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.updateTireResellerSuccess, (state, {payload}) => {
    const content = [...state.response.content];
    const newData = content.map((tws: TireResellerFull) => {
      if (tws.id === payload.id) tws = payload;
      return tws;
    });
    return {
      ...state,
      response: {...state.response, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(TireResellerActions.updateBusinessTireResellerSuccess, (state, {payload}) => {
    return {
      ...state,
      tireReseller: payload,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(TireResellerActions.updateTireResellerFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(TireResellerActions.getTireResellerById, (state) => ({
    ...state,
    error: {} as HttpErrorResponse,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
  })),
  on(TireResellerActions.getTireResellerByIdSuccess, (state, {payload}) => ({
    ...state,
    error: {} as HttpErrorResponse,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    tireReseller: payload
  })),
  on(TireResellerActions.getTireResellerByIdFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
  })),
  on(TireResellerActions.getTireResellerPricesProfit, (state) => ({
    ...state,
    pricesProfit: {
      ...state.pricesProfit,
      isLoader: true,
      isLoaderSuccess: false,
      error: {} as HttpErrorResponse
    },
  })),
  on(TireResellerActions.getTireResellerPricesProfitSuccess, (state, {payload}) => ({
    ...state,
    pricesProfit: {
      ...state.pricesProfit,
      list: payload,
      isLoader: false,
      isLoaderSuccess: true,
      error: {} as HttpErrorResponse
    },
  })),
  on(TireResellerActions.getTireResellerPricesProfitFailure, (state, {payload}) => ({
    ...state,
    pricesProfit: {
      ...state.pricesProfit,
      isLoader: false,
      isLoaderSuccess: false,
      error: payload
    },
  })),
  on(TireResellerActions.changeTireResellerPricesProfit, (state) => ({
    ...state,
    pricesProfit: {
      ...state.pricesProfit,
      isLoader: true,
      isLoaderSuccess: false,
      error: {} as HttpErrorResponse
    },
  })),
  on(TireResellerActions.changeTireResellerPricesProfitSuccess, (state) => ({
    ...state,
    pricesProfit: {
      ...state.pricesProfit,
      isLoader: false,
      isLoaderSuccess: true,
      error: {} as HttpErrorResponse
    },
  })),
  on(TireResellerActions.changeTireResellerPricesProfitFailure, (state, {payload}) => ({
    ...state,
    pricesProfit: {
      ...state.pricesProfit,
      isLoader: false,
      isLoaderSuccess: false,
      error: payload
    },
  })),
  on(TireResellerActions.getResellerToken, (state) => ({
    ...state,
    businessUserData: null,
    isLoading: true,
    isLoadingSuccess: false,
    error: {} as HttpErrorResponse
  })),
  on(TireResellerActions.getResellerTokenSuccess, (state, {user}) => ({
    ...state,
    businessUserData: user,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.getResellerTokenFailed, (state, {payload}) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: payload
  })),
  on(TireResellerActions.changeTWSShowing, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.changeTWSShowingSuccess, (state, {payload}) => {
    const content = [...state.response.content];
    const newData = content.map((tws: TireResellerFull) => {
      if (tws.id === payload.id) tws = payload;
      return tws;
    });
    return {
      ...state,
      response: {...state.response, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(TireResellerActions.addPrivateBrand, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.addPrivateBrandSuccess, (state, {payload}) => {
    const newBrand: INumberParam = {isPrivate: true, id: payload.id, value: payload.name};
    return {
      ...state,
      tireReseller: {...state.tireReseller, brands: [...state.tireReseller.brands, newBrand]},
      privateBrand: payload,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse
    }
  }),
  on(TireResellerActions.addPrivateBrandFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(TireResellerActions.updatePrivateBrand, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.updatePrivateBrandSuccess, (state, {payload}) => {
    const brandList = [...state.tireReseller.brands];
    const newData = brandList.map((brand: INumberParam) => {
      if (brand.id === payload.id) brand = {...brand, value: payload.name};
      return brand;
    });
    return {
      ...state,
      tireReseller: {...state.tireReseller, brands: newData},
      privateBrand: payload,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse
    }
  }),
  on(TireResellerActions.updatePrivateBrandFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(TireResellerActions.getPrivateBrandList, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.getPrivateBrandListSuccess, (state, {payload}) => ({
    ...state,
    privateBrandList: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.getPrivateBrandListFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(TireResellerActions.getPrivateBrand, (state) => ({
    ...state,
    privateBrand: {} as ManufacturerElement,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.getPrivateBrandSuccess, (state, {payload}) => ({
    ...state,
    privateBrand: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.getPrivateBrandFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(TireResellerActions.getTireInfoById, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.getTireInfoByIdSuccess, (state, {payload}) => ({
    ...state,
    resellersByTire: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(TireResellerActions.getTireInfoByIdFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function TireResellerReducer(state: TireResellersState | undefined, action: Action): any {
  return tireResellerReducer(state, action);
}
