<div class="dialog manufacturer-add-dialog">
  <div class="dialog-title">
    <h3 class="title">
      {{'MANUFACTURER' | translate}}
    </h3>
    <div class="title-actions">
      <mat-icon class="close" (click)="closeAddDialog()">
        close
      </mat-icon>
    </div>
  </div>

  <ng-container [ngSwitch]="data.modalType">
    <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="editCriteria"></ng-container>
    <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="editCriteria"></ng-container>
    <ng-container *ngSwitchCase="EModalType.delete" [ngTemplateOutlet]="deleteCriteria"></ng-container>
  </ng-container>

  <ng-template #editCriteria>
    <mat-dialog-content>
      <div class="dialog-form-wrap">
        <div class="table-loader" *ngIf="(isLoading$ | async) || (privateBrandLoading$ | async)">
          <mat-spinner></mat-spinner>
        </div>
        <form [formGroup]="manufacturerForm">
          <div class="form-group" [ngClass]="data.resellerPrivateBrand ? '' : 'row'">
            <div class="form-col" [ngClass]="data.resellerPrivateBrand ? '' : 'col-6'">
              <app-input [label]="ECriteriaLabel.title" formControlName="Name"
                         [isRequired]="true" [placeholder]="ECriteriaLabel.private"></app-input>
            </div>
<!--            <div class="col-6 form-col">-->
<!--              <app-input [isNumber]="true" [label]="ECriteriaLabel.priority" formControlName="Priority"-->
<!--                         [isRequired]="true" [placeholder]="'0'"></app-input>-->
<!--            </div>-->
            <div class="col-6 form-col" *ngIf="!data.resellerPrivateBrand && (params$ | async) as params">
              <app-select [label]="ECriteriaLabel.country" [isRequired]="true" [options]="params.Country"
                          [placeholder]="ECriteriaLabel.selectCountry" formControlName="CountryId" [selectSearch]="true"></app-select>
            </div>
          </div>

          <ng-container [ngSwitch]="data.modalType" *ngIf="!data.resellerPrivateBrand">
            <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="imageLoader"></ng-container>
            <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="imageUpdate"></ng-container>
          </ng-container>

          <div class="checkbox" *ngIf="data.resellerPrivateBrand">
            <mat-checkbox formControlName="IsActive" class="form-checkbox">{{'ACTIVE' | translate}}</mat-checkbox>
          </div>

          <div class="save-btn">
            <button (click)="submit()" class="btn-admin" [disabled]="!manufacturerForm.valid">
              {{'SAVE' | translate}}
            </button>
          </div>
        </form>
      </div>
    </mat-dialog-content>

  </ng-template>
</div>

<ng-template #deleteCriteria>
    <div class="delete-action-wrap">
      <div class="table-loader" *ngIf="(isLoading$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <p class="delete-text">{{'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_CRITERIA_WITH_VALUE' | translate}} {{data.name}}
        ?</p>
      <button (click)="deleteItem(data.id)" class="btn-admin">{{'DELETE' | translate}}</button>
    </div>
</ng-template>

<ng-template #imageLoader>
  <app-image-uploader [showImage]="true" (uploadImage)="updateImage($event)" [imageSize]="imageSize">
    <ng-container>
      {{'LOGO' | translate}}
    </ng-container>
    <ng-container requirements>
      {{'FILE_SIZE_MUST_BE_NOT_GREATER_THAN_1234PX1234PX' | translate}}
    </ng-container>
  </app-image-uploader>
</ng-template>

<ng-template #imageUpdate>
  <app-image-uploader [imgSrc]="data.logo" [showImage]="true" (uploadImage)="updateImage($event)" [imageSize]="imageSize">
    <ng-container>
      {{'LOGO' | translate}}
    </ng-container>
    <ng-container requirements>
      {{'FILE_SIZE_MUST_BE_NOT_GREATER_THAN_1234PX1234PX' | translate}}
    </ng-container>
  </app-image-uploader>
</ng-template>


