import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { IBanner, IBannerList, IBannerSection } from '../../modules/state/general-admin/banners';

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  readonly PATH = '/ad';

  constructor(private apiService: ApiService) {
  }

  getData(typeId: number, params?: { page: number }): Observable<IBannerList> {
    return this.apiService.get(`${ this.PATH }/list/${ typeId }`, {...params});
  }

  getBannerSection(): Observable<IBannerSection[]> {
    return this.apiService.get(`${ this.PATH }/section`);
  }

  getBannerSectionById(id: number): Observable<IBannerList> {
    return this.apiService.get(`${ this.PATH }/${ id }`);
  }

  updateBanner(element: FormData): Observable<IBanner> {
    return this.apiService.put(`${ this.PATH }/edit`, element);
  }

  addBanner(element: FormData): Observable<IBanner> {
    return this.apiService.put(`${ this.PATH }/add`, element);
  }
}
