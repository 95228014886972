import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import {
  addManufacturerElement,
  deleteManufacturerElement,
  IPrivateBrand,
  ManufacturerElement,
  updateManufacturerElement,
} from '../../../../../../modules/state/general-admin/criteria/manufacturer';
import { EModalType } from '../../../../../../modules/shared/interfaces/table.interfaces';
import {
  getManufacturerError,
  getManufacturerLoader,
  getManufacturerSuccessLoader
} from '../../../../../../modules/state/general-admin/criteria/manufacturer/manufacturer.selectors';
import { isEmptyObj } from '../../../../../../utils/helpers';
import { NotificationService } from '../../../../../../api/notification.service';
import { ECriteriaControls, EModalMessage } from '../../criteria.constants';
import { ImageModeEnum } from '../../../../../../modules/shared/enums/imageMode.enums';
import { IImageSize } from '../../../../../../modules/shared/enums/imageLoader.enums';
import { getLookupParameters, ILookupParameters } from '../../../../../../modules/state/general-admin/characteristic';
import {
  selectLookupParams
} from '../../../../../../modules/state/general-admin/characteristic/characteristic.selectors';
import { addPrivateBrand, updatePrivateBrand } from '../../../../../../modules/state/general-admin/tire-reseller';
import {
  getResellerError, getResellerLoader,
  getResellerSuccessLoader
} from '../../../../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';

@Component({
  selector: 'app-manufacturer-add-dialog',
  templateUrl: './manufacturer-add-dialog.component.html',
  styleUrls: ['./manufacturer-add-dialog.component.scss']
})
export class ManufacturerAddDialogComponent implements OnInit, OnDestroy {
  public manufacturerForm: FormGroup;
  public EModalType = EModalType;
  public destroy$: Subject<void> = new Subject<void>();
  public imageMode: ImageModeEnum = ImageModeEnum.None;
  public isLoading$: Observable<boolean>;
  public privateBrandLoading$: Observable<boolean>;
  public ECriteriaLabel = ECriteriaControls;
  public imageSize: IImageSize = {width: 1234, height: 1234};
  public params$: Observable<ILookupParameters>;

  constructor(private dialog: MatDialogRef<ManufacturerElement>,
              private fb: FormBuilder,
              private store$: Store,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: ManufacturerElement) {
  }

  closeAddDialog(): void {
    this.dialog.close();
  }

  updateImage(image: File): void {
    this.manufacturerForm.controls['Logo.File']?.setValue(image);
    this.manufacturerForm.controls['Logo.Mode']?.setValue(ImageModeEnum.Replace);
  }

  ngOnInit(): void {
    this.initForm();
    this.getParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getParams(): void {
    if (this.data.resellerPrivateBrand) return;
    this.store$.dispatch(getLookupParameters({payload: 'Country'}));
    this.params$ = this.store$.select(selectLookupParams);
    this.manufacturerForm.controls['CountryId'].setValidators([Validators.required]);
    this.manufacturerForm.controls['CountryId'].updateValueAndValidity();
  }

  initForm(): void {
    if (this.data.modalType === EModalType.add) {
      this.manufacturerForm = this.fb.group({
        Name: ['', [Validators.required]],
        // Priority: ['', [Validators.required]],
        'Logo.File': [null, []],
        'Logo.Mode': [this.imageMode, []],
        IsActive: [true],
        CountryId: ['']
      });
      return;
    }
    this.manufacturerForm = this.fb.group({
      Name: [this.data.name, [Validators.required]],
      // Priority: [this.data.priority, [Validators.required]],
      'Logo.File': [null],
      'Logo.Mode': [this.imageMode],
      IsActive: [true],
      CountryId: [this.data.country?.id]
    });
    this.isLoading$ = this.store$.select(getManufacturerLoader);
    this.privateBrandLoading$ = this.store$.select(getResellerLoader);
  }

  submit(): void {
    if (this.data.modalType === EModalType.add && !this.data.resellerPrivateBrand) {
      this.store$.dispatch(addManufacturerElement({
        payload: this.createFormData(),
        brandType: this.data.brandType as string
      }));
      this.checkRequest(EModalMessage.add);
      return;
    }
    if (this.data.modalType === EModalType.edit && !this.data.resellerPrivateBrand) {
      this.store$.dispatch(updateManufacturerElement({
        payload: this.createFormData(),
        id: this.data.id,
        brandType: this.data.brandType as string
      }))
    }
    if (this.data.resellerPrivateBrand) {
      const payload: IPrivateBrand = {
        name: this.manufacturerForm.value.Name,
        logo: {
          mode: ImageModeEnum.None,
          file: ''
        },
        isActive: this.manufacturerForm.value.isActive,
        tireResellerId: this.data.tireResellerId
      }
      this.data.modalType === EModalType.add ? this.store$.dispatch(addPrivateBrand({payload}))
        : this.store$.dispatch(updatePrivateBrand({payload, id: this.data.id}));
      const message = this.data.modalType === EModalType.add ? EModalMessage.add : EModalMessage.edit;
      this.checkPrivateBrandEditing(message);
      return;
    }
    this.checkRequest(EModalMessage.edit);
  }

  deleteItem(itemId: number): void {
    this.store$.dispatch(deleteManufacturerElement({payload: itemId}));
    this.checkRequest(EModalMessage.delete);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getManufacturerError),
      this.store$.select(getManufacturerSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.dialog.close();
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.error.message);
        }
      })
  }

  checkPrivateBrandEditing(message: string): void {
    combineLatest(
      this.store$.select(getResellerError),
      this.store$.select(getResellerSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.close();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.error.message);
        }
      })
  }
  createFormData(): FormData {
    const formValue = {...this.manufacturerForm.value};
    const formData = new FormData();
    for (const key in formValue) {
      formData.append(key, formValue[key]);
    }
    formData.append('ManufactureType', this.data.brandType as string)
    return formData;
  }
}
