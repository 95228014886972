import { HttpErrorResponse } from "@angular/common/http";

export interface ILeadStatus {
  name: string,
  isActive: boolean,
  priority: string,
  modalType?: string
}

export interface ILeadStatusDB extends ILeadStatus {
  id: number
}

export interface ILeadsStatusState {
  current: ILeadStatus,
  deleteId: number | null,
  isLoading: boolean,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  content: ILeadStatusDB[],
  error: HttpErrorResponse
}
