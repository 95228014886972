import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';

import { EModalType, ERimTitle } from '../../../../modules/shared/interfaces/table.interfaces';
import { IRim } from '../../../../modules/state/general-admin/rims/rims.model';
import {
  addRim,
  deleteRim,
  getRimsError,
  getRimsLoader,
  getRimsSuccessLoader,
  updateRim
} from '../../../../modules/state/general-admin/rims';
import { ERimLabel, ERimPlaceholder } from '../rims.enums';
import { ILookupParameters } from '../../../../modules/state/general-admin/characteristic';
import {
  selectCharacteristicSuccessLoader,
  selectLookupParams
} from '../../../../modules/state/general-admin/characteristic/characteristic.selectors';
import { ImageModeEnum } from '../../../../modules/shared/enums/imageMode.enums';
import { NotificationService } from '../../../../api/notification.service';
import { isEmptyObj } from '../../../../utils/helpers';
import { EModalMessage } from '../../criteria/components/criteria.constants';
import { IImageSize } from '../../../../modules/shared/enums/imageLoader.enums';

@Component({
  selector: 'app-manage-rims-dialog',
  templateUrl: './manage-rims-dialog.component.html',
  styleUrls: ['./manage-rims-dialog.component.scss']
})
export class ManageRimsDialogComponent implements OnInit, OnDestroy {

  public rimFrom: FormGroup;
  public EModalType = EModalType;
  public destroy$: Subject<void> = new Subject<void>();
  public ErimLabel = ERimLabel;
  public ERimPlaceholder = ERimPlaceholder;
  // public diameterOptions$: Observable<ICharacteristicOption[]>;
  public params$: Observable<ILookupParameters>;
  public isParamsLoad$: Observable<boolean>;
  public modalTitle: string = ERimTitle.add;
  public imageMode: string = ImageModeEnum.None;
  public loader$: Observable<boolean>;
  public imageSize: IImageSize = {width: 5000, height: 5000};

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private store$: Store,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: IRim) {
  }

  ngOnInit(): void {
    this.getData();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    if (this.data.modalType === EModalType.add) {
      this.rimFrom = this.fb.group({
        ArticleNumber: ['', [Validators.required]],
        ManufacturerId: ['', [Validators.required]],
        BruttoPrice: ['', [Validators.required]],
        RadiusCharacteristicOptionId: ['', [Validators.required]],
        ArticleName: ['', [Validators.required]],
        'Logo.File': [null],
        'Logo.Mode': [this.imageMode]
      });
      return;
    }
    this.rimFrom = this.fb.group({
      ArticleNumber: [this.data.articleNumber, [Validators.required]],
      ManufacturerId: [this.data.manufacturer.id, [Validators.required]],
      BruttoPrice: [this.data.bruttoPrice, [Validators.required]],
      RadiusCharacteristicOptionId: [this.data.radiusCharacteristic?.id, [Validators.required]],
      ArticleName: [this.data.articleName, [Validators.required]],
      'Logo.File': [null],
      'Logo.Mode': [this.imageMode]
    });
  }

  getData(): void {
    // this.diameterOptions$ = this.store$.select(getOptionsById(3));
    this.isParamsLoad$ = this.store$.select(selectCharacteristicSuccessLoader);
    this.params$ = this.store$.select(selectLookupParams);
    this.loader$ = this.store$.select(getRimsLoader);
    if (this.data.modalType === EModalType.edit) this.modalTitle = ERimTitle.edit;
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  updateImage(image: File): void {
    this.rimFrom.controls['Logo.File']?.setValue(image);
    this.rimFrom.controls['Logo.Mode']?.setValue(ImageModeEnum.Replace);
  }

  submit(event: Event): void {
    event.preventDefault();
    if (this.data.modalType === EModalType.add) {
      this.store$.dispatch(addRim({payload: this.createFormData()}));
      this.checkRequest(EModalMessage.add);
      return;
    }
    this.store$.dispatch(updateRim({payload: this.createFormData()}));
    this.checkRequest(EModalMessage.edit);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getRimsError),
      this.store$.select(getRimsSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }

  createFormData(): FormData {
    const formValue = {...this.rimFrom.value};
    const formData = new FormData();
    for (const key in formValue) formData.append(key, formValue[key]);
    if (this.data.modalType === EModalType.edit) {
      formData.append("Id", this.data.id.toString());
      return formData;
    }
    return formData;
  }

  deleteRim(): void {
    this.store$.dispatch(deleteRim({id: this.data.id}));
    this.checkRequest(EModalMessage.delete);
  }
}
