<div class="dialog manufacturer-add-dialog">
  <div class="dialog-title">
    <h3 class="title">
      {{mainTitle | translate}}
    </h3>
    <div class="title-actions">
      <mat-icon class="close" (click)="closeAddDialog()">close</mat-icon>
    </div>
  </div>

  <ng-container [ngSwitch]="data.modalType">
    <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="editBlogElement"></ng-container>
    <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="editBlogElement"></ng-container>
    <ng-container *ngSwitchCase="EModalType.delete" [ngTemplateOutlet]="deleteBlogElement"></ng-container>
  </ng-container>

  <ng-template #editBlogElement>
    <mat-dialog-content>
      <div class="dialog-form-wrap">
        <div class="table-loader" *ngIf="!(loader$ | async)">
          <mat-spinner></mat-spinner>
        </div>
        <div [formGroup]="blogForm">
          <div class="form-group row">

            <div class="col-6 form-right">
              <div class="form-title">
                <app-input [label]="EBlogInputTitle.title" [placeholder]="EBlogInputPlaceholder.title"
                           [isRequired]="true" formControlName="Title"></app-input>
              </div>

              <div class="double-field">
                <div class="col-6 form-col">
                  <app-select [label]="EBlogInputTitle.type" [placeholder]="EBlogInputPlaceholder.type"
                              [options]="newsTypeOptions"
                              [isRequired]="true" formControlName="NewsType"></app-select>
                </div>
                <div class="col-6 form-col">
                  <app-input [label]="EBlogInputTitle.auditor" [placeholder]="EBlogInputPlaceholder.auditor"
                             [isRequired]="true" formControlName="Auditor"></app-input>
                </div>
              </div>
              <angular-editor [placeholder]="EBlogInputPlaceholder.description | translate" [config]="editorConfig"
                              formControlName="Description"></angular-editor>
            </div>
            <div class="col-6 form-col">
              <ng-container [ngSwitch]="data.modalType">
                <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="imageLoader"></ng-container>
                <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="imageUpdate"></ng-container>
              </ng-container>
            </div>
          </div>
          <div class="save-btn">
            <button class="btn-admin" (click)="submit()" [disabled]="!blogForm.valid">
              {{'SAVE' | translate}}
            </button>
          </div>
        </div>
      </div>
    </mat-dialog-content>

  </ng-template>
</div>
<ng-template #deleteBlogElement>
  <div class="delete-action-wrap">
    <p class="delete-text">{{'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ARTICLE' | translate}} {{data.title}}?</p>
    <button (click)="deleteItem(data.id)" class="btn-admin">{{'DELETE' | translate}}</button>
  </div>
</ng-template>

<ng-template #imageLoader>
  <app-image-uploader [showImage]="true" (uploadImage)="updateImage($event)" [imageSize]="imageSize">
    <ng-container>
      {{'UPLOAD_IMG' | translate}}
    </ng-container>
    <ng-container requirements>
      {{'SUPPORTED_FORMATS_PNG_JPG_MAX_SIZE' | translate}}
    </ng-container>
  </app-image-uploader>
</ng-template>

<ng-template #imageUpdate>
  <app-image-uploader [imgSrc]="data.previewPictureUrl" [showImage]="true" [imageSize]="imageSize" (uploadImage)="updateImage($event)">
    <ng-container>
      {{'UPLOAD_IMG' | translate}}
    </ng-container>
    <ng-container requirements>
      {{'SUPPORTED_FORMATS_PNG_JPG_MAX_SIZE' | translate}}
    </ng-container>
  </app-image-uploader>
</ng-template>
