import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentsComponent } from './components/payments/payments.component';
import { PaymentsRoutingModule } from './payments-routing.module';
import { SharedModule } from "../shared/shared.module";
import { StarterTableModule } from 'starter-shared/src/app/modules/starter-table/starter-table.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PaymentsComponent,
  ],
  exports: [
    PaymentsComponent
  ],
  imports: [
    CommonModule,
    PaymentsRoutingModule,
    SharedModule,
    StarterTableModule,
    ReactiveFormsModule
  ]
})
export class PaymentsModule {
}
