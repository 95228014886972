import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import ManufacturerApiService from "../../../../../api/general-admin-services/manufacturer.api.service";
import * as manufacturerActions from "./manufacturer.actions";

@Injectable()
export class ManufacturerEffects {
  constructor(
    private actions$: Actions,
    private manufacturerApiService: ManufacturerApiService
  ) {
  }

  getManufacturerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manufacturerActions.getManufacturerData),
      switchMap(() =>
        this.manufacturerApiService.getManufacturerData().pipe(
          map(response => manufacturerActions.getManufacturerDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(manufacturerActions.getManufacturerDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createManufacturerElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manufacturerActions.addManufacturerElement),
      switchMap(action =>
        this.manufacturerApiService.addManufacturerElement(action.payload).pipe(
          map(response => manufacturerActions.addManufacturerElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(manufacturerActions.addManufacturerElementFailure({payload: err})))
        )
      )
    )
  )

  updateManufacturerElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manufacturerActions.updateManufacturerElement),
      switchMap(action =>
        this.manufacturerApiService.updateManufacturerElement(action.payload, action.id).pipe(
          map(response => manufacturerActions.updateManufacturerElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(manufacturerActions.updateManufacturerElementFailure({payload: err})))
        )
      )
    )
  )

  deleteManufacturerElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manufacturerActions.deleteManufacturerElement),
      switchMap(action =>
        this.manufacturerApiService.deleteManufacturerElement(action.payload).pipe(
          map(() => manufacturerActions.deleteManufacturerElementSuccess()),
          catchError((err: HttpErrorResponse) => of(manufacturerActions.deleteManufacturerElementFailure({payload: err})))
        )
      )
    )
  )

  switchManufacturerStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manufacturerActions.switchManufacturerStatus),
      switchMap(action =>
        this.manufacturerApiService.switchManufacturerStatus(action.id).pipe(
          map(() => manufacturerActions.switchManufacturerStatusSuccess({id: action.id})),
          catchError((err: HttpErrorResponse) => of(manufacturerActions.switchManufacturerStatusFailure({payload: err})))
        )
      )
    )
  )
}
