import { HttpErrorResponse } from '@angular/common/http';
import { IParameter } from '../../state/general-admin/tire-reseller';

export interface LoginResponse {
  ttl: number;
  fullName: string;
  createdAt: string;
  email: string;
  token: string;
  role: string;
  inventory: boolean,
  vehicleType: number,
  tireResellerId: string,
  subscriptionType: IParameter,
  twsName?: string;
}

export interface ValidationErrorResponse {
  failures: { [key: string]: string };
  status: number;
  message: string;
  internalErrorCode: number;
  internalErrorName: string;
}

export enum UserRoles {
  AdminUser = 'generalAdmin',
  BusinessUser = 'tireReseller'
}

export interface UserState {
  user: LoginResponse,
  error: HttpErrorResponse | null
}

export interface AuthState {
  user: UserState
}

export enum EUserRoutes {
  Admin = 'admin',
  Reseller = 'business'
}

export interface IResellerRoute {
  path?: string,
  resellerId: string,
  title: string,
  // needId: boolean
}

export const CRoutesNeedId = [
  'inventory-set-up-cars/:resellerId', 'inventory-set-up-motorcycles/:resellerId',
  'set-price-cars/:resellerId', 'set-price-motorcycles/:resellerId'
]
