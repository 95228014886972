import { HttpErrorResponse } from "@angular/common/http";
import { IResponse } from '../../../../api/api-data/api.interfaces';

export interface BlogElement {
  title: string,
  auditor: string,
  description: string,
  previewPictureUrl: string,
  createdAt: Date,
  publish: boolean,
  type: {id: number, value: string},
  modalType?: string
}

export interface BlogDBElement extends BlogElement {
  id: number
}
export interface BlogState {
  result: IResponse<BlogDBElement[]>;
  deleteId: number | null;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  error: HttpErrorResponse;
}

export interface IBlogParams {
  page: number,
  pageSize?: number,
  filters?: string
}
