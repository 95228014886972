import { Component, Input, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";

import { adminRoutes } from "../../t4u-admin/t4u-admin-routing.module";
import { businessRoutes } from "../../business-user/business-user-routing.module";
import { UserLogout } from "../../modules/auth/reducers/user.actions";
import { EUserRoutes, IResellerRoute, UserRoles } from '../../modules/auth/interfaces/auth.interface';
import { Router, Routes } from '@angular/router';
import { AuthService } from '../../modules/auth/services/auth.service';
import { EBusinessRoutes } from '../../business-user/business-user.enums';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() userRole: string;
  public userRoles = UserRoles;
  public menuToggled: boolean = false;
  public adminRoot = EUserRoutes.Admin;
  public businessRoot = EUserRoutes.Reseller;
  public adminRoutes = adminRoutes;
  public businessRoutes: Routes;
  public routesWithId: IResellerRoute[] = [];
  public firstRoutesWithId: IResellerRoute[] = [];
  public routePath: string;

  constructor(private store$: Store,
              private router: Router,
              public auth: AuthService) {
  }

  ngOnInit(): void {
    this.businessRoutes = this.checkBusinessPages();
  }

  toggleMenu(): void {
    this.menuToggled = !this.menuToggled;
  }

  onLogout(): void {
    const genAdmin = this.userRole === UserRoles.AdminUser;
    this.store$.dispatch(UserLogout({genAdmin}));
  }

  checkUserVehicleType(): Routes {
    let routes = businessRoutes;
    if(!this.auth.reseller) return [];
    if (this.auth.reseller.vehicleType === 0) {
      routes = routes.filter(route => route.path !== EBusinessRoutes.inventoryMoto
        && route.path !== EBusinessRoutes.setPriceMoto
        && route.path !== EBusinessRoutes.priceListMoto);
    } else {
      routes = routes.filter(route => route.path !== EBusinessRoutes.inventoryCars
        && route.path !== EBusinessRoutes.setPriceCars
        && route.path !== EBusinessRoutes.priceListCars);
    }
    return routes;
  }

  checkBusinessPages(): Routes {
    let routes = this.checkUserVehicleType();
    if (!this.auth.reseller) return routes;
    const resellerId = this.auth.reseller.tireResellerId;
    switch (this.auth.reseller.subscriptionType.id) {
      case (3):
        routes = routes.filter(route =>
          route.path !== EBusinessRoutes.managePrices &&
          route.path !== EBusinessRoutes.expectedProfit &&
          route.path !== EBusinessRoutes.leads &&
          route.path !== EBusinessRoutes.setPriceCars &&
          route.path !== EBusinessRoutes.setPriceMoto);
        break;
      case (5):
        routes = routes.filter(route =>
          route.path !== EBusinessRoutes.inventoryCars &&
          route.path !== EBusinessRoutes.inventoryMoto &&
          route.path !== EBusinessRoutes.goldLeads
        );
        break;
      case (1):
        routes = this.checkUserVehicleType();
        break;
      case (6):
        routes = this.checkUserVehicleType();
        break;
      case (7):
        routes = routes.filter(route =>
          route.path !== EBusinessRoutes.inventoryCars &&
          route.path !== EBusinessRoutes.inventoryMoto &&
          route.path !== EBusinessRoutes.goldLeads
        );
        break;
      default:
        routes = routes.filter(route =>
          route.path !== EBusinessRoutes.managePrices &&
          route.path !== EBusinessRoutes.expectedProfit &&
          route.path !== EBusinessRoutes.inventoryCars &&
          route.path !== EBusinessRoutes.inventoryMoto &&
          route.path !== EBusinessRoutes.setPriceMoto &&
          route.path !== EBusinessRoutes.setPriceCars &&
          route.path !== EBusinessRoutes.goldLeads
        );
    }
    const routesPath: { path?: string, title: string }[] = [];
    routes.forEach(route => routesPath.push({path: route.path, title: route.data?.menuTitle}));
    routesPath.forEach(route => {
      this.routesWithId.push({path: route.path, resellerId, title: route.title});
    })
    this.routesWithId = this.routesWithId.slice(2, this.routesWithId.length);
    this.firstRoutesWithId.push({path: routesPath[0].path, resellerId, title: routesPath[0].title});
    this.firstRoutesWithId.push({path: routesPath[1].path, resellerId, title: routesPath[1].title});
    return routes;
  }

  navigate(route: IResellerRoute): void {
    this.routePath = route.path as string;
    const path = route.path?.split('/')[0];
    this.router.navigate(['/', this.businessRoot, path, route.resellerId]);
    return;
  }
}
