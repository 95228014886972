<div class="content statistics" dir="rtl">
  <div class="content-head">
    <div class="head-title">{{'LEADS' | translate}} ({{month | translate}})</div>
  </div>
  <div class="content-body list">
    <div class="info-cards" *ngIf="(statistic$ | async) as list">
      <div class="card" *ngFor="let item of checkLeadsBySubscription(list.leads)" [ngClass]="{'item-virtual': item.virtualNumber}">
        <div class="name">{{item.leadsTitle | translate}}</div>
        <div class="card-info">
          <div class="count">
            {{item.leadsNumber | number}}
          </div>

        </div>
        <div class="virtual-phone" *ngIf="item.virtualNumber">{{changeVirtualNumber(item.virtualNumber)}}</div>
      </div>
      <div class="card total">
        <div class="name">{{EStatisticTitle.totalBalance | translate}}</div>
        <div class="card-info">
          <div class="count">
            {{list.total.leadsNumber | number}}
            <span class="count-number">({{list.total.uniqueLeadsNumber | number}})</span>
            <mat-icon *ngIf="!!list.total.leadsNumber" matTooltip="{{'DUPLICATED_LEAD_NOTIFICATION' | translate}}"
                      matTooltipPosition="above"
                      class="info">info
            </mat-icon>
          </div>
          <span class="alone"></span>
          <div class="count">{{list.total.uniqueLeadBalanceBeforeVat | currency : 'ILS' : 'symbol': '1.0-1'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="content blog_table" *ngIf="(leadsList$ | async) as response">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="filterContent(response.content, response.totalRecord)"
      [language]="languageService.mainLanguage.value"
      [isLoadingTable]="!(isTableLoad$ | async)"
      (onClick)="opent4uLink($event)"
      [filterData]="response.filters"
      [dateRange]="initialDateRange"
      (filters)="getTableFilters($event)"
      (dropdownValue)="changeStatus($event)"
    >
    </starter-table>
    <div *ngIf="response.content && !response.content.length" class="no-result-table">
      {{'NO_DATA_MATCHING_THE_FILTER' | translate}}
    </div>
    <div class="pagination" *ngIf="response.content">
          <span class="pagination-item"
                *ngFor="let page of response.content | paginate:
                { itemsPerPage: 10,
                 currentPage: currentPage,
                 totalItems: response.totalRecord }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>
