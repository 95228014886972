import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as TireResellerActions from "./tire-reseller.actions";
import TireResellersApiService from "../../../../api/general-admin-services/tire-reseller.api.service";

@Injectable()
export class TireResellerEffects {
  constructor(private actions$: Actions,
              private TireResellersApiService: TireResellersApiService) {
  }

  getTireReseller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.getTireResellerList),
      switchMap(action =>
        this.TireResellersApiService.getTireResellerData(action.payload).pipe(
          map(response => TireResellerActions.getTireResellerListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.getTireResellerListFailure({payload: err}))
          )
        )
      )
    )
  )

  addTireReseller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.addTireReseller),
      switchMap(action =>
        this.TireResellersApiService.addTireResellerElement(action.payload).pipe(
          map(response => TireResellerActions.addTireResellerSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.addTireResellerFailure({payload: err}))
          )
        )
      )
    )
  )

  updateTireReseller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.updateTireReseller),
      switchMap(action =>
        this.TireResellersApiService.updateTireResellerElement(action.payload).pipe(
          map(reseller => {
            if (action.businessUser) return TireResellerActions.updateBusinessTireResellerSuccess({payload: reseller})
            return TireResellerActions.updateTireResellerSuccess({payload: reseller})
          }),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.updateTireResellerFailure({payload: err}))
          )
        )
      )
    )
  )

  editTireResellerMaskyooNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.editTireResellerMaskyooNumber),
      switchMap(action =>
        this.TireResellersApiService.editTireResellerMaskyooNumber(action.payload).pipe(
          map(payload => {
            return TireResellerActions.editTireResellerMaskyooNumberSuccess({payload})
          }),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.editTireResellerMaskyooNumberFailure({payload: err}))
          )
        )
      )
    )
  )

  getTireResellerById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.getTireResellerById),
      switchMap(action =>
        this.TireResellersApiService.getTireResellerById(action.payload).pipe(
          map(reseller => TireResellerActions.getTireResellerByIdSuccess({payload: reseller})),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.getTireResellerByIdFailure({payload: err}))
          )
        )
      )
    )
  )


  getTireInfoById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.getTireInfoById),
      switchMap(action =>
        this.TireResellersApiService.getTireInfoById(action.payload).pipe(
          map(payload => TireResellerActions.getTireInfoByIdSuccess({payload})),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.getTireInfoByIdFailure({payload: err}))
          )
        )
      )
    )
  )

  getTireResellerPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.getTireResellerPricesProfit),
      switchMap(action =>
        this.TireResellersApiService.getTireResellerPrices(action.id).pipe(
          map(prices => TireResellerActions.getTireResellerPricesProfitSuccess({payload: prices})),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.getTireResellerPricesProfitFailure({payload: err}))
          )
        )
      )
    )
  )

  changeTireResellerPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.changeTireResellerPricesProfit),
      switchMap(action =>
        this.TireResellersApiService.changeTireResellerPrices(action.id, action.profit).pipe(
          map(() => TireResellerActions.changeTireResellerPricesProfitSuccess()),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.changeTireResellerPricesProfitFailure({payload: err}))
          )
        )
      )
    )
  )

  getResellerToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.getResellerToken),
      switchMap(action =>
        this.TireResellersApiService.getResellerToken(action.id).pipe(
          map((user) => TireResellerActions.getResellerTokenSuccess({user})),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.getResellerTokenFailed({payload: err}))
          )
        )
      )
    )
  )

  changeTWSShowing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.changeTWSShowing),
      switchMap(action =>
        this.TireResellersApiService.changeTWSShowing(action.id, action.active).pipe(
          map((payload) => TireResellerActions.changeTWSShowingSuccess({payload: payload.entity})),
          catchError((err: HttpErrorResponse) =>
            of(TireResellerActions.changeTWSShowingFailed({payload: err}))
          )
        )
      )
    )
  )

  addPrivateBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.addPrivateBrand),
      switchMap(action =>
        this.TireResellersApiService.addPrivateBrand(action.payload).pipe(
          map(response => TireResellerActions.addPrivateBrandSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(TireResellerActions.addPrivateBrandFailure({payload: err})))
        )
      )
    )
  )

  editPrivateBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.updatePrivateBrand),
      switchMap(action =>
        this.TireResellersApiService.updatePrivateBrand(action.payload, action.id).pipe(
          map(response => TireResellerActions.updatePrivateBrandSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(TireResellerActions.updatePrivateBrandFailure({payload: err})))
        )
      )
    )
  )

  getPrivateBrandList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.getPrivateBrandList),
      switchMap(action =>
        this.TireResellersApiService.getPrivateBrandList(action.payload).pipe(
          map(response => TireResellerActions.getPrivateBrandListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(TireResellerActions.getPrivateBrandListFailure({payload: err})))
        )
      )
    )
  )

  getPrivateBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TireResellerActions.getPrivateBrand),
      switchMap(action =>
        this.TireResellersApiService.getPrivateBrand(action.resellerId, action.brandId).pipe(
          map(response => TireResellerActions.getPrivateBrandSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(TireResellerActions.getPrivateBrandFailure({payload: err})))
        )
      )
    )
  )
}
