import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

import { environment } from "../environments/environment";
import { IconsService } from './api/icons.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tires4u-admin-panel-frontend';

  constructor(translate: TranslateService, iconsService: IconsService,) {
    const langCode = localStorage.getItem('langCode');
    translate.setDefaultLang(environment.defaultLanguage);
    if (langCode) {
      translate.use(langCode);
    }
    iconsService.registryIcons();
  }
}
