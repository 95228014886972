import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeInputComponent),
    multi: true
  }]
})
export class TimeInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() startTime: string = '';
  @Input() isError: boolean = false;
  public value: string;
  public onChange: (p: any) => void = () => {};
  public onTouch: () => void = () => {};

  valueChange(event: any): void {
    this.value = event.target.value;
    this.onTouch();
    this.onChange(event.target.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
