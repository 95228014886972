import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public localLang: string | null = localStorage.getItem('langCode');
  public lang = this.localLang ? this.localLang : environment.defaultLanguage;
  public mainLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(this.lang);

  changeLanguage(ln: string): void {
    this.mainLanguage.next(ln)
  }
}
