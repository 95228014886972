import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMapsMarker } from '../maps.interface';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import Geocoder = google.maps.Geocoder;

@Component({
  selector: 'app-component',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {

  public zoom: number = 12;
  private geoCoder: Geocoder;
  @ViewChild('search') public searchElementRef: ElementRef;
  public marker: IMapsMarker = {
    latitude: 32.109333,
    longitude: 34.855499,
    address: '',
    // googleSearchText: ''
  };

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private dialog: MatDialogRef<GoogleMapComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IMapsMarker | null
  ) { }

  ngOnInit(): void {
    this.checkAddress();
    this.setMapsAutocomplete();
  }

  checkAddress(): void {
    if (!this.data) return;
    this.marker.longitude = this.data.longitude;
    this.marker.latitude = this.data.latitude;
    this.marker.address = this.data.address;
    this.zoom = 18;
  }

  setMapsAutocomplete(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocompleteOptions: google.maps.places.AutocompleteOptions = {
        componentRestrictions: { country: 'IL' },
        types: ['geocode'],
      };
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, autocompleteOptions);

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (!place.geometry) return;

          this.marker.latitude = place.geometry.location.lat();
          this.marker.longitude = place.geometry.location.lng();
          this.zoom = 18;

          this.getCurrentLocation().pipe(take(1)).subscribe({
            next: (address) => {
              this.marker = {
                latitude: this.marker.latitude,
                longitude: this.marker.longitude,
                address
              }
            }
          });
        });
      });
    });
  }


  getCurrentLocation(): Observable<string> {
    return new Observable<string>((obsever) => {
      let latlng = {lat: this.marker.latitude, lng: this.marker.longitude};
      this.geoCoder.geocode({'location': latlng}, (results) => {
        if (results[0]) {
          obsever.next(results[0].formatted_address);
        } else {
          obsever.next('');
        }
      });
    });
  }

  markerDragEnd(event: google.maps.MouseEvent): void {
    this.marker.latitude = event.latLng.lat();
    this.marker.longitude = event.latLng.lng();
    this.getCurrentLocation().pipe(take(1)).subscribe({
      next: (address) => {
        this.marker = {
          latitude: this.marker.latitude,
          longitude: this.marker.longitude,
          // googleSearchText: this.marker.googleSearchText,
          address
        }
      }
    });
  }

  closeAddDialog(): void {
    this.dialog.close();
  }

  saveAddress(): void {
    this.dialog.close(this.marker);
  }

  // getGoogleSearchWord(event: any): void {
  //   this.marker.googleSearchText = event.target.value;
  // }
}
