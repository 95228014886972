<div class="nav-tabs">
  <nav
    mat-tab-nav-bar
    disableRipple
    #navTabs
  >
    <a mat-tab-link
       *ngFor="let tab of navTabList"
       [routerLink]="tab.path"
       routerLinkActive
       #rla="routerLinkActive"
       [active]="rla.isActive"
    >
      {{ tab.data?.menuTitle | translate}}
    </a>
  </nav>
</div>
