import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ManufacturerState } from "./manufacturer.model";

export const getManufacturerState = createFeatureSelector<ManufacturerState>('manufacturer');

export const getManufacturerSuccessLoader = createSelector(getManufacturerState,
  (state: ManufacturerState): boolean => state.isLoadingSuccess);

export const getManufacturerLoader = createSelector(getManufacturerState,
  (state: ManufacturerState): boolean => state.isLoading);

export const getManufacturerError = createSelector(getManufacturerState,
  (state: ManufacturerState) => state.error);

export const getTireManufactureTable = createSelector(getManufacturerState,
  (state: ManufacturerState) => state.content.Tire);

export const getRimManufactureTable = createSelector(getManufacturerState,
  (state: ManufacturerState) => state.content.Rim);
