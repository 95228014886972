import { CellTypes, TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';

export const ManageCarTableMeta: TableMeta[] = [
  {
    "name": 'PUBLISH',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Checkbox,
    "jsonPath": 'isActive',
  },
  {
    "name": 'INV',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Icon,
    "jsonPath": 'inv',
  },
  {
    "name": 'ID',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'id',
  },
  {
    "name": 'NAME',
    "sortable": false,
    "filterable": false,
    "sliced": false,
    "type": CellTypes.String,
    "jsonPath": 'name',
    "sortName": 'Name'
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'brand.value',
    "filterName": "Brand"
  },
  {
    "name": 'PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.EditablePrice,
    "jsonPath": 'bruttoPrice',
    "sortName": 'BruttoPrice'
  },
  {
    "name": 'SPEED_CODE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'speedCode.value',
    "filterName": 'SpeedCode',
    "sortName": 'SpeedCodeValue'
  },
  {
    "name": 'WEIGHT',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'weightCode.value',
    "filterName": 'WeightCode',
    "sortName": 'WeightCodeValue'
  },
  {
    "name": 'DIAMETER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'diameter.value',
    "filterName": 'Diameter',
    "sortName": 'DiameterValue'
  },
  {
    "name": 'HEIGHT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'height.value',
    "filterName": 'Height',
    "sortName": 'HeightValue'
  },
  {
    "name": 'WIDTH',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'width.value',
    "filterName": 'Width',
    "sortName": 'WidthValue'
  },
  {
    "name": 'TIRE_TYPE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.ObjectArray,
    "jsonPath": 'tireType',
    "filterName": 'TireType',
    "sortName": 'TireTypeValue'
  },
  {
    "name": 'SUBCATEGORY',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'subTireType.value',
  },
  {
    "name": 'ACTIONS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Action,
    "jsonPath": 'actions',
    actions: [{
      action: 'edit',
      icon: 'border_color'
    },
      {
        action: 'copy',
        icon: 'content_copy'
      }]
  },
]

export const ManageMotoTireTableMeta: TableMeta[] = [
  {
    "name": 'PUBLISH',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Checkbox,
    "jsonPath": 'isActive',
  },
  {
    "name": 'ID',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'id',
  },
  {
    "name": 'NAME',
    "sortable": false,
    "filterable": false,
    "sliced": false,
    "type": CellTypes.String,
    "jsonPath": 'name',
    "sortName": 'Name'
  },
  {
    "name": 'FRONT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Icon,
    "jsonPath": 'isFront',
  },
  {
    "name": 'BACK',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Icon,
    "jsonPath": 'isBack',
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'manufacturer.value',
    "filterName": 'Manufacturer',
  },
  {
    "name": 'PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.EditablePrice,
    "jsonPath": 'bruttoPrice',
    "sortName": 'BruttoPrice'
  },
  {
    "name": 'SPEED_CODE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'speedCode.value',
    "filterName": 'SpeedCode',
    "sortName": 'SpeedCodeValue'
  },
  {
    "name": 'WEIGHT',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'weightCode.value',
    "filterName": 'WeightCode',
    "sortName": 'WeightCodeValue'
  },
  {
    "name": 'DIAMETER',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'diameter.value',
    "filterName": 'Diameter',
    "sortName": 'DiameterValue'
  },
  {
    "name": 'HEIGHT',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'height.value',
    "filterName": 'Height',
    "sortName": 'HeightValue'
  },
  {
    "name": 'WIDTH',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'width.value',
    "filterName": 'Width',
    "sortName": 'WidthValue'
  },
  {
    "name": 'TIRE_TYPE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'tireType.value',
    "filterName": 'TireType',
    "sortName": 'TireTypeValue'
  },
  {
    "name": 'REMARKS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'remark',
  },
  {
    "name": 'ACTIONS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Action,
    "jsonPath": 'actions',
    actions: [{
      action: 'edit',
      icon: 'border_color'
    }]
  },
]

export const ManageTWSTAbleMeta = [
  {
    "name": 'ACTIVE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.Checkbox,
    "jsonPath": 'active',
    'filterName': 'Active',
    'activeColumn': true
  },
  {
    "name": 'CATEGORY',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'vehicleType.value',
    "filterName": 'VehicleType'
  },
  // {
  //   "name": 'ID',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.String,
  //   "jsonPath": 'id',
  // },
  {
    "name": 'TWS_NAME',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.ClickableString,
    "jsonPath": 'twsName',
    "filterName": 'TWSName'
  },
  {
    "name": 'SUBSCRIPTION',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'subscriptionType.value',
  },
  {
    "name": 'LOCATION',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'address.city.value',
    "filterName": 'City'
  },
  {
    "name": 'ADDRESS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'address.street',
  },
  // {
  //   "name": 'ADDRESS_NUMBER',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.String,
  //   "jsonPath": 'address.buildingNumber',
  // },
  {
    "name": 'OFFICE_PHONE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PhoneWithZero,
    "jsonPath": 'officePhone',
  },
  {
    "name": 'EMAIL',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Email,
    "jsonPath": 'email',
  },
  {
    "name": 'MOBILE_PHONE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PhoneWithZero,
    "jsonPath": 'contactPhone',
  },
  {
    "name": 'LEADS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Quantity,
    "jsonPath": 'numberOfLeads',
    "sliced": false
  },
  {
    "name": 'TOTAL',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Quantity,
    "jsonPath": 'total',
    "sliced": false
  },
  {
    "name": 'Zap XML',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Zap,
    "jsonPath": 'zapTireShopId',
  },
  {
    "name": 'ACTIONS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Action,
    "jsonPath": 'actions',
    actions: [{
      action: 'edit',
      icon: 'border_color'
    }]
  }
]
export const ManageRimsTableMeta: TableMeta[] = [
  {
    "name": 'STATUS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Checkbox,
    "jsonPath": 'status',
  },
  {
    "name": 'IMAGE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Image,
    "jsonPath": 'logoUrl',
  },
  {
    "name": 'ARTICLE_NUMBER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'articleNumber',
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'manufacturer.value',
    "filterName": 'Manufacturer'
  },
  // {
  //   "name": 'Article name',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.String,
  //   "jsonPath": 'articleName',
  // },
  {
    "name": 'NETTO_PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'bruttoPrice',
  },
  {
    "name": 'RIM_DIAMETER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'radiusCharacteristic.value',
  },
  // {
  //   "name": 'Prices',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.String,
  //   "jsonPath": '',
  // },
  {
    "name": 'ACTIONS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Action,
    "jsonPath": 'actions',
    actions: [{
      action: 'edit',
      icon: 'border_color'
    },
      {
        action: 'delete',
        icon: 'delete'
      }]
  }
]

export const ManageLeadsTableMeta: TableMeta[] = [
  {
    "name": 'CATEGORY',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'category',
    "filterName": 'Category'
  },
  {
    "name": 'CODE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'code',
  },
  {
    "name": 'LEAD_TYPE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'leadType',
    "filterName": 'LeadType'
  },
  {
    "name": 'TWS_NAME',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'twsName',
    "filterName": 'TWSName'
  },
  {
    "name": 'BRAND',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'brand',
  },
  {
    "name": 'NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.ClickableString,
    "jsonPath": 'name',
  },
  {
    "name": 'DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'date',
    "calendarHeader": true,
    "filterName": "date",
    "needCheckRow": true,
    "removeMinDate": true
  },
  {
    "name": 'QNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'qnt',
  },
  // {
  //   "name": 'TIME',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.String,
  //   "jsonPath": 'time',
  // },
  {
    "name": 'CONSUMER_LEAD',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'consumerLead',
  },
  {
    "name": 'PHONE_NUMBER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PhoneWithZero,
    "jsonPath": 'phoneNumber',
  },
  {
    "name": 'VIRTUAL_PHONE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PhoneWithZero,
    "jsonPath": 'virtualPhone',
  },
  {
    "name": 'DURATION',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'duration',
  },
  {
    "name": 'CALL_STATUS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Color,
    "jsonPath": 'callStatus',
  },
  {
    "name": 'INSTALL_DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'installationDate',
    "calendarHeader": true,
    "filterName": "installDate"
  },
  {
    "name": 'TWS_STATUS',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.DropDownMenu,
    "jsonPath": 'twsStatus',
    "filterName": 'TwsStatus'
  },
  {
    "name": 'ADMIN_STATUS',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.DropDownMenu,
    "jsonPath": 'adminStatus',
    "filterName": 'AdminStatus'
  },
  // {
  //   "name": 'ACTIONS',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.Action,
  //   "jsonPath": 'actions',
  //   actions: [{
  //     action: 'delete',
  //     icon: 'delete'
  //   }]
  // }
]

export const blogTableMeta: TableMeta[] = [
  {
    "name": 'PUBLISH',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.Checkbox,
    "jsonPath": 'publish',
    "filterName": 'Publish'
  },
  {
    "name": 'IMAGE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Image,
    "jsonPath": 'previewPictureUrl',
  },
  {
    "name": 'TYPE',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'type.value',
    "filterName": 'Type'
  },
  {
    "name": 'TITLE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'title',
  },
  {
    "name": 'AUDITOR',
    "sortable": false,
    "filterable": true,
    "type": CellTypes.String,
    "jsonPath": 'auditor',
    "filterName": 'Auditor'
  },
  {
    "name": 'DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'createdAt',
    "calendarHeader": true
  },
  {
    "name": 'ACTIONS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Action,
    "jsonPath": 'actions',
    "actions": [
      {
        action: 'edit',
        icon: 'border_color'
      },
      {
        action: 'delete',
        icon: 'delete'
      }
    ]
  }
]

export const OrderTableMeta = [
  {
    "name": 'DATE_OF_ORDER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.DayOnly,
    "jsonPath": 'installationDate',
  },
  {
    "name": 'USER_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'userName',
  },
  {
    "name": 'LEAD_AMOUNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'quantity',
  },
  {
    "name": 'TIRE_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'size',
  },
  {
    "name": 'NEAR_PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'amount'
  }
]

export const GoldLeadsTableMeta: TableMeta[] = [
  {
    "name": 'NEAR_PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'amount',
  },
  {
    "name": 'TIRE_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'size',
  },
  {
    "name": 'AMOUNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'quantity',
  },
  {
    "name": 'TWS_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'tireReseller.value',
  },
  {
    "name": 'DATE_OF_ORDER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.DayOnly,
    "jsonPath": 'orderDate',
  },
]

export const GoldLeadsBusinessTableMeta: TableMeta[] = [
  {
    "name": 'TOTAL',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'totalCost',
  },
  {
    "name": 'PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'amount',
  },
  {
    "name": 'TIRE_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'size',
  },
  {
    "name": 'AMOUNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'quantity',
  },
  {
    "name": 'CONSUMER_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'userName',
  },
  {
    "name": 'DATE_OF_ORDER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.DayOnly,
    "jsonPath": 'orderDate',
  },
]

export const paymentsPerMonthTableMeta: TableMeta[] = [
  {
    "name": 'MONTHLY_AMOUNT_VAT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'uniqueMonthlyAmountVat',
  },
  {
    "name": 'MONTHLY_AMOUNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'uniqueMonthlyAmount',
  },
  {
    "name": 'PRICE_PER_LEAD',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.LeadPrice,
    "jsonPath": 'pricePerLead',
    "clickableText": "PRICE_DETAILS"
  },
  {
    "name": 'LEADS_FROM_BEGIN_OF_MONTH',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'uniqueLeadFromBeginMonth',
  },
  {
    "name": 'LEAD_TYPE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'leadType',
  },
  {
    "name": 'A_MONTH',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'month',
  },
  // {
  //   "name": '',
  //   "sortable": false,
  //   "filterable": false,
  //   "type": CellTypes.ClickableString,
  //   "jsonPath": 'actions',
  //   actions: [{
  //     action: 'show',
  //     icon: 'visibility'
  //   }]
  // }
];

export const monthlyReportTableMeta: TableMeta[] = [
  {
    "name": 'A_MONTH',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'month',
  },
  {
    "name": 'LEAD_TYPE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Array,
    "jsonPath": 'leadType',
  },
  {
    "name": 'LEADS_FROM_BEGIN_OF_MONTH',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Array,
    "jsonPath": 'leadFromBeginMonth',
  },
  {
    "name": 'PRICE_PER_LEAD',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.CurrencyArray,
    "jsonPath": 'pricePerLead',
  },
  {
    "name": 'MONTHLY_AMOUNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Array,
    "jsonPath": 'monthlyAmount',
  },
  {
    "name": 'TOTAL_SUM_BEFORE_VAT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'totalSum',
  },
  {
    "name": 'AFTER_VAT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'totalAfterVAT',
  }
];

export const goldLeadsTableMeta: TableMeta[] = [
  {
    "name": 'CODE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'code',
  },
  {
    "name": 'QNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'quantity',
  },
  {
    "name": 'TIRE_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'size',
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'manufacture',
  },
  {
    "name": 'PRICE_PER_CUSTOMER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'pricePerCustomer',
  },
  {
    "name": 'DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'orderDate',
  },
  {
    "name": 'CONSUMER_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'userName',
  },
  {
    "name": 'PHONE_NUMBER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PhoneWithZero,
    "jsonPath": 'phoneNumber',
  },
  {
    "name": 'ASSEMBLY_COORDINATION',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'installationDate',
  },
  {
    "name": 'TIRE_DEALERSHIP_STATUS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.DropDownMenu,
    "jsonPath": 'transitionStatusManagement',
  },
  {
    "name": 'TRANSITION_STATUS_MANAGEMENT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'tireDealershipStatus',
  },
  {
    "name": 'REMARKS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.ShowPopup,
    "jsonPath": 'remark',
  },
];

export const billingOrdersTableMeta: TableMeta[] = [
  {
    "name": 'ORDER_NUMBER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'orderId',
  },
  {
    "name": 'TIRE_QUANTITY_ORDER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'totalQuantity',
    "needDetails": true,
  },
  {
    "name": 'TIRE_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.OrderItemArray,
    "jsonPath": 'tireName',
    "needDetails": false
  },
  {
    "name": 'MANUFACTURER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.OrderItemArray,
    "jsonPath": 'brandName',
    "needDetails": false
  },
  {
    "name": 'ORDER_PRICE_AFTER_VAT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'totalAmount',
  },
  {
    "name": 'ORDER_DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'orderDate',
  },
  {
    "name": 'CONSUMER_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'customerName',
  },
  {
    "name": 'PHONE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.PhoneWithZero,
    "jsonPath": 'customerPhone',
  },
  {
    "name": 'LOCATION',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.OrderItemArray,
    "jsonPath": 'locationName',
    "needDetails": false
  },
  {
    "name": 'TWS_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'tireResellerName',
    "needDetails": false
  },
  {
    "name": 'ORDER_INSTALL_DATE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'installationDate',
  },
  {
    "name": 'BILLING_STATUS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.BillingStatus,
    "jsonPath": 'billingStatus',
  },
  {
    "name": 'OPERATIONAL_STATUS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.OrderStatus,
    "jsonPath": 'orderStatus',
  },
  {
    "name": 'REMARKS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.EditableRemark,
    "jsonPath": 'remark',
  },
  {
    "name": 'MORE_DETAILS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Action,
    "jsonPath": 'actions',
    "actions": [
      {
        action: 'edit',
        icon: 'border_color'
      }
    ]
  }
];

export const ConnectImageTableMeta: TableMeta[] = [
  {
    name: '',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'select',
    checkboxHeader: true
  },
  {
    name: 'IMAGE',
    sortable: false,
    filterable: false,
    type: CellTypes.Image,
    jsonPath: 'pictureUrl',
  },
  {
    name: 'MODEL_NAME',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'name',
  },
  {
    name: 'MANUFACTURER',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'brand.name',
  },
  {
    name: 'DESCRIPTION',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'description',
  },
]

export const BannersTableMeta: TableMeta[] = [
  {
    name: 'PAGE',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'page'
  },
  {
    name: 'LOCATION',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'location',
  },
  {
    name: 'PARAMETER',
    sortable: false,
    filterable: false,
    type: CellTypes.String,
    jsonPath: 'banner.parameter',
  },
  {
    name: 'IMAGE',
    sortable: false,
    filterable: false,
    type: CellTypes.ImagesArray,
    jsonPath: 'banner.images',
  },
  {
    "name": 'ACTIONS',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Action,
    "jsonPath": 'actions',
    "actions": [
      {
        action: 'edit',
        icon: 'border_color'
      }
    ]
  }
]

export const LeadDetailsTableMeta: TableMeta[] = [
  {
    "name": 'DATE_OF_ORDER',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Date,
    "jsonPath": 'orderDate',
  },
  {
    "name": 'USER_NAME',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'userName',
  },
  {
    "name": 'AMOUNT',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'quantity',
  },
  {
    "name": 'SIZE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.String,
    "jsonPath": 'size',
  },
  {
    "name": 'PRICE',
    "sortable": false,
    "filterable": false,
    "type": CellTypes.Currency,
    "jsonPath": 'price',
  },
]
