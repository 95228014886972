import { IParameter } from '../../../modules/state/general-admin/tire-reseller';

export enum ECarTireLabel {
  brand = 'MANUFACTURER',
  name = 'NAME',
  importerPrice = 'IMPORTER_PRICE',
  type = 'TYPE',
  width = 'WIDTH',
  height = 'HEIGHT',
  radius = 'RADIUS',
  speedCode = 'SPEED_CODE',
  weightCode = 'WEIGHT_CODE',
  runFlat = 'RUN_FLAT',
  noiseLevel = 'NOISE_LEVEL',
  roadGrip = 'ROAD_GRIP',
  fuelConsumption = 'FUEL_CONSUMPTION',
  weight = 'WEIGHT',
  ratio = 'RATIO',
  diameter = 'DIAMETER',
  remark = 'REMARK',
  subcategory = 'SUBCATEGORY',
  costPrice = 'COST_PRICE',
  netPrice = 'NET_PRICE',
  tireModel = 'TIRE_MODEL',
  tireType = 'TIRE_TYPE',
  tireNumbers = 'NUMBER_OF_TIRES',
  warehouseLocation = 'WAREHOUSE_LOCATION',
  productionYear = 'PRODUCTION_YEAR'
}

export enum ECarTirePlaceholder {
  brand = 'SELECT_MANUFACTURER',
  name = 'ENTER_NAME',
  importerPrice = 'ENTER_IMPORTER_PRICE',
  type = 'SELECT_TYPE',
  width = 'SELECT_WIDTH',
  height = 'SELECT_HEIGHT',
  radius = 'SELECT_RADIUS',
  speedCode = 'SELECT_SPEED_CODE',
  weightCode = 'SELECT_WEIGHT_CODE',
  runFlat = 'SELECT_RUN_FLAT',
  noiseLevel = 'SELECT_NOISE_LEVEL',
  roadGrip = 'SELECT_ROAD_GRIP',
  fuelConsumption = 'SELECT_FUEL_CONSUMPTION',
  weight = 'SELECT_WEIGHT',
  ratio = 'SELECT_RATIO',
  diameter = 'SELECT_DIAMETER',
  remark = 'ENTER_REMARK',
  subcategory = 'SELECT_SUBCATEGORY',
  costPrice = 'ENTER_COST_PRICE',
  netPrice = 'ENTER_NET_PRICE',
  tireModel = 'ENTER_TIRE_MODEL',
  tireType = 'SELECT_TIRE_TYPE',
  tireNumbers = 'ENTER_NUMBER_OF_TIRES',
  warehouseLocation = 'ENTER_WAREHOUSE_LOCATION',
  productionYear = 'ENTER_PRODUCTION_YEAR'
}

export enum ETireCharacteristic {
  width = 'Width',
  height = 'Height',
  radius = 'Radius',
  speedCode = 'SpeedCode',
  weightCode = 'WeightCode',
  runFlat = 'Runflat',
  noiseLevel = 'NoiseLevel',
  roadGrip = 'RoadGrip',
  fuelConsumption = 'FuelConsumption',
  weight = 'Weight',
  ratio = 'RatioCut',
  diameter = 'Diameter'
}

export enum ETirePricesLabel {
  percent = 'PERCENT',
  manufacturer = 'MANUFACTURER',
  action = 'INCREASE/DECREASE'
}

export enum ETirePricesPlaceholder {
  percent = 'ENTER_PERCENT',
  manufacturer = 'SELECT_MANUFACTURER',
  action = 'SELECT_ACTION'
}

export const pricesAction: IParameter[] = [
  {id: 0, value: 'INCREASE'},
  {id: 1, value: 'DECREASE'}
]

export enum ETireRoute {
  review = 'forReview',
  manage = 'manage'
}
