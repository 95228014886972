import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IResponse } from '../api-data/api.interfaces';
import {
  IBusinessCarTire,
  ICarTireDBData,
  ICarTireExpanded,
  ICarTiresParams
} from "../../modules/state/general-admin/manage-car-tires";
import { ApiService } from "../api.service";
import { ITireBruttoPrice } from '../../modules/state/business-user/article-price';

@Injectable({
  providedIn: "root",
})
export default class ManageCarTiresApiService {
  readonly PATH = `/tire`;

  constructor(private apiService: ApiService) {
  }

  getCarTiresList(url?: string, params?: ICarTiresParams): Observable<IResponse<ICarTireDBData[]>> {
    let path = url ? url : '';
    return this.apiService.get(`${this.PATH}${path}`, {...params});
  }

  addCarTire(element: FormData): Observable<ICarTireExpanded> {
    return this.apiService.post(`${this.PATH}`, element);
  }

  updateCarTire(element: FormData): Observable<ICarTireExpanded> {
    return this.apiService.put(`${this.PATH}`, element);
  }

  exportFile(filters: string, fileName: string): Observable<ICarTireExpanded> {
    return this.apiService.exportFile(`${this.PATH}/export`, {filters}, fileName);
  }

  changeVisibility(id: number): Observable<ICarTireExpanded> {
    return this.apiService.put(`${this.PATH}/${id}/switchstatus`);
  }

  changeTirePrices(prices: FormData): Observable<any> {
    return this.apiService.post(`${this.PATH}/changeprices`, prices);
  }

  addBusinessCarTire(element: FormData): Observable<IBusinessCarTire> {
    return this.apiService.post(`${this.PATH}/business/expectedprofit/newtire`, element);
  }

  changeTireBruttoPrice(id: number, price: FormData): Observable<ITireBruttoPrice> {
    return this.apiService.post(`/articleprice/setarticlebruttoprice/${id}`, price);
  }
}
