import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";
import { BlogDBElement, IBlogParams } from "./blog.model";
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const GET_BLOG_DATA = '[BLOG] Get data';
export const GET_BLOG_SUCCESS = '[BLOG] Get data success';
export const GET_BLOG_FAILURE = '[BLOG] Get data failure';

export const ADD_BLOG_ELEMENT = '[BLOG] Add element';
export const ADD_BLOG_ELEMENT_SUCCESS = '[BLOG] Add element success';
export const ADD_BLOG_ELEMENT_FAILURE = '[BLOG] Add element failure';

export const UPDATE_BLOG_ELEMENT = '[BLOG] Update element';
export const UPDATE_BLOG_ELEMENT_SUCCESS = '[BLOG] Update element success';
export const UPDATE_BLOG_ELEMENT_FAILURE = '[BLOG] Update element failure';

export const DELETE_BLOG_ELEMENT = '[BLOG] Delete element';
export const DELETE_BLOG_ELEMENT_SUCCESS = '[BLOG] Delete element success';
export const DELETE_BLOG_ELEMENT_FAILURE = '[BLOG] Delete element failure';

export const CHANGE_BLOG_ELEMENT_VISIBILITY = '[BLOG] Change element visibility';
export const CHANGE_BLOG_ELEMENT_VISIBILITY_SUCCESS = '[BLOG] Change element visibility success';
export const CHANGE_BLOG_ELEMENT_VISIBILITY_FAILURE = '[BLOG] Change element visibility failure';

export const getBlogData = createAction(
  GET_BLOG_DATA,
  props<{ params: IBlogParams }>()
)

export const getBlogDataSuccess = createAction(
  GET_BLOG_SUCCESS,
  props<{ payload: IResponse<BlogDBElement[]> }>()
)

export const getBlogDataFailure = createAction(
  GET_BLOG_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addBlogElement = createAction(
  ADD_BLOG_ELEMENT,
  props<{ payload: FormData }>()
)

export const addBlogElementSuccess = createAction(
  ADD_BLOG_ELEMENT_SUCCESS,
  props<{ payload: BlogDBElement }>()
)

export const addBlogElementFailure = createAction(
  ADD_BLOG_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateBlogElement = createAction(
  UPDATE_BLOG_ELEMENT,
  props<{ payload: FormData, id: number }>()
)

export const updateBlogElementSuccess = createAction(
  UPDATE_BLOG_ELEMENT_SUCCESS,
  props<{ payload: BlogDBElement }>()
)

export const updateBlogElementFailure = createAction(
  UPDATE_BLOG_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteBlogElement = createAction(
  DELETE_BLOG_ELEMENT,
  props<{ id: number }>()
)

export const deleteBlogElementSuccess = createAction(
  DELETE_BLOG_ELEMENT_SUCCESS
)

export const deleteBlogElementFailure = createAction(
  DELETE_BLOG_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changeElementVisibility = createAction(
  CHANGE_BLOG_ELEMENT_VISIBILITY,
  props<{ id: number }>()
)

export const changeElementVisibilitySuccess = createAction(
  CHANGE_BLOG_ELEMENT_VISIBILITY_SUCCESS
)

export const changeElementVisibilityFailure = createAction(
  CHANGE_BLOG_ELEMENT_VISIBILITY_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

