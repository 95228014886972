export enum EConnectImageLabel {
  type = 'TIRE_TYPE',
  brand = 'MANUFACTURER',
  model = 'MODEL'
}

export enum EConnectImagePlaceholder {
  type = 'SELECT_TIRE_TYPE',
  brand = 'SELECT_MANUFACTURER',
  model = 'SELECT_MODEL'
}
