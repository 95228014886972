import { createFeatureSelector, createSelector } from "@ngrx/store";

import { ICarTireDBData, ManageCarTiresState } from "./manage-car-tires.model";
import { IResponse } from '../../../../api/api-data/api.interfaces';

export const getManageCarTiresState = createFeatureSelector<ManageCarTiresState>('manageCarTires');

export const getManageCarTiresData = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState): IResponse<ICarTireDBData[]> => state.result);

export const getCarTiresSuccessLoader = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState): boolean => state.isLoadingSuccess);

export const getCarTiresLoader = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState): boolean => state.isLoading);

export const getCarTiresError = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState) => state.error);

export const getBusinessCarTireLoader = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState) => state.businessCarTire.isLoader);

export const getBusinessCarTireSuccessLoader = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState) => state.businessCarTire.isLoadSuccess);

export const getBusinessCarTireError = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState) => state.businessCarTire.error);

export const getCarTirePricesLoader = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState) => state.prices.loader);

export const getCarTirePricesSuccessLoader = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState) => state.prices.loaderSuccess);

export const getCarTirePricesError = createSelector(getManageCarTiresState,
  (state: ManageCarTiresState) => state.prices.error);
