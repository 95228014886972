import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { IManufactureList, ManufacturerElement, ManufacturerState } from './manufacturer.model';
import * as manufacturerActions from './manufacturer.actions';
import { EBrandType } from '../../../../shared/interfaces/table.interfaces';

export const initialState: ManufacturerState = {
  currentBrandType: '',
  deleteId: 0,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  content: {} as IManufactureList,
  error: null,
};

const manufacturerReducer = createReducer(
  initialState,
  on(manufacturerActions.getManufacturerData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null,
  })),
  on(manufacturerActions.getManufacturerDataSuccess, (state, {payload}) => (
    {
      ...state,
      content: payload,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    })),
  on(manufacturerActions.getManufacturerDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(manufacturerActions.addManufacturerElement, (state, {brandType}) => ({
    ...state,
    currentBrandType: brandType,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null,
  })),
  on(manufacturerActions.addManufacturerElementSuccess, (state, {payload}) => {
    let newTireContent = [...state.content.Tire];
    let newRimContent = [...state.content.Rim];
    if (state.currentBrandType === EBrandType.tire) {
      newTireContent.push(payload)
    } else {
      newRimContent.push(payload);
    }
    return {
      ...state,
      content: {
        Rim: newRimContent,
        Tire: newTireContent
      },
      current: {} as ManufacturerElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(manufacturerActions.addManufacturerElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(manufacturerActions.updateManufacturerElement, (state, {brandType}) => ({
    ...state,
    currentBrandType: brandType,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null,
  })),
  on(manufacturerActions.updateManufacturerElementSuccess, (state, {payload}) => {
    let newTireContent = [...state.content.Tire];
    let newRimContent = [...state.content.Rim];
    const tireContent = newTireContent.map((mnf: ManufacturerElement) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    const rimContent = newRimContent.map((mnf: ManufacturerElement) => {
      if (mnf.id === payload.id) mnf = payload
      return mnf;
    });
    return {
      ...state,
      content: {
        Rim: rimContent,
        Tire: tireContent
      },
      current: {} as ManufacturerElement,
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(manufacturerActions.updateManufacturerElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(manufacturerActions.deleteManufacturerElement, (state, {payload}) => ({
    ...state,
    deleteId: payload,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null,
  })),
  on(manufacturerActions.deleteManufacturerElementSuccess, (state) => {
    let tireContent = [...state.content.Tire];
    let rimContent = [...state.content.Rim];
    const tireData = tireContent.filter((mnf: ManufacturerElement) => mnf.id !== state.deleteId);
    const rimData = rimContent.filter((mnf: ManufacturerElement) => mnf.id !== state.deleteId);
    return {
      ...state,
      content: {
        Rim: rimData,
        Tire: tireData
      },
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(manufacturerActions.deleteManufacturerElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(manufacturerActions.switchManufacturerStatus, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null,
  })),
  on(manufacturerActions.switchManufacturerStatusSuccess, (state, {id}) => {
    let newTireContent = [...state.content.Tire];
    let newRimContent = [...state.content.Rim];
    const tireContent = newTireContent.map((mnf: ManufacturerElement) => {
      if (mnf.id === id) mnf = {...mnf, isActive: !mnf.isActive};
      return mnf;
    });
    const rimContent = newRimContent.map((mnf: ManufacturerElement) => {
      if (mnf.id === id) mnf = {...mnf, isActive: !mnf.isActive}
      return mnf;
    });
    return {
      ...state,
      content: {
        Rim: rimContent,
        Tire: tireContent
      },
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(manufacturerActions.switchManufacturerStatusFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function ManufacturerReducer(state: ManufacturerState | undefined, action: Action): any {
  return manufacturerReducer(state, action);
}



