import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as OrdersActions from "./orders.actions";
import { OrdersService } from '../../../../api/orders.service';
import { NotificationService } from '../../../../api/notification.service';
import { EModalMessage } from '../../../../t4u-admin/pages/criteria/components/criteria.constants';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class OrdersEffects {
  constructor(private actions$: Actions,
              private alertService: NotificationService,
              private dialog: MatDialog,
              private ordersService: OrdersService) {
  }

  getOrdersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.getOrdersListAction),
      switchMap(action =>
        this.ordersService.getOrdersList(action.params).pipe(
          map(response => OrdersActions.getOrdersListSuccessAction({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(OrdersActions.getOrdersListFailureAction({payload: err}))
          )
        )
      )
    )
  )

  changeOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.changeOrderStatus),
      switchMap(action =>
        this.ordersService.changeOrderStatus(action.id, action.status).pipe(
          map(() => {
            this.alertService.onSuccess(EModalMessage.statusChanged);
            return OrdersActions.changeOrderStatusSuccess()
          }),
          catchError((err: HttpErrorResponse) => {
              this.alertService.onError(err.message);
              return of(OrdersActions.changeOrderStatusFailed({payload: err}))
            }
          )
        )
      )
    )
  )

  changeBillingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.changeBillingStatus),
      switchMap(action =>
        this.ordersService.changeBillingStatus(action.id, action.status).pipe(
          map(() => {
            this.alertService.onSuccess(EModalMessage.statusChanged);
            this.dialog.closeAll();
            return OrdersActions.changeBillingStatusSuccess()
          }),
          catchError((err: HttpErrorResponse) => {
              this.alertService.onError(err.message);
              return of(OrdersActions.changeBillingStatusFailed({payload: err}))
            }
          )
        )
      )
    )
  )

  getPaymentInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.getPaymentOrderInfo),
      switchMap(action =>
        this.ordersService.getGrowPaymentLink(action.orderId).pipe(
          map(payload => OrdersActions.getPaymentOrderInfoSuccess({payload})),
          catchError((err: HttpErrorResponse) =>
            of(OrdersActions.getPaymentOrderInfoFailed({payload: err}))
          )
        )
      )
    )
  )

  sendPaymentLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.sendPaymentLink),
      switchMap(action =>
        this.ordersService.sendPaymentLink(action.orderId).pipe(
          map(payload => {
            this.alertService.onSuccess(EModalMessage.smsWasSend);
            this.dialog.closeAll();
            return OrdersActions.sendPaymentLinkSuccess({payload})
          }),
          catchError((err: HttpErrorResponse) =>
            of(OrdersActions.sendPaymentLinkFailed({payload: err}))
          )
        )
      )
    )
  )

  editOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.editOrder),
      mergeMap(action =>
        this.ordersService.editOrder(action.id, action.payload).pipe(
          mergeMap(() => {
            if (action.sendSmsAfterEdit) {
              return this.ordersService.sendPaymentLink(action.id).pipe(
                map((data) => OrdersActions.sendPaymentLinkSuccess({payload: data})),
                catchError(error => of(OrdersActions.sendPaymentLinkFailed({payload: error})))
              );
            } else {
              return of(OrdersActions.editOrderSuccess());
            }
          }),
          catchError(error => of(OrdersActions.editOrderFailed(error)))
        )
      )
    )
  );

  chargeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.chargeOrder),
      switchMap(action =>
        this.ordersService.chargeOrder(action.payload).pipe(
          map((payload) => OrdersActions.chargeOrderSuccess({payload})),
          catchError((err: HttpErrorResponse) => {
              this.alertService.onError(err.message);
              return of(OrdersActions.chargeOrderFailed({payload: err}))
            }
          )
        )
      )
    )
  )

  getOrderStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.getOrdersStatistic),
      switchMap(action =>
        this.ordersService.getOrderStatistic(action.params).pipe(
          map((payload) => OrdersActions.getOrdersStatisticSuccess({payload})),
          catchError((err: HttpErrorResponse) => {
              this.alertService.onError(err.message);
              return of(OrdersActions.getOrdersStatisticFailed({payload: err}))
            }
          )
        )
      )
    )
  )

  exportOrderStatistic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.exportOrdersStatistic),
      switchMap(action =>
        this.ordersService.exportOrderStatistic(action.params, action.fileName).pipe(
          map(() => OrdersActions.exportOrdersStatisticSuccess()),
          catchError((err: HttpErrorResponse) => {
              this.alertService.onError(err.message);
              return of(OrdersActions.exportOrdersStatisticFailed({payload: err}))
            }
          )
        )
      )
    )
  )
}
