import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from 'rxjs';

import {
  CellTypes,
  IFilterValue,
  TableMeta
} from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { ManageTwsDialogComponent } from "./manage-tws-dialog/manage-tws-dialog.component";
import { ManageTWSTAbleMeta } from '../pages.constants';
import {
  getBusinessUserToken,
  getResellerLoader,
  getTireResellerResponse
} from '../../../modules/state/general-admin/tire-reseller/tire-reseller.selectors';
import {
  changeTWSShowing,
  getResellerToken,
  getTireResellerList,
  TireResellerDBElement,
  TireResellerFull
} from '../../../modules/state/general-admin/tire-reseller';
import { EModalType, ITableCheckData, ITableData } from '../../../modules/shared/interfaces/table.interfaces';
import { IFilters, IResponse } from '../../../api/api-data/api.interfaces';
import { EVehicleType } from './tws.enums';
import { LanguageService } from '../../../api/language.service';
import { getFilterQuery } from '../../../utils/helpers';
import { getLookupParameters } from '../../../modules/state/general-admin/characteristic';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { prodWebSiteUrl, stagingWebSiteUrl } from '../../../api/api-data/api.constants';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-manage-tws',
  templateUrl: './manage-tws.component.html',
  styleUrls: ['./manage-tws.component.scss']
})
export class ManageTwsComponent implements OnInit, OnDestroy {
  public tableMeta: TableMeta[] = ManageTWSTAbleMeta;
  public resellerResponse$: Observable<IResponse<TireResellerFull[]>>;
  public currentPage: number = 1;
  public EVehicleType = EVehicleType;
  public filtersData: IFilters = {};
  public isTableLoad$: Observable<boolean>;
  public filters: string = '';
  public searchControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();
  public searchQuery = '';

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService,
              private auth: AuthService,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.filtersData['Active'] = {value: [1], sign: '=='};
    this.filters = getFilterQuery(this.filtersData);
    this.store$.dispatch(getTireResellerList({payload: {page: 1, filters: this.filters}}));
    this.store$.dispatch(getLookupParameters({payload: 'TireManufacturer,Manufacture,VehicleType,TWSMoreOptions,Subscription,TireType,TWSTireType,Location'}));
    this.resellerResponse$ = this.store$.select(getTireResellerResponse);
    this.isTableLoad$ = this.store$.select(getResellerLoader);
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value.length < 3) return;
        this.currentPage = 1;
        this.searchQuery = value;
        this.store$.dispatch(getTireResellerList({payload: {page: 1, search: this.searchQuery}}));
      })
  }

  openAddDialog(id: number, value: string): void {
    this.dialog.open(ManageTwsDialogComponent, {
      panelClass: 'admin-manufacturer-add-dialog',
      data: {
        modalType: EModalType.add,
        vehicleType: {id, value},
        filters: this.filters,
        page: this.currentPage,
        search: this.searchQuery
      },
      width: '950px',
    })
  }

  dispatchEvent(table: ITableData<TireResellerDBElement>): void {
    if (table.source === CellTypes.ClickableString && !table.data.active) return;
    if (table.source === CellTypes.Zap) {
      const prod = environment.production;
      const link = prod ? prodWebSiteUrl : stagingWebSiteUrl;
      window.open(`${link}/zapmirror?tireShopID=${table.data.zapTireShopId}`, '_blank');
      return;
    }
    if (table.source === CellTypes.ClickableString && table.data.active) {
      this.store$.dispatch(getResellerToken({id: table.data.id}));
      this.loginAsReseller();
      return;
    }
    this.dialog.open(ManageTwsDialogComponent, {
      panelClass: 'admin-manufacturer-add-dialog',
      data: {
        ...table.data,
        modalType: EModalType.edit,
        filters: this.filters,
        page: this.currentPage,
        search: this.searchQuery
      },
      width: '950px',
    })
  }

  loginAsReseller(): void {
    this.store$.select(getBusinessUserToken).pipe(take(2)).subscribe({
      next: (user) => {
        if (!user) return;
        this.auth.setUser(user, false);
        window.open(`${this.document.location.origin}/business/dashboard/${user.tireResellerId}`, '_blank');
      }
    })
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.filters = getFilterQuery(this.filtersData);
    this.store$.dispatch(getTireResellerList({payload: {page: this.currentPage, filters: this.filters}}));
  }

  getFilters(event: IFilterValue): void {
    this.filtersData[event.filterKey] = {value: event.checkedIds, sign: '=='};
    if (!event.checkedIds.length) delete this.filtersData[event.filterKey];
    this.filters = getFilterQuery(this.filtersData);
    this.store$.dispatch(getTireResellerList({payload: {page: this.currentPage, filters: this.filters}}));
  }

  changeTWSStatus(table: ITableCheckData<TireResellerFull>): void {
    this.store$.dispatch(changeTWSShowing({id: table.target.id, active: !table.target.active}));
  }
}
