import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as heightActions from "./height.actions";
import { CriteriaService } from '../../../../../api/general-admin-services/criteria.service';
import { heightPath } from '../../../../../api/api-data/api.constants';

@Injectable()
export class HeightEffects {
  constructor(private actions$: Actions,
              private criteriaService: CriteriaService) {
  }

  getHeightData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(heightActions.getHeightData),
      switchMap(() =>
        this.criteriaService.getExpandedCriteriaData(heightPath).pipe(
          map(response => heightActions.getHeightDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(heightActions.getHeightDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createHeightElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(heightActions.addHeightElement),
      switchMap(action =>
        this.criteriaService.addExpandedCriteriaElement(heightPath, action.payload).pipe(
          map(response => heightActions.addHeightElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(heightActions.addHeightElementFailure({payload: err})))
        )
      )
    )
  )

  updateHeightElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(heightActions.updateHeightElement),
      switchMap(action =>
        this.criteriaService.updateExpandedCriteriaElement(action.payload, action.id).pipe(
          map(response => heightActions.updateHeightElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(heightActions.updateHeightElementFailure({payload: err})))
        )
      )
    )
  )

  deleteHeightElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(heightActions.deleteHeightElement),
      switchMap(action =>
        this.criteriaService.deleteCriteria(action.payload).pipe(
          map(() => heightActions.deleteHeightElementSuccess()),
          catchError((err: HttpErrorResponse) => of(heightActions.deleteHeightElementFailure({payload: err})))
        )
      )
    )
  )
}
