import { ICharacteristicObject, IOptions } from '../modules/state/general-admin/characteristic';
import {
  ECarTireLabel,
  ECarTirePlaceholder,
  ETireCharacteristic
} from '../t4u-admin/pages/manage-car-tires/manage-car.enums';
import { ILeadInfo, ILeadsCount, } from '../modules/state/general-admin/leads/leads.model';
import { EStatisticTitle } from '../modules/statistics/components/statistics/statistics.enums';
import { ELeadsTitle } from '../t4u-admin/pages/manage-leads/leads.enum';
import {
  IGolLead,
  IMonthlyLeadsList,
  IMonthlyLeadsListExtended,
  IPaymentsLeadsCount,
  IReportPayments,
  ITotalMonthlyReport
} from '../modules/state/general-admin/payments/payments.model';

export function refactorCharacteristicList(list: IOptions): ICharacteristicObject {
  const characteristicList: ICharacteristicObject = {};
  for (const key in list) {
    switch (+key) {
      case(1):
        characteristicList.width = {
          options: list[key],
          name: ETireCharacteristic.width,
          label: ECarTireLabel.width,
          placeholder: ECarTirePlaceholder.width
        };
        break;
      case(2):
        characteristicList.height = {
          options: list[key],
          name: ETireCharacteristic.height,
          label: ECarTireLabel.height,
          placeholder: ECarTirePlaceholder.height
        };
        break;
      case(3):
        characteristicList.radius = {
          options: list[key],
          name: ETireCharacteristic.radius,
          label: ECarTireLabel.radius,
          placeholder: ECarTirePlaceholder.radius
        };
        break;
      case(4):
        characteristicList.speedCode = {
          options: list[key],
          name: ETireCharacteristic.speedCode,
          label: ECarTireLabel.speedCode,
          placeholder: ECarTirePlaceholder.speedCode
        };
        break;
      case(5):
        characteristicList.weightCode = {
          options: list[key],
          name: ETireCharacteristic.weightCode,
          label: ECarTireLabel.weightCode,
          placeholder: ECarTirePlaceholder.weightCode
        };
        break;
      case(6):
        characteristicList.runflat = {
          options: list[key],
          name: ETireCharacteristic.runFlat,
          label: ECarTireLabel.runFlat,
          placeholder: ECarTirePlaceholder.runFlat
        };
        break;
      case(7):
        characteristicList.noiseLevel = {
          options: list[key],
          name: ETireCharacteristic.noiseLevel,
          label: ECarTireLabel.noiseLevel,
          placeholder: ECarTirePlaceholder.noiseLevel
        };
        break;
      case(8):
        characteristicList.roadGrip = {
          options: list[key],
          name: ETireCharacteristic.roadGrip,
          label: ECarTireLabel.roadGrip,
          placeholder: ECarTirePlaceholder.roadGrip
        };
        break;
      case(9):
        characteristicList.fuelConsumption = {
          options: list[key],
          name: ETireCharacteristic.fuelConsumption,
          label: ECarTireLabel.fuelConsumption,
          placeholder: ECarTirePlaceholder.fuelConsumption
        };
        break;
      case(10):
        characteristicList.weight = {
          options: list[key],
          name: ETireCharacteristic.weight,
          label: ECarTireLabel.weight,
          placeholder: ECarTirePlaceholder.weight
        };
        break;
      case(11):
        characteristicList.ratio = {
          options: list[key],
          name: ETireCharacteristic.ratio,
          label: ECarTireLabel.ratio,
          placeholder: ECarTirePlaceholder.ratio
        };
        break;
      case(12):
        characteristicList.diameter = {
          options: list[key],
          name: ETireCharacteristic.diameter,
          label: ECarTireLabel.diameter,
          placeholder: ECarTirePlaceholder.diameter
        };
        break;
    }
  }
  return characteristicList;
}

export function convertLeadsCount(leads: ILeadsCount, generalAdmin: boolean): ILeadInfo[] {
  const total = {
    leads: leads.totalBalance,
    amount: leads.totalAmount,
    title: EStatisticTitle.totalBalance,
    bgSecondary: true
  };
  const leadInfo = [
    {leads: leads.phoneLeads, amount: leads.phoneLeadsAmount, title: EStatisticTitle.phone},
    {leads: leads.goldLeads, amount: leads.goldLeadsAmount, title: EStatisticTitle.gold, link: true},
    {leads: leads.converseLeads, amount: leads.converseLeadsAmount, title: EStatisticTitle.converse},
    {leads: leads.indexLeads, amount: leads.indexLeadsAmount, title: EStatisticTitle.index},
    {leads: leads.navigationsLeads, amount: leads.navigationsLeadsAmount, title: EStatisticTitle.nav},
    {leads: leads.zapLeads, amount: leads.zapLeadsAmount, title: EStatisticTitle.zap},
    {leads: leads.rimsLeads, amount: leads.rimsLeadsAmount, title: EStatisticTitle.rims},
  ];
  if (generalAdmin) {
    leadInfo.push(total);
  } else {
    leadInfo.push({...total, title: EStatisticTitle.businessUserTotal});
  }
  return leadInfo;
}

export function convertPaymentsLeadsCount(leads: IPaymentsLeadsCount): { leadsList: ILeadInfo[], goldLeads: IGolLead[] } {
  return {
    leadsList: [
      {amount: leads.phoneLeadsCost, leads: leads.phoneLeads, title: EStatisticTitle.phone},
      {amount: leads.goldLeads, leads: leads.goldLeads, title: EStatisticTitle.gold, link: true},
      {amount: leads.converseLeadsCost, leads: leads.converseLeads, title: EStatisticTitle.converse},
      {amount: leads.indexLeadsCost, leads: leads.indexLeads, title: EStatisticTitle.index},
      {amount: leads.navigationsLeadsCost, leads: leads.navigationsLeads, title: EStatisticTitle.nav},
      {amount: leads.zapLeadsCost, leads: leads.zapLeads, title: EStatisticTitle.zap},
      {amount: leads.rimsLeadsCost, leads: leads.rimsLeads, title: EStatisticTitle.rims},
      {amount: leads.totalCost, leads: leads.totalCost, title: EStatisticTitle.totalPaidBalance, bgSecondary: true}
    ],
    goldLeads: leads.goldLeadCosts
  }
}

export function convertLeadsTable(leads: ILeadsCount): ILeadInfo[] {
  return [
    {leads: leads.phoneLeads, amount: leads.phoneLeadsAmount, title: ELeadsTitle.phone},
    {leads: leads.goldLeads, amount: leads.goldLeadsAmount, title: ELeadsTitle.gold},
    {leads: leads.converseLeads, amount: leads.converseLeadsAmount, title: ELeadsTitle.converse},
    {leads: leads.indexLeads, amount: leads.indexLeadsAmount, title: ELeadsTitle.index},
    {leads: leads.navigationsLeads, amount: leads.navigationsLeadsAmount, title: ELeadsTitle.navigation},
    {leads: leads.zapLeads, amount: leads.zapLeadsAmount, title: ELeadsTitle.zap},
    {leads: leads.rimsLeads, amount: leads.rimsLeadsAmount, title: ELeadsTitle.rims},
  ]
}

export function convertMonthlyReport(data: IMonthlyLeadsListExtended): ITotalMonthlyReport[] {
  if(!data) return [];
  const monthObject = data.result.reduce((acc, item) => {
    const transformDate = item.month.split('-').reverse().join('/');
    acc[transformDate] = acc[transformDate] ? [...acc[transformDate], item] : [item];
    return acc;
  }, {} as {[key: string]: IMonthlyLeadsList[]});
  const monthlyReport = Object.keys(monthObject).reduce((acc, key) => {
    acc[key] = {
      month: key,
      monthNumber: +key.split('/')[0],
      year: +key.split('/')[1],
      leadType: monthObject[key].map(item => item.leadType),
      leadFromBeginMonth: monthObject[key].map(item => item.uniqueLeadFromBeginMonth),
      monthlyAmount: monthObject[key].map(item => item.uniqueMonthlyAmount),
      monthlyAmountVAT: monthObject[key].map(item => item.uniqueMonthlyAmountVat),
      pricePerLead: monthObject[key].map(item => item.pricePerLead),
    };
    return acc;
  }, {} as { [key: string]: IReportPayments });
  const sortedReport = Object.values(monthlyReport).sort((a, b) => a.monthNumber - b.monthNumber).sort((a, b) => a.year - b.year);
  const final = sortedReport.splice(-3).sort((a, b) => b.monthNumber - a.monthNumber);
  return addTotalBalance(Object.values(final));
}

function addTotalBalance(report: IReportPayments[]): ITotalMonthlyReport[] {
  if (!report) return [];
  let list: ITotalMonthlyReport[] = [...report] as ITotalMonthlyReport[];
  // list.map(item => {
  //   const index = item.leadType?.findIndex((value) => value === 'Gold');
  //   if (index !== -1) {
  //     const monthly = item.monthlyAmount[index];
  //     const monthlyVAT = item.monthlyAmountVAT[index];
  //     // return {
  //     //   ...item,
  //     //   monthlyAmount: item.monthlyAmount,
  //     //   monthlyAmountVAT: item.monthlyAmountVAT
  //     // }
  //     list = list.map(month => {
  //       let totalSum = month.monthlyAmount.reduce((sum, arrayItem) => {
  //         return sum + arrayItem
  //       }, 0);
  //       totalSum = totalSum - monthly;
  //       let totalAfterVAT = month.monthlyAmountVAT.reduce((sum, arrayItem) => {
  //         return sum + arrayItem
  //       }, 0);
  //       totalAfterVAT = totalAfterVAT - monthlyVAT;
  //       return {...month, totalSum, totalAfterVAT};
  //     });
  //   } else {
  //     list = list.map(month => {
  //       let totalSum = month.monthlyAmount.reduce((sum, arrayItem) => {
  //         return sum + arrayItem
  //       }, 0);
  //       let totalAfterVAT = month.monthlyAmountVAT.reduce((sum, arrayItem) => {
  //         return sum + arrayItem
  //       }, 0);
  //       return {...month, totalSum, totalAfterVAT};
  //     });
  //   }
  //   return {...item};
  // });
  list = list.map(month => {
    let totalSum = month.monthlyAmount.reduce((sum, arrayItem) => {
      return sum + arrayItem
    }, 0);
    let totalAfterVAT = Math.round(totalSum * 1.17);
    // let totalAfterVAT = month.monthlyAmountVAT.reduce((sum, arrayItem) => {
    //   return sum + arrayItem
    // }, 0);
    return {...month, totalSum, totalAfterVAT};
  });
  return list;
}
