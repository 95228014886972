import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  IEditablePrice,
  IFilterValue,
  TableMeta
} from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../../api/language.service';
import { IFilters, IResponse } from '../../../api/api-data/api.interfaces';
import {
  EModalType,
  EVehicleType,
  ITableCheckData,
  ITableData
} from '../../../modules/shared/interfaces/table.interfaces';
import { ManageMotoTireTableMeta } from '../pages.constants';
import {
  IMotoTireDBData,
  IMotoTiresParams
} from '../../../modules/state/general-admin/manage-moto-tires/manage-moto-tires.model';
import {
  changeMotoTireBruttoPrice,
  changeMotoTireVisibility,
  exportMotoTireFile,
  getMotoTiresData,
  getMotoTiresError,
  getMotoTiresList,
  getMotoTiresLoader,
  getMotoTiresSuccessLoader
} from '../../../modules/state/general-admin/manage-moto-tires';
import { ManageMotoTiresDialogComponent } from './manage-moto-tires-dialog/manage-moto-tires-dialog.component';
import { getCharacteristicOptionsList, getLookupParameters } from '../../../modules/state/general-admin/characteristic';
import { getFilterQuery, isEmptyObj } from '../../../utils/helpers';
import { NotificationService } from '../../../api/notification.service';
import { EModalMessage } from '../criteria/components/criteria.constants';

@Component({
  selector: 'app-manage-motorcycle-tires',
  templateUrl: './manage-motorcycle-tires.component.html',
  styleUrls: ['./manage-motorcycle-tires.component.scss']
})
export class ManageMotorcycleTiresComponent implements OnInit, OnDestroy {

  public tableMeta: TableMeta[] = ManageMotoTireTableMeta;
  public motoTiresList$: Observable<IResponse<IMotoTireDBData[]>>;
  public currentPage: number = 1;
  public filtersData: IFilters = {};
  public filters: string = '';
  public searchControl: FormControl = new FormControl('');
  public searchQuery: string = '';
  public destroy$: Subject<void> = new Subject<void>();
  public isTableLoad$: Observable<boolean>;
  public sortingData: string[] = [];
  public sorts: string = '';

  constructor(private dialog: MatDialog,
              private store$: Store,
              private alertService: NotificationService,
              public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.store$.dispatch(getMotoTiresList({}));
    this.store$.dispatch(getCharacteristicOptionsList({id: '1,2,4,5,12'}));
    this.store$.dispatch(getLookupParameters({payload: 'TireManufacturer,TireType'}));
    this.motoTiresList$ = this.store$.select(getMotoTiresData);
    this.isTableLoad$ = this.store$.select(getMotoTiresLoader);
  }

  openAddDialog(): void {
    this.dialog.open(ManageMotoTiresDialogComponent, {
      panelClass: 'dialog-manage-car-tires',
      width: '926px',
      data: {modalType: EModalType.add}
    })
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.getMotoTireList();
  }

  dispatchEvent(table: ITableData<IMotoTireDBData>): void {
    this.dialog.open(ManageMotoTiresDialogComponent, {
      panelClass: 'dialog-manage-car-tires',
      width: '926px',
      data: {...table.data, modalType: EModalType.edit}
    })
  }

  getFilters(filtersValue: IFilterValue): void {
    this.filtersData[filtersValue.filterKey] = {value: filtersValue.checkedIds, sign: '=='};
    if (!filtersValue.checkedIds.length) delete this.filtersData[filtersValue.filterKey];
    this.filters = getFilterQuery(this.filtersData);
    this.getMotoTireList()
  }

  export(): void {
    this.store$.dispatch(exportMotoTireFile({filters: this.filters, fileName: 'Moto_Tire'}));
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$),
      switchMap((search) => {
        this.searchQuery = search;
        this.currentPage = 1;
        this.store$.dispatch(getMotoTiresList({
          params: {
            page: 1, search,
            filters: this.filters,
            sorts: this.sorts
          }
        }));
        return this.store$.select(getMotoTiresData);
      })).pipe(takeUntil(this.destroy$)).subscribe();
  }

  changeVisibility(event: ITableCheckData<IMotoTireDBData>): void {
    this.store$.dispatch(changeMotoTireVisibility({id: event.target.id}));
    this.checkRequest(EModalMessage.statusChanged);
  }

  changePrices(): void {
    const params = {
      page: this.currentPage,
      search: this.searchQuery,
      filters: this.filters,
    }
    this.dialog.open(ManageMotoTiresDialogComponent, {
      panelClass: 'dialog-manage-car-tires',
      width: '580px',
      data: {modalType: EModalType.prices, params}
    })
  }

  sortData(sortValue: string): void {
    if (sortValue.includes('-')) {
      this.sortingData = this.sortingData.filter(item => item !== sortValue.slice(1));
    } else {
      this.sortingData = this.sortingData.filter(item => item !== `-${sortValue}`);
    }
    this.sortingData.push(sortValue);
    this.sorts = this.sortingData.join();
    this.getMotoTireList();
  }

  getMotoTireList(): void {
    const params: IMotoTiresParams = {
      page: this.currentPage,
      filters: getFilterQuery(this.filtersData),
      search: this.searchQuery,
      sorts: this.sorts
    };
    this.store$.dispatch(getMotoTiresList({params}));
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getMotoTiresError),
      this.store$.select(getMotoTiresSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.alertService.onSuccess(message);
          }
          if (!loader && error && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }

  getNewBruttoPrice(event: IEditablePrice, list: IMotoTireDBData[]): void {
    const content = [...list];
    const item = content.find(tire => tire.id === event.id);
    let same: boolean = item?.id === event.id && item?.bruttoPrice === event.price;
    if (same) return;
    const value: any = {
      ArticleId: event.id.toString(),
      BruttoPrice: event.price.toString(),
      VehicleType: EVehicleType.moto
    };
    const payload = new FormData();
    for (const key in value) payload.append(key, value[key]);
    this.store$.dispatch(changeMotoTireBruttoPrice({id: event.id, payload}));
    this.checkRequest(EModalMessage.pricesChanged);
  }
}

