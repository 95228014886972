import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true
  },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    }
  ]
})
export class InputComponent implements ControlValueAccessor, Validator {

  @Input() placeholder: string;
  @Input() label: string;
  @Input() isTextArea: boolean = false;
  @Input() isNumber: boolean = false;
  @Input() isPhone: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() subLabel: string;
  @Input() onlyInteger: boolean = false;
  @Input() includePhoneCode: boolean = false;
  @Input() removePhoneCode: boolean = false;
  public value: string;
  public control: AbstractControl | null = null;
  public onChange: (p: any) => void = () => {};
  public onTouch: () => void = () => {};

  valueChange(event: any): void {
    this.value = event.target.value;
    this.onTouch();
    this.onChange(event.target.value);
  }

  phoneValueChange(event: any): void {
    this.value = event.target.value;
    if (this.value === '' || this.includePhoneCode) {
      this.onTouch();
      this.onChange(this.value);
      return;
    }
    this.onTouch();
    this.onChange(`+972${this.value}`);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    if (this.removePhoneCode && value.includes('+972')) {
      this.value = value.replace('+972', '');
      return;
    }
    this.value = value;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control) return null;
    this.control = control;
    return control.value ? null : {...control.errors};
  }

  checkInteger(event: any): void {
    const value = event.target.value;
    if (!this.onlyInteger) return;
    if (event.key === '.' || event.key === ',') {
      event.preventDefault();
      return;
    }
    this.value = Number.parseInt(value.replace(/^0+/, '')).toString();
  }

  checkPasteHandler(event: ClipboardEvent): void {
    if (!this.onlyInteger) return;
    const pasteData = event.clipboardData?.getData('text');
    if (!pasteData) return;
    event.preventDefault();
    this.value = Number.parseInt(pasteData).toString();
  }
}
