import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as RimsActions from "./rims.actions";
import { RimsService } from '../../../../api/general-admin-services/rims.service';

@Injectable()
export class RimsEffects {
  constructor(private actions$: Actions,
              private rimsService: RimsService) {
  }

  getRimsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RimsActions.getRimsList),
      switchMap(action =>
        this.rimsService.getRimsList(action.params).pipe(
          map(response => RimsActions.getRimsListSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(RimsActions.getRimsListFailure({payload: err}))
          )
        )
      )
    )
  )

  addRim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RimsActions.addRim),
      switchMap(action =>
        this.rimsService.addRim(action.payload).pipe(
          map(rim => RimsActions.addRimSuccess({payload: rim})),
          catchError((err: HttpErrorResponse) =>
            of(RimsActions.addRimFailure({payload: err}))
          )
        )
      )
    )
  )

  updateRim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RimsActions.updateRim),
      switchMap(action =>
        this.rimsService.updateRim(action.payload).pipe(
          map(response => RimsActions.updateRimSuccess({payload: response.entity})),
          catchError((err: HttpErrorResponse) =>
            of(RimsActions.updateRimFailure({payload: err}))
          )
        )
      )
    )
  )

  changeRimVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RimsActions.changRimVisibility),
      switchMap(action =>
        this.rimsService.changeRimVisibility(action.id).pipe(
          map(() => RimsActions.changRimVisibilitySuccess()),
          catchError((err: HttpErrorResponse) =>
            of(RimsActions.changRimVisibilityFailure({payload: err}))
          )
        )
      )
    )
  )

  deleteRim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RimsActions.deleteRim),
      switchMap(action =>
        this.rimsService.deleteRim(action.id).pipe(
          map(() => RimsActions.deleteRimSuccess({id: action.id})),
          catchError((err: HttpErrorResponse) =>
            of(RimsActions.deleteRimFailure({payload: err}))
          )
        )
      )
    )
  )
}
