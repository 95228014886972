import { BlogDBElement, BlogState } from './blog.model';
import * as BlogActions from './blog.actions';
import { Action, createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

export const initialState: BlogState = {
  deleteId: null,
  result: {
    rowCount: 0,
    pageSize: 0,
    currentPage: 0,
    pageCount: 0,
    content: [],
  },
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: {} as HttpErrorResponse,
};

const blogReducer = createReducer(
  initialState,
  on(BlogActions.getBlogData, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BlogActions.getBlogDataSuccess, (state, {payload}) => ({
    ...state,
    result: payload,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(BlogActions.getBlogDataFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true
  })),
  on(BlogActions.addBlogElement, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BlogActions.addBlogElementSuccess, (state, {payload}) => ({
    ...state,
    result: {...state.result, content: [...state.result.content, payload]},
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse,
  })),
  on(BlogActions.addBlogElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(BlogActions.updateBlogElement, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BlogActions.updateBlogElementSuccess, (state, {payload}) => {
    const BlogData = [...state.result.content];
    const newData = BlogData.map((mnf: BlogDBElement) => {
      if (mnf.id === payload.id) mnf = payload;
      return mnf;
    });
    return {
      ...state,
      result: {...state.result, content: newData},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(BlogActions.updateBlogElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(BlogActions.deleteBlogElement, (state, {id}) => ({
    ...state,
    deleteId: id,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BlogActions.deleteBlogElementSuccess, (state) => {
    const blogData = [...state.result.content];
    const filtered = blogData.filter((mnf: BlogDBElement) => mnf.id !== state.deleteId);
    return {
      ...state,
      result: {...state.result, content: filtered},
      isLoading: false,
      isLoadingSuccess: true,
      error: {} as HttpErrorResponse,
    }
  }),
  on(BlogActions.deleteBlogElementFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
  on(BlogActions.changeElementVisibility, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: {} as HttpErrorResponse,
  })),
  on(BlogActions.changeElementVisibilitySuccess, (state) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    error: {} as HttpErrorResponse
  })),
  on(BlogActions.changeElementVisibilityFailure, (state, {payload}) => ({
    ...state,
    error: payload,
    isLoading: false,
    isLoadingFailure: true,
  })),
);

export function BlogReducer(state: BlogState | undefined, action: Action): any {
  return blogReducer(state, action);
}
