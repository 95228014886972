import { Component } from '@angular/core';
import { Routes } from '@angular/router';
import { carTiresRoutes } from '../../manage-car-tires-routing.module';

@Component({
  selector: 'app-manage-car-tires-main',
  templateUrl: './car-tires.html',
  styleUrls: ['./car-tires.component.scss']
})
export class CarTiresComponent {
  public routesList: Routes = carTiresRoutes;
}
