<div class="table-loader" *ngIf="(isLoading$ | async)">
  <mat-spinner></mat-spinner>
</div>
<div class="content statistics" dir="rtl">
  <div class="content-head">
    <div class="head-title">{{resellerShopName}}</div>
    <div class="btn-block">
      <button class="btn-admin" (click)="openDetails()">
        <mat-icon class="icon-edit">border_color</mat-icon>
        {{'EDIT' | translate}}
      </button>
    </div>
  </div>
  <div class="content-body statistic-count">
    <div class="content-title-wrap" [formGroup]="dateForm">
      <div *ngIf="!leadsFoAllTime" class="content-body-title">{{'LEAD_OF_THE_MONTH' | translate}}  ({{month | translate}})</div>
      <div *ngIf="leadsFoAllTime" class="content-body-title">{{'LEADS_OF' | translate}} {{today.getFullYear()}} {{'YEAR' | translate}}</div>
      <div class="select">
        <div>{{today.getFullYear()}} {{'YEAR' | translate}}</div>
        <app-select [isSorted]="false" formControlName="month" [placeholder]="'SELECT_MONTH'"
                    [options]="monthes.reverse()"></app-select>
      </div>
    </div>
    <div class="info-cards" *ngIf="(statistic$ | async) as list">
      <div class="card" *ngFor="let item of checkLeadsBySubscription(list.leads)" [ngClass]="{'item-virtual': item.virtualNumber}">
        <div class="name">{{item.leadsTitle | translate}}</div>
        <div class="card-info">
          <div class="count">
            {{item.leadsNumber | number}}
<!--            <span *ngIf="!!item.leadsNumber" class="count-number">({{item.leadsNumber | number}})</span>-->
<!--            <mat-icon *ngIf="!!item.leadsNumber" matTooltip="{{'DUPLICATED_LEAD_NOTIFICATION' | translate}}"-->
<!--                      matTooltipPosition="above"-->
<!--                      class="info">info-->
<!--            </mat-icon>-->
          </div>
        </div>
        <div class="virtual-phone" *ngIf="item.virtualNumber">{{changeVirtualNumber(item.virtualNumber)}}</div>
      </div>
      <div class="card total">
        <div class="name">{{EStatisticTitle.totalBalance | translate}}</div>
        <div class="card-info">
          <div class="count">
            {{list.total.leadsNumber | number}}
            <span class="count-number">({{list.total.uniqueLeadsNumber | number}})</span>
            <mat-icon *ngIf="!!list.total.uniqueLeadsNumber" matTooltip="{{'DUPLICATED_LEAD_NOTIFICATION' | translate}}"
                      matTooltipPosition="above"
                      class="info">info
            </mat-icon>
          </div>
          <span class="alone"></span>
          <div class="count">{{list.total.uniqueLeadBalanceBeforeVat.toFixed(0) | currency : 'ILS' : 'symbol': '1.0-1'}}</div>
        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="(ordersTableContent$ | async) as content">
    <div class="content-table" *ngIf="checkOrdersTableShowing()">
      <div class="content-table-title">{{'ORDERS_OF_TODAY' | translate}}</div>
      <starter-table
        [tableMeta]="orderTableMeta"
        [tableData]="content.result"
        [language]="languageService.mainLanguage.value"
      >
      </starter-table>
      <div *ngIf="content && !content.result.length" class="no-result-table">
        {{'THERE_WERE_NO_ORDERS_TODAY' | translate}}
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="(notificationList$ | async) as notifications">
  <ng-container *ngIf="notifications?.length">
    <div class="content-body-title">{{'NOTIFICATIONS' | translate}}</div>
    <div class="notifications-wrap">
      <div class="notification" *ngFor="let notification of sortNotification(notifications)">
        <div>
          <div class="notification-title">{{notification.topic}}</div>
          <div class="notification-text">{{notification.message}}</div>
        </div>
        <div class="notification-date">{{notification.createdAt | date:'dd/MM/yyyy'}}</div>
      </div>
    </div>
  </ng-container>
</div>

<div class="wrap-content">
  <div class="content-block">
    <div class="icon-block">
      <div>{{convertPhone(reseller.contactPhone)}}</div>
      <mat-icon svgIcon="phone"></mat-icon>
    </div>
  </div>
  <div class="content-block">
    <div class="icon-block">
      <div>{{reseller.email}}</div>
      <mat-icon svgIcon="mail"></mat-icon>
    </div>
  </div>
</div>
