import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";

import { AuthService } from "../../modules/auth/services/auth.service";
import { selectUser } from "../../modules/auth/reducers/user.selectors";
import { UserRoles } from "../../modules/auth/interfaces/auth.interface";

@Injectable({
  providedIn: 'root'
})
export class BusinessGuard implements CanActivate {
  constructor(private store$: Store,
              private authService: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return combineLatest([
      this.store$.pipe(select(selectUser)),
      this.authService.getBusinessUser()]
    ).pipe(
      map(([stateUser, storageUser]) => {
        if (stateUser?.role === UserRoles.BusinessUser || storageUser?.role === UserRoles.BusinessUser) {
          return true
        } else {
          this.router.navigate(['/auth', 'login']);
          return false
        }
      })
    )
  }
}
