<div class="table-loader" *ngIf="(isLoading$ | async)">
  <mat-spinner></mat-spinner>
</div>
<div class="content statistics" dir="rtl" [formGroup]="filterForm">
  <div class="content-head">
    <div *ngIf="!isBusiness" class="head-title">{{'PAYMENTS' | translate}}</div>
    <div *ngIf="isBusiness" class="head-title">{{'PAYMENTS/REPORTS' | translate}}</div>
    <div class="btn-block">
      <button *ngIf="!isBusiness" (click)="export()" class="btn-admin btn-white export-btn">
        <mat-icon class="icon-img" svgIcon="upload"></mat-icon>
        {{'EXPORT' | translate}}
      </button>
      <div class="select" *ngIf="isBusiness">
        <div class="input">
          <mat-date-range-input [rangePicker]="picker" [max]="today" [min]="minDate">
            <input matStartDate formControlName="dateFrom" placeholder="{{'START_DATE' | translate}}">
            <input matEndDate formControlName="dateTo" placeholder="{{'END_DATE' | translate}}">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker [disabled]="false" #picker></mat-date-range-picker>
        </div>
      </div>
    </div>
  </div>
  <div class="content-body" [ngClass]="isBusiness ? 'payment-business' : 'statistic-count'">
    <div class="title-block" *ngIf="!isBusiness">
      <div class="title">{{mainTitle | translate}}</div>
      <div class="btn-block" *ngIf="(params$ | async) as params">
        <div class="select">
          <div>{{'TWS' | translate}}</div>
          <div class="input">
            <mat-select formControlName="tireResellerId" placeholder="{{'SELECT_TWS' | translate}}">
              <mat-option [value]="allTWS">{{allTWS.value | translate}}</mat-option>
              <mat-option *ngFor="let reseller of sortContent(params.TWSName)" [value]="reseller">{{ reseller.value
                }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="select">
          <div>{{'PERIOD' | translate}}</div>
          <div class="input">
            <mat-date-range-input [rangePicker]="picker" [max]="today">
              <input matStartDate formControlName="dateFrom" placeholder="{{'START_DATE' | translate}}">
              <input matEndDate formControlName="dateTo" placeholder="{{'END_DATE' | translate}}">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker [disabled]="false" #picker></mat-date-range-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="leads-wrap">
      <div class="table-loader" *ngIf="(isLoad$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <div class="info-cards" *ngIf="(statistic$ | async) as list">
        <div class="card" *ngFor="let item of list.leads" [ngClass]="{'item-virtual': item.virtualNumber}">
          <div class="name">{{item.leadsTitle | translate}}</div>
          <div class="card-info">
            <div class="count">{{item.leadsCost | currency : 'ILS' : 'symbol': '1.0-1'}}</div>
            <div *ngIf="(isOneReseller || isBusiness) && item.leadsTitle === EStatisticTitle.gold" class="link"
                 (click)="openDetails()">{{'PRICE_DETAILS' | translate}}</div>
            <span [ngClass]="isOneReseller || isBusiness ? 'alone' : ''"></span>
            <div class="count">
              {{item.leadsNumber | number}}
              <span *ngIf="!!item.uniqueLeadsNumber" class="count-number">({{item.uniqueLeadsNumber | number}})</span>
              <span *ngIf="!!item.nettoLeadsNumber" class="count-number">({{item.nettoLeadsNumber | number}})</span>
              <mat-icon *ngIf="!!item.nettoLeadsNumber" matTooltip="{{'NETTO_LEAD_NOTIFICATION' | translate}}"
                        matTooltipPosition="above"
                        class="info">info
              </mat-icon>
              <!--              <mat-icon *ngIf="!!item.leadsNumber" matTooltip="{{'DUPLICATED_LEAD_NOTIFICATION' | translate}}"-->
<!--                        matTooltipPosition="above"-->
<!--                        class="info">info-->
<!--              </mat-icon>-->
            </div>
          </div>
          <div class="virtual-phone" *ngIf="item.virtualNumber">{{changeVirtualNumber(item.virtualNumber)}}</div>
        </div>
        <div class="card total">
          <div class="name">{{EStatisticTitle.totalLeadsNumber | translate}}</div>
          <div class="card-info">
            <div class="count">
              {{list.total.leadsNumber | number}}
              <span class="count-number">({{list.total.uniqueLeadsNumber | number}})</span>
              <mat-icon *ngIf="!!list.total.uniqueLeadsNumber" matTooltip="{{'DUPLICATED_LEAD_NOTIFICATION' | translate}}"
                        matTooltipPosition="above"
                        class="info">info
              </mat-icon>
            </div>
          </div>
        </div>
        <div class="card total">
          <div class="name">{{EStatisticTitle.totalAmountBeforeVAT | translate}}</div>
          <div class="card-info">
            <div class="count">{{list.total.leadBalanceBeforeVat.toFixed(0) | currency : 'ILS' : 'symbol': '1.0-1'}}
            </div>
          </div>
        </div>
        <div class="card total">
          <div class="name">{{EStatisticTitle.totalAmountAfterVAT | translate}}</div>
          <div class="card-info">
            <div class="count">{{list.total.leadBalanceAfterVat.toFixed(0) | currency : 'ILS' : 'symbol': '1.0-1'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <span class="vat">*{{'THE_DATA_PRESENTED_DOESNT_INCLUDE_VAT' | translate}}</span>-->
  </div>
</div>

<div *ngIf="!(isLoading$ | async)">
  <div class="content-wrapper payments" *ngIf="checkOrdersTableShowing()">
    <div class="content-wrapper-item table-1-wrap">
      <div class="content" *ngIf="(paymentsPerMonthContent$ | async) as payments">
        <div class="content-body table">
          <starter-table [tableMeta]="paymentsPerMonthMeta" [tableData]="sortByMonth(payments.result)"
                         [language]="languageService.mainLanguage.value" (onClick)="openGoldPriceDetails($event)">
          </starter-table>
        </div>
        <div class="total-table monthly">
          <div>{{payments.uniqueTotalAmountVat | currency: 'ILS' : 'symbol': '1.0-1'}}</div>
          <div>{{payments.uniqueTotalAmount | currency: 'ILS' : 'symbol': '1.0-1'}}</div>
        </div>
      </div>
    </div>
    <div class="content-wrapper-item table-2-wrap">
      <div class="content" *ngIf="(ordersTableContent$ | async) as orders">
        <div class="content-body table">
          <starter-table [tableMeta]="orderTableMeta" [tableData]="orders.result"
                         [language]="languageService.mainLanguage.value">
          </starter-table>
        </div>
        <div class="total-table">
          <div>{{'TOTAL' | translate}}</div>
          <div>{{orders.totalGoldPrice | currency: 'ILS' : 'symbol': '1.0-1'}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="content monthly-big">
    <starter-table [tableMeta]="monthlyReport" [tableData]="reportPayments"
                   [language]="languageService.mainLanguage.value"></starter-table>
  </div>
</div>
