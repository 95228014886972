import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';

import { IResponse } from '../api-data/api.interfaces';
import { IRim, IRimParams, IRimUpdated } from '../../modules/state/general-admin/rims/rims.model';

@Injectable({
  providedIn: 'root'
})
export class RimsService {
  readonly PATH = `/rims`;

  constructor(private apiService: ApiService) {
  }

  getRimsList(params?: IRimParams): Observable<IResponse<IRim[]>> {
    return this.apiService.get(`${this.PATH}`, {...params});
  }

  addRim(rim: FormData): Observable<IRim> {
    return this.apiService.post(`${this.PATH}`, rim);
  }

  updateRim(rim: FormData): Observable<IRimUpdated> {
    return this.apiService.put(`${this.PATH}`, rim);
  }

  changeRimVisibility(id: number): Observable<any> {
    return this.apiService.put(`${this.PATH}/${id}/switchstatus`);
  }

  deleteRim(id: number): Observable<any> {
    return this.apiService.delete(`${this.PATH}`, id.toString());
  }
}
