import { Component } from '@angular/core';
import { Routes } from '@angular/router';
import { bannersRoutes } from '../banners-routing.module';

@Component({
  selector: 'app-banners-list',
  templateUrl: './banners-list.component.html',
  styleUrls: ['./banners-list.component.scss']
})
export class BannersListComponent {
  public routesList: Routes = bannersRoutes;
}
