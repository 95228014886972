import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../../../api/notification.service';
import {
  addLocation,
  IDbLocation,
  updateLocation
} from '../../../../../../modules/state/general-admin/criteria/location';
import { EModalMessage } from '../../criteria.constants';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEmptyObj } from '../../../../../../utils/helpers';
import { EModalType } from '../../../../../../modules/shared/interfaces/table.interfaces';
import {
  getLocationError,
  getLocationLoader,
  getLocationSuccessLoader
} from '../../../../../../modules/state/general-admin/criteria/location/location.selector';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent implements OnInit, OnDestroy {
  public locationForm: FormGroup;
  public EModalType = EModalType;
  public destroy$: Subject<void> = new Subject<void>();
  public isLoading$: Observable<boolean>;

  constructor(private dialog: MatDialog,
              private store$: Store,
              private fb: FormBuilder,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: IDbLocation) {
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    if (this.data.modalType === EModalType.add) {
      this.locationForm = this.fb.group({
        name: ['', [Validators.required]]
      });
      return;
    }
    this.locationForm = this.fb.group({
      name: [this.data.name, [Validators.required]],
    });
    this.isLoading$ = this.store$.select(getLocationLoader);
  }

  submit(event: Event): void {
    event.preventDefault();
    if (this.data.modalType === EModalType.add) {
      this.store$.dispatch(addLocation({payload: this.locationForm.value}));
      this.checkRequest(EModalMessage.add);
      return;
    }
    if (this.data.modalType === EModalType.edit) {
      this.store$.dispatch(updateLocation({payload: {...this.locationForm.value, id: this.data.id}}));
    }
    this.checkRequest(EModalMessage.edit);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getLocationError),
      this.store$.select(getLocationSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }
}
