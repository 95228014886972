<div class="content payments">
  <div class="content-head">
    <div class="head-right">
      <h3 class="head-title">
        {{'MANAGE_TWS' | translate}}
      </h3>
      <div class="search-wrap">
        <mat-form-field class="form-element search" appearance="fill">
          <input [formControl]="searchControl" placeholder="{{'SEARCH' | translate}}" matInput type="text" autocomplete="off">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="actions">
      <button class="btn-admin" (click)="openAddDialog(1,EVehicleType.moto)">
        <mat-icon>add</mat-icon>
        {{'ADD_MOTO_TWS' | translate}}
      </button>
      <button class="btn-admin" (click)="openAddDialog(0,EVehicleType.car)">
        <mat-icon>add</mat-icon>
        {{'ADD_CAR_TWS' | translate}}
      </button>
    </div>
  </div>
  <div class="content blog_table" *ngIf="(resellerResponse$ | async) as response">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="response.content"
      [language]="languageService.mainLanguage.value"
      [filterData]="response.filters"
      [isLoadingTable]="!(isTableLoad$ | async)"
      (onClick)="dispatchEvent($event)"
      (filters)="getFilters($event)"
      (onCheck)="changeTWSStatus($event)"
    >
    </starter-table>
    <div *ngIf="response.content && !response.content.length" class="no-result-table">
      {{'NO_DATA_MATCHING_THE_FILTER' | translate}}
    </div>
    <div class="pagination" *ngIf="response.content">
          <span class="pagination-item"
                *ngFor="let page of response.content | paginate:
                { itemsPerPage: response.pageSize,
                 currentPage: response.currentPage,
                 totalItems: response.rowCount }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>

