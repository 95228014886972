import { HttpErrorResponse } from "@angular/common/http";

export interface NotificationData {
  topic: string;
  message: string;
  carMotoTypes: string[];
}

export interface IAdminNotification {
  id: number,
  message: string,
  topic: string,
  createdAt: Date,
  dismissed: boolean
}

export interface NotificationDBData extends NotificationData{
  id: string;
}

export interface AdminNotificationsState {
  notificationList: IAdminNotification[];
  notificationForm: NotificationData;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  isLoading: boolean;
  error: HttpErrorResponse;
}
