import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ISubDBElement, ISubscriptionState } from './subscription.model';
import { HttpErrorResponse } from '@angular/common/http';

export const getSubscriptionState = createFeatureSelector<ISubscriptionState>('subscription');

export const getSubscriptionTable = createSelector(getSubscriptionState,
  (state: ISubscriptionState): ISubDBElement[] => state.content);

export const getSubscriptionSuccessLoader = createSelector(getSubscriptionState,
  (state: ISubscriptionState): boolean => state.isLoadingSuccess);

export const getSubscriptionLoader = createSelector(getSubscriptionState,
  (state: ISubscriptionState): boolean => state.isLoading);

export const getSubscriptionError = createSelector(getSubscriptionState,
  (state: ISubscriptionState): HttpErrorResponse => state.error);
