import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";
import { ISubDBElement, ISubscriptionElement } from './subscription.model';

export const GET_SUBSCRIPTION_DATA = '[SUBSCRIPTION] Get data';
export const GET_SUBSCRIPTION_SUCCESS = '[SUBSCRIPTION] Get data success';
export const GET_SUBSCRIPTION_FAILURE = '[SUBSCRIPTION] Get data failure';

export const ADD_SUBSCRIPTION_ELEMENT = '[SUBSCRIPTION] Add element';
export const ADD_SUBSCRIPTION_ELEMENT_SUCCESS = '[SUBSCRIPTION] Add element success';
export const ADD_SUBSCRIPTION_ELEMENT_FAILURE = '[SUBSCRIPTION] Add element failure';

export const UPDATE_SUBSCRIPTION_ELEMENT = '[SUBSCRIPTION] Update element';
export const UPDATE_SUBSCRIPTION_ELEMENT_SUCCESS = '[SUBSCRIPTION] Update element success';
export const UPDATE_SUBSCRIPTION_ELEMENT_FAILURE = '[SUBSCRIPTION] Update element failure';

export const DELETE_SUBSCRIPTION_ELEMENT = '[SUBSCRIPTION] Delete element';
export const DELETE_SUBSCRIPTION_ELEMENT_SUCCESS = '[SUBSCRIPTION] Delete element success';
export const DELETE_SUBSCRIPTION_ELEMENT_FAILURE = '[SUBSCRIPTION] Delete element failure';

export const getSubscriptionData = createAction(
  GET_SUBSCRIPTION_DATA,
)

export const getSubscriptionDataSuccess = createAction(
  GET_SUBSCRIPTION_SUCCESS,
  props<{ payload: ISubDBElement[] }>()
)

export const getSubscriptionDataFailure = createAction(
  GET_SUBSCRIPTION_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addSubscriptionElement = createAction(
  ADD_SUBSCRIPTION_ELEMENT,
  props<{ payload: ISubscriptionElement }>()
)

export const addSubElementSuccess = createAction(
  ADD_SUBSCRIPTION_ELEMENT_SUCCESS,
  props<{ payload: ISubDBElement }>()
)

export const addSubElementFailure = createAction(
  ADD_SUBSCRIPTION_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateSubscriptionElement = createAction(
  UPDATE_SUBSCRIPTION_ELEMENT,
  props<{ payload: ISubscriptionElement, id: number }>()
)

export const updateSubElementSuccess = createAction(
  UPDATE_SUBSCRIPTION_ELEMENT_SUCCESS,
  props<{ payload: ISubDBElement }>()
)

export const updateSubElementFailure = createAction(
  UPDATE_SUBSCRIPTION_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteSubscriptionElement = createAction(
  DELETE_SUBSCRIPTION_ELEMENT,
  props<{ payload: number }>()
)

export const deleteSubElementSuccess = createAction(
  DELETE_SUBSCRIPTION_ELEMENT_SUCCESS
)

export const deleteSubElementFailure = createAction(
  DELETE_SUBSCRIPTION_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
