import { HttpErrorResponse } from "@angular/common/http";

export interface HeightElement {
  id: number,
  value: string,
  isActive: boolean,
  priority: string,
  additionalValue: string
}

export interface HeightState {
  current: HeightElement;
  deleteId: number | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  content: HeightElement[];
  error: HttpErrorResponse;
}
