import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { EModalType, EVehicleType } from '../../../../../../modules/shared/interfaces/table.interfaces';
import {
  getTireTypeError, getTireTypeLoader,
  getTireTypeSuccessLoader
} from '../../../../../../modules/state/general-admin/criteria/tire-type/tire-type.selector';
import {
  addTireTypeElement,
  deleteTireTypeElement,
  TireTypeElement,
  updateTireTypeElement
} from '../../../../../../modules/state/general-admin/criteria/tire-type';
import { isEmptyObj } from '../../../../../../utils/helpers';
import { NotificationService } from '../../../../../../api/notification.service';
import { ECriteriaControls, EModalMessage } from '../../criteria.constants';

@Component({
  selector: 'app-tires-type-add-dialog',
  templateUrl: './tires-type-add-dialog.component.html',
  styleUrls: ['./tires-type-add-dialog.component.scss']
})
export class TiresTypeAddDialogComponent implements OnInit, OnDestroy {

  public tireTypeForm: FormGroup;
  public EModalType = EModalType;
  public destroy$: Subject<void> = new Subject<void>();
  public dialogTitle: string = EVehicleType.carTitle;
  public vehicleType: string;
  public isLoading$: Observable<boolean>;
  public ECriteriaLabel = ECriteriaControls;

  constructor(private dialog: MatDialog,
              private store$: Store,
              private fb: FormBuilder,
              private alertService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: TireTypeElement) {
  }

  closeAddDialog(): void {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.initForms();
    this.checkData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForms(): void {
    if (this.data.type === EModalType.add) {
      this.tireTypeForm = this.fb.group({
        name: ['', [Validators.required]],
        // priority: ['', [Validators.required]],
        isActive: [false]
      });
      return;
    }
    this.tireTypeForm = this.fb.group({
      name: [this.data.name, [Validators.required]],
      // priority: [this.data.priority, [Validators.required]],
      isActive: [this.data.isActive]
    });
  }

  checkData(): void {
    this.vehicleType = this.data.vehicleType as string;
    if (this.data.vehicleType === EVehicleType.moto) {
      this.dialogTitle = EVehicleType.motoTitle;
    }
    this.isLoading$ = this.store$.select(getTireTypeLoader);
  }

  submit(event: Event): void {
    event.preventDefault();
    if (this.data.type === EModalType.add) {
      this.store$.dispatch(addTireTypeElement({
          payload: {
            ...this.tireTypeForm.value,
            vehicleType: this.data.vehicleType,
            parentTireTypeId: null
          }
        })
      );
      this.checkRequest(EModalMessage.add);
      return;
    }
    if (this.data.type === EModalType.edit) {
      this.store$.dispatch(updateTireTypeElement({
        payload: {
          ...this.tireTypeForm.value,
          vehicleType: this.data.vehicleType,
          parentTireTypeId: this.data.parentTireTypeId,
        },
        id: this.data.id
      }));
      this.checkRequest(EModalMessage.edit);
    }
  }

  deleteItem(item: TireTypeElement): void {
    this.store$.dispatch(deleteTireTypeElement({payload: item}));
    this.checkRequest(EModalMessage.delete);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(getTireTypeError),
      this.store$.select(getTireTypeSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && isEmptyObj(error)) {
            this.dialog.closeAll();
            this.alertService.onSuccess(message);
          }
          if (!loader && !isEmptyObj(error)) this.alertService.onError(error.statusText);
        }
      })
  }
}
