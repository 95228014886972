import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";

import * as BlogActions from "./blog.actions";
import BlogApiService from "../../../../api/general-admin-services/blog.api.service";

@Injectable()
export class BlogEffects {
  constructor(private actions$: Actions,
              private BlogApiService: BlogApiService) {
  }

  getBlogData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.getBlogData),
      switchMap((action) =>
        this.BlogApiService.getBlogData(action.params).pipe(
          map(response => BlogActions.getBlogDataSuccess({payload: response})),
          catchError((err: HttpErrorResponse) =>
            of(BlogActions.getBlogDataFailure({payload: err}))
          )
        )
      )
    )
  )

  createBlogElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.addBlogElement),
      switchMap(action =>
        this.BlogApiService.addBlogElement(action.payload).pipe(
          map(response => BlogActions.addBlogElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(BlogActions.addBlogElementFailure({payload: err})))
        )
      )
    )
  )

  updateBlogElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.updateBlogElement),
      switchMap(action =>
        this.BlogApiService.updateBlogElement(action.payload, action.id).pipe(
          map(response => BlogActions.updateBlogElementSuccess({payload: response})),
          catchError((err: HttpErrorResponse) => of(BlogActions.updateBlogElementFailure({payload: err})))
        )
      )
    )
  )

  deleteBlogElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.deleteBlogElement),
      switchMap(action =>
        this.BlogApiService.deleteBlogElement(action.id).pipe(
          map(() => BlogActions.deleteBlogElementSuccess()),
          catchError((err: HttpErrorResponse) => of(BlogActions.deleteBlogElementFailure({payload: err})))
        )
      )
    )
  )

  changeElementVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.changeElementVisibility),
      switchMap(action =>
        this.BlogApiService.changeVisibility(action.id).pipe(
          map(() => BlogActions.changeElementVisibilitySuccess()),
          catchError((err: HttpErrorResponse) => of(BlogActions.changeElementVisibilityFailure({payload: err})))
        )
      )
    )
  )
}
