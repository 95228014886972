import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../api.service";
import {
  ICriteriaLeads,
  ILead,
  ILeadExpanded,
  ILeadsCount,
  ILeadsParams,
  IStatistic,
  IStatisticParams
} from '../../modules/state/general-admin/leads/leads.model';
import { IResponse } from '../api-data/api.interfaces';
import { leadsCodePath } from '../api-data/api.constants';
import { IMonthlyLeadsListExtended, IOrdersExtended } from '../../modules/state/general-admin/payments/payments.model';

@Injectable({
  providedIn: "root",
})
export default class StatisticsApiService {
  readonly PATH = `/statistic`;
  readonly leadsPath = `/lead/list`;

  constructor(private apiService: ApiService) {
  }

  getStatisticsData(params?: IStatisticParams): Observable<ILeadsCount> {
    return this.apiService.get(`${this.PATH}/leadscount`, {...params});
  }

  getGoldLeadsList(params?: IStatisticParams): Observable<IOrdersExtended> {
    return this.apiService.get(`${this.PATH}/payment/goldleads`, {...params});
  }

  getMonthlyPaymentsLeads(params?: IStatisticParams): Observable<IMonthlyLeadsListExtended> {
    return this.apiService.get(`${this.leadsPath}/monthlysummary`, {...params});
  }

  getPaymentsLeadsCount(params?: IStatisticParams): Observable<any> {
    return this.apiService.get(`${this.PATH}/payment/leadscount`, {...params});
  }

  getLeadsList(dateFrom: string, dateTo: string, params?: ILeadsParams): Observable<IResponse<ILead[]>> {
    return this.apiService.get(`${this.leadsPath}/${dateFrom}/${dateTo}`, {...params});
  }

  exportLeadsStatistic(params: IStatisticParams, fileName: string): Observable<any> {
    return this.apiService.exportFile(`${this.PATH}/leadscount/export`, {...params}, fileName);
  }

  exportLeadsFile(params: IStatisticParams, fileName: string): Observable<any> {
    return this.apiService.exportFile(`${this.leadsPath}/export/${params.dateFrom}/${params.dateTo}`, {...params}, fileName);
  }

  exportPaymentsStatistic(params: IStatisticParams, fileName: string): Observable<any> {
    return this.apiService.exportFile(`${this.PATH}/payment/leadscount/export`, {...params}, fileName);
  }

  getLeadsCriteria(): Observable<ICriteriaLeads[]> {
    return this.apiService.get(leadsCodePath);
  }

  changeLeadTWSStatus(id: number, status: string): Observable<ILeadExpanded> {
    return this.apiService.get(`/lead/${id}/changeleadtwsstatus`, {id, status});
  }

  changeLeadAdminStatus(id: number, status: string): Observable<ILeadExpanded> {
    return this.apiService.get(`/lead/${id}/changeleadadminstatus`, {id, status});
  }

  getStatistic(params?: IStatisticParams): Observable<IStatistic> {
    return this.apiService.get(`${this.PATH}/leadscalculation`, {...params});
  }

  exportStatisticFile(params: IStatisticParams, fileName: string): Observable<any> {
    return this.apiService.exportFile(`${this.PATH}/leadscalculation/export`, {...params}, fileName);
  }
}
