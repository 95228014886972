import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IConnectImageElement, IConnectImageParams } from "../../modules/state/general-admin/connect-image";
import { IResponse } from '../api-data/api.interfaces';

@Injectable({
  providedIn: "root",
})
export default class ConnectImageApiService {
  readonly PATH = `/imageconnection`;

  constructor(private apiService: ApiService) {
  }

  getImageConnectionList(params: IConnectImageParams): Observable<IResponse<IConnectImageElement[]>> {
    return this.apiService.get(`${this.PATH}`, {...params});
  }

  addImageForTires(formData: FormData): Observable<any> {
    return this.apiService.post(`${this.PATH}`, formData);
  }
}
