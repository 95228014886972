import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import {
  getLocationData,
  IDbLocation,
  ILocationParams
} from '../../../../../modules/state/general-admin/criteria/location';
import {
  getLocationLoader,
  getLocationTable
} from '../../../../../modules/state/general-admin/criteria/location/location.selector';
import { TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { LocationTableMeta } from '../criteria.constants';
import { EModalType, ICriteriaItem, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import { LanguageService } from '../../../../../api/language.service';
import { IResponse } from '../../../../../api/api-data/api.interfaces';
import { LocationDialogComponent } from './location-dialog/location-dialog.component';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy {
  public locationResponse$: Observable<IResponse<IDbLocation[]>>;
  public tableMeta: TableMeta[] = LocationTableMeta;
  public modalType: EModalType = EModalType.add;
  public isTableLoad$: Observable<boolean>;
  public currentPage: number = 1;
  public search: string = '';
  public sorts: string = '';
  public searchControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  public openAddDialog(): void {
    this.dialog.open(LocationDialogComponent, {
      panelClass: 'admin-dialog',
      data: {modalType: EModalType.add}
    })
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.loadLocationList();
    this.locationResponse$ = this.store$.select(getLocationTable);
    this.isTableLoad$ = this.store$.select(getLocationLoader);
  }

  dispatchEvent(table: ITableData<ICriteriaItem>): void {
    this.dialog.open(LocationDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...table.data, modalType: EModalType.edit}
    })
  }

  paginate(page: number): void {
    this.currentPage = page;
    this.loadLocationList();
  }

  loadLocationList(): void {
    const payload: ILocationParams = {
      page: this.currentPage,
      sorts: this.sorts,
      search: this.search
    }
    this.store$.dispatch(getLocationData({payload}));
  }

  getSortValue(sort: string): void {
    this.sorts = sort;
    this.loadLocationList();
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$),
      switchMap((value) => {
        this.search = value;
        this.currentPage = 1;
        this.loadLocationList();
        return this.store$.select(getLocationTable);
      })).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
