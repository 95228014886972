import { HttpErrorResponse } from "@angular/common/http";

export interface ISubscriptionElement {
  name: string,
  isActive: boolean,
  priority: string,
  type?:string
}

export interface ISubDBElement extends ISubscriptionElement {
  id: number
}

export interface ISubscriptionState {
  current: ISubscriptionElement,
  deleteId: number | null,
  isLoading: boolean,
  isLoadingSuccess: boolean,
  isLoadingFailure: boolean,
  content: ISubDBElement[],
  error: HttpErrorResponse
}
