<div class="content manufacturer">
  <div class="content-head">
    <h3 class="head-title">
      {{'SUBSCRIPTION' | translate}}
    </h3>
  </div>
  <div class="content-body" *ngIf="(tableContent$ | async) as content">
    <starter-table
      [tableMeta]="tableMeta"
      [tableData]="sortContent(content, sorts)"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [language]="languageService.mainLanguage.value"
      (onClick)="dispatchEvent($event)"
      (sorts)="getSortValue($event)"
    >
    </starter-table>
  </div>
</div>
