import { AbstractControl, ValidatorFn } from '@angular/forms';

export function compareTime(openTime: string, closeTime: string): boolean {
  const open = new Date(`${new Date().toLocaleDateString().split('.').reverse().join('-')} ${openTime}`).getTime();
  const close = new Date(`${new Date().toLocaleDateString().split('.').reverse().join('-')} ${closeTime}`).getTime();
  return close < open;
}

export function timeValidator(type: string, timeError: string, openTime: AbstractControl, closeTime: AbstractControl): ValidatorFn {
  return () => {
    if (openTime.value && !closeTime.value) {
      return {[type]: {closingTime: 'FILL_CLOSING_TIME'}};
    } else if (!openTime.value && closeTime.value) {
      return {[type]: {openingTime: 'FILL_OPENING_TIME'}};
    } else if (openTime.value && closeTime.value && compareTime(openTime.value, closeTime.value)) {
      return {[timeError]: {timeError: 'CLOSING_TIME_CANNOT_BE_LESS_THAN_OPENING'}}
    }
    return null;
  };
}

export function passwordValidator(control: AbstractControl, controlTwo: AbstractControl): ValidatorFn {
  return () => {
    if (control.value !== controlTwo.value)
      return {passwordError: 'VALUE_DOES_NOT_MATCH'};
    return null;
  };
}
