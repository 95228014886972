import { Component, forwardRef, Input } from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatRangeDateSelectionModel
} from '@angular/material/datepicker';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar-range.component.html',
  styleUrls: ['./calendar-range.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarRangeComponent),
      multi: true
    },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel
  ]
})
export class CalendarRangeComponent implements ControlValueAccessor {
  @Input() needMinDate: boolean = true;
  public today = new Date();
  public value: DateRange<Date>;
  public onChange: (p: any) => void = () => {};
  public onTouch: () => void = () => {};
  public minDate: Date = new Date('2023-08-01');

  constructor(private selectionModel: MatRangeDateSelectionModel<Date>,
              private selectionStrategy: DefaultMatCalendarRangeStrategy<Date>) {
  }

  dateRangeChanged(selectedDate: Date): void {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);
    this.selectionModel.updateSelection(newSelection, this);
    this.value = new DateRange<Date>(newSelection.start, newSelection.end);
    // this.onTouch();
    // this.onChange(this.value);
    if (this.selectionModel.isComplete()) {
      this.onTouch();
      this.onChange(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
