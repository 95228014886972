import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { TableMeta } from "starter-shared/src/app/modules/starter-table/interfaces/table.interface";
import { HeightAddDialogComponent } from "./height-add-dialog/height-add-dialog.component";
import { getHeightData, HeightElement } from "../../../../../modules/state/general-admin/criteria/height";
import { EModalType, ICriteriaItem, ITableData } from '../../../../../modules/shared/interfaces/table.interfaces';
import { HeightTableMeta } from '../criteria.constants';
import {
  getHeightLoader,
  getHeightTable
} from '../../../../../modules/state/general-admin/criteria/height/height.selectors';
import { LanguageService } from '../../../../../api/language.service';
import { sortContentByValue } from '../../../../../utils/helpers';

@Component({
  selector: 'app-height',
  templateUrl: './height.component.html',
  styleUrls: ['./height.component.scss']
})
export class HeightComponent implements OnInit, OnDestroy {
  public tableMeta: TableMeta[] = HeightTableMeta;
  public modalType: EModalType = EModalType.add;
  public tableContent$: Observable<HeightElement[]>;
  public isTableLoad$: Observable<boolean>;
  public sorts: string = '';
  public searchControl: FormControl = new FormControl('');
  public destroy$: Subject<void> = new Subject<void>();
  public searchQuery: string = '';
  public sortContent: Function = sortContentByValue;

  constructor(private dialog: MatDialog,
              private store$: Store,
              public languageService: LanguageService) {
  }

  public openAddDialog(): void {
    this.dialog.open(HeightAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {type: EModalType.add}
    })
  }

  ngOnInit(): void {
    this.getData();
    this.getSearchValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getData(): void {
    this.store$.dispatch(getHeightData());
    this.tableContent$ = this.store$.select(getHeightTable);
    this.isTableLoad$ = this.store$.select(getHeightLoader);
  }

  actionDispatch(table: ITableData<ICriteriaItem>): void {
    if (table.action.action === EModalType.edit) {
      this.modalType = EModalType.edit;
    }
    if (table.action.action === EModalType.delete) {
      this.modalType = EModalType.delete
    }
    this.changeCriteria(table.data);
  }

  changeCriteria(item: ICriteriaItem): void {
    this.dialog.open(HeightAddDialogComponent, {
      panelClass: 'admin-dialog',
      data: {...item, type: this.modalType}
    })
  }

  getSortValue(sorts: string): void {
    this.sorts = sorts;
  }

  getSearchValue(): void {
    this.searchControl.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.searchQuery = value;
      })
  }
}
