import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { ILeadStatus, ILeadStatusDB } from '../../modules/state/general-admin/criteria/leads-status';

@Injectable({
  providedIn: 'root'
})
export class LeadStatusService {

  readonly PATH = '/criteria/leadstatus';

  constructor(private apiService: ApiService) {
  }

  getLeadStatusData(): Observable<ILeadStatusDB[]> {
    return this.apiService.get(this.PATH);
  }

  addLeadStatus(element: ILeadStatus): Observable<ILeadStatusDB> {
    return this.apiService.post(this.PATH, element);
  }

  updateLeadStatus(element: ILeadStatus, id: number): Observable<ILeadStatusDB> {
    return this.apiService.put(`${this.PATH}/${id}`, element);
  }

  deleteLeadStatus(id: number): Observable<any> {
    return this.apiService.deleteNumber(this.PATH, id);
  }
}
