import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { LanguageService } from '../../../api/language.service';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { IStatusValue, TableMeta } from 'starter-shared/src/app/modules/starter-table/interfaces/table.interface';
import { goldLeadsTableMeta } from '../../../t4u-admin/pages/pages.constants';
import { IStatisticParams } from '../../../modules/state/general-admin/leads/leads.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IOrdersExtended } from '../../../modules/state/general-admin/payments/payments.model';
import {
  changeDealerShipStatus,
  getGoldLeadsStatistic,
  selectGoldLeadsLoader,
  selectGoldLeadStatistic,
  selectPaymentsError,
  selectPaymentsSuccessLoader
} from '../../../modules/state/general-admin/payments';
import { isEmptyObj } from '../../../utils/helpers';
import { NotificationService } from '../../../api/notification.service';
import { EModalMessage } from '../../../t4u-admin/pages/criteria/components/criteria.constants';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-gold-leads',
  templateUrl: './gold-leads.component.html',
  styleUrls: ['./gold-leads.component.scss']
})
export class GoldLeadsComponent implements OnInit, OnDestroy {
  public today: Date = new Date();
  public dateFilterForm: FormGroup;
  public isTableLoad$: Observable<boolean>;
  public goldLeadsTableMeta: TableMeta[] = goldLeadsTableMeta;
  public goldLeadsContent$: Observable<IOrdersExtended>;
  public apiParams: IStatisticParams;
  public destroy$: Subject<void> = new Subject<void>();
  public userId: string;
  public dateFrom: Date;
  public dateTo: Date;
  public minDate: Date = new Date('2023-08-01');

  constructor(private fb: FormBuilder,
              private auth: AuthService,
              public languageService: LanguageService,
              private alert: NotificationService,
              private router: Router,
              private _adapter: DateAdapter<Date>,
              private store$: Store) {
    this._adapter.setLocale(localStorage.getItem('langCode') || environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.getCurrentMonth();
    this.initForm();
    this.getData();
    this.getFiltersValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCurrentMonth(): void {
    this.userId = this.auth.reseller?.tireResellerId;
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();

    this.dateFrom = new Date(year, month, 1);
    this.dateFrom.setHours(4);
    this.dateTo = new Date();
    this.dateTo.setHours(4);
    this.apiParams = {dateFrom: this.dateFrom.toISOString(), dateTo: this.dateTo.toISOString(), tireResellerId: this.userId};
  }

  initForm(): void {
    this.dateFilterForm = this.fb.group({
      tireResellerId: [this.userId],
      dateFrom: this.dateFrom || [''],
      dateTo: this.dateTo || ['']
    })
  }

  getData(): void {
    this.goldLeadsContent$ = this.store$.select(selectGoldLeadStatistic);
    this.isTableLoad$ = this.store$.select(selectGoldLeadsLoader);
    this.getGoldsLeads(this.apiParams);
  }

  getGoldsLeads(params: IStatisticParams): void {
    this.store$.dispatch(getGoldLeadsStatistic({params}));
  }

  getFiltersValue(): void {
    this.dateFilterForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(1000))
      .subscribe({
        next: (filters) => {
          const params = {...filters};
          delete params.dateFrom;
          delete params.dateTo;
          if (filters.dateFrom) {
            const dateFrom = filters.dateFrom;
            dateFrom.setHours(4);
            params.dateFrom = dateFrom.toISOString();
          }
          if (filters.dateTo) {
            const dateTo = filters.dateTo;
            dateTo.setHours(4);
            params.dateTo = dateTo.toISOString();
          }
          this.apiParams = params;
          this.getGoldsLeads(params);
        }
      })
  }

  changeStatus(event: IStatusValue): void {
    const payload = {id: event.id, status: event.status};
    this.store$.dispatch(changeDealerShipStatus({payload}));
    this.checkRequest(EModalMessage.statusChanged);
  }

  checkRequest(message: string): void {
    combineLatest(
      this.store$.select(selectPaymentsError),
      this.store$.select(selectPaymentsSuccessLoader))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([error, loader]) => {
          if (loader && error && isEmptyObj(error)) {
            this.alert.onSuccess(message);
            this.getGoldsLeads(this.apiParams);
          }
          if (!loader && error && !isEmptyObj(error)) this.alert.onError(error.statusText);
        }
      })
  }
}
