import { Component } from '@angular/core';
import { Routes } from "@angular/router";

import { criteriaRoutes } from './criteria-routing.module';

@Component({
  selector: 'app-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.scss']
})
export class CriteriaComponent {
  public routesList: Routes = criteriaRoutes;
}
