import { createAction, props } from '@ngrx/store';
import { ILeadStatus, ILeadStatusDB } from './leads-status.model';
import { HttpErrorResponse } from '@angular/common/http';

export const GET_LEADS_STATUS_LIST = '[LEADS_STATUS] Get data';
export const GET_LEADS_STATUS_LIST_SUCCESS = '[LEADS_STATUS] Get data success';
export const GET_LEADS_STATUS_LIST_FAILURE = '[LEADS_STATUS] Get data failure';

export const ADD_LEAD_STATUS = '[LEADS_STATUS] Add lead status';
export const ADD_LEAD_STATUS_SUCCESS = '[LEADS_STATUS] Add lead status success';
export const ADD_LEAD_STATUS_FAILURE = '[LEADS_STATUS] Add lead status failure';

export const UPDATE_LEAD_STATUS = '[LEADS_STATUS] Update lead status';
export const UPDATE_LEAD_STATUS_SUCCESS = '[LEADS_STATUS] Update lead status success';
export const UPDATE_LEAD_STATUS_FAILURE = '[LEADS_STATUS] Update lead status failure';

export const DELETE_LEAD_STATUS = '[LEADS_STATUS] Delete lead status';
export const DELETE_LEAD_STATUS_SUCCESS = '[LEADS_STATUS] Delete lead status success';
export const DELETE_LEAD_STATUS_FAILURE = '[LEADS_STATUS] Delete lead status failure';

export const getLeadsStatusList = createAction(
  GET_LEADS_STATUS_LIST,
)

export const getLeadsStatusListSuccess = createAction(
  GET_LEADS_STATUS_LIST_SUCCESS,
  props<{ payload: ILeadStatusDB[] }>()
)

export const getLeadsStatusListFailure = createAction(
  GET_LEADS_STATUS_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addLeadStatus = createAction(
  ADD_LEAD_STATUS,
  props<{ payload: ILeadStatus }>()
)

export const addLeadStatusSuccess = createAction(
  ADD_LEAD_STATUS_SUCCESS,
  props<{ payload: ILeadStatusDB }>()
)

export const addLeadStatusFailure = createAction(
  ADD_LEAD_STATUS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateLeadStatus = createAction(
  UPDATE_LEAD_STATUS,
  props<{ payload: ILeadStatus, id: number }>()
)

export const updateLeadStatusSuccess = createAction(
  UPDATE_LEAD_STATUS_SUCCESS,
  props<{ payload: ILeadStatusDB }>()
)

export const updateLeadStatusFailure = createAction(
  UPDATE_LEAD_STATUS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteLeadStatus = createAction(
  DELETE_LEAD_STATUS,
  props<{ id: number }>()
)

export const deleteLeadStatusSuccess = createAction(
  DELETE_LEAD_STATUS_SUCCESS
)

export const deleteLeadStatusFailure = createAction(
  DELETE_LEAD_STATUS_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
