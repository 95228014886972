import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

import { IResponse } from '../../../../api/api-data/api.interfaces';
import { IRim, IRimParams } from './rims.model';

export const GET_RIMS_LIST = '[RIMS] Get rims list';
export const GET_RIMS_LIST_SUCCESS = '[RIMS] Get rims list success';
export const GET_RIMS_LIST_FAILURE = '[RIMS] Get rims list failure';

export const ADD_RIM = '[RIMS] Add rim';
export const ADD_RIM_SUCCESS = '[RIMS] Add rim success';
export const ADD_RIM_FAILURE = '[RIMS] Add rim failure';

export const UPDATE_RIM = '[RIMS] Update rim';
export const UPDATE_RIM_SUCCESS = '[RIMS] Update rim success';
export const UPDATE_RIM_FAILURE = '[RIMS] Update rim failure';

export const CHANGE_RIM_VISIBILITY = '[RIMS] Change rim visibility';
export const CHANGE_RIM_VISIBILITY_SUCCESS = '[RIMS] Change rim visibility success';
export const CHANGE_RIM_VISIBILITY_FAILURE = '[RIMS] Change rim visibility failure';

export const DELETE_RIM = '[RIMS] Delete rim';
export const DELETE_RIM_SUCCESS = '[RIMS] Delete rim success';
export const DELETE_RIM_FAILURE = '[RIMS] Delete rim failure';

export const getRimsList = createAction(
  GET_RIMS_LIST,
  props<{ params: IRimParams }>()
)

export const getRimsListSuccess = createAction(
  GET_RIMS_LIST_SUCCESS,
  props<{ payload: IResponse<IRim[]> }>()
)

export const getRimsListFailure = createAction(
  GET_RIMS_LIST_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addRim = createAction(
  ADD_RIM,
  props<{ payload: FormData }>()
)

export const addRimSuccess = createAction(
  ADD_RIM_SUCCESS,
  props<{ payload: IRim }>()
)

export const addRimFailure = createAction(
  ADD_RIM_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateRim = createAction(
  UPDATE_RIM,
  props<{ payload: FormData }>()
)

export const updateRimSuccess = createAction(
  UPDATE_RIM_SUCCESS,
  props<{ payload: IRim }>()
)

export const updateRimFailure = createAction(
  UPDATE_RIM_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const changRimVisibility = createAction(
  CHANGE_RIM_VISIBILITY,
  props<{ id: number }>()
)

export const changRimVisibilitySuccess = createAction(
  CHANGE_RIM_VISIBILITY_SUCCESS
)

export const changRimVisibilityFailure = createAction(
  CHANGE_RIM_VISIBILITY_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteRim = createAction(
  DELETE_RIM,
  props<{ id: number }>()
)

export const deleteRimSuccess = createAction(
  DELETE_RIM_SUCCESS,
  props<{ id: number }>()
)

export const deleteRimFailure = createAction(
  DELETE_RIM_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
