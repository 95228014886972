import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { IResponse } from '../api-data/api.interfaces';
import {
  IMotoTireDBData,
  IMotoTireExpanded,
  IMotoTiresParams
} from '../../modules/state/general-admin/manage-moto-tires/manage-moto-tires.model';
import { IInventoryTire } from '../../modules/state/business-user/article-price';

@Injectable({
  providedIn: 'root'
})
export class ManageMotoTireService {
  readonly PATH = `/motorcycletire`;

  constructor(private apiService: ApiService) {
  }

  getMotorcycleTireList(params?: IMotoTiresParams): Observable<IResponse<IMotoTireDBData[]>> {
    return this.apiService.get(`${this.PATH}/list`, {...params});
  }

  addMotoTire(payload: FormData): Observable<IMotoTireExpanded> {
    return this.apiService.post(`${this.PATH}/add`, payload);
  }

  updateMotoTire(payload: FormData): Observable<IMotoTireExpanded> {
    return this.apiService.put(`${this.PATH}/edit`, payload);
  }

  exportMotoTireFile(filters: string, fileName: string): Observable<any> {
    return this.apiService.exportFile(`${this.PATH}/export`, {filters}, fileName);
  }

  changeVisibility(id: number): Observable<IMotoTireExpanded> {
    return this.apiService.put(`${this.PATH}/${id}/switchstatus`);
  }

  addBusinessMotoTire(element: FormData): Observable<IInventoryTire> {
    return this.apiService.post(`${this.PATH}/business/expectedprofit/newtire`, element);
  }
}
