import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getCarsByPlateNumber, IPlateNumber, removeSuitablePlates } from '../../../state/business-user/article-price';
import {
  selectSuitablePlates,
  selectSuitablePlatesLoader
} from '../../../state/business-user/article-price/article-price.selectors';

@Component({
  selector: 'app-plate-search',
  templateUrl: './plate-search.component.html',
  styleUrls: ['./plate-search.component.scss'],
})
export class PlateSearchComponent implements OnInit {

  public suitablePlates$: Observable<IPlateNumber[]>;
  public isPlateNumberCorrect: boolean = false;
  public suitablePlatesLoading$: Observable<boolean>;
  public plateControl: FormControl = new FormControl('');
  public selectedMask: string = '00-000-00';
  public selectedCar: IPlateNumber | null = null;
  @Output() selectCar: EventEmitter<IPlateNumber> = new EventEmitter<IPlateNumber>();

  constructor(private store$: Store) { }

  ngOnInit(): void {
    this.searchByPlate();
    this.suitablePlates$ = this.store$.select(selectSuitablePlates);
    this.suitablePlatesLoading$ = this.store$.select(selectSuitablePlatesLoader);
  }

  searchByPlate(): void {
    this.plateControl.valueChanges.pipe(debounceTime(300)).subscribe({
      next: (value) => {
        if (value && value.toString().length >= 6) {
          this.isPlateNumberCorrect = true;
          this.store$.dispatch(getCarsByPlateNumber({payload: value}));
        } else {
          this.isPlateNumberCorrect = false;
        }
      }
    })
  }

  updateMask(event: any): void {
    this.selectedCar = null;
    const value = event.target.value.split('-').join('');
    if (value.length === 7 && this.selectedMask === '000-00-000') {
      this.selectedMask = '00-000-00';
    } else if (value.length > 6) {
      this.selectedMask = '000-00-000';
    } else if (value.length <= 6) {
      this.selectedMask = '00-000-00';
    }
  }

  selectHandler(plate: IPlateNumber): void {
    this.selectedCar = plate;
    this.plateControl.setValue('', {emitEvent: false});
    this.store$.dispatch(removeSuitablePlates());
    this.selectCar.emit(plate);
  }
}
