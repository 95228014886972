<div class="dialog manufacturer-add-dialog">
  <div class="dialog-title">
    <h3 class="title" *ngIf="data.modalType !== EModalType.delete">
      {{modalTitle | translate}}
    </h3>
    <h3 class="title" *ngIf="data.modalType === EModalType.delete">
      {{'ARE_YOU_SURE_YOU_WANT_DELETE_RIM' | translate}} {{data.articleName}} ?
    </h3>
    <div class="title-actions" *ngIf="data.modalType !== EModalType.delete">
      <mat-icon class="close" (click)="closeAddDialog()">close</mat-icon>
    </div>
  </div>

  <ng-container [ngSwitch]="data.modalType">
    <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="editModal"></ng-container>
    <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="editModal"></ng-container>
    <ng-container *ngSwitchCase="EModalType.delete" [ngTemplateOutlet]="deleteModal"></ng-container>
  </ng-container>
</div>

<ng-template #editModal>
  <mat-dialog-content>
    <div class="dialog-form-wrap">
      <div class="table-loader" *ngIf="!(isParamsLoad$ | async) || (loader$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <form [formGroup]="rimFrom" *ngIf="(isParamsLoad$ | async)">
        <div class="form-group row" *ngIf="(params$ | async) as params">
          <div class="col-6 form-col">
            <app-select formControlName="ManufacturerId" [options]="params.RimManufacturer"
                        [isRequired]="true" [placeholder]="ERimPlaceholder.brand" [label]="ErimLabel.brand"
            ></app-select>
          </div>
          <div class="col-6 form-col">
            <app-select formControlName="RadiusCharacteristicOptionId" [options]="params.RimDiameter"
                        [isRequired]="true" [placeholder]="ERimPlaceholder.diameter" [label]="ErimLabel.diameter"
            ></app-select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-6 form-col">
            <app-input formControlName="ArticleNumber" [isRequired]="true"
                       [placeholder]="ERimPlaceholder.artNumber" [label]="ErimLabel.artNumber"
            ></app-input>
          </div>
          <div class="col-6 form-col">
            <app-input formControlName="ArticleName" [isRequired]="true"
                       [placeholder]="ERimPlaceholder.artName" [label]="ErimLabel.artName"
            ></app-input>
          </div>
          <div class="col-6 form-col">
            <app-input formControlName="BruttoPrice" [isRequired]="true"
                       [isNumber]="true" [placeholder]="ERimPlaceholder.price" [label]="ErimLabel.price"
            ></app-input>
          </div>
        </div>

        <ng-container [ngSwitch]="data.modalType">
          <ng-container *ngSwitchCase="EModalType.add" [ngTemplateOutlet]="imageLoader"></ng-container>
          <ng-container *ngSwitchCase="EModalType.edit" [ngTemplateOutlet]="imageUpdate"></ng-container>
        </ng-container>
        <div class="save-btn">
          <button (click)="submit($event)" class="btn-admin" [disabled]="!rimFrom.valid">
            {{'SAVE' | translate}}
          </button>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #deleteModal>
  <div class="table-loader" *ngIf="(loader$ | async)">
    <mat-spinner></mat-spinner>
  </div>
  <div class="confirmation">
    <button class="btn-admin" (click)="deleteRim()">{{'OK' | translate}}</button>
    <button class="btn-admin cancel" (click)="closeAddDialog()">{{'CANCEL' | translate}}</button>
  </div>
</ng-template>

<ng-template #imageLoader>
  <app-image-uploader [showImage]="true" (uploadImage)="updateImage($event)" [imageSize]="imageSize">
    <ng-container>
      {{'UPLOAD_IMG' | translate}}
    </ng-container>
    <ng-container requirements>
      {{'SUPPORTED_FORMATS_PNG_JPG_MAX_SIZE' | translate}}
    </ng-container>
  </app-image-uploader>
</ng-template>

<ng-template #imageUpdate>
  <app-image-uploader [imgSrc]="data.logoUrl" [showImage]="true" (uploadImage)="updateImage($event)" [imageSize]="imageSize">
    <ng-container>
      {{'UPLOAD_IMG' | translate}}
    </ng-container>
    <ng-container requirements>
      {{'SUPPORTED_FORMATS_PNG_JPG_MAX_SIZE' | translate}}
    </ng-container>
  </app-image-uploader>
</ng-template>
