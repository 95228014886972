<div class="dialog-title" mat-dialog-title>
  <div class="title-main">
    {{ 'ORDER' | translate }}  #{{ data.orderId }}
  </div>
  <div class="notification" *ngIf="notificationText">
    {{notificationText}}
  </div>
  <div class="title-actions">
    <mat-icon class="close" (click)="cancelOrder()">close</mat-icon>
  </div>
</div>
<mat-dialog-content class="dialog-content">
  <div class="table-loader" *ngIf="(orderLoader$ | async)">
    <mat-spinner></mat-spinner>
  </div>
  <div class="dialog-form-wrap">
    <div [formGroup]="orderForm">
      <div class="form-group row">
        <div class="col-6 form-col">
          <app-input [label]="EOrderLabel.customerName"
                     [isDisabled]="true"
                     [disabled]="true"
                     [placeholder]="EOrderLabel.customerName"
                     [isRequired]="true" formControlName="customerName"></app-input>
        </div>
        <div class="col-6 form-col phone-input">
          <app-input [label]="EOrderLabel.customerPhone"
                     [placeholder]="EOrderLabel.customerPhone"
                     [removePhoneCode]="true"
                     [isDisabled]="true"
                     [isPhone]="true"
                     [isRequired]="true" formControlName="customerPhone"></app-input>
        </div>
      </div>
      <div formArrayName="products">
        <div class="form-group row" *ngFor="let product of products.controls; let i = index" [formGroupName]="i">
          <div class="col-2 btn-col form-col" *ngIf="!disabledFields">
            <button class="btn-admin" (click)="openTiresDialog(false,i)">{{ 'EDIT' | translate }}</button>
          </div>
          <div class="col-2 form-col">
            <app-input [label]="EOrderLabel.price"
                       [isDisabled]="disabledFields"
                       formControlName="price"></app-input>
          </div>
          <div class="col-5 form-col">
            <app-input [label]="EOrderLabel.tireName"
                       [placeholder]="EOrderLabel.tireName"
                       [isDisabled]="disabledFields"
                       [isRequired]="true" formControlName="name"></app-input>
          </div>
          <div class="col-2 form-col">
            <app-input [isNumber]="true" [label]="EOrderLabel.quantity" [placeholder]="EOrderLabel.quantity"
                       [isDisabled]="disabledFields"
                       formControlName="quantity"></app-input>
          </div>
          <div class="col-1 form-col delete-box" *ngIf="!disabledFields">
            <div class="delete-icon">
              <mat-icon svgIcon="delete" (click)="removeTire(i)"></mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="adding-btn col-2 btn-col form-col" *ngIf="!disabledFields">
        <button class="btn-admin" (click)="openTiresDialog(true)">{{ 'ADD_TIRE' | translate }}</button>
      </div>
      <div class="form-group row">
        <div class="col-6 form-col">
          <app-input [label]="EOrderLabel.totalAmount" [isDisabled]="true"
                     formControlName="totalAmount"></app-input>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button *ngIf="acceptCharging && !disabledFields" [disabled]="!orderForm.valid" class="btn-admin"
            (click)="chargeOrder()">{{ 'CHARGE' | translate }}
    </button>
    <button *ngIf="editingOrderSum && !disabledFields" class="btn-admin"
            (click)="editOrder(true)">{{ 'SEND_PAYMENT_LINK' | translate }}
    </button>
    <button *ngIf="editingInfoWithoutSumChanged && !disabledFields" [disabled]="!orderForm.valid" class="btn-admin"
            (click)="editOrder(false, true)">{{ 'SAVE' | translate }}
    </button>
    <button *ngIf="editingInfoWithoutSumChanged && !disabledFields" class="btn-admin cancel-btn"
            (click)="cancelOrder()">{{ 'CANCEL' | translate }}
    </button>
    <button *ngIf="disabledFields && !canceledPayment" class="btn-admin cancel-btn"
            (click)="cancelPaymentOrder()">{{ 'CANCEL' | translate }}
    </button>
  </div>
</mat-dialog-content>


<ng-template #notificationDialog>
  <div class="dialog">
    <div class="dialog-title">
      <h3 class="title">
        {{ 'EXIT_WITHOUT_SAVING_CONFIRMATION' | translate }}
      </h3>
    </div>
    <mat-dialog-content>
      <div class="confirmation">
        <button class="btn-admin" (click)=" closeAll()">{{ 'OK' | translate }}</button>
        <button class="btn-admin cancel-btn" (click)="notificationDialogRef.close();">{{ 'CANCEL' | translate }}
        </button>
      </div>
    </mat-dialog-content>
  </div>
</ng-template>

<ng-template #chargeDialog>
  <div class="dialog">
    <mat-dialog-content>

      <div class="charge">
        <iframe width="100%"
                height="600"
                id="payment-iframe"
                [src]="paymentLink"
                frameborder="0">
        </iframe>

        <div class="payment-images">
          <div class="image" *ngFor="let img of paymentImages">
            <img [src]="img.src" [alt]="img.alt">
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</ng-template>
