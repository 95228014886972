import { HttpErrorResponse } from "@angular/common/http";

export interface DiameterElement {
  id: number;
  value: string;
  isActive: boolean;
  priority: string;
}

export interface DiameterState {
  current: DiameterElement;
  deleteId: number | null;
  result: any;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  content: DiameterElement[];
  error: HttpErrorResponse;
}
