<p>styleguide works!</p>


<button  class="btn-admin">{{'CLICK_ME' | translate}}</button>
<button  class="btn-admin btn-white">{{'CLICK_ME' | translate}}</button>


<a href="/"  class="btn-admin">{{'CLICK_ME' | translate}}</a>
<a  href="/" class="btn-admin btn-white">{{'CLICK_ME' | translate}}</a>
<br><br><br><br>
<div style="padding: 20px; background: white">
  <mat-form-field class="form-element" appearance="fill">
    <input placeholder="test" matInput type="text" autocomplete="off">
  </mat-form-field>
</div>

<div style="padding: 20px; background: white">
  <mat-form-field class="form-element" appearance="fill">
    <textarea placeholder="test" matInput type="text" autocomplete="off"></textarea>
  </mat-form-field>
</div>

<div style="padding: 20px; background: white">
  <mat-form-field class="form-element" appearance="fill">
    <input disabled placeholder="test" matInput type="text" autocomplete="off">
  </mat-form-field>
</div>

<div style="padding: 20px; background: white">
  <mat-form-field class="form-element" appearance="fill">
    <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
      {{'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | translate}}
    </mat-error>
    <mat-error *ngIf="emailFormControl.hasError('required')">
      {{'EMAIL_IS_REQUIRED' | translate}}
    </mat-error>
  </mat-form-field>
</div>

<div style="padding: 20px; background: white">
  <mat-form-field  class="form-element" appearance="fill">

    <mat-select placeholder="Select food">
      <mat-option *ngFor="let food of foods" [value]="food.value">
        {{food.viewValue}}
      </mat-option>
      <mat-option *ngFor="let food of foods" [value]="food.value">
        {{food.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div style="padding: 20px; background: white">
  <mat-checkbox class="form-checkbox">{{'CHECKED' | translate}}</mat-checkbox>

</div>
<div style="padding: 20px; background: white">

  <mat-radio-button class="form-radio" value="after">{{'AFTER' | translate}}</mat-radio-button>

</div>


