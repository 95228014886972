import { createAction, props } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";

import { LoginResponse } from "../interfaces/auth.interface";

export enum UserActionsTypes {
  UserLogin = '[User] Login',
  UserLoginSuccess = '[User] Login Success',
  UserLoginFailed = '[User] Login Failed',
  UserLogout = '[User] Logout'
}

export const UserLogin = createAction(
  UserActionsTypes.UserLogin,
  props<{ email: string, password: string }>()
);

export const UserLoginSuccess = createAction(
  UserActionsTypes.UserLoginSuccess,
  props<{ payload: LoginResponse }>()
);

export const UserLoginFailed = createAction(
  UserActionsTypes.UserLoginFailed,
  props<{ payload: HttpErrorResponse }>()
);

export const UserLogout = createAction(
  UserActionsTypes.UserLogout,
  props<{ genAdmin: boolean }>()
);

