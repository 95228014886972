<div class="content blog_table">
  <div class="page-head-wrap">
    <div class="content-head">
      <div class="head-right">
        <h3 class="head-title">
          {{mainTitle | translate}}
        </h3>
        <div class="search-wrap">
          <mat-form-field class="form-element search" appearance="fill">
            <input [formControl]="searchControl" placeholder="{{'SEARCH' | translate}}" matInput type="text" autocomplete="off">
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="actions">
        <button (click)="resetAllFilters()" class="btn-admin btn-white export-btn">
          {{'RETURN_TO_DEFAULT' | translate}}
        </button>
        <button (click)="getINVOnlyTires()" class="btn-admin" [ngClass]="isOnlyINV ? '' : 'white'">
          {{'INVENTORY_ONLY' | translate}}
        </button>
        <button (click)="getINVNotDisplayTires()" class="btn-admin" [ngClass]="isINVNotDisplay ? '' : 'white'">
          {{'LIMITED_QUANTITY' | translate}}
        </button>
        <button [ngClass]="isOnlyINV ? 'hidden' : ''" (click)="resetInventory()" class="btn-admin btn-white export-btn">
          <mat-icon>restart_alt</mat-icon>
          {{'RESET_INVENTORY' | translate}}
        </button>
        <button class="btn-admin" (click)="openAddDialog()">
          <mat-icon>add</mat-icon>
          {{'ADD_TIRE' | translate}}
        </button>
      </div>
    </div>
    <div class="filters-form">
      <app-filter-selects (filtersChange)="getHeadFilters($event)" [filters]="(inventoryList$ | async)?.filters"></app-filter-selects>
    </div>
    <div class="filters-chips">
      <app-filter-chips (filterToRemove)="removeFilter($event)" [filters]="chipsValue"></app-filter-chips>
    </div>
  </div>
  <div class="content blog_table" *ngIf="(inventoryList$ | async) as tiresList">
    <starter-table
      [tableMeta]="inventoryTableMeta"
      [tableData]="tiresList.content"
      [language]="languageService.mainLanguage.value"
      [filterData]="tiresList.filters"
      [isLoadingTable]="!(isTableLoad$ | async)"
      [clearSelection]="clearSelection"
      (onClick)="actionDispatch($event)"
      (filters)="getTableFilters($event)"
      (selectValues)="createChips($event)"
      (sorts)="sortData($event)"
      (checkboxColumn)="getCheckedTires($event)"
      [columnFormValue]="formValue"
    >
    </starter-table>
    <div *ngIf="tiresList.content && !tiresList.content.length" class="no-result-table">
      {{'NO_DATA_MATCHING_THE_FILTER' | translate}}
    </div>
    <div class="pagination" *ngIf="tiresList.content">
          <span class="pagination-item"
                *ngFor="let page of tiresList.content | paginate:
                { itemsPerPage: tiresList.pageSize,
                 currentPage: tiresList.currentPage,
                 totalItems: tiresList.rowCount }"></span>
      <pagination-controls [previousLabel]="'PREVIOUS' | translate"
                           [nextLabel]="'NEXT' | translate"
                           (pageChange)="paginate($event)"></pagination-controls>
    </div>
  </div>
</div>
