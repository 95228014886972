import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

import { SpeedCodeElement } from "./speed-code.model";

export const GET_SPEED_CODE_DATA = '[SPEED_CODE] Get data';
export const GET_SPEED_CODE_SUCCESS = '[SPEED_CODE] Get data success';
export const GET_SPEED_CODE_FAILURE = '[SPEED_CODE] Get data failure';

export const ADD_SPEED_CODE_ELEMENT = '[SPEED_CODE] Add element';
export const ADD_SPEED_CODE_ELEMENT_SUCCESS = '[SPEED_CODE] Add element success';
export const ADD_SPEED_CODE_ELEMENT_FAILURE = '[SPEED_CODE] Add element failure';
export const UPDATE_SPEED_CODE_ELEMENT = '[SPEED_CODE] Update element';
export const UPDATE_SPEED_CODE_ELEMENT_SUCCESS = '[SPEED_CODE] Update element success';
export const UPDATE_SPEED_CODE_ELEMENT_FAILURE = '[SPEED_CODE] Update element failure';
export const DELETE_SPEED_CODE_ELEMENT = '[SPEED_CODE] Delete element';
export const DELETE_SPEED_CODE_ELEMENT_SUCCESS = '[SPEED_CODE] Delete element success';
export const DELETE_SPEED_CODE_ELEMENT_FAILURE = '[SPEED_CODE] Delete element failure';

export const getSpeedCodeData = createAction(
  GET_SPEED_CODE_DATA,
)

export const getSpeedCodeDataSuccess = createAction(
  GET_SPEED_CODE_SUCCESS,
  props<{ payload: SpeedCodeElement[] }>()
)

export const getSpeedCodeDataFailure = createAction(
  GET_SPEED_CODE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const addSpeedCodeElement = createAction(
  ADD_SPEED_CODE_ELEMENT,
  props<{ payload: SpeedCodeElement }>()
)

export const addSpeedCodeElementSuccess = createAction(
  ADD_SPEED_CODE_ELEMENT_SUCCESS,
  props<{ payload: SpeedCodeElement }>()
)

export const addSpeedCodeElementFailure = createAction(
  ADD_SPEED_CODE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const updateSpeedCodeElement = createAction(
  UPDATE_SPEED_CODE_ELEMENT,
  props<{ payload: SpeedCodeElement, id: number }>()
)

export const updateSpeedCodeElementSuccess = createAction(
  UPDATE_SPEED_CODE_ELEMENT_SUCCESS,
  props<{ payload: SpeedCodeElement }>()
)

export const updateSpeedCodeElementFailure = createAction(
  UPDATE_SPEED_CODE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)

export const deleteSpeedCodeElement = createAction(
  DELETE_SPEED_CODE_ELEMENT,
  props<{ payload: number }>()
)

export const deleteSpeedCodeElementSuccess = createAction(
  DELETE_SPEED_CODE_ELEMENT_SUCCESS
)

export const deleteSpeedCodeElementFailure = createAction(
  DELETE_SPEED_CODE_ELEMENT_FAILURE,
  props<{ payload: HttpErrorResponse }>()
)
